import { jsx as _jsx } from "react/jsx-runtime";
import { Fragment, useState } from 'react';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';
import styles from './QuickEditButtons.module.scss';
const QuickEditButtons = ({ initGroup, actions, className, }) => {
    var _a;
    const [group, setGroup] = useState(initGroup || Object.keys(actions)[0]);
    const array = (_a = actions[group]) === null || _a === void 0 ? void 0 : _a.call(actions, { setActionsGroup: setGroup });
    return (_jsx("div", Object.assign({ className: classNames(styles.wrapper, className) }, { children: Array.isArray(array) &&
            array.map((button, index) => _jsx(Fragment, { children: button }, index)) })));
};
export default observer(QuickEditButtons);
