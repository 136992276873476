import { makeAutoObservable } from 'mobx';
import _ from 'lodash';
import { ENUMS_ENCOUNTER_STATUSES } from 'ziphy-web-shared/basic/entities/encounters';
import { getEnumObjectValue } from 'ziphy-web-shared/basic/helpers';
import ExtractorBase from '../extractors/Base';
import { prepareMeta, prepareValuePeriod } from '../fhir.converters';
import { getCodingObjects, getExtensionObjects, getIdentifierObjects } from '../fhir.helpers';
import { joinArrayWithObjectToString, joinValuesToString } from '../fhir.utils';
class ExtractorEncounter {
    constructor(resource) {
        this.base = new ExtractorBase(this);
        this.base.SET(resource);
        makeAutoObservable(this);
    }
    get resource() {
        return this.base.resource;
    }
    /*
     * RESOURCE - Minimally prepared resource data, prepareConverters, valueObjects, etc.
     */
    get extensions() {
        return getExtensionObjects(this.resource.extension);
    }
    get identifiers() {
        return getIdentifierObjects(this.resource.identifier);
    }
    get serviceTypes() {
        return getCodingObjects(this.resource.serviceType);
    }
    get class() {
        return getCodingObjects(this.resource.class).first();
    }
    get appointments() {
        return _.map(this.resource.appointment, (x) => this.base.getChildExtractor(x));
    }
    // other in the FHIR - Group
    get subject() {
        return this.base.getChildExtractor(this.resource.subject);
    }
    // other in the FHIR - ImmunizationRecommendation
    get reasonReferences() {
        return _.map(this.resource.reasonReference, (x) => this.base.getChildExtractor(x));
    }
    get serviceProvider() {
        return this.base.getChildExtractor(this.resource.serviceProvider);
    }
    get locations() {
        return _.map(this.resource.location, (x) => ({
            location: this.base.getChildExtractor(x.location),
            status: x.status,
            physicalType: getCodingObjects(x.physicalType).first(),
            period: prepareValuePeriod(x.period),
        }));
    }
    get period() {
        return prepareValuePeriod(this.resource.period);
    }
    get status() {
        return this.resource.status;
    }
    get meta() {
        return prepareMeta(this.resource.meta);
    }
    /*
     * READ - PREPARED Values
     */
    get zid() {
        return this.identifiers.first({ config: 'zid' });
    }
    get appointment() {
        return this.appointments[0];
    }
    get location() {
        return this.locations.find((x) => x.status === 'active') || _.last(this.locations);
    }
    get serviceType() {
        return this.serviceTypes.first();
    }
    /*
     * STRING Values
     */
    get asString() {
        var _a, _b, _c, _d, _e;
        const tmp = {
            zid: (_a = this.zid) === null || _a === void 0 ? void 0 : _a.value,
            reasonReferences: joinArrayWithObjectToString(this.reasonReferences, 'asString.full', '; '),
            status: getEnumObjectValue(this.status, ENUMS_ENCOUNTER_STATUSES).value,
            address: (_d = (_c = (_b = this.location) === null || _b === void 0 ? void 0 : _b.location) === null || _c === void 0 ? void 0 : _c.address) === null || _d === void 0 ? void 0 : _d.asString,
            serviceProvider: (_e = this.serviceProvider) === null || _e === void 0 ? void 0 : _e.asString,
            full: '',
        };
        tmp.full = joinValuesToString([], ' • ');
        return tmp;
    }
}
export default ExtractorEncounter;
