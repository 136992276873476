import { makeAutoObservable } from 'mobx';
import _ from 'lodash';
import { getObservationSubType } from '../extractor.helpers';
import ExtractorBase from '../extractors/Base';
import { prepareMeta, prepareValueDateTime } from '../fhir.converters';
import { getCodingObjects, getExtensionObjects, getIdentifierObjects, getObservationValues, } from '../fhir.helpers';
import { joinArrayWithObjectToString, joinValuesToString } from '../fhir.utils';
class ExtractorObservation {
    constructor(resource) {
        this.base = new ExtractorBase(this);
        this.base.SET(resource);
        makeAutoObservable(this);
    }
    get resource() {
        return this.base.resource;
    }
    get subType() {
        return getObservationSubType(this);
    }
    /*
     * RESOURCE - Minimally prepared resource data, prepareConverters, valueObjects, etc.
     */
    get extensions() {
        return getExtensionObjects(this.resource.extension);
    }
    get identifiers() {
        return getIdentifierObjects(this.resource.identifier);
    }
    get categories() {
        return getCodingObjects(this.resource.category);
    }
    get codes() {
        return getCodingObjects(this.resource.code);
    }
    // other in the FHIR - Group | Device
    get subject() {
        return this.base.getChildExtractor(this.resource.subject);
    }
    // other in the FHIR - CareTeam
    get performers() {
        return _.map(this.resource.performer, (x) => this.base.getChildExtractor(x));
    }
    get effectiveDateTime() {
        return prepareValueDateTime(this.resource.effectiveDateTime);
    }
    get meta() {
        return prepareMeta(this.resource.meta);
    }
    /*
     * READ - PREPARED Values
     */
    get zid() {
        return this.identifiers.first({ config: 'zid' });
    }
    get codeObj() {
        return this.codes.first();
    }
    get performer() {
        return _.last(this.performers);
    }
    get values() {
        return getObservationValues(this.resource);
    }
    /*
     * STRING Values
     */
    get asString() {
        var _a;
        const tmp = {
            codeDisplay: (_a = this.codeObj) === null || _a === void 0 ? void 0 : _a.display,
            values: joinArrayWithObjectToString(this.values, 'asString', ', '),
            full: '',
        };
        tmp.full = joinValuesToString([tmp.codeDisplay, tmp.values], ' • ');
        return tmp;
    }
}
export default ExtractorObservation;
