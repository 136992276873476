import i18n from 'i18next';
import { humanize } from 'ziphy-web-shared/basic/helpers';
import { ENUMS_ACTOR_ROLE } from './enums/base';
import { IExtractorObservationSubType, IExtractorServiceRequestSubType, } from './extractor.types';
import ExtractorLocation from './extractors/Location';
import ExtractorOrganization from './extractors/Organization';
import ExtractorPatient from './extractors/Patient';
import ExtractorPractitioner from './extractors/Practitioner';
import ExtractorPractitionerRole from './extractors/PractitionerRole';
import ExtractorRelatedPerson from './extractors/RelatedPerson';
import { getCodingObjects, getExtensionObjects } from './fhir.helpers';
export function i18textOrHumanized(tKey, value) {
    return i18n.exists(tKey) ? i18n.t(tKey) : humanize(value || tKey, true);
}
export function getSubjectRole(extractor) {
    var _a, _b;
    if (extractor instanceof ExtractorPatient) {
        return {
            code: ENUMS_ACTOR_ROLE.patient.id,
            label: i18n.t(ENUMS_ACTOR_ROLE.patient.tKey),
        };
    }
    else if (extractor instanceof ExtractorRelatedPerson) {
        return {
            code: ENUMS_ACTOR_ROLE.related_person.id,
            label: ((_a = extractor.relationship) === null || _a === void 0 ? void 0 : _a.display) || i18n.t(ENUMS_ACTOR_ROLE.related_person.tKey),
        };
    }
    else if (extractor instanceof ExtractorPractitionerRole) {
        const value = ENUMS_ACTOR_ROLE[(_b = extractor.codeObj) === null || _b === void 0 ? void 0 : _b.display] || ENUMS_ACTOR_ROLE.practitioner;
        return {
            code: value.id,
            label: i18n.t(value.tKey),
        };
    }
    else if (extractor instanceof ExtractorPractitioner) {
        return {
            code: ENUMS_ACTOR_ROLE.practitioner.id,
            label: i18n.t(ENUMS_ACTOR_ROLE.practitioner.tKey),
        };
    }
    else if (extractor instanceof ExtractorOrganization) {
        return {
            code: ENUMS_ACTOR_ROLE.organization.id,
            label: i18n.t(ENUMS_ACTOR_ROLE.organization.tKey),
        };
    }
    else if (extractor instanceof ExtractorLocation) {
        return {
            code: '',
            label: '',
        };
    }
}
export function getServiceRequestSubType(r) {
    const categories = 'categories' in r ? r.categories : getCodingObjects(r.category);
    const extensions = 'extensions' in r ? r.extensions : getExtensionObjects(r.extension);
    const subtype = extensions.first('resourceSubtype');
    if ((subtype === null || subtype === void 0 ? void 0 : subtype.value) === 'lab' || categories.first('categoryServiceRequestLab')) {
        return IExtractorServiceRequestSubType.LAB;
    }
    if ((subtype === null || subtype === void 0 ? void 0 : subtype.value) === 'referral' || categories.first('categoryServiceRequestReferral')) {
        return IExtractorServiceRequestSubType.REFERRAL;
    }
    if ((subtype === null || subtype === void 0 ? void 0 : subtype.value) === 'order') {
        return IExtractorServiceRequestSubType.ORDER;
    }
}
export function getObservationSubType(r) {
    const codes = 'codes' in r ? r.codes : getCodingObjects(r.code);
    const extensions = 'extensions' in r ? r.extensions : getExtensionObjects(r.extension);
    const subtype = extensions.first('resourceSubtype');
    if ((subtype === null || subtype === void 0 ? void 0 : subtype.value) === 'hpi' || codes.first('observationHpiNarrativeCode')) {
        return IExtractorObservationSubType.HPI;
    }
    if (codes.first(['observationExaminationPhysicalFindingsNarrativeCode', { text: 'examination' }])) {
        return IExtractorObservationSubType.EXAMINATION;
    }
    return IExtractorObservationSubType.OBSERVATION;
}
