import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import _ from 'lodash';
import Icon from 'ziphy-web-shared/basic/assets/images/Icon';
import { getStylesMode } from 'ziphy-web-shared/basic/helpers';
import styles from './MultiSelectButton.module.scss';
const MultiSelectButton = ({ onOpen, onClear, selection = [], visibleCount, multiSelect, allOption, mode, label, placeholder, getSelected, clear, hasSelection, isOpen, isInvalid, readonly, innerClassNames, }) => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(isOpen);
    useEffect(() => {
        if (isOpen !== open) {
            setOpen(isOpen);
        }
    }, [open, isOpen]);
    const toggle = () => {
        setOpen(!open);
        onOpen(!open);
    };
    function handleClear() {
        onClear([]);
        setOpen(false);
        onOpen(false);
    }
    const multiselectPreview = useMemo(() => {
        if (selection.length <= visibleCount) {
            return getSelected;
        }
        else if (selection.length === 0 && allOption) {
            return allOption;
        }
        else if (selection.length === 0 && placeholder) {
            return placeholder;
        }
        else if (selection.length === 0 && !allOption && !placeholder) {
            return '';
        }
        else {
            return t('label.selected_preview', { selectedCount: selection.length });
        }
    }, [t, placeholder, allOption, selection, getSelected, visibleCount]);
    const _mode = _.isArray(mode) ? mode : [mode];
    return (_jsxs(_Fragment, { children: [(_mode.indexOf('sm') !== -1 || label || clear) && (_jsxs("div", Object.assign({ className: styles.topBar }, { children: [_mode.indexOf('sm') !== -1 && label && _jsx("div", Object.assign({ className: styles.topBarLabel }, { children: label })), clear && hasSelection && (_jsx("div", Object.assign({ className: styles.topBarClear, onClick: () => handleClear() }, { children: t('btn.clear') })))] }))), _jsxs("div", Object.assign({ className: classNames(styles.multiselect, innerClassNames, _mode && getStylesMode(mode, styles), open && styles.multiselectOpen, isInvalid && styles.multiselectError, readonly && styles.multiselectReadonly, 'multiselect'), onClick: toggle }, { children: [_jsxs("div", Object.assign({ className: classNames(styles.multiselectPreview, 'multiselect-preview') }, { children: [_mode.indexOf('lg') !== -1 && _jsx("div", Object.assign({ className: styles.multiselectLabel }, { children: label })), _jsx("div", Object.assign({ className: classNames(styles.multiselectSelected, !hasSelection && placeholder && styles.multiselectPlaceholder, _mode.indexOf('fw-400') !== -1 && styles.fw400) }, { children: multiSelect ? multiselectPreview : getSelected }))] })), _jsx("div", Object.assign({ className: classNames(styles.multiselectArrow, open && styles.multiselectArrowOpen) }, { children: _jsx(Icon, { type: 'next' }) }))] }))] }));
};
export default observer(MultiSelectButton);
