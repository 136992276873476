var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import _ from 'lodash';
import { isAbsoluteEmpty } from 'ziphy-web-shared/basic/helpers';
import { getPhoneNumberDetails as __getPhoneNumberDetails } from '@library/phoneNumber/PhoneNumber';
import { mediaAuthService as __mediaAuthService } from '@ps/services/mediaAuthService';
export const getPhoneNumberDetails = __getPhoneNumberDetails;
export const getUrlWithAuth = (url) => __mediaAuthService().getUrlWithAuth(url);
// todo: move to core/helpers
export function joinValuesToString(values, separator, unique = false) {
    let result = _.map(values, (x) => {
        if (Array.isArray(x)) {
            const isEmptyValue = x.some((subValue) => {
                return (_.isNil(subValue) ||
                    typeof subValue === 'boolean' ||
                    (typeof subValue === 'string' && subValue.trim() === ''));
            });
            return isEmptyValue ? '' : x.join('');
        }
        if (typeof x === 'string' && x.trim() !== '') {
            return x;
        }
        else if (typeof x === 'number') {
            return x.toString();
        }
        return '';
    }).filter((x) => x !== '');
    if (unique) {
        const lowerCaseSet = new Set();
        result = result.filter((x) => {
            const lowerCaseItem = x.toLowerCase();
            if (lowerCaseSet.has(lowerCaseItem)) {
                return false;
            }
            lowerCaseSet.add(lowerCaseItem);
            return true;
        });
    }
    return result.join(separator);
}
export function joinArrayWithObjectToString(values, path, separator) {
    let result = _.map(values, (item) => _.get(item, path));
    return result.filter((x) => !isAbsoluteEmpty(x)).join(separator);
}
// todo: move to core
function matchValues(value, comparator = 'eq', criteria) {
    let tmp;
    if (Array.isArray(criteria)) {
        criteria = criteria.slice().sort();
    }
    if (Array.isArray(value)) {
        value = value.slice().sort();
    }
    switch (comparator.toLowerCase()) {
        case 'eq':
            return _.isEqual(value, criteria);
        case 'ne':
            return !_.isEqual(value, criteria);
        case 'lt':
            return value < criteria;
        case 'lte':
            return value <= criteria;
        case 'gt':
            return value > criteria;
        case 'gte':
            return value >= criteria;
        case 'in':
            tmp = Array.isArray(criteria) ? criteria : [criteria];
            return tmp.every((x) => value === null || value === void 0 ? void 0 : value.includes(x));
        case 'nin':
            tmp = Array.isArray(criteria) ? criteria : [criteria];
            return tmp.every((x) => !(value === null || value === void 0 ? void 0 : value.includes(x)));
        case 'sin': // some in
            tmp = Array.isArray(criteria) ? criteria : [criteria];
            return tmp.some((x) => value === null || value === void 0 ? void 0 : value.includes(x));
        case 'snin': // some nin
            tmp = Array.isArray(criteria) ? criteria : [criteria];
            return tmp.some((x) => !(value === null || value === void 0 ? void 0 : value.includes(x)));
        case 'exists':
            return !(value === undefined || value === '' || value === null);
        case 'nexists':
            return value === undefined || value === '' || value === null;
    }
    return false;
}
export function findPreparedObject(data, params = [], useFirst, settings) {
    const _matchByObject = (valObj, filterParams) => {
        const restParams = __rest(filterParams, []);
        return _.reduce(_.omitBy(restParams, (x, k) => k.includes('Variant')), (result, criteria, key) => {
            if (!result)
                return false;
            let comparator;
            if (Array.isArray(criteria)) {
                comparator = criteria[0];
                criteria = criteria[1];
            }
            if ((settings === null || settings === void 0 ? void 0 : settings[key]) && criteria !== undefined) {
                const config = settings[key];
                const filteredItems = _.filter(config.data, (x) => {
                    const filterByKey = matchValues(x.key, comparator, criteria);
                    let filterByVariant = true;
                    let variantCriteria = restParams[key + 'Variant'];
                    if (variantCriteria) {
                        if (Array.isArray(variantCriteria)) {
                            comparator = variantCriteria[0];
                            variantCriteria = variantCriteria[1];
                        }
                        filterByVariant = matchValues(x.variant, comparator, variantCriteria);
                    }
                    return filterByKey && filterByVariant;
                });
                return _.some(filteredItems, (item) => {
                    return _.every(config.additionalConditions, ([valKey, comparator, criteriaKey]) => {
                        const val = _.get(valObj, valKey);
                        const criteria = _.get(item, criteriaKey);
                        return matchValues(val, comparator, criteria);
                    });
                });
            }
            return matchValues(valObj[key], comparator, criteria);
        }, true);
    };
    if (!Array.isArray(params)) {
        params = [params];
    }
    params = params.filter((x) => !_.isEmpty(x));
    let result = [];
    const indexMap = new Set();
    if (_.isEmpty(params)) {
        result = data;
    }
    else {
        _.forEach(data, (item, index) => {
            _.forEach(params, (x) => {
                if (!indexMap.has(index) && _matchByObject(item, x)) {
                    result.push(item);
                    indexMap.add(index);
                    if (useFirst)
                        return false;
                }
            });
            if (useFirst && result.length)
                return false;
        });
    }
    return result;
}
