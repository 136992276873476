var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect, useRef } from 'react';
function useClickOutside(callback, isObserve = true) {
    const ref = useRef(null);
    const callbackRef = useRef();
    useEffect(() => {
        callbackRef.current = callback;
    });
    useEffect(() => {
        if (!isObserve)
            return;
        function handleClick(event) {
            if (callbackRef.current && ref.current && !ref.current.contains(event.target)) {
                callbackRef.current(event);
            }
        }
        document.addEventListener('click', (e) => handleClick(e), true);
        return () => {
            document.removeEventListener('click', (e) => handleClick(e), true);
        };
    }, [isObserve]);
    return ref;
}
function ClickOutside(_a) {
    var { children, onClick, tag: Tag, isObserve } = _a, otherProps = __rest(_a, ["children", "onClick", "tag", "isObserve"]);
    const ref = useClickOutside(onClick, isObserve);
    if (Tag === 'form') {
        return (_jsx("form", Object.assign({ ref: ref }, otherProps, { children: children })));
    }
    return (_jsx("div", Object.assign({ ref: ref }, otherProps, { children: children })));
}
export default ClickOutside;
