import { getExtractor } from './extractor.config';
let initialized = false;
export let findResourceById;
export let findResourcesByCondition;
export function initResourceExtractor({ findResourceById: _findResourceById, findResourcesByCondition: _findResourcesByCondition, }) {
    findResourceById = _findResourceById;
    findResourcesByCondition = _findResourcesByCondition;
    initialized = true;
}
export function resourceExtractor(resource, variant) {
    if (!initialized) {
        throw new Error('Initialize FHIR with initResourceExtractor');
    }
    let extractor = getExtractor(resource, variant);
    if (extractor) {
        // @ts-ignore
        return new extractor(resource);
    }
}
