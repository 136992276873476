import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useLayoutEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { PageNoResults } from 'ziphy-web-shared/basic/lib/simple';
import ContainerScrollable from '../simple/containerScrollable/ContainerScrollable';
import { Loader } from './Loader';
import styles from './WithLoaderWrapper.module.scss';
export const WithLoaderWrapper = ({ id, children, mode = 'update', isLoading = false, visibleCondition = true, withWrapper = true, topShadow = false, bottomShadow = false, noResults, className, 
//
loader, loaderMode, loaderClassName, }) => {
    const { t } = useTranslation();
    const initialize = useRef(null);
    useLayoutEffect(() => {
        if (initialize.current === null && isLoading === false) {
            initialize.current = isLoading;
        }
    }, [isLoading]);
    function renderContent() {
        noResults =
            noResults || (noResults !== null && _jsx(PageNoResults, { children: t('errors.no_results') }));
        const content = visibleCondition ? children : noResults;
        // if (id === 'one') {
        //   console.log(mode, initialize.current, isLoading)
        // }
        return (_jsx(_Fragment, { children: !isLoading ? (_jsx(_Fragment, { children: content })) : mode === 'silent' && initialize.current !== null ? (_jsx(_Fragment, { children: content })) : mode === 'update' && initialize.current !== null ? (_jsxs(_Fragment, { children: [_jsx(_Fragment, { children: content }), _jsx(Loader, { type: loader, mode: loaderMode, className: loaderClassName, asOverlay: mode === 'update' })] })) : (_jsx(Loader, { type: loader, mode: loaderMode, className: loaderClassName })) }));
    }
    if (withWrapper) {
        if (topShadow || bottomShadow) {
            return (_jsx(ContainerScrollable, Object.assign({ wrapperClassName: classNames(styles.wrapper, className), top: topShadow, bottom: bottomShadow }, { children: renderContent() })));
        }
        return _jsx("div", Object.assign({ className: classNames(styles.wrapper, className) }, { children: renderContent() }));
    }
    return renderContent();
};
