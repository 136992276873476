var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { cssTransition, toast } from 'react-toastify';
import styles from './Alerts.module.scss';
const Zoom = cssTransition({ enter: 'slide-in-top', exit: 'fade-out' });
const defaultOptions = {
    position: 'top-center',
    icon: false,
    theme: 'colored',
    closeButton: false,
    transition: Zoom,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
};
class ShowAlert {
    success(content, options = {}) {
        const { isApiMessage } = options, toastOptions = __rest(options, ["isApiMessage"]);
        const toastId = isApiMessage ? 'api_toast_id' : JSON.stringify(content);
        let customOptions = Object.assign(Object.assign(Object.assign({}, defaultOptions), { toastId }), toastOptions);
        return toast.success(_jsx(Content, { content: content }), customOptions);
    }
    error(content, options = {}) {
        const { isApiMessage } = options, toastOptions = __rest(options, ["isApiMessage"]);
        const toastId = isApiMessage ? 'api_toast_id' : JSON.stringify(content);
        let customOptions = Object.assign(Object.assign(Object.assign({}, defaultOptions), { toastId }), toastOptions);
        return toast.error(_jsx(Content, { content: content }), customOptions);
    }
}
export const showAlert = new ShowAlert();
function Content({ content }) {
    return (_jsx(_Fragment, { children: typeof content === 'object' && ('title' in content || 'content' in content) ? (_jsxs(_Fragment, { children: [(content === null || content === void 0 ? void 0 : content.title) && _jsx("div", Object.assign({ className: styles.title }, { children: content.title })), _jsx("div", Object.assign({ className: styles.content }, { children: content.content }))] })) : (_jsx("div", Object.assign({ className: styles.content }, { children: content }))) }));
}
