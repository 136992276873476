import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useMemo, useState } from 'react';
import classNames from 'classnames';
import { getStylesMode } from 'ziphy-web-shared/basic/helpers';
import styles from './ProgressRing.module.scss';
const ProgressRing = ({ radius = 28, stroke = 1, progress = 0, spin = false, centered = false, mode = 'primary', className = '', }) => {
    const [normalizedRadius] = useState(radius - stroke * 2);
    const [circumference] = useState(normalizedRadius * 2 * Math.PI);
    const strokeDashoffset = useMemo(() => {
        return circumference - (progress / 100) * circumference;
    }, [circumference, progress]);
    return (_jsx("div", Object.assign({ className: classNames(styles.progressRing, spin && styles.spin, getStylesMode(mode, styles), className), style: centered ? { marginTop: -radius, marginLeft: -radius } : {} }, { children: _jsxs("svg", Object.assign({ height: radius * 2, width: radius * 2 }, { children: [_jsx("circle", { className: styles.base, strokeWidth: stroke, r: normalizedRadius, cx: radius, cy: radius }), _jsx("circle", { className: styles.color, strokeWidth: stroke, strokeDasharray: circumference + ' ' + circumference, style: { strokeDashoffset }, r: normalizedRadius, cx: radius, cy: radius })] })) })));
};
export default ProgressRing;
