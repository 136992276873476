import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import _ from 'lodash';
import Icon from 'ziphy-web-shared/basic/assets/images/Icon';
import { CustomLink } from 'ziphy-web-shared/basic/lib/buttons';
import { analytics } from 'ziphy-web-shared/basic/utils/analytics';
import styles from './GoBack.module.scss';
const GoBack = ({ autoTarget, onClick, historyBack = false, className }) => {
    let history = useHistory();
    function handleOnClick(event) {
        if (_.isFunction(onClick)) {
            onClick(event);
        }
        analytics.breadcrumb('nav-goBack', {});
        if (historyBack) {
            history.goBack();
        }
    }
    return (_jsx(CustomLink, Object.assign({ autoTarget: autoTarget, onClick: handleOnClick, className: classNames(styles.toolbarGoBack, className) }, { children: _jsx(Icon, { type: "back", className: styles.icon }) })));
};
export default GoBack;
