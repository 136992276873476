export const MockMedicationResources = [
    {
        resourceId: '673dc2dac81239053fd8c499',
        data: {
            medication: {
                code: {
                    coding: [
                        {
                            system: 'http://www.nlm.nih.gov/research/umls/rxnorm',
                            code: '1741739',
                            display: 'emtricitabine 200 MG / rilpivirine 25 MG / tenofovir alafenamide 25 MG Oral Tablet [Odefsey]',
                        },
                    ],
                },
                form: {
                    coding: [
                        {
                            system: 'http://www.nlm.nih.gov/research/umls/rxnorm',
                            code: '317541',
                            display: 'Oral Tablet',
                        },
                    ],
                },
                ingredient: [
                    {
                        itemCodeableConcept: {
                            coding: [
                                {
                                    system: 'http://www.nlm.nih.gov/research/umls/rxnorm',
                                    code: '276237',
                                    display: 'emtricitabine',
                                },
                            ],
                        },
                        isActive: true,
                        strength: {
                            numerator: { value: 200.0, system: 'http://unitsofmeasure.org', code: 'MG' },
                            denominator: { value: 1.0, system: 'http://unitsofmeasure.org', code: '1' },
                        },
                    },
                    {
                        itemCodeableConcept: {
                            coding: [
                                {
                                    system: 'http://www.nlm.nih.gov/research/umls/rxnorm',
                                    code: '1102270',
                                    display: 'rilpivirine',
                                },
                            ],
                        },
                        isActive: true,
                        strength: {
                            numerator: { value: 25.0, system: 'http://unitsofmeasure.org', code: 'MG' },
                            denominator: { value: 1.0, system: 'http://unitsofmeasure.org', code: '1' },
                        },
                    },
                ],
                brand: {
                    coding: [
                        {
                            system: 'http://www.nlm.nih.gov/research/umls/rxnorm',
                            code: '1741734',
                            display: 'Odefsey',
                        },
                    ],
                    text: 'Odefsey',
                },
                pharmClass: [
                    { text: 'Nucleosides [CS]' },
                    {
                        text: 'Human Immunodeficiency Virus Nucleoside Analog Reverse Transcriptase Inhibitor [EPC]',
                    },
                ],
            },
            statement: {
                reviewedFrom: 'MedicationStatement/500',
                dateAsserted: '2015-01-23T10:00:00+00:00',
                dosage: [
                    {
                        text: 'one capsule three times daily',
                        additionalInstruction: ['with meals', 'may cause drowsiness'],
                        patientInstruction: 'with meals',
                        timing: { code: { code: 'three_times_a_day' } },
                        asNeeded: true,
                        site: 'Left eye',
                        route: 'Oral',
                        method: 'Oral',
                        doseAndRate: [{ doseQuantity: { code: 'TAB', value: 4 } }],
                    },
                ],
                status: 'intended',
                effectivePeriod: {
                    start: '2015-01-23',
                    end: '2015-01-30',
                },
                derivedFrom: 'MedicationStatement/123',
                derivationReason: 'increase',
                reasonCode: { text: 'Treatment for condition X' },
                reasonReference: [{ reference: 'Condition/100' }],
                note: 'Patient is responding well to treatment',
            },
            prescription: {
                status: 'draft',
                dispenseRequest: {
                    validityPeriod: {
                        start: '2015-01-23',
                        end: '2015-01-30',
                    },
                },
                substitution: { allowedBoolean: false },
                note: 'Dispense 30 tablets per month',
            },
        },
        meta: {
            lastUpdated: '2024-11-22T13:11:13Z',
            created: '2024-11-20T11:07:06Z',
            originEncounterId: '66fe98a0ad9d11a70ee9b465',
            source: 'notes',
        },
    },
];
