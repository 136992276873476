import { makeAutoObservable, reaction, runInAction } from 'mobx'

import _ from 'lodash'
import { officesService, practiceServicesService } from 'ziphy-web-shared/basic/api'
import { $paymentMethods } from 'ziphy-web-shared/basic/entities/paymentMethods'
import { localStore } from 'ziphy-web-shared/basic/utils'

import clientService from '@services/client'

import { extendPlace } from '@helpers/addressHelper'
import { getConfigByCountry } from '@helpers/countries'

import { $places } from '@store'

import mainConfig from '@config/main'

import { bookingSchema } from '../config/bookingSchema'
import BookingPatientsStore from './bookingPatientsStore'
import BookingServicesStore from './bookingServicesStore'
import BookingSymptomsStore from './bookingSymptomsStore'

export class BookingStore {
  constructor() {
    this.$services = new BookingServicesStore(this)
    this.patients = new BookingPatientsStore(this)
    this.$symptoms = new BookingSymptomsStore(this)

    makeAutoObservable(this)
  }

  isInitialized = false
  isLoadingPlace = true
  isLoadingPractices = true
  isLoadingPracticeServices = true

  schemaConfigKey = null

  _prevLoadedPlaceId = null
  _prevLoadedServePracticeIds = null

  detectedPlace = null
  defaultPlace = mainConfig.booking.useDefaultPlace ? getConfigByCountry().defaultPlace : null
  placeId = null
  $places = $places

  $services
  practices = {}
  offices = {}
  practiceServices = {}

  serveOfficesIds = []
  voucher = null

  predefinedServiceIds = []
  _serviceId = null
  patients
  $symptoms
  selectedTime = null
  _practiceId = null
  _finishPracticeId = null
  officeId = null
  paymentMethodId = null

  // clear
  clear(full = false) {
    this.isInitialized = false
    this._prevLoadedPlaceId = null
    this._prevLoadedServePracticeIds = null

    this.practices = {}
    this.offices = {}
    this.practiceServices = {}

    this.clearAfterPlaceChanging()

    if (full) {
      this.clearPlace()
    }
  }

  clearAfterPlaceChanging() {
    this.serveOfficesIds = []
    this.voucher = null

    this.predefinedServiceIds = []
    this._serviceId = null
    this.patients.clear()
    this.$symptoms.clear()
    this.selectedTime = null
    this._practiceId = null
    this._finishPracticeId = null
    this.officeId = null
    this.paymentMethodId = null
  }

  clearPlace() {
    this.placeId = null
    this.$places.clear()
  }

  // Computed
  get isLoading() {
    return this.isLoadingPlace || this.isLoadingPractices || this.isLoadingPracticeServices
  }

  get schemaConfig() {
    return bookingSchema[this.schemaConfigKey]
  }

  get place() {
    return this.$places.getById(this.placeId)
  }

  get placeExtended() {
    return extendPlace(this.place)
  }

  get usablePlace() {
    if (!this.isLoadingPlace) {
      return this.place || this.defaultPlace
    }
    return undefined
  }

  get serviceId() {
    if (this._serviceId) {
      return this._serviceId
    }
    return null
  }

  get service() {
    return this.$services.getServiceById(this.serviceId)
  }

  get isClinicAddressType() {
    return this.service.isAtClinic
  }

  get availableServices() {
    const practiceServicesByPracticeId = _.groupBy(this.practiceServices, 'practiceId')

    let serviceIds = []

    _.forEach(this.availablePracticeIds, (id) => {
      const tmp = practiceServicesByPracticeId[id]
      if (tmp) {
        serviceIds = serviceIds.concat(tmp.map((x) => x.serviceId))
      }
    })

    const predefinedServiceIds = this.serviceId ? [this.serviceId] : this.predefinedServiceIds

    if (predefinedServiceIds.length) {
      serviceIds = serviceIds.filter((x) => predefinedServiceIds.includes(x))
    }

    serviceIds = _.uniq(serviceIds)

    return this.$services.items.filter((x) => serviceIds.includes(x.id))
  }

  get availableServiceIds() {
    return this.availableServices.map((x) => x.id)
  }

  get office() {
    return this.offices[this.officeId]
  }

  get practiceId() {
    return (
      this.office?.practiceId ||
      this.voucher?.practiceId ||
      this._finishPracticeId ||
      this._practiceId
    )
  }

  get practice() {
    return this.practices[this.practiceId]
  }

  get servePractices() {
    return this.getFilteredPractices()
  }

  get servePracticeIds() {
    return this.servePractices.map((x) => x.id)
  }

  get availablePracticeIds() {
    return this.practiceId !== null ? [this.practiceId] : this.servePracticeIds
  }

  get practiceService() {
    return _.find(
      this.practiceServices,
      (x) => x.serviceId === this.serviceId && x.practiceId === this.practiceId,
    )
  }

  get hasVoucherOrCode() {
    return Boolean(this.voucher || this.office)
  }

  get paymentMethod() {
    return $paymentMethods.getById(this.paymentMethodId)
  }

  get payments() {
    return this.patients.items.reduce(
      (acc, patient) => {
        const patientPayment = (() => {
          if (patient.useInsurance) {
            return 0
          }
          if (patient.voucher) {
            return patient.voucher.price
          }
          return this.practiceService?.price
        })()

        return {
          patients: { ...acc.patients, [patient.id]: patientPayment },
          total: acc.total + patientPayment,
          priceForAll: acc.priceForAll + this.practiceService?.price,
        }
      },
      { patients: {}, total: 0, priceForAll: 0 },
    )
  }

  get bookingPayload() {
    const patientsPayload = this.patients.items.map((x) => ({
      id: x.id,
      voucherCode: x.voucher?.code,
      useInsurance: x.useInsurance,
    }))

    const payload = {
      practiceId: this.practiceId,
      placeId: this.placeId,
      serviceId: this.serviceId,
      patients: patientsPayload,
      start: `$datetime:${this.selectedTime}`,
      symptomIds: this.$symptoms.selectedIds,
      answers: this.$symptoms.answers,
    }

    if (this.office) {
      payload.practiceId = this.office.practiceId
      payload.officeId = this.office.id
    }

    if (this.payments.total > 0) {
      payload.paymentMethodId = this.paymentMethodId
    }

    return payload
  }

  get searchParams() {
    return {
      placeId: this.placeId,
      serviceId: this.serviceId,
      patientIds: this.patients.items.map((x) => x.id),
      practiceId: this.practiceId,
      officeId: this.officeId,
    }
  }

  // Mutations
  SET_SCHEMA_CONFIG_KEY = (value) => {
    this.schemaConfigKey = value
  }

  SET_PLACE_ID = (value = null) => {
    this.placeId = value
  }

  SET_PREDEFINED_SERVICE_IDS = (value = []) => {
    this.predefinedServiceIds = value
  }

  SET_SERVICE_ID = (value = null) => {
    this._serviceId = value
  }

  SET_PRACTICE_ID = (value = null) => {
    this._practiceId = value
  }

  SET_FINISH_PRACTICE_ID = (value = null) => {
    this._finishPracticeId = value
  }

  SET_OFFICE_ID = (value = null) => {
    this.officeId = value
  }

  SET_VOUCHER = (value = null) => {
    this.voucher = value
  }

  SET_SELECTED_TIME = (time = null) => {
    this.selectedTime = time
  }

  SET_PAYMENT_METHOD_ID = (id = null) => {
    this.paymentMethodId = id
  }

  // Actions
  getFilteredPractices(withCodeChecking = true) {
    const practiceIds = this.serveOfficesIds.reduce((acc, x) => {
      const office = this.offices[x]
      // filter offices with code
      if (
        office &&
        (_.isEmpty(office.code) ||
          _.toLower(office.code) === _.toLower(this.office?.code) ||
          !withCodeChecking)
      ) {
        acc.push(office.practiceId)
      }
      return acc
    }, [])

    const result = _.uniq(practiceIds).map((id) => {
      let hasSymptoms = false

      const item = this.practices[id]
      if (item) {
        hasSymptoms = this.$symptoms.allAvailablePracticeIds.includes(id)
      }

      if (hasSymptoms) {
        return item
      }
      return null
    })

    return result.filter((x) => x)
  }

  async init({ placeId, serviceId, predefinedServiceIds, patientIds } = {}) {
    this.isInitialized = false

    // TODO maybe will be better if predefinedServiceIds renamed to forceLoadServiceIds or make another solution for loading predefinedServiceIds
    await Promise.all([
      this.$services.load(),
      this.$symptoms.load(), //
    ])

    if (placeId && placeId !== this.placeId) {
      await this.loadPlaces(+placeId)
      if (!this.serveOfficesIds.length) {
        await this.loadOfficesPracticesByPlace()
      }
      if (_.isEmpty(this.practiceServices)) {
        await this.loadPracticeServices()
      }
    }

    if (serviceId && serviceId !== this.serviceId) {
      this.SET_SERVICE_ID(+serviceId)
    }

    if (predefinedServiceIds?.length) {
      this.SET_PREDEFINED_SERVICE_IDS(predefinedServiceIds)
    }

    if (patientIds?.length && !this.patients.selectedIds.length) {
      await this.loadPatients(patientIds)
    }

    runInAction(() => {
      this.isInitialized = true
    })
  }

  async loadPlaces(placeId) {
    this.isLoadingPlace = true

    if (!$places.items.length) {
      await $places.get()
    }

    const checkPlaceId = () => {
      if (!$places.getById(placeId)) {
        placeId = null
      }
    }

    if (!placeId) {
      placeId = localStore.get('userSettings.bookPlaceId', null)
      checkPlaceId()
    }

    if (!placeId) {
      const res = await clientService.getAppointments({ expand: {}, getEvents: false, limit: 1 })
      const lastAppt = res.prepared?.[0]?.appointment
      if (lastAppt) {
        placeId = lastAppt.placeId
        checkPlaceId()
      }
    }

    if (!placeId && $places.items.length) {
      placeId = $places.items[0]?.id
    }

    if (placeId) {
      this.SET_PLACE_ID(placeId)
    }

    runInAction(() => {
      this.isLoadingPlace = false
    })
  }

  async loadPatients(patientIds = []) {
    if (patientIds.length) {
      await this.patients.get()
      const hasPatients = patientIds.every((x) => {
        return this.patients.getOriginalById(x)
      })
      if (hasPatients) {
        patientIds.forEach((id) => this.patients.addSelected(id))
      }
    }
  }

  async loadOfficesPracticesByPlace(place) {
    if (!place) place = this.usablePlace
    if (!place) {
      this.isLoadingPractices = false
      return
    }
    if (_.isEqual(this._prevLoadedPlaceId, place?.id)) {
      return
    }
    this._prevLoadedPlaceId = place?.id

    this.isLoadingPractices = true
    // const res = await officesService.listServeAddress<ExpandResultWithPractice>({
    const res = await officesService.listServeAddress({
      expand: { office: ['practiceId'] },
      zip: place.zip,
      coords: place.coords,
      country: place.country,
    })

    runInAction(() => {
      const { offices, officesIds } = _.reduce(
        res.prepared?.offices?.items,
        (acc, x) => {
          acc.offices[x.id] = x
          acc.officesIds.push(x.id)
          return acc
        },
        { offices: {}, officesIds: [] },
      )
      const practices = _.keyBy(res.prepared?.practices, 'id')

      this.offices = { ...this.offices, ...offices }
      this.practices = { ...this.practices, ...practices }
      this.serveOfficesIds = officesIds

      this.isLoadingPractices = false
    })
  }

  async loadPracticeServices() {
    if (!this.servePracticeIds.length) {
      return
    }
    if (_.isEqual(this._prevLoadedServePracticeIds, this.servePracticeIds)) {
      return
    }

    this._prevLoadedServePracticeIds = this.servePracticeIds
    this.isLoadingPracticeServices = true
    const response = await practiceServicesService.getFiltered({
      practiceIds: this.servePracticeIds,
    })

    runInAction(() => {
      const practiceServices = _.keyBy(response.prepared?.practiceServices?.items, 'id')
      this.practiceServices = { ...this.practiceServices, ...practiceServices }
      this.isLoadingPracticeServices = false
    })
  }
}

const store = new BookingStore()

reaction(
  () => store.placeId,
  (placeId) => {
    localStore.set('userSettings.bookPlaceId', placeId, { lifeTime: 3600 })
  },
)

export default store
