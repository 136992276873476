import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { Fragment, isValidElement, useMemo } from 'react';
import classNames from 'classnames';
import Icon from 'ziphy-web-shared/basic/assets/images/Icon';
import { getStylesMode } from 'ziphy-web-shared/basic/helpers';
import { CustomLink } from 'ziphy-web-shared/basic/lib/buttons';
import { BubbleLoader } from 'ziphy-web-shared/basic/lib/loaders';
import styles from './PatientInfoBox.module.scss';
export const PatientInfoBox = ({ autoTarget, to = autoTarget === null || autoTarget === void 0 ? void 0 : autoTarget.to, href = autoTarget === null || autoTarget === void 0 ? void 0 : autoTarget.href, onClick = autoTarget === null || autoTarget === void 0 ? void 0 : autoTarget.onClick, wvAction = autoTarget === null || autoTarget === void 0 ? void 0 : autoTarget.wvAction, value, secondaryValue, label, className, mode = 'md', leftElement: _left, rightElement: _right, rightSecondElement: _rightSecond, params, clickable = Boolean(to || href || onClick || wvAction), withError, withArrow, isLoading, }) => {
    _left = useMemo(() => {
        if (Array.isArray(_left)) {
            return _left.reduce((acc, x, index) => (Object.assign(Object.assign({}, acc), { [`el${index}`]: x })), {});
        }
        else {
            return isValidElement(_left) ? { el: _left } : _left;
        }
    }, [_left]);
    _right = useMemo(() => {
        if (Array.isArray(_right)) {
            return _right.reduce((acc, x, index) => (Object.assign(Object.assign({}, acc), { [`el${index}`]: x })), {});
        }
        else {
            return isValidElement(_right) ? { el: _right } : _right;
        }
    }, [_right]);
    _rightSecond = useMemo(() => {
        if (Array.isArray(_rightSecond)) {
            return _rightSecond.reduce((acc, x, index) => (Object.assign(Object.assign({}, acc), { [`el${index}`]: x })), {});
        }
        else {
            return isValidElement(_rightSecond) ? { el: _rightSecond } : _rightSecond;
        }
    }, [_rightSecond]);
    const leftElement = _left ? Object.entries(_left).filter(([_, value]) => value) : [];
    const rightElement = _right ? Object.entries(_right).filter(([_, value]) => value) : [];
    const rightSecondElement = _rightSecond
        ? Object.entries(_rightSecond).filter(([_, value]) => value)
        : [];
    return (_jsxs(CustomLink, Object.assign({ to: to, href: href, onClick: onClick, wvAction: wvAction, className: classNames(styles.wrapper, mode && getStylesMode(mode, styles), clickable && !isLoading && styles.clickable, withError && styles.withError, className), params: params }, { children: [leftElement.length > 0 && (_jsx("div", Object.assign({ className: styles.leftElement }, { children: leftElement.map(([key, value]) => (_jsx(Fragment, { children: value }, key))) }))), _jsxs("div", Object.assign({ className: styles.content }, { children: [value && (_jsxs("div", Object.assign({ className: styles.value }, { children: [_jsx("div", Object.assign({ className: styles.valuePrimary }, { children: value })), secondaryValue && _jsx("div", Object.assign({ className: styles.valueSecondary }, { children: secondaryValue }))] }))), label && _jsx("div", Object.assign({ className: styles.label }, { children: label }))] })), rightElement.length > 0 && (_jsx("div", Object.assign({ className: styles.rightElement }, { children: rightElement.map(([key, value]) => (_jsx(Fragment, { children: typeof value === 'function' ? (_jsxs("div", Object.assign({ className: styles.icon, onClick: (e) => {
                            e.stopPropagation();
                            value(e);
                        } }, { children: [key === 'onEdit' && _jsx(Icon, { type: "edit" }), key === 'onDelete' && _jsx(Icon, { type: "trash" }), key === 'onInfo' && _jsx(Icon, { type: "info" })] }))) : (value) }, key))) }))), rightSecondElement.length > 0 && (_jsx("div", Object.assign({ className: styles.rightSecondElement, onClick: (e) => e.stopPropagation() }, { children: rightSecondElement.map(([key, value]) => (_jsx(Fragment, { children: typeof value === 'function' ? (_jsx("div", Object.assign({ className: styles.icon, onClick: (e) => {
                            e.stopPropagation();
                            value(e);
                        } }, { children: key === 'onCopy' && _jsx(Icon, { type: "copy" }) }))) : (value) }, key))) }))), withArrow && _jsx("div", Object.assign({ className: styles.arrow }, { children: _jsx(Icon, { type: "next" }) })), isLoading && (_jsx("div", Object.assign({ className: styles.loader }, { children: _jsx(BubbleLoader, {}) })))] })));
};
