export const ENUMS_APPOINTMENT_STATUSES = {
    proposed: {
        id: 'proposed',
        tKey: 'fhir.appointment_statuses.proposed',
    },
    pending: {
        id: 'pending',
        tKey: 'fhir.appointment_statuses.pending',
    },
    booked: {
        id: 'booked',
        tKey: 'fhir.appointment_statuses.booked',
    },
    arrived: {
        id: 'arrived',
        tKey: 'fhir.appointment_statuses.arrived',
    },
    fulfilled: {
        id: 'fulfilled',
        tKey: 'fhir.appointment_statuses.fulfilled',
    },
    cancelled: {
        id: 'cancelled',
        tKey: 'fhir.appointment_statuses.cancelled',
    },
    noshow: {
        id: 'noshow',
        tKey: 'fhir.appointment_statuses.noshow',
    },
    'entered-in-error': {
        id: 'entered-in-error',
        tKey: 'fhir.appointment_statuses.entered-in-error',
    },
    'checked-in': {
        id: 'checked-in',
        tKey: 'fhir.appointment_statuses.checked-in',
    },
    waitlist: {
        id: 'waitlist',
        tKey: 'fhir.appointment_statuses.waitlist',
    },
};
