import { makeAutoObservable } from 'mobx';
import { getSubjectRole } from '../extractor.helpers';
import ExtractorBase from '../extractors/Base';
import { prepareMeta } from '../fhir.converters';
import { getCodingObjects, getExtensionObjects, getIdentifierObjects, withFullName, } from '../fhir.helpers';
class ExtractorPractitionerRole {
    constructor(resource) {
        this.base = new ExtractorBase(this);
        this.base.SET(resource);
        makeAutoObservable(this);
    }
    get resource() {
        return this.base.resource;
    }
    /*
     * RESOURCE - Minimally prepared resource data, prepareConverters, valueObjects, etc.
     */
    get extensions() {
        return getExtensionObjects(this.resource.extension);
    }
    get identifiers() {
        return getIdentifierObjects(this.resource.identifier);
    }
    get codes() {
        return getCodingObjects(this.resource.code);
    }
    get practitioner() {
        return this.base.getChildExtractor(this.resource.practitioner);
    }
    get meta() {
        return prepareMeta(this.resource.meta);
    }
    /*
     * READ - PREPARED Values
     */
    get zid() {
        return this.identifiers.first({ config: 'zid' });
    }
    get codeObj() {
        return this.codes.first();
    }
    get role() {
        return getSubjectRole(this);
    }
    get name() {
        var _a;
        return this.practitioner.name || withFullName({ name: (_a = this.resource.practitioner) === null || _a === void 0 ? void 0 : _a.display });
    }
    /*
     * STRING Values
     */
    get asString() {
        var _a;
        return {
            zid: (_a = this.zid) === null || _a === void 0 ? void 0 : _a.value,
            full: '',
        };
    }
}
export default ExtractorPractitionerRole;
