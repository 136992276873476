import React from 'react'

import { Link } from 'react-router-dom'

import queryString from 'query-string'
import { nativeScrollTo } from 'ziphy-web-shared/basic/helpers'
import { $router } from 'ziphy-web-shared/basic/utils/router'

export const TosLink = ({ to = '', type, children, onlyScroll = false }) => {
  function handleClick(e) {
    e.preventDefault()

    nativeScrollTo(to)

    if (!onlyScroll) {
      $router.searchParams.set({ [type]: to })
    }
  }

  return (
    <Link to={'?' + queryString.stringify({ [type]: to })} onClick={handleClick}>
      {children}
    </Link>
  )
}
