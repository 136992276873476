import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatDate } from 'ziphy-web-shared/basic/utils/time';
import RoleName from '@ps/library/RoleName';
import ViewCode from '@ps/library/viewCode/ViewCode';
const AllergyIntoleranceDetails = ({ event, extractor }) => {
    const { t } = useTranslation();
    const { codeObj, subject, recorder, asString } = extractor;
    return (_jsxs("div", Object.assign({ className: "event_details" }, { children: [_jsxs("div", Object.assign({ className: "event_details-header" }, { children: [_jsxs("div", Object.assign({ className: "event_details-title" }, { children: [asString.codeDisplay, event.isSelfReported && (_jsx("span", Object.assign({ className: "badge-self_reported" }, { children: t('ps.label.self_reported') })))] })), _jsx("div", Object.assign({ className: "event_details-code" }, { children: _jsx(ViewCode, { codeObj: codeObj }) }))] })), _jsxs("div", Object.assign({ className: "event_details-body" }, { children: [
                    // todo: values probably legacy and unused
                    asString.values && (_jsxs("div", Object.assign({ className: "event_details-line" }, { children: [t('ps.event_viewer.reaction:'), " ", asString.values] }))), asString.notes && (_jsxs("div", Object.assign({ className: "event_details-line mb-10" }, { children: [t('ps.event_viewer.note:'), " ", asString.notes] }))), asString.type && (_jsxs("div", Object.assign({ className: "event_details-line" }, { children: [t('ps.event_viewer.type:'), " ", asString.type] }))), asString.clinicalStatus && (_jsxs("div", Object.assign({ className: "event_details-line" }, { children: [t('ps.event_viewer.status:'), " ", asString.clinicalStatus] }))), asString.verificationStatus && (_jsxs("div", Object.assign({ className: "event_details-line" }, { children: [t('ps.event_viewer.verification_status:'), " ", asString.verificationStatus] }))), subject && (_jsxs("div", Object.assign({ className: "event_details-line mt-10" }, { children: [t('ps.event_viewer.patient'), " ", subject.name.fullName] }))), recorder && (_jsxs("div", Object.assign({ className: "event_details-line" }, { children: [t('ps.event_viewer.recorder:'), " ", recorder.name.fullName, ' ', _jsx(RoleName, { role: recorder.role })] }))), _jsx("div", Object.assign({ className: "event_details-line event_details-line--date" }, { children: formatDate(event.effectiveDate) }))] }))] })));
};
export default AllergyIntoleranceDetails;
