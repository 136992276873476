import { makeAutoObservable } from 'mobx';
import { getUuid } from 'ziphy-web-shared/basic/helpers';
import { $loader, globalLoaderHandlers } from 'ziphy-web-shared/basic/utils';
import { ContextController } from '../../storageManager';
/*
 * Templates
 */
class MedicationStore {
    constructor(key = getUuid()) {
        makeAutoObservable(this);
    }
    // Items
    // Actions
    //////
    withContext(params) {
        return Object.assign({}, params);
    }
}
export const $medicationsContexts = new ContextController((key) => new MedicationStore(key));
/*
 * Other
 */
$loader.subscribe(globalLoaderHandlers.LOGOUT, () => {
    $medicationsContexts.clear();
});
