import _ from 'lodash';
import { removeHttp } from 'ziphy-web-shared/basic/helpers';
export var CodingOrganizations;
(function (CodingOrganizations) {
    CodingOrganizations["SNOMED"] = "snomed";
    CodingOrganizations["LOINC"] = "loinc";
    CodingOrganizations["CPT"] = "cpt";
    CodingOrganizations["CVX"] = "cvx";
    CodingOrganizations["RxNorm"] = "rxnorm";
    CodingOrganizations["ICD10"] = "icd10";
    CodingOrganizations["ICD10CM"] = "icd10cm";
    CodingOrganizations["ICD10PCS"] = "icd10pcs";
    CodingOrganizations["HCPCS"] = "hcpcs";
    CodingOrganizations["NDC"] = "ndc";
    CodingOrganizations["HL7"] = "HL7";
    CodingOrganizations["THL7"] = "terminologyHL7";
    CodingOrganizations["NPI"] = "npi";
    CodingOrganizations["FHIR_ZIPHY"] = "fhirZiphy";
    CodingOrganizations["FHIR_LABQ"] = "fhirLabq";
})(CodingOrganizations || (CodingOrganizations = {}));
export const CODING_ORG_CONFIG = {
    [CodingOrganizations.SNOMED]: {
        id: CodingOrganizations.SNOMED,
        system: 'http://snomed.info/sct',
        label: 'SNOMED',
    },
    [CodingOrganizations.LOINC]: {
        id: CodingOrganizations.LOINC,
        system: 'https://loinc.org',
        label: 'LOINC',
    },
    [CodingOrganizations.CPT]: {
        id: CodingOrganizations.CPT,
        system: 'http://www.ama-assn.org/go/cpt',
        label: 'CPT',
    },
    [CodingOrganizations.CVX]: {
        id: CodingOrganizations.CVX,
        system: 'http://hl7.org/fhir/sid/cvx',
        label: 'CVX',
    },
    [CodingOrganizations.RxNorm]: {
        id: CodingOrganizations.RxNorm,
        system: 'http://www.nlm.nih.gov/research/umls/rxnorm',
        label: 'RxNorm',
    },
    [CodingOrganizations.ICD10]: {
        id: CodingOrganizations.ICD10,
        system: 'http://hl7.org/fhir/sid/icd-10',
        label: 'ICD-10',
    },
    [CodingOrganizations.ICD10CM]: {
        id: CodingOrganizations.ICD10CM,
        system: 'http://hl7.org/fhir/sid/icd-10-cm',
        label: 'ICD-10 CM',
    },
    [CodingOrganizations.ICD10PCS]: {
        id: CodingOrganizations.ICD10PCS,
        system: 'http://hl7.org/fhir/sid/ex-icd-10-procedures',
        label: 'ICD-10 PCS',
    },
    [CodingOrganizations.HCPCS]: {
        id: CodingOrganizations.HCPCS,
        system: 'https://www.cms.gov/Medicare/Coding/HCPCSReleaseCodeSets',
        label: 'HCPCS',
    },
    [CodingOrganizations.NDC]: {
        id: CodingOrganizations.NDC,
        system: 'http://hl7.org/fhir/sid/ndc',
        label: 'NDC',
    },
    [CodingOrganizations.HL7]: {
        id: CodingOrganizations.HL7,
        system: 'https://hl7.org',
        label: 'HL7',
    },
    [CodingOrganizations.THL7]: {
        id: CodingOrganizations.THL7,
        system: 'http://terminology.hl7.org',
        label: 'HL7',
    },
    [CodingOrganizations.NPI]: {
        id: CodingOrganizations.NPI,
        system: 'http://hl7.org/fhir/sid/us-npi',
        label: 'NPI',
    },
    [CodingOrganizations.FHIR_ZIPHY]: {
        id: CodingOrganizations.FHIR_ZIPHY,
        system: 'https://fhir.ziphy.com',
        label: 'ZIPHY',
    },
    [CodingOrganizations.FHIR_LABQ]: {
        id: CodingOrganizations.FHIR_LABQ,
        system: 'https://fhir.labq.com',
        label: 'LABQ',
    },
};
export const IDENTIFIER_TYPE_CODING_SYSTEMS = {
    identifierBookingEntity: {
        userId: {
            system: 'http://fhir.ziphy.com/1.0/ziphy-booking-entity',
            code: 'user_id',
        },
        appointmentId: {
            system: 'http://fhir.ziphy.com/1.0/ziphy-booking-entity',
            code: 'appointment',
        },
        placeId: {
            system: 'http://fhir.ziphy.com/1.0/ziphy-booking-entity',
            code: 'location',
        },
    },
};
export const IDENTIFIER_SYSTEMS_CONFIG = {
    zid: {
        main: {
            system: 'https://fhir.ziphy.com/1.0/ziphy-identifier',
        },
        labq: {
            system: 'https://fhir.labq.com/1.0/labq-identifier',
        },
    },
    identifierPatientPassport: {
        labq: {
            system: 'https://fhir.labq.com/1.0/patient-passport',
        },
    },
};
export const CATEGORIES_SYSTEMS_CONFIG = {
    categoryServiceRequestLab: {
        main: {
            system: 'http://snomed.info/sct',
            code: '108252007',
            display: 'Laboratory procedure',
        },
    },
    categoryServiceRequestReferral: {
        main: {
            system: 'http://snomed.info/sct',
            code: '103696004',
            display: 'Patient referral to specialist',
        },
    },
};
export const CODING_SYSTEMS_CONFIG = Object.assign(Object.assign(Object.assign({}, CATEGORIES_SYSTEMS_CONFIG), IDENTIFIER_TYPE_CODING_SYSTEMS), { 
    // Appointment
    appointmentServiceType: {
        main: {
            system: 'https://fhir.ziphy.com/1.0/ziphy-identifier',
        },
    }, 
    // Observations
    observationHpiNarrativeCode: {
        main: {
            system: 'https://loinc.org',
            code: '10164-2',
            display: 'History of Present illness Narrative',
        },
    }, observationExaminationPhysicalFindingsNarrativeCode: {
        main: {
            system: 'https://loinc.org',
            code: '29545-1',
            display: 'Physical findings',
        },
    }, observationAlcoholConsumptionCode: {
        main: {
            system: 'https://fhir.ziphy.com',
            code: 'alcohol_consumption_social_history',
            display: 'Alcohol consumption',
        },
    }, observationAlcoholConsumptionValues: {
        unknown: {
            system: 'https://fhir.ziphy.com',
            code: 'unknown_alcohol_consumption_social_history',
            display: 'Unknown',
        },
        never: {
            system: 'https://fhir.ziphy.com',
            code: 'never_alcohol_consumption_social_history',
            display: 'Never',
        },
        former: {
            system: 'https://fhir.ziphy.com',
            code: 'former_alcohol_consumption_social_history',
            display: 'Former',
        },
        yes_sometimes: {
            system: 'https://fhir.ziphy.com',
            code: 'yes_sometimes_alcohol_consumption_social_history',
            display: 'Yes, sometimes',
        },
        '1_2_drinks_a_week': {
            system: 'https://fhir.ziphy.com',
            code: '1_2_drinks_a_week_alcohol_consumption_social_history',
            display: '1-2 drinks a week',
        },
        '3_4_drinks_a_week': {
            system: 'https://fhir.ziphy.com',
            code: '3_4_drinks_a_week_alcohol_consumption_social_history',
            display: '5+ drinks a week',
        },
    }, 
    // Notes AllergyIntolerance
    allergyIntoleranceClinicalStatusValue: {
        active: {
            system: 'http://terminology.hl7.org/CodeSystem/allergyintolerance-clinical',
            code: 'active',
            display: 'Active',
        },
        inactive: {
            system: 'http://terminology.hl7.org/CodeSystem/allergyintolerance-clinical',
            code: 'inactive',
            display: 'Inactive',
        },
        resolved: {
            system: 'http://terminology.hl7.org/CodeSystem/allergyintolerance-clinical',
            code: 'resolved',
            display: 'Resolved',
        },
    }, allergyIntoleranceVerificationStatusValue: {
        unconfirmed: {
            system: 'http://terminology.hl7.org/CodeSystem/allergyintolerance-verification',
            code: 'unconfirmed',
            display: 'Unconfirmed',
        },
        confirmed: {
            system: 'http://terminology.hl7.org/CodeSystem/allergyintolerance-verification',
            code: 'confirmed',
            display: 'Confirmed',
        },
        refuted: {
            system: 'http://terminology.hl7.org/CodeSystem/allergyintolerance-verification',
            code: 'refuted',
            display: 'Refuted',
        },
        entered_in_error: {
            system: 'http://terminology.hl7.org/CodeSystem/allergyintolerance-verification',
            code: 'entered-in-error',
            display: 'Entered in Error',
        },
    }, allergyIntoleranceNoKnownDrugAllergy: {
        main: {
            system: 'http://snomed.info/sct',
            code: '409137002',
            display: 'No known drug allergy',
        },
    }, conditionClinicalStatusValue: {
        active: {
            system: 'http://terminology.hl7.org/CodeSystem/condition-clinical',
            code: 'active',
            display: 'Active',
        },
        recurrence: {
            system: 'http://terminology.hl7.org/CodeSystem/condition-clinical',
            code: 'recurrence',
            display: 'Recurrence',
        },
        relapse: {
            system: 'http://terminology.hl7.org/CodeSystem/condition-clinical',
            code: 'relapse',
            display: 'Relapse',
        },
        inactive: {
            system: 'http://terminology.hl7.org/CodeSystem/condition-clinical',
            code: 'inactive',
            display: 'Inactive',
        },
        remission: {
            system: 'http://terminology.hl7.org/CodeSystem/condition-clinical',
            code: 'remission',
            display: 'Remission',
        },
        resolved: {
            system: 'http://terminology.hl7.org/CodeSystem/condition-clinical',
            code: 'resolved',
            display: 'Resolved',
        },
    }, conditionVerificationStatusValue: {
        unconfirmed: {
            system: 'http://terminology.hl7.org/CodeSystem/condition-ver-status',
            code: 'unconfirmed',
            display: 'Unconfirmed',
        },
        provisional: {
            system: 'http://terminology.hl7.org/CodeSystem/condition-ver-status',
            code: 'provisional',
            display: 'Provisional',
        },
        differential: {
            system: 'http://terminology.hl7.org/CodeSystem/condition-ver-status',
            code: 'differential',
            display: 'Differential',
        },
        confirmed: {
            system: 'http://terminology.hl7.org/CodeSystem/condition-ver-status',
            code: 'confirmed',
            display: 'Confirmed',
        },
        refuted: {
            system: 'http://terminology.hl7.org/CodeSystem/condition-ver-status',
            code: 'refuted',
            display: 'Refuted',
        },
        entered_in_error: {
            system: 'http://terminology.hl7.org/CodeSystem/condition-ver-status',
            code: 'entered-in-error',
            display: 'Entered in Error',
        },
    } });
export const EXTENSION_SYSTEMS_CONFIG = {
    resourceSubtype: {
        main: {
            url: 'https://fhir.ziphy.com/1.0/StructureDefinition/resource-subtype',
        },
    },
    observationExaminationTemplate: {
        main: {
            url: 'https://fhir.ziphy.com/1.0/StructureDefinition/observation-examinationTemplate',
        },
    },
};
export const CODING_ORG_CONFIG_PREPARED = (function () {
    let result = _.orderBy(CODING_ORG_CONFIG, (x) => x.system.length, 'desc');
    return result.map((x) => ({ clearedSystem: removeHttp(x.system), config: x }));
})();
export const CODING_SYSTEMS_CONFIG_PREPARED = (() => {
    const result = _.flatMap(CODING_SYSTEMS_CONFIG, (items, key) => {
        return _.map(items, (x, variant) => ({
            // @ts-ignore
            clearedSystem: removeHttp(x.system),
            // @ts-ignore
            code: x.code,
            key,
            variant,
        }));
    });
    return _.orderBy(result, (x) => x.clearedSystem.length, 'desc');
})();
export const IDENTIFIER_SYSTEMS_CONFIG_PREPARED = (() => {
    const result = _.flatMap(IDENTIFIER_SYSTEMS_CONFIG, (items, key) => {
        return _.map(items, (x, variant) => ({
            clearedSystem: removeHttp(x.system),
            key,
            variant,
        }));
    });
    return _.orderBy(result, (x) => x.clearedSystem.length, 'desc');
})();
export const EXTENSION_SYSTEMS_CONFIG_PREPARED = (() => {
    const result = _.flatMap(EXTENSION_SYSTEMS_CONFIG, (items, key) => {
        return _.map(items, (x, variant) => ({
            clearedUrl: removeHttp(x.url),
            key,
            variant,
        }));
    });
    return _.orderBy(result, (x) => x.clearedUrl.length, 'desc');
})();
