import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';
import Icon from 'ziphy-web-shared/basic/assets/images/Icon';
import { $layouts } from 'ziphy-web-shared/basic/utils/layouts';
import { $notices } from '@store';
import styles from './Notification.module.scss';
const Notification = ({ className }) => {
    const handleClick = () => {
        $layouts.showRightSidebar('notifications', {});
    };
    return (_jsx("div", Object.assign({ onClick: handleClick, className: classNames(styles.container, className, $notices.hasActualItems && styles.containerRedMark) }, { children: _jsx(Icon, { type: 'notification' }) })));
};
export default observer(Notification);
