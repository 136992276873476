export const TIME_FOR_ONSITE_REBOOK = 2 * 60; // minutes
export const TIME_FOR_VIRTUAL_REBOOK = 60; // minutes
export const TIME_FOR_CONFIRMATION = 2 * 60; // minutes
export const APPT_COLOR_PALETTE = {
    inProgress: { bg: '#DEF5E6', font: { title: '#518975', subtitle: '#2A5E45' } },
    done: { bg: '#F6F6F6', font: { title: '#B1B1BB', subtitle: '#B1B1BB' } },
    upcoming: {
        onsite_full: {
            individual: { bg: '#37A2DB', font: { title: '#FFFFFF', subtitle: '#C5E4F4' } },
            family: { bg: '#37A2DB', font: { title: '#FFFFFF', subtitle: '#C5E4F4' } },
            group: { bg: '#13BDC1', font: { title: '#FFFFFF', subtitle: '#C7F5F6' } },
        },
        onsite_agent: {
            individual: { bg: '#5856D6', font: { title: '#FFFFFF', subtitle: '#CBCBFF' } },
            family: { bg: '#5856D6', font: { title: '#FFFFFF', subtitle: '#CBCBFF' } },
            group: { bg: '#13BDC1', font: { title: '#FFFFFF', subtitle: '#C7F5F6' } },
        },
        virtual_full: {
            individual: { bg: '#BD76B5', font: { title: '#FFFFFF', subtitle: '#F6D6F2' } },
            family: { bg: '#BD76B5', font: { title: '#FFFFFF', subtitle: '#F6D6F2' } },
            group: { bg: '#13BDC1', font: { title: '#FFFFFF', subtitle: '#C7F5F6' } },
        },
        virtual_provider: {
            individual: { bg: '#DE526B', font: { title: '#FFFFFF', subtitle: '#FFCBD5' } },
            family: { bg: '#DE526B', font: { title: '#FFFFFF', subtitle: '#FFCBD5' } },
            group: { bg: '#13BDC1', font: { title: '#FFFFFF', subtitle: '#C7F5F6' } },
        },
    },
};
