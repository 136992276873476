import AsStringTableDetails from '@ps/library/eventsViewer/tableDetails/AsStringTableDetails';
import AppointmentEventModal from '@ps/library/modals/AppointmentEvent.modal';
export const appointmentConfig = {
    appointment_main: {
        needDeepInclude: [
            '*:encounter > Encounter:serviceProvider',
            '*:encounter > Encounter:location.location',
        ],
        eventModal: AppointmentEventModal,
        condition: [
            { field: 'resource', eq: 'Appointment' },
            { field: 'subtype', eq: ['appointment'] },
        ],
        rowType: 'appointments',
        viewer: {
            tableDetails: AsStringTableDetails,
        },
    },
    appointment_next: {
        needDeepInclude: [
            '*:encounter > Encounter:serviceProvider',
            '*:encounter > Encounter:location.location',
        ],
        eventModal: AppointmentEventModal,
        condition: [
            { field: 'resource', eq: 'Appointment' },
            { field: 'subtype', eq: ['next_appointment'] },
        ],
        rowType: 'appointments',
        viewer: {
            tableDetails: AsStringTableDetails,
        },
    },
};
