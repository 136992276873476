var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { BaseService } from '../baseService';
export class Symptoms extends BaseService {
    constructor(props) {
        super(props);
    }
    read(params, options) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            let res;
            res = yield this.api.fetchLoggedV3('symptoms.read', params, options);
            return Object.assign(Object.assign({}, res), { preparedValue: (_a = res.prepared) === null || _a === void 0 ? void 0 : _a.symptom });
        });
    }
    list(params, options) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            let res;
            res = yield this.api.fetchLoggedV3('symptoms.list', params, options);
            return Object.assign(Object.assign({}, res), { preparedValue: ((_a = res.prepared) === null || _a === void 0 ? void 0 : _a.symptoms.items) || [] });
        });
    }
    check(params, options) {
        return __awaiter(this, void 0, void 0, function* () {
            let res;
            params.symptomIds = params.symptomIds || [];
            params.answers = params.answers || {};
            res = yield this.api.fetchLoggedV3('symptoms.check', params, Object.assign({ prepareResultParams: { mapping: false } }, options));
            return Object.assign(Object.assign({}, res), { preparedValue: res.prepared });
        });
    }
    include(params, options) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            let res;
            res = yield this.api.fetchLoggedV3('symptoms.include', params, options);
            return Object.assign(Object.assign({}, res), { preparedValue: (_a = res.prepared) === null || _a === void 0 ? void 0 : _a.symptom });
        });
    }
    exclude(params, options) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            let res;
            res = yield this.api.fetchLoggedV3('symptoms.exclude', params, options);
            return Object.assign(Object.assign({}, res), { preparedValue: (_a = res.prepared) === null || _a === void 0 ? void 0 : _a.symptom });
        });
    }
    /*
     * Custom Methods
     */
    getAll(params, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.list(Object.assign({ limit: 1000, order: [
                    ['category', 'asc'],
                    ['name', 'asc'],
                ] }, params), options);
        });
    }
}
