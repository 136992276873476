var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import _ from 'lodash';
import { BaseService } from '../baseService';
export class PracticeServices extends BaseService {
    constructor(props) {
        super(props);
    }
    read(params, options) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            let res;
            res = yield this.api.fetchV3('practice_services.read', params, options);
            return Object.assign(Object.assign({}, res), { preparedValue: (_a = res.prepared) === null || _a === void 0 ? void 0 : _a.practiceService });
        });
    }
    list(params, options) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            let res;
            res = yield this.api.fetchV3('practice_services.list', params, options);
            return Object.assign(Object.assign({}, res), { preparedValue: ((_a = res.prepared) === null || _a === void 0 ? void 0 : _a.practiceServices.items) || [] });
        });
    }
    /*
     * Custom Methods
     */
    getFiltered({ id, practiceIds, serviceIds, limit = 1000, }, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const filters = [{ eq: ['is_active', true] }];
            if (id) {
                filters.push({ eq: ['id', id] });
            }
            else {
                if (_.isArray(practiceIds) && practiceIds.length) {
                    filters.push({ in: ['practice_id', practiceIds] });
                }
                if (_.isArray(serviceIds) && serviceIds.length) {
                    filters.push({ in: ['service_id', serviceIds] });
                }
            }
            return yield this.list({ filter: { and: filters }, limit }, options);
        });
    }
}
