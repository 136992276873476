import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'

import i18n from 'i18next'
import _ from 'lodash'
import { useLoader } from 'ziphy-web-shared/basic/hooks'
import { formatDate } from 'ziphy-web-shared/basic/utils/time'

import MediaPreview from '@library/media/MediaPreview'
import Modal from '@library/modal/Modal'
import ModalToolbarBtn from '@library/modal/toolbar/ModalToolbarBtn'
import DtlGroup from '@library/ui/dtl/DtlGroup'
import DtlItem from '@library/ui/dtl/DtlItem'

import { resourceExtractor } from '@fhir/extractor'

import { openEventModal } from '@ps/helpers/events'
import { useGetMediaItems } from '@ps/library/CustomHooks'
import RoleName from '@ps/library/RoleName'

import { $psHistory, $psResources } from '@store'

import styles from './BaseDocuments.module.scss'

const BaseDocumentsModal = ({ _core, baseItem, items }) => {
  const { t } = useTranslation()

  const baseEvent = baseItem.event

  const apptEvent = useMemo(() => {
    return $psHistory.getEventById(baseEvent.appointmentId, 'appointment_main')
  }, [$psHistory.events, baseEvent])

  const { isLoading } = useLoader(async () => {
    const events = [..._.map(items, (x) => x.event), apptEvent]
    return await $psResources.getCompleteResources({ items: events })
  })

  const baseExtractor = useMemo(() => {
    let resource = baseItem.resource || $psResources.getById(baseItem.event.id)
    return resourceExtractor(resource)
  }, [isLoading])

  const relatedAppt = useMemo(() => {
    const resource = $psResources.getById(apptEvent.id)
    const extractor = resourceExtractor(resource)
    return { event: apptEvent, resource, extractor }
  }, [isLoading])

  const mediaItems = useGetMediaItems({ isLoading, items })

  return (
    <Modal centered scrollable size="xl" className={styles.modal}>
      <div className="modal-toolbar">
        <ModalToolbarBtn type="close" action={_core.onHide} />
      </div>

      <Modal.Header>
        <div className="modal-header-title title-md text-first-letter-uppercase">
          {items?.length > 1
            ? baseExtractor?.asString.type ||
              (i18n.exists(baseEvent.label) && t(baseEvent.label)) ||
              baseExtractor?.asString.full
            : baseExtractor?.asString.typeDescription || baseExtractor?.asString.full}
        </div>
      </Modal.Header>

      <Modal.Body isLoading={isLoading} className="styled_scroll">
        <DtlGroup className="row pb-30 row--mb-20">
          <DtlItem className="col-12 col-lg-4" label={t('ps.label.upload_date')}>
            {formatDate(baseEvent.effectiveDate)}
          </DtlItem>

          {baseExtractor?.operator && (
            <DtlItem className="col-12 col-lg-4" label={t('ps.label.operator')}>
              {baseExtractor.operator.name.fullName} <RoleName role={baseExtractor.operator.role} />
            </DtlItem>
          )}

          {relatedAppt?.extractor?.asString?.appointmentType && (
            <DtlItem className="col-12 col-lg-4" label={t('ps.label.appointment')}>
              {
                <span
                  className="a"
                  onClick={() =>
                    openEventModal([_.pick(relatedAppt, ['event', 'resource'])], false)
                  }
                >
                  {relatedAppt.extractor.asString.appointmentType}
                </span>
              }
            </DtlItem>
          )}
        </DtlGroup>

        <div className="title-sm">
          {t(
            baseExtractor?.resource?.resourceType === 'DocumentReference'
              ? 'ps.label.files'
              : 'ps.label.media',
          )}
        </div>
        <MediaPreview items={mediaItems} />
      </Modal.Body>
    </Modal>
  )
}

export default observer(BaseDocumentsModal)
