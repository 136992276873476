import AllergyIntoleranceDetails from '@ps/library/eventsViewer/details/AllergyIntoleranceDetails';
import CarePlanDetails from '@ps/library/eventsViewer/details/CarePlanDetails';
import ConditionDetails from '@ps/library/eventsViewer/details/ConditionDetails';
import ImmunizationDetails from '@ps/library/eventsViewer/details/ImmunizationDetails';
import ObservationDetails from '@ps/library/eventsViewer/details/ObservationDetails';
import ObservationExaminationDetails from '@ps/library/eventsViewer/details/ObservationExaminationDetails';
import ProcedureDetails from '@ps/library/eventsViewer/details/ProcedureDetails';
import ServiceRequestDetails from '@ps/library/eventsViewer/details/ServiceRequestDetails';
import AsStringTableDetails from '@ps/library/eventsViewer/tableDetails/AsStringTableDetails';
import CarePlanTableDetails from '@ps/library/eventsViewer/tableDetails/CarePlanTableDetails';
import HistoryEventModal from '@ps/library/modals/HistoryEvent.modal';
export const medicalHistoryConfig = {
    medical_history_observation: {
        eventModal: HistoryEventModal,
        condition: {
            and: [
                { field: 'resource', eq: 'Observation' },
                { field: 'diagnosticreportId', exists: false },
            ],
            or: [
                { field: 'subtype', eq: ['medical_history'] },
                { field: 'subtype', eq: ['medical_history', 'intake'] },
                { field: 'subtype', eq: ['medical_history', 'appointment_reason_symptom'] },
            ],
        },
        rowType: 'medical_history',
        viewer: {
            tableDetails: AsStringTableDetails,
            details: ObservationDetails,
        },
    },
    medical_history_condition: {
        eventModal: HistoryEventModal,
        condition: {
            and: [{ field: 'resource', eq: 'Condition' }],
            or: [
                { field: 'subtype', eq: ['medical_history'] },
                { field: 'subtype', eq: ['medical_history', 'intake'] },
            ],
        },
        rowType: 'medical_history',
        viewer: {
            tableDetails: AsStringTableDetails,
            details: ConditionDetails,
        },
    },
    medical_history_allergy: {
        eventModal: HistoryEventModal,
        condition: {
            and: [{ field: 'resource', eq: 'AllergyIntolerance' }],
            or: [
                { field: 'subtype', eq: ['medical_history', 'allergy'] },
                { field: 'subtype', eq: ['medical_history', 'allergy', 'intake'] },
            ],
        },
        rowType: 'medical_history',
        viewer: {
            tableDetails: AsStringTableDetails,
            details: AllergyIntoleranceDetails,
        },
    },
    medical_history_immunization: {
        eventModal: HistoryEventModal,
        condition: {
            and: [{ field: 'resource', eq: 'Immunization' }],
            or: [
                { field: 'subtype', eq: ['medical_history', 'immunization'] },
                { field: 'subtype', eq: ['medical_history', 'immunization', 'intake'] },
            ],
        },
        rowType: 'medical_history',
        viewer: {
            tableDetails: AsStringTableDetails,
            details: ImmunizationDetails,
        },
    },
    medical_history_assessment: {
        // todo: LEGACY
        eventModal: HistoryEventModal,
        condition: [
            { field: 'resource', eq: 'Condition' },
            { field: 'subtype', eq: ['medical_history', 'diagnosis'] },
        ],
        rowType: 'medical_history',
        viewer: {
            tableDetails: AsStringTableDetails,
            details: ConditionDetails,
        },
    },
    medical_history_procedure: {
        eventModal: HistoryEventModal,
        condition: {
            and: [{ field: 'resource', eq: 'Procedure' }],
            or: [
                { field: 'subtype', eq: ['medical_history', 'procedure'] },
                { field: 'subtype', eq: ['medical_history', 'procedure', 'intake'] },
            ],
        },
        rowType: 'medical_history',
        viewer: {
            tableDetails: AsStringTableDetails,
            details: ProcedureDetails,
        },
    },
    medical_history_vitals: {
        eventModal: HistoryEventModal,
        condition: {
            and: [{ field: 'resource', eq: 'Observation' }],
            or: [
                { field: 'subtype', in: ['medical_history', 'note', 'vital'] },
                { field: 'subtype', in: ['medical_history', 'lab'] },
            ],
        },
        rowType: 'medical_history',
        viewer: {
            tableDetails: AsStringTableDetails,
            details: ObservationDetails,
        },
    },
    medical_history_careplan: {
        eventModal: HistoryEventModal,
        condition: [
            { field: 'resource', eq: 'CarePlan' },
            { field: 'subtype', eq: ['medical_history', 'note', 'care_plan'] },
        ],
        rowType: 'medical_history',
        viewer: {
            tableDetails: CarePlanTableDetails,
            details: CarePlanDetails,
        },
    },
    medical_history_examination: {
        eventModal: HistoryEventModal,
        condition: [
            { field: 'resource', eq: 'Observation' },
            { field: 'subtype', eq: ['medical_history', 'note', 'examination'] },
        ],
        appTypes: ['provider'],
        rowType: 'medical_history',
        viewer: {
            tableDetails: AsStringTableDetails,
            details: ObservationExaminationDetails,
        },
    },
    medical_history_hpi: {
        eventModal: HistoryEventModal,
        condition: [
            { field: 'resource', eq: 'Observation' },
            { field: 'subtype', eq: ['medical_history', 'note', 'hpi'] },
        ],
        appTypes: ['provider'],
        rowType: 'medical_history',
        viewer: {
            tableDetails: AsStringTableDetails,
            details: ObservationDetails,
        },
    },
    medical_history_service_request_order: {
        eventModal: HistoryEventModal,
        condition: {
            and: [{ field: 'resource', eq: 'ServiceRequest' }],
            or: [{ field: 'subtype', eq: ['medical_history', 'order'] }],
        },
        rowType: 'medical_history',
        viewer: {
            tableDetails: AsStringTableDetails,
            details: ServiceRequestDetails,
        },
    },
    medical_history_service_request_referral: {
        eventModal: HistoryEventModal,
        condition: {
            and: [{ field: 'resource', eq: 'ServiceRequest' }],
            or: [{ field: 'subtype', eq: ['medical_history', 'referral'] }],
        },
        rowType: 'medical_history',
        viewer: {
            tableDetails: AsStringTableDetails,
            details: ServiceRequestDetails,
        },
    },
};
