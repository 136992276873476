import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';
import Icon from 'ziphy-web-shared/basic/assets/images/Icon';
import { isAbsoluteEmpty } from 'ziphy-web-shared/basic/helpers';
import MultiSelect from 'ziphy-web-shared/basic/lib/forms/fields/multiSelect/MultiSelect';
import styles from './SelectWithAdditional.module.scss';
const SelectWithAdditional = ({ field, items, additionalField, itemsFullWidth, onConfirm, label, placeholder, mode, isActive, }) => {
    const anyFieldHasError = field.isInvalid || (additionalField === null || additionalField === void 0 ? void 0 : additionalField.isInvalid);
    return (_jsxs("div", Object.assign({ className: classNames(styles.wrapper, additionalField && styles.withAdditionalVisible, additionalField && anyFieldHasError && styles.withAdditionalVisibleError, !isAbsoluteEmpty(field.value) && styles.notEmpty, onConfirm && styles.confirmable, isActive && styles.active) }, { children: [_jsx(MultiSelect, { wrapperClassName: styles.selectWrapper, className: styles.select, innerClassNames: styles.selectForBorders, items: items, field: field, label: label, placeholder: placeholder, mode: mode, isActive: isActive, itemsFullWidth: itemsFullWidth }), additionalField &&
                React.cloneElement(additionalField.component, additionalField.fieldType === 'select'
                    ? {
                        wrapperClassName: styles.secondarySelectWrapper,
                        className: styles.secondarySelect,
                        innerClassNames: styles.secondarySelectBorders,
                    }
                    : {
                        className: styles.secondaryInput,
                    }), field.value && onConfirm && (_jsx("div", Object.assign({ onClick: () => onConfirm(), className: styles.submitBtn }, { children: _jsx(Icon, { type: 'check' }) })))] })));
};
export default observer(SelectWithAdditional);
