import { useEffect } from 'react';
export const useRemoveRootLoader = (props) => {
    useEffect(() => {
        if (!props.isInitialized)
            return;
        const rootLoader = document.querySelector('#root-loader');
        const rootLoaderStyles = document.querySelector('#root-loader-styles');
        rootLoader.classList.add('root-loader--hide');
        setTimeout(() => {
            rootLoader === null || rootLoader === void 0 ? void 0 : rootLoader.remove();
            rootLoaderStyles === null || rootLoaderStyles === void 0 ? void 0 : rootLoaderStyles.remove();
        }, props.duration || 300);
    }, [props.isInitialized]);
};
