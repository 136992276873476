import moment from 'moment-timezone';
export const getParsedDate = (value, timezone) => {
    if (timezone) {
        return moment(value).tz(timezone);
    }
    else {
        return moment(value);
    }
};
export const getDaysBoard = ({ dates, visibleDaysCount, todayLabel, timezone, isMobile = false, }) => {
    const today = moment.tz(timezone);
    const slicedDates = isMobile ? dates : dates.slice(0, visibleDaysCount);
    let prevMonth = null;
    const result = slicedDates.map((item) => {
        const date = getParsedDate(item.key, timezone);
        const month = date.format('MMM');
        const properties = {
            isLocked: !item.value,
            isToday: today.isSame(date, 'day'),
            day: date.format('DD'),
            weekDay: date.format('ddd'),
            value: date,
            valueString: item.key,
            notice: today.isSame(date, 'day') ? todayLabel : undefined,
        };
        if (!prevMonth) {
            prevMonth = month;
        }
        else if (prevMonth !== month) {
            properties.notice = month;
            prevMonth = month;
        }
        return properties;
    });
    return result;
};
