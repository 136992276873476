import _ from 'lodash';
import queryString from 'query-string';
export const qsParseOptions = {
    arrayFormat: 'comma',
    parseBooleans: true,
    parseNumbers: true,
};
export function routerSearchParams({ history, location, search, type = 'auto', qsOptions = qsParseOptions, }) {
    var _a;
    search = search || (location === null || location === void 0 ? void 0 : location.search) || ((_a = history.location) === null || _a === void 0 ? void 0 : _a.search);
    const searchParams = queryString.parse(search, qsOptions);
    const setSearchParams = (payload) => {
        const params = {
            search: queryString.stringify(payload, qsOptions),
        };
        if (type === 'push') {
            history.push(params);
        }
        else if (type === 'replace') {
            history.replace(params);
        }
        else {
            if (_.isEqual(searchParams, payload)) {
                history.replace(params);
            }
            else {
                history.push(params);
            }
        }
    };
    const getAllQueryParams = () => {
        return searchParams;
    };
    const getQueryParam = (key) => {
        return searchParams[key];
    };
    const setQueryParam = (params, value) => {
        const updatedParams = Object.assign({}, searchParams);
        if (typeof params === 'object') {
            Object.entries(params).forEach(([key, val]) => {
                if (val == null) {
                    delete updatedParams[key];
                }
                else {
                    if (typeof val === 'number') {
                        val = val.toString();
                    }
                    updatedParams[key] = val;
                }
            });
        }
        else if (typeof params === 'string') {
            if (value == null) {
                delete updatedParams[params];
            }
            else {
                if (typeof value === 'number') {
                    value = value.toString();
                }
                updatedParams[params] = value;
            }
        }
        setSearchParams(updatedParams);
    };
    const removeQueryParam = (params) => {
        const updatedParams = Object.assign({}, searchParams);
        if (typeof params === 'string') {
            delete updatedParams[params];
        }
        else if (Array.isArray(params)) {
            params.forEach((key) => delete updatedParams[key]);
        }
        setSearchParams(updatedParams);
    };
    const clearQueryParams = () => setSearchParams({});
    return {
        getAll: getAllQueryParams,
        get: getQueryParam,
        set: setQueryParam,
        remove: removeQueryParam,
        clear: clearQueryParams,
    };
}
