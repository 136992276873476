import React from 'react'

import { observer } from 'mobx-react-lite'

import classNames from 'classnames'
import { ClickOutside } from 'ziphy-web-shared/basic/lib/utilities'
import { $layouts } from 'ziphy-web-shared/basic/utils/layouts'

import styles from '@src/layouts/sidebar/Sidebar.module.scss'
import SidebarFooter from '@src/layouts/sidebar/SidebarFooter'
import SidebarLogo from '@src/layouts/sidebar/SidebarLogo'
import SidebarMenu from '@src/layouts/sidebar/SidebarMenu'

const Sidebar = () => {
  const style = {
    bottom: $layouts.marginBottom,
  }

  function hideSidebar() {
    $layouts.sidebarToggle(false)
  }

  return (
    <ClickOutside
      onClick={hideSidebar}
      className={classNames('sidebar', styles.wrapper, 'styled_scroll', 'styled_scroll--bg-gray')}
      style={style}
    >
      <SidebarLogo onHideSidebar={hideSidebar} />
      <div className={styles.body}>
        <SidebarMenu onHideSidebar={hideSidebar} />
      </div>
      <SidebarFooter />
    </ClickOutside>
  )
}

export default observer(Sidebar)
