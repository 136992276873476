import React, { useEffect, useRef, useState } from 'react'

import { isAndroid } from 'react-device-detect'
import { useTranslation } from 'react-i18next'

import { fetchBlob } from 'ziphy-web-shared/basic/helpers'

import styles from '@library/media/mediaTypes/viewer/Types.module.scss'

const BaseDocumentPdf = ({
  src,
  viewParams = '#toolbar=0',
  onIsLoading,
  onIsDefault,
  openInIframe = true,
  ...passedProps
}) => {
  const [blob, setBlob] = useState()
  const [isError, setIsError] = useState(false)
  const { t } = useTranslation()

  const loaded = () => onIsLoading(false)

  useEffect(() => {
    if (isAndroid) {
      loaded()
      return
    }

    if (openInIframe) return

    fetchBlob(src)
      .then((response) => {
        if (response.status !== 200) {
          throw new Error('Not correct url')
        } else {
          setBlob(URL.createObjectURL(response.data))
        }
      })
      .catch(() => {
        setIsError(true)
      })
      .finally(loaded)
  }, [openInIframe, src])

  useEffect(() => {
    return () => blob && URL.revokeObjectURL(blob)
  }, [blob])

  if (isAndroid) {
    return <div className={styles.warning}>{t('ps.alert.must_download_pdf')}</div>
  }

  return (
    <>
      {openInIframe ? (
        <iframe src={src + viewParams} onLoad={loaded} />
      ) : (
        blob && <object data={blob + viewParams} type="application/pdf" {...passedProps} />
      )}
      {isError && (
        <div className={styles.defaultContent}>
          <div className={styles.icon} />
          <div className={styles.text}>{t('errors.preview_unavailable')}</div>
        </div>
      )}
    </>
  )
}

export default BaseDocumentPdf
