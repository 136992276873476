import _ from 'lodash';
import { findResourceById, findResourcesByCondition, resourceExtractor, } from '../extractor';
import { getReferenceId } from '../fhir.helpers';
class ExtractorBase {
    constructor(context) {
        this.context = context;
    }
    SET(resource) {
        this.resource = resource;
    }
    getContainedValue(id) {
        let result;
        if ((id === null || id === void 0 ? void 0 : id.startsWith('#')) && 'contained' in this.resource) {
            const trimmedId = _.trimStart(id, '#');
            result = _.find(this.resource.contained, (x) => {
                // hack for TS, needed fix api prepareResponseData
                const containedId = _.get(x, 'Id') || x.id;
                return containedId === trimmedId || containedId === id;
            });
        }
        return result;
    }
    getChildExtractor(obj) {
        const id = obj === null || obj === void 0 ? void 0 : obj.reference;
        let resource = findResourceById(id);
        if (!resource) {
            resource = this.getContainedValue(id);
        }
        return resourceExtractor(resource);
    }
    getChildExtractorByCondition(callback) {
        const id = getReferenceId({
            id: this.resource.id,
            resourceType: this.resource.resourceType,
        });
        return findResourcesByCondition((r) => callback(r, id)).map((resource) => resourceExtractor(resource));
    }
}
export default ExtractorBase;
