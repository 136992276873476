import { makeAutoObservable } from 'mobx';
import { getServiceRequestSubType } from '../extractor.helpers';
import { IExtractorServiceRequestSubType } from '../extractor.types';
import ExtractorBase from '../extractors/Base';
import { prepareMeta } from '../fhir.converters';
import { getCodingObjects, getExtensionObjects, getIdentifierObjects } from '../fhir.helpers';
import { joinValuesToString } from '../fhir.utils';
class ExtractorServiceRequest {
    constructor(resource) {
        this.base = new ExtractorBase(this);
        this.base.SET(resource);
        makeAutoObservable(this);
    }
    get resource() {
        return this.base.resource;
    }
    get subType() {
        return getServiceRequestSubType(this);
    }
    get isReferral() {
        return this.subType === IExtractorServiceRequestSubType.REFERRAL;
    }
    get isOrder() {
        return this.subType === IExtractorServiceRequestSubType.ORDER;
    }
    /*
     * RESOURCE - Minimally prepared resource data, prepareConverters, valueObjects, etc.
     */
    get extensions() {
        return getExtensionObjects(this.resource.extension);
    }
    get identifiers() {
        return getIdentifierObjects(this.resource.identifier);
    }
    get categories() {
        return getCodingObjects(this.resource.category);
    }
    get codes() {
        return getCodingObjects(this.resource.code);
    }
    get performerTypes() {
        return getCodingObjects(this.resource.performerType);
    }
    get reasonCodes() {
        return getCodingObjects(this.resource.reasonCode);
    }
    // other in the FHIR - Group | Device
    get subject() {
        return this.base.getChildExtractor(this.resource.subject);
    }
    // other in the FHIR - Device
    get requester() {
        return this.base.getChildExtractor(this.resource.requester);
    }
    get meta() {
        return prepareMeta(this.resource.meta);
    }
    /*
     * READ - PREPARED Values
     */
    get zid() {
        return this.identifiers.first({ config: 'zid' });
    }
    get codeObj() {
        return this.codes.first();
    }
    get category() {
        return this.categories.first();
    }
    get performerType() {
        return this.performerTypes.first();
    }
    get reasonCode() {
        return this.reasonCodes.first();
    }
    /*
     * STRING Values
     */
    get asString() {
        var _a, _b, _c, _d;
        const tmp = {
            codeDisplay: (_a = this.codeObj) === null || _a === void 0 ? void 0 : _a.display,
            performerType: (_b = this.performerType) === null || _b === void 0 ? void 0 : _b.display,
            reasonCode: (_c = this.reasonCode) === null || _c === void 0 ? void 0 : _c.display,
            category: (_d = this.category) === null || _d === void 0 ? void 0 : _d.display,
            full: '',
            short: '',
        };
        tmp.full = joinValuesToString(this.isReferral ? [tmp.performerType, tmp.reasonCode] : [tmp.category, tmp.codeDisplay], ' • ');
        tmp.short = this.isReferral ? tmp.performerType : tmp.codeDisplay;
        return tmp;
    }
}
export default ExtractorServiceRequest;
