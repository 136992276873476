var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { makeAutoObservable, runInAction } from 'mobx';
import { waitForVariable } from 'ziphy-web-shared/basic/helpers';
class AwsCaptchaStore {
    constructor() {
        this.enabled = false;
        makeAutoObservable(this);
    }
    SET_NEED_CAPTCHA(value) {
        runInAction(() => (this.enabled = value));
    }
    request({ url, requestParams }) {
        return __awaiter(this, void 0, void 0, function* () {
            let response;
            let error;
            try {
                yield waitForVariable('AwsWafIntegration');
                // @ts-ignore
                const client = window.AwsWafIntegration;
                const res = yield client.fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(requestParams),
                });
                this.SET_NEED_CAPTCHA(false);
                response = yield res.json();
            }
            catch (e) {
                error = e;
                if (!e.code) {
                    this.SET_NEED_CAPTCHA(true);
                }
            }
            return { response, error };
        });
    }
}
export const $awsCaptcha = new AwsCaptchaStore();
