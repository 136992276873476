import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';
import _ from 'lodash';
import styles from './PaymentMethodsIcon.module.scss';
const PaymentMethodsIcon = ({ brand, className }) => {
    brand = _.kebabCase(brand.toLowerCase());
    return _jsx("div", { className: classNames(styles.icon, styles[brand], className) });
};
export default observer(PaymentMethodsIcon);
