import _ from 'lodash'
import moment from 'moment-timezone'
import { apiService, eventsService } from 'ziphy-web-shared/basic/api'
import {
  APPOINTMENTS_DEFAULT_EXPAND_LIST,
  APPOINTMENTS_DEFAULT_EXPAND_READ,
} from 'ziphy-web-shared/basic/entities/appointments'
import { $auth } from 'ziphy-web-shared/basic/entities/auth'
import { APP_TYPE, isProviderApp, isStaffApp } from 'ziphy-web-shared/basic/helpers'
import { $router } from 'ziphy-web-shared/basic/utils/router'

import { prepareApptListByMap } from '@services/client.helpers'

import ChatModal from '@library/chat/Chat.modal'

import { $modal } from '@store'

import { apptEvents } from '@config/appts'
import { apptStatuses } from '@config/apptsCore'
import mainConfig from '@config/main'
import { routeActions } from '@config/routeActions'

class coreClient {
  //
  // Notification center
  //
  async getNotifications({ lastTime = false }) {
    if (_.isObject(lastTime)) {
      lastTime = lastTime.format('YYYY-MM-DDTHH:mm:ss')
    }

    const response = await apiService.fetchLoggedV3('user.notifications.list', {
      filter: {
        and: [{ gt: ['created', '$datetime:' + lastTime] }, { eq: ['role', APP_TYPE] }],
      },
      limit: 1000,
    })

    return _.get(response, 'prepared.notifications.items', [])
  }

  async getEventsByNotifications(list = []) {
    let result = _.cloneDeep(list)

    const eventsIds = []
    _.forEach(result, (x) => eventsIds.push(x.eventId))

    if (eventsIds.length > 0) {
      const eventsResponse = await eventsService.search({ ids: eventsIds })
      const events = eventsResponse.preparedValue

      if (events?.length > 0) {
        result = result.map((item) => {
          item.event = events?.find((x) => x.id === item.eventId) || {}
          return item
        })
      }
    }

    return result
  }

  async getPreparedNotifications(params) {
    let result

    result = await this.getNotifications(params)
    result = await this.getEventsByNotifications(result)

    const staffActors = ['agent', 'provider']

    result = result.map((x) => {
      x.actions = []
      x.hideIfViewed = false

      const actualLimit = moment
        .utc(x.created)
        .add(mainConfig.notifications.actualPeriod, 'minutes')
      x.isActualFn = function () {
        return !this.viewed && moment.utc().isSameOrBefore(actualLimit)
      }
      x.isActual = x.isActualFn()

      const { appointmentId, actorRole } = x.event

      if (!appointmentId) {
        x.actions.push({ type: 'mark_as_read' })
        return x
      }

      const forClient = $auth.role?.role === 'client'
      const isGeneralChat = _.find(x.event?.recipients, (account) => account.role === 'client')

      if (x.notification === 'chat_message_received') {
        x.hideIfViewed = true
        x.actions.push({
          appointmentId,
          type: 'reply',
          action() {
            $modal.add(ChatModal, {
              appointmentId,
              partnerRole: forClient || !_.includes(staffActors, actorRole) ? 'general' : actorRole,
              staffOnly: isGeneralChat ? false : _.includes(staffActors, actorRole),
            })
          },
          viewOnClick: false,
        })
      }

      x.actions.push({
        appointmentId,
        type: 'view_appointment',
        async action() {
          const encounterId =
            x.event.encounterId ||
            _.head(x.event.updates.appointment?.encounterIds) ||
            _.head(x.event.updates.providerAppointmentSnapshot?.encounterIds)

          $router.executeAutoTarget(routeActions.APPT({ appointmentId, encounterId }))
        },
      })

      if (!x.viewed) {
        x.actions.push({ type: 'mark_as_read' })
      }

      return x
    })

    return result
  }

  async viewNotifications({ ids = [] }) {
    let requestData = ids.map((id) => ({ method: 'user.notifications.view', params: { id } }))
    let result = []

    if (requestData.length) {
      const response = await apiService.fetchLoggedBatchV3(requestData)

      _.forEach(response, (x) => {
        const item = _.get(x, 'prepared.notification')

        if (item) {
          result.push(item)
        }
      })
    }

    return result
  }

  //
  // Appointments ACTIONS
  //
  async getAppointments({
    practiceId,
    type,
    fromDate, // todo: legacy
    toDate, // todo: legacy
    dateFrom = fromDate,
    dateTo = toDate,
    expand = APPOINTMENTS_DEFAULT_EXPAND_LIST,
    getEvents = true,
    checkRoles = true,
    skipAlert = false,
    limit,
    offset,
  }) {
    let roleFilter = []
    let practiceFilter = []
    let statusFilter = []
    let dateFromFilter = []
    let dateToFilter = []
    let order

    if (checkRoles && isProviderApp()) {
      roleFilter.push({ in: ['provider_role_id', $auth.availableRoles.map((x) => x.id)] })
    }

    if (practiceId) {
      practiceFilter.push({
        in: ['practice_id', Array.isArray(practiceId) ? practiceId : [practiceId]],
      })
    }

    if (dateFrom) {
      if (typeof dateFrom === 'string') dateFrom = moment(dateFrom)
      dateFrom = dateFrom.utc().format('YYYY-MM-DDTHH:mm:ss')
      dateFromFilter = [{ gte: ['appointed_start', `$datetime:${dateFrom}`] }]
    }
    if (dateTo) {
      if (typeof dateTo === 'string') dateTo = moment(dateTo)
      dateTo = dateTo.utc().format('YYYY-MM-DDTHH:mm:ss')
      dateToFilter = [{ lte: ['appointed_start', `$datetime:${dateTo}`] }]
    }

    if (type === 'active') {
      statusFilter = [{ in: ['status', apptStatuses.active] }]
      order = [
        ['appointed_start', 'asc'],
        ['id', 'asc'],
      ]
      limit = 1000
    } else if (type === 'past') {
      statusFilter = [{ in: ['status', apptStatuses.past] }]
      order = [
        ['appointed_start', 'desc'],
        ['id', 'desc'],
      ]
    } else if (type === 'actual') {
      statusFilter = [
        {
          or: [
            { eq: ['status', 'started'] },
            { and: [{ in: ['status', apptStatuses.actual] }, ...dateFromFilter, ...dateToFilter] },
          ],
        },
      ]
      dateFromFilter = []
      dateToFilter = []

      order = [
        ['appointed_start', 'asc'],
        ['id', 'asc'],
      ]
      getEvents = false
    }

    let response = await apiService.fetchLoggedV3(
      'appointments.list',
      {
        expand,
        filter: {
          and: [
            ...roleFilter,
            ...practiceFilter,
            ...statusFilter,
            ...dateFromFilter,
            ...dateToFilter,
          ],
        },
        order,
        limit,
        offset,
      },
      { skipAlert, addition: type },
    )

    const appointments = response.prepared?.appointments?.items || []
    if (appointments.length) {
      if (getEvents) {
        const eventsResponse = await eventsService.search(
          {
            appointmentIds: appointments.map((x) => x.id),
            events: apptEvents.needForStatus,
          },
          { skipAlert: true, addition: type },
        )
        response.prepared.events = eventsResponse.preparedValue
      }
      response.prepared = prepareApptListByMap(response.prepared, appointments)
    } else {
      response.prepared = []
    }

    return response
  }

  async getOneAppointment({
    id,
    source = undefined,
    skipAlert,
    expand = APPOINTMENTS_DEFAULT_EXPAND_READ,
    getEvents = true,
    checkRoles = true,
  }) {
    const isSnapshot = isStaffApp() && source === 'snapshot'

    let method = 'appointments.read'
    let response

    if (isSnapshot) {
      method = 'appointment_snapshots.read_by_appointment'
      expand = APPOINTMENTS_DEFAULT_EXPAND_LIST
      expand.appointmentSnapshot = expand.appointment
      response = await apiService.fetchLoggedV3(
        method,
        { appointmentId: id, expand },
        { skipAlert },
      )
    } else {
      response = await apiService.fetchLoggedV3(method, { id, expand }, { skipAlert })
    }

    let appointmentId = response.prepared?.appointment?.id

    if (isSnapshot) {
      appointmentId = response.prepared?.appointmentSnapshot?.appointmentId
      if (appointmentId) {
        const tmp = _.cloneDeep(response.prepared.appointmentSnapshot)
        tmp.snapshotId = tmp.id
        tmp.id = tmp.appointmentId
        response.prepared.appointment = tmp
      }
    }

    if (checkRoles && isProviderApp()) {
      const providerRoleId = response.prepared?.appointment?.providerRoleId
      if ($auth.availableRoles.every((x) => x.id !== providerRoleId)) {
        response.prepared = null
        return response
      }
    }

    if (appointmentId) {
      if (getEvents) {
        const eventsResponse = await eventsService.search({
          appointmentIds: [appointmentId],
          events: apptEvents.needForStatus,
        })
        response.prepared.events = eventsResponse.preparedValue
      }
      response.prepared = _.first(
        prepareApptListByMap(response.prepared, [response.prepared.appointment]),
      )
    }

    return response
  }

  async readyForConference({ id }) {
    return await apiService.fetchLoggedV3('appointments.ready_for_conference', {
      id,
    })
  }

  async acceptAppointment({ id }) {
    return await apiService.fetchLoggedV3('appointments.accept', { id })
  }

  async startAppointment({ id }) {
    return await apiService.fetchLoggedV3('appointments.start', {
      id,
    })
  }

  async finishAppointment({ id }) {
    return await apiService.fetchLoggedV3('appointments.finish', { id })
  }

  async cancelAppointment({ id }) {
    return await apiService.fetchLoggedV3('appointments.cancel', { id })
  }

  async completeAppointment({ id, ok }) {
    return await apiService.fetchLoggedV3('appointments.confirm', {
      id,
      userConfirms: ok,
    })
  }

  async sendFeedbackAppointment({ id, feedback }) {
    return await apiService.fetchLoggedV3('appointments.feedback', {
      id,
      feedback,
    })
  }

  async sendSurveyAppointment({ id, survey }) {
    return await apiService.fetchLoggedV3('appointments.survey', { id, survey })
  }

  //
  // Profile
  //
  async getZoomSignature({ id, roleId }) {
    return await apiService.fetchLoggedV3(
      'appointments.get_meeting_signature',
      {
        id,
      },
      { roleId },
    )
  }

  //
  // Booking
  //
  async createAppointment(payload) {
    return await apiService.fetchLoggedV3('appointments.create', payload)
  }

  async rebookAppointment(payload) {
    return await apiService.fetchLoggedV3('appointments.book', payload)
  }

  async selfRebookAppointment(payload) {
    return await apiService.fetchLoggedV3('appointments.rebook', payload)
  }

  async changePaymentMethod(payload) {
    return await apiService.fetchLoggedV3('appointments.change_payment_method', payload)
  }
}

export default new coreClient()
