import { action, computed, makeAutoObservable, makeObservable, observable, runInAction } from 'mobx';
export class GlobalStorage {
    constructor({ idKey, updateCondition = (x) => true }) {
        this.asMap = new Map();
        this.idKey = idKey;
        this.updateCondition = action(updateCondition);
        makeObservable(this, {
            /* asMap - leads to more perenders outside the store,
             * and should be controlled with computed.struct for already filtered lists
             * by id, if necessary.
             *
             * get items() as computed.struct {
             *   return this.ids.map((id) => this.getById(id)).filter((x) => x)
             * }
             * */
            asMap: observable,
            /* asObject - at very large volumes problems may arise in theory. */
            asObject: computed.struct,
            UPDATE: action,
            DELETE: action,
        });
    }
    get asObject() {
        return Object.fromEntries(this.asMap);
    }
    UPDATE(value) {
        if (value === undefined || value === null)
            return;
        if ('items' in value)
            value = value.items;
        const item = Array.isArray(value) ? value[0] : value;
        const canUpdate = this.updateCondition(item);
        if (!canUpdate)
            return;
        runInAction(() => {
            if (Array.isArray(value)) {
                value.forEach((v) => this.asMap.set(v[this.idKey], v));
            }
            else {
                this.asMap.set(value[this.idKey], value);
            }
        });
    }
    DELETE(id) {
        this.asMap.delete(id);
    }
    clear() {
        this.asMap.clear();
    }
}
export class ContextController {
    constructor(storeCreator) {
        this.contexts = {}; // change T to any
        makeAutoObservable(this, { contexts: observable.shallow });
        this.storeCreator = storeCreator;
    }
    get(key, storeCreator) {
        if (this.contexts[key]) {
            return this.contexts[key];
        }
        runInAction(() => {
            this.contexts[key] = storeCreator ? storeCreator(key) : this.storeCreator(key);
        });
        return this.contexts[key];
    }
    delete(key) {
        delete this.contexts[key];
    }
    clear() {
        this.contexts = {};
    }
}
export class IdsController {
    constructor() {
        this.idsSet = new Set();
        makeAutoObservable(this, { list: computed.struct });
    }
    get list() {
        return Array.from(this.idsSet);
    }
    add(id) {
        this.idsSet.add(id);
    }
    delete(id) {
        this.idsSet.delete(id);
    }
    set(newIds) {
        this.idsSet = new Set(newIds);
    }
}
