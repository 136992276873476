var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { useLoader } from 'ziphy-web-shared/basic/hooks';
import Modal from '@library/modal/Modal';
import ModalToolbarBtn from '@library/modal/toolbar/ModalToolbarBtn';
import Button from '@nedoShared/basic/lib/simple/button/Button';
import { resourceExtractor } from '@fhir/extractor';
import { apptEventsSchema } from '@ps/config/events';
import { groupEventsBySubcategory } from '@ps/helpers/events';
import AppointmentEventDetails from '@ps/library/modals/appointmentEvent/AppointmentEventDetails';
import AppointmentEventTabs from '@ps/library/modals/appointmentEvent/AppointmentEventTabs';
import { $appts, $psHistory, $psResources } from '@store';
import { routeActions } from '@config/routeActions';
const AppointmentEventModal = ({ _core, baseItem }) => {
    var _a, _b, _c;
    const { t } = useTranslation();
    const event = baseItem.event;
    const [extractor, setExtractor] = useState();
    const [canGoToAppointment, setCanGoToAppointment] = useState(false);
    const eventsBySubcategory = useMemo(() => {
        const events = _.filter($psHistory.events, (x) => {
            if (x.appointmentId === event.id)
                return true;
            return ['document_hipaa_consent', 'document_telehealth_consent'].includes(x.configId);
        });
        return groupEventsBySubcategory(events, apptEventsSchema);
    }, [$psHistory.events, event]);
    const { isLoading } = useLoader(() => __awaiter(void 0, void 0, void 0, function* () {
        let events = [..._.flatten(Object.values(eventsBySubcategory)), event];
        const encounterEvent = $psHistory.getEncounterByAppointmentId(event.id);
        if (encounterEvent)
            events.push(encounterEvent);
        yield $psResources.getCompleteResources({ items: events });
        const extractor = resourceExtractor($psResources.getById(event.id));
        setExtractor(extractor);
        const res = yield $appts.checkOne({ id: extractor.asString.zid });
        setCanGoToAppointment(res.isSuccess);
    }));
    return (_jsxs(Modal, Object.assign({ centered: true, scrollable: true, size: "xxl", fullHeight: true }, { children: [_jsx("div", Object.assign({ className: "modal-toolbar" }, { children: _jsx(ModalToolbarBtn, { type: "close", action: _core.onHide }) })), !isLoading && ((_a = extractor.asString) === null || _a === void 0 ? void 0 : _a.appointmentType) && (_jsx(_Fragment, { children: _jsxs(Modal.Header, { children: [_jsxs("div", Object.assign({ className: "modal-header-title title-md" }, { children: [extractor.asString.appointmentType, ", ", (_b = extractor.start) === null || _b === void 0 ? void 0 : _b.asString] })), canGoToAppointment && (_jsx(Button, Object.assign({ autoTarget: routeActions.APPT({
                                appointmentId: extractor.asString.zid,
                                encounterId: (_c = extractor.encounter) === null || _c === void 0 ? void 0 : _c.asString.zid,
                            }), mode: ['sm', 'primary'], className: "mt-10" }, { children: t('ps.label.go_to_appointment') })))] }) })), _jsxs(Modal.Body, Object.assign({ isLoading: isLoading, className: "styled_scroll" }, { children: [_jsx(AppointmentEventDetails, { extractor: extractor }), _jsx(AppointmentEventTabs, { eventsBySubcategory: eventsBySubcategory })] }))] })));
};
AppointmentEventModal.displayName = 'AppointmentEventModal';
export default observer(AppointmentEventModal);
