import { routeActions } from '@config/routeActions'

export const bookingSchema = {
  home: {
    isInitPage: true,
    required: [],
    initFailAction: () => null,
    prevStep: () => null,
    nextStep: () => routeActions.BOOK_PATIENTS(),
  },
  patients: {
    required: ['place', 'service'],
    initFailAction: () => routeActions.BOOK_START(),
    prevStep: () => routeActions.BOOK_START(),
    nextStep: () => routeActions.BOOK_SYMPTOMS(),
  },
  symptoms: {
    required: ['place', 'service'],
    initFailAction: () => routeActions.BOOK_START(),
    prevStep: () => routeActions.BOOK_PATIENTS(),
    nextStep: () => routeActions.BOOK_DATE(),
  },
  date: {
    required: ['place', 'service', 'patients'],
    initFailAction: () => routeActions.BOOK_START(),
    prevStep: () => routeActions.BOOK_SYMPTOMS(),
    nextStep: () => routeActions.BOOK_FINISH(),
  },
  finish: {
    required: ['place', 'service', 'patients', 'practice'],
    initFailAction: () => routeActions.BOOK_START(),
    prevStep: () => routeActions.BOOK_DATE(),
    nextStep: ({ appointmentId }) => routeActions.APPT({ appointmentId }),
  },
}
