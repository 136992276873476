import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useTranslation } from 'react-i18next';
const AsStringTableDetails = ({ event, extractor }) => {
    const { t } = useTranslation();
    let asString = 'asString' in extractor ? extractor.asString : { full: '' };
    // temporarily fix
    if (typeof asString === 'string') {
        asString = { full: asString };
    }
    // temporarily fix
    return (_jsxs(_Fragment, { children: [asString.full, event.isSelfReported && (_jsx("span", Object.assign({ className: "badge-self_reported" }, { children: t('ps.label.self_reported') })))] }));
};
export default AsStringTableDetails;
