import React from 'react'

import { $loader } from 'ziphy-web-shared/basic/utils'

import clientService from '@services/client'

import SelectPaymentMethodModal from '@nedoShared/features/paymentMethods/paymentMethodsController/selectPaymentMethodModal/SelectPaymentMethod.modal'

import { $appts } from '@store'

const BookingRepayModal = ({ _core, appointment }) => {
  const handleOnSelect = $loader.registerHandler('appt-handle-action-button-repay', async (id) => {
    const res = await clientService.changePaymentMethod({
      id: appointment.id,
      paymentMethodId: id,
    })
    if (res.prepared) {
      await $appts.getOne({ id: appointment.id })
    }
  })

  return (
    <SelectPaymentMethodModal
      selectedId={appointment.paymentMethodId}
      onSelectId={handleOnSelect}
      _core={_core}
    />
  )
}

export default BookingRepayModal
