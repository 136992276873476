import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import _ from 'lodash';
import Icon from 'ziphy-web-shared/basic/assets/images/Icon';
import { getStylesMode } from 'ziphy-web-shared/basic/helpers';
import styles from './Checkbox.module.scss';
const Checkbox = ({ field, binaryValue, name, label, className = '', labelClass = '', isButton = false, readonly = false, mode, additionalField, }) => {
    const { t } = useTranslation();
    const { value, onChange, isInvalid } = field;
    const isBinary = useMemo(() => typeof binaryValue !== 'undefined', [binaryValue]);
    const isChecked = useMemo(() => {
        if (_.isBoolean(value)) {
            if (isBinary) {
                return value === binaryValue;
            }
            else {
                return value;
            }
        }
        else if (_.isArray(value) && typeof name !== 'undefined') {
            return value.indexOf(name) !== -1;
        }
        else if (_.isObject(value) && typeof name !== 'undefined') {
            return _.find(value, (x, key) => x && key === name);
        }
        else {
            return value === name;
        }
    }, [value, name]); //eslint-disable-line react-hooks/exhaustive-deps
    function onClick() {
        if (readonly) {
            return;
        }
        if (isBinary) {
            onChange(binaryValue, name);
        }
        else {
            onChange(!isChecked, name);
        }
    }
    return (_jsxs("div", Object.assign({ className: classNames(styles.wrapper, className && className, additionalField && styles.withAdditionalVisible) }, { children: [_jsxs("div", Object.assign({ className: classNames(styles.formCheck, mode && getStylesMode(mode, styles), isChecked && styles.checked, isButton && styles.btn, readonly && styles.readonly, !label && !binaryValue && styles.noLabel, isInvalid && styles.invalid), onClick: onClick }, { children: [_jsx("div", Object.assign({ className: styles.checkContainer }, { children: _jsx(Icon, { className: styles.icon, type: "check" }) })), (label || isBinary) && (_jsx("div", Object.assign({ className: classNames(styles.label, labelClass) }, { children: label || (isBinary && (binaryValue ? t('btn.yes') : t('btn.no'))) })))] })), additionalField &&
                React.cloneElement(additionalField.component, additionalField.fieldType === 'select'
                    ? {
                        wrapperClassName: styles.secondarySelectWrapper,
                        className: styles.secondarySelect,
                        innerClassNames: styles.secondarySelectBorders,
                    }
                    : {
                        className: styles.secondaryInput,
                        mode: 'lg',
                    })] })));
};
export default observer(Checkbox);
