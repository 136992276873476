import _ from 'lodash';
import moment from 'moment-timezone';
export const changeDateMonth = (date, isNextMonth) => {
    // Decrease year
    if (date.month() === 0 && !isNextMonth) {
        return date
            .clone()
            .set('year', date.year() - 1)
            .set('month', 11);
    }
    // Increase year
    if (date.month() === 11 && isNextMonth) {
        return date
            .clone()
            .set('year', date.year() + 1)
            .set('month', 0);
    }
    // Add or substract
    return date.clone().add(isNextMonth ? 1 : -1, 'months');
};
const _getCalendarCells = (date, shownOtherDays) => {
    const daysInMonth = date.daysInMonth();
    const calendarCells = [];
    const prepareCell = (date, dayNumber, isShown = true) => {
        return {
            text: isShown ? String(dayNumber) : '',
            value: date.clone().date(dayNumber),
        };
    };
    // Push current month day cells
    for (let i = 0; i < daysInMonth; i++) {
        calendarCells.push(prepareCell(date, i + 1));
    }
    // Fill in the cells of other months
    const cellsToAdd = 42 - daysInMonth;
    const weekdayNames = moment.weekdaysMin();
    // Add to start from previous month
    const firstWeekDay = date.clone().startOf('month').format('dd');
    const addToStart = weekdayNames.indexOf(firstWeekDay);
    if (addToStart > 0) {
        const lastMonth = date.clone().subtract(1, 'month');
        for (let i = 0; i < addToStart; i++) {
            calendarCells.unshift(prepareCell(lastMonth, lastMonth.daysInMonth() - i, shownOtherDays));
        }
    }
    // Add to end from next month
    const addToEnd = cellsToAdd - addToStart;
    if (addToEnd > 0) {
        const nextMonth = date.clone().add(1, 'month');
        for (let i = 0; i < addToEnd; i++) {
            calendarCells.push(prepareCell(nextMonth, i + 1, shownOtherDays));
        }
    }
    return calendarCells;
};
export const getCalendarRows = (date, shownOtherDays) => {
    const cells = _getCalendarCells(date, shownOtherDays);
    const itemOnRow = 7;
    return _.chunk(cells, itemOnRow);
};
const _getYearCells = (date, start, end) => {
    const cells = [];
    for (let i = start; i <= end; i++) {
        cells.push({
            text: String(i),
            value: date.clone().set('year', i),
        });
    }
    return cells;
};
export const getYearRows = (date, minDate, maxDate) => {
    const modelYear = date.get('year');
    let start = moment(minDate).get('year');
    let end = moment(maxDate).get('year');
    if (modelYear < start) {
        start = modelYear;
    }
    if (modelYear > end) {
        end = modelYear;
    }
    return _getYearCells(date, start, end);
};
const _getMonthCells = (date) => {
    const monthNames = moment.months();
    const cells = [];
    for (let i = 0; i <= 11; i++) {
        cells.push({
            text: monthNames[i],
            value: date.clone().set('month', i),
        });
    }
    return cells;
};
export const getMonthRows = (date) => {
    const cells = _getMonthCells(date);
    const itemOnRow = 3;
    return _.chunk(cells, itemOnRow);
};
