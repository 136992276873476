import { jsx as _jsx } from "react/jsx-runtime";
import React, { useLayoutEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { ErrorBoundary, useErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { setRG } from 'ziphy-web-shared/basic/utils/analytics';
import ErrorPage from '@pages/errors/ErrorPage';
import { $modal } from '@store';
const PageProvider = ({ children, onEnter, onLeave, onEnterScrollToTop }) => {
    const { t } = useTranslation();
    useLayoutEffect(() => {
        $modal.dropAll();
        if (onEnterScrollToTop) {
            window.scrollTo({ top: 0 });
        }
        if (onEnter)
            onEnter();
        return () => {
            if (onLeave)
                onLeave();
        };
    }, []);
    return (_jsx(ErrorBoundary, Object.assign({ fallbackRender: ({ error }) => {
            if (!error.manual) {
                setRG('send', {
                    error: error.message,
                });
            }
            return (_jsx(ErrorPage, { title: error.title || (!error.manual && t('alert.error.something_went_wrong')), message: error.messageComponent || error.message, button: error.manual ? error.button : false }));
        } }, { children: children })));
};
export default observer(PageProvider);
class PageLoadError extends Error {
    constructor(message, { title = '', messageComponent, button } = {}, manual = false) {
        super(message);
        this.name = 'PageLoadError';
        this.title = title;
        this.messageComponent = messageComponent;
        this.button = button;
        this.manual = manual;
    }
}
export const usePageErrorBoundary = () => {
    const errorBoundary = useErrorBoundary();
    return {
        error: (message, options) => {
            errorBoundary.showBoundary(new PageLoadError(message, options, true));
        },
    };
};
