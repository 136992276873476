import _ from 'lodash';
import { outputPresets, prepValuePresets, validationRules } from './Form.config';
import { FormTypes } from './Form.types';
const valueIsEmpty = (value) => _.isEmpty(_.isString(value) ? value.trim() : value);
const hasRule = (ruleName, rules) => _.find(rules, (x) => x.name === ruleName);
var FormHelpers;
(function (FormHelpers) {
    FormHelpers.presetHandler = (value, action, preset) => {
        if (!action)
            return value;
        const arr = _.isArray(action) ? action : [action];
        let result = value;
        _.forEach(arr, (item) => {
            let handler;
            if (preset === FormTypes.Preset.OUTPUT)
                handler = outputPresets[item];
            else if (preset === FormTypes.Preset.PREP_VALUE)
                handler = prepValuePresets[item];
            if (_.isFunction(handler))
                result = handler(result);
        });
        return result;
    };
    // STORE
    FormHelpers.updateStoreValue = ({ value, fieldName, store, storeUpdateMethod, }) => {
        if (!store) {
            return false;
        }
        if (storeUpdateMethod) {
            if (_.isString(storeUpdateMethod))
                store[storeUpdateMethod](value);
            else if (_.isFunction(storeUpdateMethod))
                storeUpdateMethod(value);
        }
        else if (_.isObject(store)) {
            const method = 'SET_' + _.toUpper(_.snakeCase(fieldName));
            if (_.isFunction(store[method]))
                store[method](value);
        }
    };
    // RULES
    FormHelpers.prepareRules = (rules) => {
        const result = [];
        _.forEach(rules, (item) => {
            if (_.isString(item))
                result.push({ name: item });
            else if (_.isFunction(item))
                result.push({ name: 'func', value: item });
            else if (_.isRegExp(item))
                result.push({ name: 'pattern', value: item });
            else if (!_.isEmpty(item))
                result.push(item);
        });
        return result;
    };
    // VALIDATIONS
    FormHelpers.validByRule = (value, rule, rules, selfForm) => {
        const { name = null, condition = null } = rule || {};
        if (_.isFunction(condition) && !condition(value, rule, rules, selfForm))
            return { isValid: true };
        const handler = validationRules[name];
        if (!handler) {
            console.warn('Unknown validation rule ', name);
            return { isValid: false, error: `unknown_rule: ${name}` };
        }
        const isNotReq = valueIsEmpty(value) &&
            !hasRule('required', rules) &&
            !hasRule('minLen1OrEmpty', rules) &&
            !hasRule('func', rules);
        if (isNotReq || handler(value, rule, rules, selfForm))
            return { isValid: true };
        return { isValid: false, error: name };
    };
    FormHelpers.getValidResult = (errors = []) => {
        const isValid = _.isEmpty(errors);
        return { isValid, isInvalid: !isValid, errors };
    };
})(FormHelpers || (FormHelpers = {}));
export default FormHelpers;
