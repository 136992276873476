import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { observer } from 'mobx-react-lite';
import Icon from 'ziphy-web-shared/basic/assets/images/Icon';
import { $layouts } from 'ziphy-web-shared/basic/utils/layouts';
import styles from './Burger.module.scss';
const Burger = () => {
    function handleClick(e) {
        e.stopPropagation();
        $layouts.sidebarToggle();
    }
    return (_jsx("div", Object.assign({ className: styles.burger, onClick: handleClick }, { children: _jsx(Icon, { type: "burger" }) })));
};
export default observer(Burger);
