import i18n from 'i18next'
import _ from 'lodash'

import { apptStatusRules } from '@config/appts'

export const apptStatuses = {
  list: ['booked', 'unbooked', 'started', 'finished', 'complete', 'canceled', 'exception'], //
  active: ['booked', 'unbooked', 'started', 'finished'],
  isActive(status) {
    return this.active.includes(status)
  },
  actual: ['booked', 'started', 'finished', 'complete', 'exception'],
  isActual(status) {
    return this.actual.includes(status)
  },
  past: ['complete', 'canceled', 'exception'],
  isPast(status) {
    return this.past.includes(status)
  },
  done: ['finished', 'complete', 'canceled', 'exception'],
  isDone(status) {
    return this.done.includes(status)
  },
  success: ['finished', 'complete'],
  isSuccess(status) {
    return this.success.includes(status)
  },
  progress: ['started', 'finished'],
  isProgress(status) {
    return this.progress.includes(status)
  },
}

export const availableActions = {
  viewForms: false,
  fillForms: false,
  fillIntake: false,

  callOffice: false,
  callEmergency: false,

  textGeneral: false,
  textStaff: false,

  viewTextGeneral: false,
  viewTextStaff: false,

  startVideo: false,

  // provider && agent
  accept: false,
  confirm: false,
  reject: false,
  shift: false,
  start: false,
  finish: false,
  pureFinish: true,

  // only provider
  note: false,
  notes: false,

  // only agent
  way: false,
  delay: false,

  // only client
  rebook: false,
  selfRebook: false,
  repay: false,
  complete: false,
  cancel: false,
  survey: false,
  feedback: false,
}

export function getLastEvents(needle, events) {
  const sortedEvents = _.orderBy(events, ['id'], ['desc']) // maybe sorting is not necessary

  let tmp = _.cloneDeep(needle)
  let result = []

  _.forEach(sortedEvents, (item) => {
    const index = tmp.indexOf(item.event)

    if (index !== -1) {
      result.push(item)
      tmp.splice(index, 1)
    }
  })

  return result
}

// isDefault - display in future steps in timeline
// isCurrent - if is current or was current
// isFinale - guaranteed last active step in timeline
export function getAllStatuses(fullAppt) {
  const steps = apptStatusRules(fullAppt)

  let result = steps.map(() => [])
  let current = false
  let final = false

  _.forEach(steps, (group, index) => {
    let rule

    _.forEach(group, (item) => {
      if (!final && item.isCurrent()) {
        current = item.id
        rule = item

        if (_.isFunction(item.isFinal)) {
          final = true
        }
      } else if (!rule && _.isFunction(item.isDefault) && item.isDefault()) {
        rule = item
      }
    })

    result[index] = _.pickBy(rule, (x) => !_.isFunction(x))
  })

  let isFound = false

  result = result.filter((x) => !_.isEmpty(x))
  result = result.map((item) => {
    let status = i18n.t(`appt_statuses.${item.id}.status`)

    let extend = {
      previous: false,
      current: false,
      texts: { status, ...item.texts },
      payAttention: [],
    }

    if (current) {
      if (item.id === current) {
        extend.current = true
        isFound = true

        _.forEach(item.requiredActions, (x, key) => {
          if (item.actions[key] && x) {
            extend.payAttention.push(key)
          }
        })
      } else if (!isFound) {
        extend.previous = true
      }
    }

    return { ...item, ...extend }
  })

  // console.log(
  //   fullAppt.appointment.id,
  //   fullAppt.appointment.type,
  // );
  // result.map(x => console.log(x.previous, x.current, x));
  // console.log('//////////////////');

  return result
}
