import { useEffect, useState } from 'react';
import _ from 'lodash';
export const useInnerValue = (value) => {
    const [innerValue, setInnerValue] = useState(value);
    useEffect(() => {
        if (!_.isEqual(value, innerValue)) {
            setInnerValue(value);
        }
    }, [value]);
    return [innerValue, setInnerValue];
};
