import { makeAutoObservable } from 'mobx';
import _ from 'lodash';
import { ENUMS_MEDICATIONS_STATUSES } from 'ziphy-web-shared/basic/entities/medical/medications';
import { getEnumObjectValue } from 'ziphy-web-shared/basic/helpers';
import ExtractorBase from '../extractors/Base';
import { prepareDosage, prepareMeta, prepareValueDateTime } from '../fhir.converters';
import { getCodingObjects, getExtensionObjects, getIdentifierObjects } from '../fhir.helpers';
import { joinValuesToString } from '../fhir.utils';
class ExtractorMedicationStatement {
    constructor(resource) {
        this.base = new ExtractorBase(this);
        this.base.SET(resource);
        makeAutoObservable(this);
    }
    get resource() {
        return this.base.resource;
    }
    /*
     * RESOURCE - Minimally prepared resource data, prepareConverters, valueObjects, etc.
     */
    get extensions() {
        return getExtensionObjects(this.resource.extension);
    }
    get identifiers() {
        return getIdentifierObjects(this.resource.identifier);
    }
    get categories() {
        return getCodingObjects(this.resource.category);
    }
    get medicationCodeableConcepts() {
        return getCodingObjects(this.resource.medicationCodeableConcept);
    }
    // other in the FHIR - Group
    get subject() {
        return this.base.getChildExtractor(this.resource.subject);
    }
    get informationSource() {
        return this.base.getChildExtractor(this.resource.informationSource);
    }
    get medicationReference() {
        return this.base.getChildExtractor(this.resource.medicationReference);
    }
    get dosages() {
        return _.map(this.resource.dosage, (x) => prepareDosage(x));
    }
    get status() {
        return this.resource.status;
    }
    get dateAsserted() {
        return prepareValueDateTime(this.resource.dateAsserted);
    }
    get meta() {
        return prepareMeta(this.resource.meta);
    }
    /*
     * READ - PREPARED Values
     */
    get zid() {
        return this.identifiers.first({ config: 'zid' });
    }
    get codeObj() {
        var _a;
        return ((_a = this.medicationReference) === null || _a === void 0 ? void 0 : _a.codeObj) || this.medicationCodeableConcepts.first();
    }
    get dosage() {
        return this.dosages[0];
    }
    /*
     * STRING Values
     */
    get asString() {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
        const tmp = {
            codeDisplay: (_a = this.codeObj) === null || _a === void 0 ? void 0 : _a.display,
            dose: ((_d = (_c = (_b = this.dosage) === null || _b === void 0 ? void 0 : _b.doseAndRate) === null || _c === void 0 ? void 0 : _c.dose) === null || _d === void 0 ? void 0 : _d.asString) || ((_e = this.dosage) === null || _e === void 0 ? void 0 : _e.text),
            route: (_g = (_f = this.dosage) === null || _f === void 0 ? void 0 : _f.route) === null || _g === void 0 ? void 0 : _g.display,
            site: (_j = (_h = this.dosage) === null || _h === void 0 ? void 0 : _h.site) === null || _j === void 0 ? void 0 : _j.display,
            timing: (_l = (_k = this.dosage) === null || _k === void 0 ? void 0 : _k.timing) === null || _l === void 0 ? void 0 : _l.asString,
            dosage: (_m = this.dosage) === null || _m === void 0 ? void 0 : _m.asString,
            status: getEnumObjectValue(this.resource.status, ENUMS_MEDICATIONS_STATUSES).value,
            full: '',
            routeSite: '',
        };
        tmp.full = joinValuesToString([tmp.codeDisplay, tmp.dosage], ' • ');
        tmp.routeSite = joinValuesToString([tmp.route, ['(', tmp.site, ')']], ' ');
        return tmp;
    }
}
export default ExtractorMedicationStatement;
