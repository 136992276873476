import { makeAutoObservable } from 'mobx';
import ExtractorBase from '../extractors/Base';
import { prepareCustomDate, prepareMeta } from '../fhir.converters';
import { getCodingObjects, getExtensionObjects, getIdentifierObjects } from '../fhir.helpers';
import { joinValuesToString } from '../fhir.utils';
class ExtractorImmunization {
    constructor(resource) {
        this.base = new ExtractorBase(this);
        this.base.SET(resource);
        makeAutoObservable(this);
    }
    get resource() {
        return this.base.resource;
    }
    /*
     * RESOURCE - Minimally prepared resource data, prepareConverters, valueObjects, etc.
     */
    get extensions() {
        return getExtensionObjects(this.resource.extension);
    }
    get identifiers() {
        return getIdentifierObjects(this.resource.identifier);
    }
    get codes() {
        return getCodingObjects(this.resource.vaccineCode);
    }
    get reportOrigins() {
        return getCodingObjects(this.resource.reportOrigin);
    }
    get subject() {
        return this.base.getChildExtractor(this.resource.patient);
    }
    get meta() {
        return prepareMeta(this.resource.meta);
    }
    /*
     * READ - PREPARED Values
     */
    get zid() {
        return this.identifiers.first({ config: 'zid' });
    }
    get codeObj() {
        return this.codes.first();
    }
    get reportOrigin() {
        return this.reportOrigins.first();
    }
    get dateOccurrence() {
        return prepareCustomDate({
            dateTime: this.resource.occurrenceDateTime,
            string: this.resource.occurrenceString,
        });
    }
    /*
     * STRING Values
     */
    get asString() {
        var _a, _b;
        const tmp = {
            codeDisplay: (_a = this.codeObj) === null || _a === void 0 ? void 0 : _a.display,
            dateOccurrence: (_b = this.dateOccurrence) === null || _b === void 0 ? void 0 : _b.asString,
            full: '',
        };
        tmp.full = joinValuesToString([tmp.codeDisplay], ' • ');
        return tmp;
    }
}
export default ExtractorImmunization;
