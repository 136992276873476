import { useEffect, useRef } from 'react'

import i18n from 'i18next'
import _ from 'lodash'
import { myackAxiosInstance } from 'ziphy-web-shared/basic/api'
import { localStore } from 'ziphy-web-shared/basic/utils'

import { extendPlace, PLACE_TEMPLATE } from '@helpers/addressHelper'
import { getConfigByCountry } from '@helpers/countries'

import mainConfig from '@config/main'

class map {
  async getGeocode(params) {
    const geocoder = new window.google.maps.Geocoder()
    const response = await geocoder.geocode(params)
    return response.results
  }

  async getTimeZoneId(lat, lng) {
    const timestamp = Math.round(new Date().getTime() / 1000)
    const res = await myackAxiosInstance.get(
      `${mainConfig.googleMaps.url}/timezone/json?location=${lat},${lng}&timestamp=${timestamp}&key=${mainConfig.googleMaps.key}`,
    )

    return res.data.timeZoneId
  }

  getGeoAllow(defaultValue = null) {
    return localStore.get('userSettings.geoAllow', defaultValue)
  }

  setGeoAllow(status, data) {
    const payload = {
      status: status.toString(),
      last: Date.now(),
      data,
    }

    localStore.set('userSettings.geoAllow', payload)
  }

  clearGeoAllow() {
    localStore.remove('userSettings.geoAllow')
  }

  isDetailedPlace = (place) => {
    if (_.isEmpty(place)) {
      return false
    }

    const hasCoords = !_.isEmpty(place.coords)
    const hasZip = !_.isEmpty(place.zip)

    return hasCoords && hasZip
  }

  getClearedPlace = (place) => {
    let tmp = _.cloneDeep(PLACE_TEMPLATE)

    tmp.coords = place.coords

    return tmp
  }

  isEqualCoords(v1, v2) {
    return JSON.stringify(v1) === JSON.stringify(v2)
  }

  formatApiData = (res = {}, updateCoords = false) => {
    let tmp = _.cloneDeep(PLACE_TEMPLATE)

    if (res.address_components) {
      let locality = '',
        neighborhood = '',
        sublocality = '',
        level2 = '',
        establishment = '',
        building = '',
        street = '',
        premise = '',
        subpremise = '',
        zip = '',
        country = '',
        state = ''

      _.forEach(res.address_components, (el) => {
        if (el.types.includes('locality') && !locality) locality = el.short_name
        if (el.types.includes('sublocality') && !sublocality) sublocality = el.short_name
        if (el.types.includes('neighborhood') && !neighborhood) neighborhood = el.short_name
        if (el.types.includes('administrative_area_level_2') && !level2) level2 = el.short_name
        if (el.types.includes('route') && !street) street = el.long_name
        if (el.types.includes('establishment') && !establishment) establishment = el.short_name
        if (el.types.includes('street_number') && !building) building = el.short_name
        if (el.types.includes('premise') && !premise) premise = el.short_name
        if (el.types.includes('subpremise') && !subpremise) subpremise = el.short_name
        if (el.types.includes('postal_code') && !zip) zip = el.short_name
        if (el.types.includes('country') && !country) country = el.short_name
        if (el.types.includes('administrative_area_level_1') && !state) state = el.short_name
      })

      tmp.city = locality || sublocality || neighborhood || level2
      tmp.street = street || establishment
      tmp.building = building || premise || (street ? establishment : '')
      tmp.apartment = subpremise
      tmp.zip = zip
      tmp.country = country
      tmp.state = state
    }

    if (res.place_id) {
      tmp.geocoderId = res.place_id
    }

    if (res.geometry && updateCoords) {
      tmp.coords = {
        lat: res.geometry.location.lat(),
        lng: res.geometry.location.lng(),
      }
    }

    return tmp
  }

  async getUserGeolocation(updatePlace, settings = {}) {
    return new Promise((resolve) => {
      const onSuccess = (res) => {
        const coords = { lat: res.coords.latitude, lng: res.coords.longitude }
        const geoAllow = this.getGeoAllow()

        this.setGeoAllow(true, { coords })

        if (geoAllow === null && _.isFunction(updatePlace)) {
          updatePlace() // callback need for update place in the store
        }

        resolve({ coords, error: null })
      }

      const onError = (err) => {
        const coords = null
        this.setGeoAllow(false, { coords })

        resolve({ coords, error: err.message })
      }

      navigator.geolocation.getCurrentPosition(onSuccess, onError, {
        enableHighAccuracy: true,
        timeout: 10000,
        maximumAge: 10000,
        ...settings,
      })
    })
  }

  async fetchPlaceDetails(place) {
    if (!_.isEmpty(place) && !this.isDetailedPlace(place)) {
      let res, updateCoords

      try {
        if (place.geocoderId) {
          res = await this.getGeocode({ placeId: place.geocoderId })
          updateCoords = true
        }

        if (_.isEmpty(res) && !_.isEmpty(place.coords)) {
          res = await this.getGeocode({ location: place.coords })
        }

        if (_.isEmpty(res)) {
          res = await this.getGeocode({ address: extendPlace(place).address() })
          updateCoords = true
        }

        if (place.updateHookCoords) {
          updateCoords = true
          delete place.updateCoords
        }
      } catch (e) {
        console.warn('Unable to update place details: ', e)
      }

      if (res) {
        let id = place.id
        place = { coords: place.coords, ...this.formatApiData(res[0], updateCoords) }

        if (id) {
          place.id = id
        }
      }
    }

    return place
  }

  getCurrentPlace(props) {
    const { callback, updatePlace, details = false, force = false } = props // mapsIsLoaded,

    const geoAllow = this.getGeoAllow()
    let place = getConfigByCountry().defaultPlace

    const _checkDetailed = (value) => {
      if (details && !this.isDetailedPlace(value)) {
        this.fetchPlaceDetails(value).then((detailedPlace) => {
          this.setGeoAllow(true, detailedPlace)

          if (_.isFunction(callback)) callback(detailedPlace)
          return detailedPlace
        })
      }

      if (_.isFunction(callback)) callback(value)
      return value
    }

    // if user geo UNKNOWN
    if (geoAllow === null && force) {
      this.getUserGeolocation(updatePlace).then(({ coords }) => {
        if (coords) _checkDetailed({ coords })
      })
    }
    // if user geo ALLOWED
    else if (geoAllow && geoAllow.status === 'true') {
      let isActual = Date.now() < geoAllow.last + 10 * 60 * 1000 // each 10 minutes

      if (geoAllow.data && geoAllow.data.coords && isActual) {
        place = _checkDetailed(geoAllow.data)
      } else {
        place = {}

        this.getUserGeolocation(updatePlace).then(({ coords, error }) => {
          if (coords) {
            _checkDetailed({ coords })
          } else if (error) {
            _checkDetailed(getConfigByCountry().defaultPlace)
            this.clearGeoAllow()
          }
        })
      }
    }
    // if user geo BLOCKED
    else if (geoAllow && geoAllow.data) {
      if (Date.now() > geoAllow.last + 24 * 60 * 60 * 1000) {
        // each 24 hours
        this.getUserGeolocation(updatePlace).then(({ coords }) => {
          if (coords) _checkDetailed({ coords })
        })
      }
    }

    return place
  }
}

const service = new map()

export const useCurrentPlace = () => {
  const fn = (...args) => service.getCurrentPlace(...args)

  const fnRef = useRef(fn)
  useEffect(() => {}, [])

  return fnRef.current
}

export const mapLoader = async () => {
  const loaderOptions = {
    apiKey: mainConfig.googleMaps.key,
    language: i18n.language,
    libraries: [],
  }
  if ('HTMLDialogElement' in window) {
    loaderOptions.libraries.push('places')
  }
  const loader = new window.google.maps.plugins.loader.Loader(loaderOptions)
  return await loader.load()
}

service.useCurrentPlace = useCurrentPlace
service.mapLoader = mapLoader

export default service
