import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';
import { $auth } from 'ziphy-web-shared/basic/entities/auth';
import { isUrl } from 'ziphy-web-shared/basic/helpers';
import { useScrollDirection } from 'ziphy-web-shared/basic/hooks';
import { $layouts } from 'ziphy-web-shared/basic/utils/layouts';
import { $router } from 'ziphy-web-shared/basic/utils/router';
import Burger from 'ziphy-web-shared/entityViews/layouts/toolbar/burger/Burger';
import GoBack from 'ziphy-web-shared/entityViews/layouts/toolbar/goBack/GoBack';
import Notes from 'ziphy-web-shared/entityViews/layouts/toolbar/notes/Notes';
import Notification from '@nedoShared/entityViews/layouts/toolbar/notification/Notification';
import styles from './Toolbar.module.scss';
const Toolbar = ({ goBackProps = null, useBackToApptUrl = false, useScroll = true, hasBurger = false, children, className, }) => {
    const { isScrolled } = useScrollDirection({ distanceDown: 100 });
    if (useBackToApptUrl) {
        const url = $router.searchParams.get('backToApptUrl');
        if (url) {
            if (isUrl(url)) {
                goBackProps.autoTarget.href = url;
            }
            else {
                goBackProps.autoTarget.to = url;
            }
        }
    }
    return (_jsxs("div", Object.assign({ className: classNames(styles.toolbar, className, useScroll && isScrolled && styles.toolbarFixed), id: 'toolbar' }, { children: [goBackProps && _jsx(GoBack, Object.assign({}, goBackProps, { className: styles.goBack })), hasBurger && _jsx(Burger, {}), children, !$router.hasTag('hideRightSidebar') && $auth.isLogged && (_jsxs("div", Object.assign({ className: classNames(styles.rightSidebar, !goBackProps && !hasBurger && !children && styles.rightSidebarAlignRight) }, { children: [_jsx(Notification, { className: classNames(styles.rightSidebarButton, $layouts.rightSidebar.active === 'notifications' && styles.rightSidebarButtonHidden) }), _jsx(Notes, { className: classNames(styles.rightSidebarButton, $layouts.rightSidebar.active === 'notes' && styles.rightSidebarButtonDisplayNone) })] })))] })));
};
export default observer(Toolbar);
