import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import classNames from 'classnames';
import { getStylesMode } from 'ziphy-web-shared/basic/helpers';
import styles from './Icon.module.scss';
import { default as audioIcon, ReactComponent as AudioIcon } from './ui/audio.svg';
import { default as backIcon, ReactComponent as BackIcon } from './ui/back.svg';
import { default as burgerIcon, ReactComponent as BurgerIcon } from './ui/burger.svg';
import { default as calendarIcon, ReactComponent as CalendarIcon } from './ui/calendar.svg';
import { default as carTravelIcon, ReactComponent as CarTravelIcon } from './ui/car-travel.svg';
import { default as checkIcon, ReactComponent as CheckIcon } from './ui/check.svg';
import { default as checksIcon, ReactComponent as ChecksIcon } from './ui/checks.svg';
import { default as closeIcon, ReactComponent as CloseIcon } from './ui/close.svg';
import { default as copyIcon, ReactComponent as CopyIcon } from './ui/copy.svg';
import { default as editIcon, ReactComponent as EditIcon } from './ui/edit.svg';
import { default as emailIcon, ReactComponent as EmailIcon } from './ui/email.svg';
import { default as infoIcon, ReactComponent as InfoIcon } from './ui/info.svg';
import { default as moveLeftArrowIcon, ReactComponent as MoveLeftArrowIcon, } from './ui/move-left-arrow.svg';
import { default as moveRightArrowIcon, ReactComponent as MoveRightArrowIcon, } from './ui/move-right-arrow.svg';
import { default as nextIcon, ReactComponent as NextIcon } from './ui/next.svg';
import { default as notificationIcon, ReactComponent as NotificationIcon, } from './ui/notification.svg';
import { default as phoneIcon, ReactComponent as PhoneIcon } from './ui/phone.svg';
import { default as plusIcon, ReactComponent as PlusIcon } from './ui/plus.svg';
import { default as settingsIcon, ReactComponent as SettingsIcon } from './ui/settings.svg';
import { default as trashIcon, ReactComponent as TrashIcon } from './ui/trash.svg';
const Icon = ({ tag = 'svg', mode, alt = '', type, className, color, size = 28, width = size, height = size, onClick, }) => {
    const isSvg = tag === 'svg';
    let result = { img: '', svg: null };
    if (type === 'audio')
        result = { img: audioIcon, svg: AudioIcon };
    else if (type === 'back')
        result = { img: backIcon, svg: BackIcon };
    else if (type === 'burger')
        result = { img: burgerIcon, svg: BurgerIcon };
    else if (type === 'calendar')
        result = { img: calendarIcon, svg: CalendarIcon };
    else if (type === 'check')
        result = { img: checkIcon, svg: CheckIcon };
    else if (type === 'checks')
        result = { img: checksIcon, svg: ChecksIcon };
    else if (type === 'close')
        result = { img: closeIcon, svg: CloseIcon };
    else if (type === 'copy')
        result = { img: copyIcon, svg: CopyIcon };
    else if (type === 'plus')
        result = { img: plusIcon, svg: PlusIcon };
    else if (type === 'trash')
        result = { img: trashIcon, svg: TrashIcon };
    else if (type === 'phone')
        result = { img: phoneIcon, svg: PhoneIcon };
    else if (type === 'edit')
        result = { img: editIcon, svg: EditIcon };
    else if (type === 'info')
        result = { img: infoIcon, svg: InfoIcon };
    else if (type === 'next')
        result = { img: nextIcon, svg: NextIcon };
    else if (type === 'email')
        result = { img: emailIcon, svg: EmailIcon };
    else if (type === 'notification')
        result = { img: notificationIcon, svg: NotificationIcon };
    else if (type === 'settings')
        result = { img: settingsIcon, svg: SettingsIcon };
    else if (type === 'carTravel')
        result = { img: carTravelIcon, svg: CarTravelIcon };
    else if (type === 'moveLeftArrowIcon')
        result = { img: moveLeftArrowIcon, svg: MoveLeftArrowIcon };
    else if (type === 'moveRightArrowIcon')
        result = { img: moveRightArrowIcon, svg: MoveRightArrowIcon };
    if (isSvg && result.svg) {
        return (_jsx(result.svg, { width: width, height: height, className: classNames(mode && getStylesMode(mode, styles), className), color: color, onClick: onClick }));
    }
    if (result.img) {
        return (_jsx("img", { src: result.img, alt: alt, width: width, height: height, className: className, onClick: onClick }));
    }
    return null;
};
export default Icon;
