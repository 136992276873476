import { makeAutoObservable } from 'mobx';
import { getSubjectRole } from '../extractor.helpers';
import ExtractorBase from '../extractors/Base';
import { prepareMeta } from '../fhir.converters';
import { getAddressObjects, getCodingObjects, getExtensionObjects, getIdentifierObjects, getTelecomObjects, withFullName, } from '../fhir.helpers';
class ExtractorLocation {
    constructor(resource) {
        this.base = new ExtractorBase(this);
        this.base.SET(resource);
        makeAutoObservable(this);
    }
    get resource() {
        return this.base.resource;
    }
    /*
     * RESOURCE - Minimally prepared resource data, prepareConverters, valueObjects, etc.
     */
    get extensions() {
        return getExtensionObjects(this.resource.extension);
    }
    get identifiers() {
        return getIdentifierObjects(this.resource.identifier);
    }
    get types() {
        return getCodingObjects(this.resource.type);
    }
    get address() {
        return getAddressObjects(this.resource.address).first();
    }
    get telecoms() {
        return getTelecomObjects(this.resource.telecom);
    }
    get managingOrganization() {
        return this.base.getChildExtractor(this.resource.managingOrganization);
    }
    get position() {
        return this.resource.position;
    }
    get hoursOfOperations() {
        return this.resource.hoursOfOperation;
    }
    get description() {
        return this.resource.description;
    }
    get mode() {
        return this.resource.mode;
    }
    get availabilityExceptions() {
        return this.resource.availabilityExceptions;
    }
    get status() {
        return this.resource.status;
    }
    get meta() {
        return prepareMeta(this.resource.meta);
    }
    /*
     * READ - PREPARED Values
     */
    get zid() {
        return this.identifiers.first({
            config: 'zid',
            typeConfig: 'identifierBookingEntity',
            typeConfigVariant: 'placeId',
        });
    }
    get role() {
        return getSubjectRole(this);
    }
    get name() {
        return withFullName({ name: this.resource.name });
    }
    get phone() {
        return this.telecoms.last({ system: 'phone' });
    }
    get email() {
        return this.telecoms.last({ system: 'email' });
    }
    /*
     * STRING Values
     */
    get asString() {
        var _a;
        const tmp = {
            zid: (_a = this.zid) === null || _a === void 0 ? void 0 : _a.value,
            full: '',
        };
        return tmp;
    }
}
export default ExtractorLocation;
