var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { makeAutoObservable, runInAction } from 'mobx';
import { servicesService } from 'ziphy-web-shared/basic/api';
import { APP_TYPE } from 'ziphy-web-shared/basic/helpers';
class BookingServicesStore {
    constructor(store) {
        this._items = [];
        this._rootStore = store;
        makeAutoObservable(this);
    }
    // Computed
    get items() {
        // todo: make reusable
        return this._items.map((item) => {
            const { agentLoad, providerLoad, reasons } = item;
            const additionalParams = {
                isVirtual: false,
                isAtHome: false,
                isAtClinic: false,
            };
            if (reasons.includes('clinic')) {
                additionalParams.isAtClinic = true;
            }
            else {
                if ([null, 'virtual'].includes(agentLoad) && [null, 'virtual'].includes(providerLoad)) {
                    additionalParams.isVirtual = true;
                }
                if (agentLoad === 'onsite' && [null, 'virtual'].includes(providerLoad)) {
                    additionalParams.isAtHome = true;
                }
            }
            return Object.assign(Object.assign({}, item), additionalParams);
        });
    }
    // Mutations
    // Actions
    getServiceById(id) {
        return this.items.find((i) => i.id === id);
    }
    load() {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield servicesService.list({
                filter: {
                    and: [
                        { eq: ['is_active', true] },
                        { ne: ['patient_group_type', 'group'] },
                        { contains: ['allowed_to', [APP_TYPE]] },
                    ],
                },
                limit: 1000,
            }, { cacheTime: 900 });
            runInAction(() => {
                var _a;
                this._items = ((_a = response.prepared) === null || _a === void 0 ? void 0 : _a.services.items) || [];
            });
            return response;
        });
    }
}
export default BookingServicesStore;
