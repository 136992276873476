import { makeAutoObservable } from 'mobx';
import _ from 'lodash';
import ExtractorBase from '../extractors/Base';
import { prepareAttachment, prepareMeta } from '../fhir.converters';
import { getCodingObjects, getExtensionObjects, getIdentifierObjects } from '../fhir.helpers';
import { joinValuesToString } from '../fhir.utils';
class ExtractorDocumentReference {
    constructor(resource) {
        this.base = new ExtractorBase(this);
        this.base.SET(resource);
        makeAutoObservable(this);
    }
    get resource() {
        return this.base.resource;
    }
    /*
     * RESOURCE - Minimally prepared resource data, prepareConverters, valueObjects, etc.
     */
    get extensions() {
        return getExtensionObjects(this.resource.extension);
    }
    get identifiers() {
        return getIdentifierObjects(this.resource.identifier);
    }
    get masterIdentifier() {
        return getIdentifierObjects(this.resource.masterIdentifier).first();
    }
    get categories() {
        return getCodingObjects(this.resource.category);
    }
    get types() {
        return getCodingObjects(this.resource.type);
    }
    // other in the FHIR - Group | Device
    get subject() {
        return this.base.getChildExtractor(this.resource.subject);
    }
    // other in the FHIR -  Device
    get authors() {
        return _.map(this.resource.author, (x) => this.base.getChildExtractor(x));
    }
    get contents() {
        return _.map(this.resource.content, (x) => ({
            attachment: prepareAttachment(x.attachment),
            format: getCodingObjects(x.format).first(),
        }));
    }
    get status() {
        return this.resource.status;
    }
    get description() {
        return this.resource.description;
    }
    get meta() {
        return prepareMeta(this.resource.meta);
    }
    /*
     * READ - PREPARED Values
     */
    get zid() {
        return this.identifiers.first({ config: 'zid' });
    }
    get category() {
        return this.categories.first();
    }
    get type() {
        return this.types.first();
    }
    get author() {
        return _.last(this.authors);
    }
    get content() {
        return this.contents[0];
    }
    /*
     * STRING Values
     */
    get asString() {
        var _a;
        const tmp = {
            type: (_a = this.type) === null || _a === void 0 ? void 0 : _a.display,
            description: this.description,
            full: '',
            typeDescription: '',
        };
        tmp.full = tmp.description;
        tmp.typeDescription = joinValuesToString([tmp.type, tmp.description], ' • ');
        return tmp;
    }
}
export default ExtractorDocumentReference;
