var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import _ from 'lodash';
import { BaseService } from '../baseService';
export const APPOINTMENTS_DEFAULT_EXPAND_LIST = {
    encounter: [
        'patientId',
        // 'patientSymptomsId',
        //
    ],
    appointment: [
        'serviceId',
        // 'practiceServiceId',
        'placeId',
        'officeId',
        'practiceId',
        'encounterIds',
        //
        'voucherId',
        'paymentId',
        'paymentMethodId',
        //
        'agentRoleId',
        'providerRoleId',
    ],
};
export const APPOINTMENTS_DEFAULT_EXPAND_READ = (() => {
    const list = _.cloneDeep(APPOINTMENTS_DEFAULT_EXPAND_LIST);
    list.encounter.push('patientSymptomsId');
    return list;
})();
export class Appointments extends BaseService {
    constructor(props) {
        super(props);
    }
    create(params, options) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            let res;
            res = yield this.api.fetchLoggedV3('appointments.create', params, options);
            return Object.assign(Object.assign({}, res), { preparedValue: (_a = res.prepared) === null || _a === void 0 ? void 0 : _a.appointment });
        });
    }
    read(params, options) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            let res;
            res = yield this.api.fetchLoggedV3('appointments.read', params, options);
            return Object.assign(Object.assign({}, res), { preparedValue: (_a = res.prepared) === null || _a === void 0 ? void 0 : _a.appointment });
        });
    }
    delete(params, options) {
        return __awaiter(this, void 0, void 0, function* () {
            let res;
            res = yield this.api.fetchLoggedV3('appointments.delete', params, options);
            return res;
        });
    }
    list(params, options) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            let res;
            res = yield this.api.fetchLoggedV3('appointments.list', params, options);
            return Object.assign(Object.assign({}, res), { preparedValue: ((_a = res.prepared) === null || _a === void 0 ? void 0 : _a.appointments.items) || [] });
        });
    }
}
