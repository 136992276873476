import { makeAutoObservable, reaction, runInAction } from 'mobx'

import _ from 'lodash'
import moment from 'moment-timezone'
import { callsService, documentTemplatesService } from 'ziphy-web-shared/basic/api'
import { $auth } from 'ziphy-web-shared/basic/entities/auth'
import { isEqualIds, isStaffApp } from 'ziphy-web-shared/basic/helpers'
import { localStore } from 'ziphy-web-shared/basic/utils'

import clientService from '@services/client'

import hAppt from '@helpers/hAppt'

class ApptsStore {
  constructor() {
    makeAutoObservable(this)
  }

  activeList = []

  byRoute = {
    fullAppt: {},
    statuses: [],
    status: {},
    actions: {},
  }

  documentTemplates = {}
  actualList = []

  clearByRoute() {
    this.byRoute = {
      fullAppt: {},
      statuses: [],
      status: {},
      actions: {},
    }
  }

  clear() {
    this.activeList = []
    this.clearByRoute()
    this.documentTemplates = {}
    this.actualList = []
  }

  // Computed

  // Mutations
  SET_BY_ROUTE(value) {
    this.byRoute = value
  }

  SET_ACTUAL_LIST(value) {
    this.actualList = value
  }

  //
  // GET Actions
  //
  async getActive({ practiceId, dateFrom, dateTo } = {}) {
    const res = await clientService.getAppointments({
      type: 'active',
      practiceId,
      dateFrom,
      dateTo,
    })
    runInAction(() => (this.activeList = res.prepared))

    return { ...res, preparedValue: res.prepared }
  }

  async getActual() {
    if (!isStaffApp()) {
      return
    }

    const date = moment()

    const dateFrom = date.clone().subtract(2, 'hour').startOf('hour')
    const dateTo = date.clone().add(4, 'hour').endOf('hour')

    const res = await clientService.getAppointments({ type: 'actual', dateFrom, dateTo })
    const prepared = res.prepared.map((x) => hAppt.extendFullAppt(x))

    this.SET_ACTUAL_LIST(prepared)
  }

  async getOne({ id = undefined, setByRoute = true, expand = undefined }) {
    let res = await clientService.getOneAppointment({ id, skipAlert: true, expand })
    let prepared = res.prepared

    if (_.isEmpty(prepared) && isStaffApp()) {
      res = await clientService.getOneAppointment({
        id,
        skipAlert: true,
        source: 'snapshot',
        expand,
      })
      prepared = res.prepared
    }

    if (!_.isEmpty(prepared)) {
      prepared = hAppt.extendFullAppt(prepared)

      if (setByRoute) {
        if (!_.isEmpty(prepared.fullAppt.encounters)) {
          const encounterIds = Object.keys(prepared.fullAppt.encounters)
          const response = await documentTemplatesService.batchPatientForms({ encounterIds })
          const documentTemplates = {}
          response?.forEach((x) => {
            documentTemplates[encounterIds[response.indexOf(x)]] = _.sortBy(x.preparedValue, (x) =>
              x.type === 'patient_tos' ? 1 : 0,
            )
          })
          runInAction(
            () => (this.documentTemplates = { ...this.documentTemplates, ...documentTemplates }),
          )
        }
        prepared = hAppt.extendFullAppt(prepared.fullAppt)
        this.SET_BY_ROUTE(prepared)
      }
    }

    return { isSuccess: !_.isEmpty(prepared), item: prepared }
  }

  async checkOne({ id }) {
    const res = await clientService.getOneAppointment({ id, skipAlert: true, expand: {} })
    return { isSuccess: !_.isEmpty(res.prepared) }
  }

  recalculateAppt(id) {
    if (isEqualIds(id, this.byRoute.fullAppt?.appointment?.id)) {
      const prepared = hAppt.extendFullAppt(this.byRoute.fullAppt)
      this.SET_BY_ROUTE(prepared)
    }
  }

  //
  // Role Actions
  //
  async callOffice({ appointmentId }) {
    const response = await callsService.create({ appointmentId })
    return { isSuccess: response.prepared }
  }

  async readyForConference({ id }) {
    const res = await clientService.readyForConference({ id })
    const isSuccess = res && res.prepared

    if (isSuccess) {
      await this.getOne({ id })
    }

    return { isSuccess }
  }

  joinConference({ id }) {
    localStore.set('userSettings.apptVideo.id_' + id, moment().format('x'), { lifeTime: 86400 })
    this.recalculateAppt(id)
  }

  leaveConference({ id }) {
    localStore.remove('userSettings.apptVideo.id_' + id)
    this.recalculateAppt(id)
  }

  async confirm({ id }) {
    const res = await clientService.acceptAppointment({ id })
    const isSuccess = res && res.prepared

    if (isSuccess) {
      await this.getOne({ id })
    }

    return { isSuccess }
  }

  async start({ id }) {
    const res = await clientService.startAppointment({ id })
    const isSuccess = res && res.prepared

    if (isSuccess) {
      await this.getOne({ id })
    }

    return { isSuccess }
  }

  async finish({ id }) {
    const res = await clientService.finishAppointment({ id })
    const isSuccess = res && res.prepared

    if (isSuccess) {
      await this.getOne({ id })
    }

    return { isSuccess }
  }

  // Only For CLIENT?
  async cancel(id) {
    const res = await clientService.cancelAppointment({ id })

    if (res && res.prepared) {
      await this.getOne({ id })
      await this.getActive()
      return { isSuccess: true }
    }

    return { isSuccess: false }
  }

  async complete({ id, ok }) {
    const res = await clientService.completeAppointment({ id, ok })

    if (res && res.prepared) {
      await this.getActive()
      await this.getOne({ id })
      return { isSuccess: true }
    }

    return { isSuccess: false }
  }

  async sendFeedback(id, { feedback }) {
    const res = await clientService.sendFeedbackAppointment({ id, feedback })

    if (res && res.prepared) {
      await this.getOne({ id })
      return { isSuccess: true }
    } else {
      return { isSuccess: false }
    }
  }

  async sendSurvey(id, { survey }) {
    const res = await clientService.sendSurveyAppointment({ id, survey })

    if (res && res.prepared) {
      await this.getOne({ id })
      return { isSuccess: true }
    } else {
      return { isSuccess: false }
    }
  }
}

const store = new ApptsStore()

reaction(
  () => $auth.role?.id,
  async () => {
    store.clear()
  },
)

reaction(
  () => $auth.isLogged,
  async (val) => {
    if (val === true) {
      store.getActual()
      store.getActive()
    }
  },
)

export default store
