import React, { useState } from 'react'

import { useTranslation } from 'react-i18next'

import classNames from 'classnames'
import _ from 'lodash'
import Icon from 'ziphy-web-shared/basic/assets/images/Icon'
import { ProgressRing } from 'ziphy-web-shared/basic/lib/loaders'

import BaseAudio from '@library/media/mediaTypes/base/BaseAudio'

import styles from './Types.module.scss'

const Audio = ({ src, onIsLoading }) => {
  const { t } = useTranslation()

  const [isLoading, setIsLoading] = useState(true)
  const [isDefault, setIsDefault] = useState(false)

  function changeIsLoading(value) {
    setIsLoading(value)

    if (_.isFunction(onIsLoading)) {
      onIsLoading(value)
    }
  }

  return (
    <div
      className={classNames(
        styles.audio,
        isLoading && styles.isLoading,
        isDefault && styles.isDefault,
      )}
    >
      <BaseAudio src={src} controls={true} onIsLoading={changeIsLoading} onIsError={setIsDefault} />
      {isDefault && (
        <div className={styles.defaultContent}>
          <Icon className={styles.icon} type={'audio'} width={56} height={56} />
          <div className={styles.text}>{t('errors.cannot_play_audio')}</div>
        </div>
      )}
      {isLoading && (
        <ProgressRing
          radius={28}
          progress={25}
          spin={true}
          centered={true}
          className={styles.loader}
        />
      )}
    </div>
  )
}

export default Audio
