import React from 'react'

import { observer } from 'mobx-react-lite'
import { Transition } from 'react-spring/renderprops'

import _ from 'lodash'
import { $windowSize } from 'ziphy-web-shared/basic/utils'

import presets from './presets'

export const TransitionWrapper = observer(({ ...props }) => {
  const {
    preset = 'fade',
    from,
    enter,
    leave,
    children,
    onEnter,
    onEntered,
    onLeave,
    onLeaved,
    unique = true,
    ...passedProps
  } = props

  const size = $windowSize

  return (
    <Transition
      from={getPreset(getWrapperConfig(preset, 'from', props), { size })}
      enter={(item) => async (next, cancel) => {
        if (!item && _.isFunction(onEnter)) onEnter()

        const cfg = getWrapperConfig(preset, 'enter', props)
        await getAsyncPreset(cfg, { item, next, cancel, size })

        if (item && _.isFunction(onEntered)) onEntered()
      }}
      leave={(item) => async (next, cancel) => {
        if (item && _.isFunction(onLeave)) onLeave()

        const cfg = getWrapperConfig(preset, 'leave', props)
        await getAsyncPreset(cfg, { item, next, cancel, size })

        if (item && _.isFunction(onLeaved)) onLeaved()
      }}
      config={getWrapperConfig(preset, 'config', props)}
      unique={unique}
      {...passedProps}
    >
      {children}
    </Transition>
  )
})

function getConfig(value = '') {
  let path = value

  if (typeof value === 'string') {
    path = value.split('.')
  }

  return path.reduce((arr, key) => arr[key], presets)
}

function getWrapperConfig(preset, type, props = {}) {
  let custom = props[type]

  if (custom) {
    if (_.isString(custom) || _.isArray(custom)) {
      return getConfig(custom)
    }

    return custom
  }

  return getConfig([preset.split('.'), type])
}

export function getPreset(cfg, { item, next, cancel, size = {} } = {}) {
  if (_.isString(cfg)) {
    cfg = getConfig(cfg)
  }

  if (_.isFunction(cfg)) {
    cfg = cfg({ item, next, cancel, size })

    if (_.isString(cfg)) {
      cfg = getPreset(cfg, { item, next, cancel, size })
    }
  }

  return cfg
}

export function getPresetGroup(name, { size = {} } = {}) {
  let result = {}

  _.forEach(getConfig(name), (value, key) => {
    if (_.isFunction(value) || _.isString(value)) {
      result[key] = getPreset([name, key], { size })
    } else {
      result[key] = value
    }
  })

  return result
}

export async function getAsyncPreset(cfg, { item, next, cancel, size = {} } = {}) {
  if (_.isString(cfg)) {
    cfg = getConfig(cfg)
  }

  if (_.isFunction(cfg)) {
    cfg = await cfg({ item, next, cancel, size })

    if (_.isString(cfg)) {
      await getAsyncPreset(cfg, { item, next, cancel, size })
      return false
    }
  }

  if (!_.isEmpty(cfg) && _.isObject(cfg) && _.isFunction(next)) {
    await next(cfg)
  }
}
