var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { BaseService } from '../baseService';
export class Events extends BaseService {
    constructor(props) {
        super(props);
    }
    read(params, options) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            let res;
            res = yield this.api.fetchLoggedV3('events.read', params, options);
            return Object.assign(Object.assign({}, res), { preparedValue: (_a = res.prepared) === null || _a === void 0 ? void 0 : _a.event });
        });
    }
    list(params, options) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            let res;
            res = yield this.api.fetchLoggedV3('events.list', params, options);
            return Object.assign(Object.assign({}, res), { preparedValue: ((_a = res.prepared) === null || _a === void 0 ? void 0 : _a.events.items) || [] });
        });
    }
    /*
     * Custom Methods
     */
    search(params, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const { ids, appointmentIds, events } = params;
            const filters = [];
            if (ids) {
                filters.push({ in: ['id', ids] });
            }
            if (appointmentIds) {
                filters.push({ in: ['appointment_id', appointmentIds] });
            }
            if (events) {
                filters.push({ in: ['event', events] });
            }
            return yield this.list({
                filter: { and: filters },
                limit: 1000,
            }, options);
        });
    }
}
