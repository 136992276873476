import { useEffect, useRef } from 'react';
export const useChangeScrollDirection = (callback) => {
    const ref = useRef();
    useEffect(() => {
        const element = ref.current;
        function onScrollHandle(e) {
            if (typeof callback === 'function') {
                callback(e);
            }
            if (e.shiftKey) {
                return false;
            }
            let isTrackpad = false;
            if (e.wheelDeltaY) {
                if (e.wheelDeltaY === e.deltaY * -3) {
                    isTrackpad = true;
                }
            }
            else if (e.deltaMode === 0) {
                isTrackpad = true;
            }
            if (isTrackpad) {
                return false;
            }
            if (e.wheelDeltaY !== 0) {
                element.scrollLeft -= e.wheelDelta;
                e.preventDefault();
            }
        }
        element.addEventListener('wheel', onScrollHandle, { passive: false });
        return () => element.removeEventListener('wheel', onScrollHandle);
    }, [callback]);
    return ref;
};
