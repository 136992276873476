import { makeAutoObservable } from 'mobx';
import _ from 'lodash';
import { getEnumObjectValue } from 'ziphy-web-shared/basic/helpers';
import { ENUMS_APPOINTMENT_STATUSES } from '../enums/appointments';
import { ENUMS_ACTOR_ROLE } from '../enums/base';
import ExtractorBase from '../extractors/Base';
import { prepareMeta, prepareValueDateTime, prepareValuePeriod } from '../fhir.converters';
import { getCodingObjects, getExtensionObjects, getIdentifierObjects } from '../fhir.helpers';
import { joinValuesToString } from '../fhir.utils';
class ExtractorAppointment {
    constructor(resource) {
        this.base = new ExtractorBase(this);
        this.base.SET(resource);
        makeAutoObservable(this);
    }
    get resource() {
        return this.base.resource;
    }
    /*
     * RESOURCE - Minimally prepared resource data, prepareConverters, valueObjects, etc.
     */
    get extensions() {
        return getExtensionObjects(this.resource.extension);
    }
    get identifiers() {
        return getIdentifierObjects(this.resource.identifier);
    }
    get serviceTypes() {
        return getCodingObjects(this.resource.serviceType);
    }
    get appointmentType() {
        return getCodingObjects(this.resource.appointmentType).first();
    }
    // other in the FHIR -  Device | HealthcareService
    get participants() {
        return _.map(this.resource.participant, (x) => ({
            type: getCodingObjects(x.type).first(),
            actor: this.base.getChildExtractor(x.actor),
            status: x.status,
            period: prepareValuePeriod(x.period),
        }));
    }
    get encounters() {
        return this.base.getChildExtractorByCondition((r, id) => {
            return r.resourceType === 'Encounter' && _.some(r.appointment, (y) => y.reference === id);
        });
    }
    get created() {
        return prepareValueDateTime(this.resource.created);
    }
    get start() {
        return prepareValueDateTime(this.resource.start);
    }
    get end() {
        return prepareValueDateTime(this.resource.end);
    }
    get description() {
        return this.resource.description;
    }
    get comment() {
        return this.resource.comment;
    }
    get status() {
        return this.resource.status;
    }
    get meta() {
        return prepareMeta(this.resource.meta);
    }
    /*
     * READ - PREPARED Values
     */
    get zid() {
        return this.identifiers.first({
            config: 'zid',
            typeConfig: 'identifierBookingEntity',
            typeConfigVariant: 'appointmentId',
        });
    }
    get encounter() {
        return _.first(this.encounters);
    }
    get agent() {
        return this.participants.find((x) => { var _a, _b; return ((_b = (_a = x.actor) === null || _a === void 0 ? void 0 : _a.role) === null || _b === void 0 ? void 0 : _b.code) === ENUMS_ACTOR_ROLE.agent.id; });
    }
    get provider() {
        return this.participants.find((x) => { var _a, _b; return ((_b = (_a = x.actor) === null || _a === void 0 ? void 0 : _a.role) === null || _b === void 0 ? void 0 : _b.code) === ENUMS_ACTOR_ROLE.provider.id; });
    }
    get serviceType() {
        return this.serviceTypes.first('appointmentServiceType');
    }
    /*
     * STRING Values
     */
    get asString() {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
        const tmp = {
            zid: (_a = this.zid) === null || _a === void 0 ? void 0 : _a.value,
            appointmentType: (_b = this.appointmentType) === null || _b === void 0 ? void 0 : _b.display,
            serviceType: (_c = this.serviceType) === null || _c === void 0 ? void 0 : _c.display,
            status: getEnumObjectValue(this.status, ENUMS_APPOINTMENT_STATUSES).value,
            provider: (_f = (_e = (_d = this.provider) === null || _d === void 0 ? void 0 : _d.actor) === null || _e === void 0 ? void 0 : _e.name) === null || _f === void 0 ? void 0 : _f.fullName,
            agent: (_j = (_h = (_g = this.agent) === null || _g === void 0 ? void 0 : _g.actor) === null || _h === void 0 ? void 0 : _h.name) === null || _j === void 0 ? void 0 : _j.fullName,
            symptoms: (_l = (_k = this.encounter) === null || _k === void 0 ? void 0 : _k.asString) === null || _l === void 0 ? void 0 : _l.reasonReferences,
            encounterStatus: '',
            combinedStatus: '',
            staff: '',
            full: '',
        };
        tmp.combinedStatus = tmp.encounterStatus || tmp.status;
        tmp.staff = joinValuesToString([tmp.provider, tmp.agent], ' • ');
        tmp.full = joinValuesToString([tmp.appointmentType, tmp.serviceType, tmp.symptoms], ' • ');
        return tmp;
    }
}
export default ExtractorAppointment;
