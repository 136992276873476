export const ENUMS_CONTACTS_PHONE_USE = {
    mobile: {
        id: 'mobile',
        tKey: 'enums.telecom.mobile',
        alterLabel: 'label.primary',
        isPrimary: true,
    },
    home: {
        id: 'home',
        tKey: 'enums.telecom.home',
        alterLabel: 'enums.telecom.home',
        isPrimary: false,
    },
    work: {
        id: 'work',
        tKey: 'enums.telecom.work',
        alterLabel: 'enums.telecom.work',
        isPrimary: false,
    },
};
export const ENUMS_CONTACTS_EMAIL_USE = {
    home: {
        id: 'home',
        tKey: 'enums.telecom.home',
        alterLabel: 'label.primary',
        isPrimary: true,
    },
    work: {
        id: 'work',
        tKey: 'enums.telecom.work',
        alterLabel: 'enums.telecom.work',
        isPrimary: false,
    },
};
