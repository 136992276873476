import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { Fragment } from 'react';
import classNames from 'classnames';
import _ from 'lodash';
const DayPickerYear = ({ currentDate, selectedDate, setModel, setView, minDate, maxDate, availableYears, board, setRefYear, }) => {
    const handleSelectYear = (value) => {
        return () => {
            setModel(value);
            setView('month');
        };
    };
    const isSelected = (value) => {
        if (!selectedDate) {
            return false;
        }
        return value.isSame(selectedDate, 'year');
    };
    const isCurrent = (value) => {
        return value.isSame(currentDate, 'year');
    };
    const isLocked = (value) => {
        if (!_.isEmpty(availableYears)) {
            return !(value.format('YYYY') in availableYears);
        }
        else {
            return value.isBefore(minDate, 'year') || value.isAfter(maxDate, 'year');
        }
    };
    return (_jsx("div", Object.assign({ className: classNames('day-picker-year', 'styled_scroll') }, { children: _jsx("div", Object.assign({ className: "body" }, { children: _jsx("div", Object.assign({ className: "row-year" }, { children: board.map(({ text, value }, i) => (_jsxs(Fragment, { children: [text && !isLocked(value) && (_jsx("div", Object.assign({ className: classNames('cell', {
                                'cell-selected': isSelected(value),
                                'cell-current': isCurrent(value),
                            }), onClick: handleSelectYear(value), ref: setRefYear && setRefYear(value, 'YYYY') }, { children: text }))), text && isLocked(value) && (_jsx("div", Object.assign({ className: classNames('cell', {
                                'cell-selected': isSelected(value),
                                'cell-current': isCurrent(value),
                                'cell-locked': true,
                            }), ref: setRefYear && setRefYear(value, 'YYYY') }, { children: text })))] }, value.toString() + i))) })) })) })));
};
export default DayPickerYear;
