import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { Fragment } from 'react';
import classNames from 'classnames';
import _ from 'lodash';
export const DayPickerCalendar = ({ currentDate, selectedDate, model, setDate, minDate, maxDate, availableDays, disabledDays, board, dayWeek = true, nameMonth = false, setRefDay, }) => {
    const handleSelectDay = (value) => {
        return () => setDate(value);
    };
    const isSelected = (value) => {
        if (!selectedDate) {
            return false;
        }
        return value.isSame(selectedDate, 'day');
    };
    const isOtherMonth = (value) => {
        return !value.isSame(model, 'month');
    };
    const isCurrent = (value) => {
        return value.isSame(currentDate, 'day');
    };
    const isLocked = (value) => {
        if (!_.isEmpty(availableDays)) {
            return !(value.format('YYYY-MM-DD') in availableDays);
        }
        else {
            return (value.isBefore(minDate, 'day') ||
                value.isAfter(maxDate, 'day') ||
                (!_.isEmpty(disabledDays) && value.format('YYYY-MM-DD') in disabledDays));
        }
    };
    const getHeader = () => {
        return board && board.length ? board[0] : [];
    };
    return (_jsxs("div", Object.assign({ className: "day-picker-calendar" }, { children: [_jsxs("div", Object.assign({ className: "header" }, { children: [dayWeek && (_jsx("div", Object.assign({ className: "day-week" }, { children: getHeader().map(({ value }, i) => (_jsx("div", Object.assign({ className: 'cell' }, { children: value.format('ddd') }), i))) }))), nameMonth && _jsx("div", Object.assign({ className: "name-month" }, { children: model.format('MMMM YYYY') }))] })), _jsx("div", Object.assign({ className: "body" }, { children: board.map((cells, rowIndex) => (_jsx("div", Object.assign({ className: "row-week" }, { children: cells.map(({ text, value }, i) => (_jsxs(Fragment, { children: [!text && _jsx("div", Object.assign({ className: classNames('cell', 'cell-hidden') }, { children: "\u00A0" })), text && !isLocked(value) && (_jsx("div", Object.assign({ className: classNames('cell', {
                                    'cell-selected': isSelected(value),
                                    'cell-other-month': isOtherMonth(value),
                                    'cell-current': isCurrent(value),
                                }), onClick: handleSelectDay(value), ref: setRefDay && setRefDay(value, 'YYYYMMDD') }, { children: text }))), text && isLocked(value) && (_jsx("div", Object.assign({ className: classNames('cell', {
                                    'cell-selected': isSelected(value),
                                    'cell-other-month': isOtherMonth(value),
                                    'cell-current': isCurrent(value),
                                    'cell-locked': true,
                                }), ref: setRefDay && setRefDay(value, 'YYYYMMDD') }, { children: text })))] }, value.toString() + i))) }), rowIndex))) }))] })));
};
export default DayPickerCalendar;
