import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatDate } from 'ziphy-web-shared/basic/utils/time';
import RoleName from '@ps/library/RoleName';
import ViewCode from '@ps/library/viewCode/ViewCode';
const ServiceRequestDetails = ({ event, extractor }) => {
    const { t } = useTranslation();
    const { asString, codeObj, reasonCode, subject, requester } = extractor;
    return (_jsxs("div", Object.assign({ className: "event_details" }, { children: [_jsxs("div", Object.assign({ className: "event_details-header" }, { children: [_jsx("div", Object.assign({ className: "event_details-title" }, { children: asString.short })), _jsx("div", Object.assign({ className: "event_details-code" }, { children: _jsx(ViewCode, { codeObj: codeObj }) }))] })), _jsxs("div", Object.assign({ className: "event_details-body" }, { children: [reasonCode && (_jsxs("div", Object.assign({ className: "event_details-line mb-10" }, { children: [t('ps.event_viewer.reason:'), " ", reasonCode.display, reasonCode && (_jsxs(_Fragment, { children: ["\u00A0\u2022\u00A0 ", _jsx(ViewCode, { codeObj: reasonCode })] }))] }))), asString.category && (_jsxs("div", Object.assign({ className: "event_details-line mb-10" }, { children: [t('ps.event_viewer.type:'), " ", asString.category] }))), subject && (_jsxs("div", Object.assign({ className: "event_details-line mt-10" }, { children: [t('ps.event_viewer.subject:'), " ", subject.name.fullName] }))), requester && (_jsxs("div", Object.assign({ className: "event_details-line" }, { children: [t('ps.event_viewer.requester:'), " ", requester.name.fullName, ' ', _jsx(RoleName, { role: requester.role })] }))), _jsx("div", Object.assign({ className: "event_details-line event_details-line--date" }, { children: formatDate(event.effectiveDate) }))] }))] })));
};
export default ServiceRequestDetails;
