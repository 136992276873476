import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { Redirect, useHistory } from 'react-router-dom';
import { $auth } from 'ziphy-web-shared/basic/entities/auth';
import { webView } from 'ziphy-web-shared/basic/utils';
import { $router } from 'ziphy-web-shared/basic/utils/router';
import mainConfig from '@config/main';
const AuthProvider = ({ children, saveReferrer = true }) => {
    const history = useHistory();
    const isInit = useRef(true);
    if (!$auth.isLogged) {
        if (webView.isWebView) {
            webView.close();
            return null;
        }
        const unAuthPage = mainConfig.unAuthPage;
        if (unAuthPage.type === false) {
            // do nothing
        }
        else if (unAuthPage.type === 'parent') {
            const targetHost = `${mainConfig.web.parentUrl}${unAuthPage.path}`;
            let result = '';
            if (unAuthPage.parentBackUrlType === 'same-domain-without-host') {
                result = targetHost + window.location.href.replace(window.location.origin, '');
            }
            else if (unAuthPage.parentBackUrlType === 'get-with-host') {
                result = targetHost + encodeURIComponent(window.location.href);
            }
            else {
                result = targetHost;
            }
            window.location.replace(result);
            return null;
        }
        else if (unAuthPage.type === 'url') {
            window.location.href = unAuthPage.path;
            return null;
        }
        else {
            let redirectParams;
            if (isInit.current && saveReferrer) {
                const referrer = history.location.pathname;
                redirectParams = referrer && { query: { referrer } };
            }
            return _jsx(Redirect, { to: $router.toPath(unAuthPage.path, redirectParams) });
        }
    }
    if (isInit.current) {
        isInit.current = false;
    }
    return _jsx(_Fragment, { children: children });
};
export default observer(AuthProvider);
