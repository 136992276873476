var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { makeAutoObservable, runInAction } from 'mobx';
import _ from 'lodash';
import { placesService } from 'ziphy-web-shared/basic/api';
import maps from '@library/map/Map';
import { extendPlace } from '@helpers/addressHelper';
class PlacesStore {
    constructor() {
        this.items = [];
        makeAutoObservable(this);
    }
    // Computed
    get extendedItems() {
        return this.items.map((item) => extendPlace(item));
    }
    // Mutations
    SET_ITEMS(value) {
        this.items = value;
    }
    // Actions
    clear() {
        this.items = [];
    }
    get() {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield placesService.getActiveList();
            runInAction(() => {
                var _a;
                this.items = ((_a = response.prepared) === null || _a === void 0 ? void 0 : _a.places.items) || [];
            });
        });
    }
    add(params) {
        return __awaiter(this, void 0, void 0, function* () {
            params = _.omit(params, 'id'); // super-hack
            if (!params.timezone && !_.isEmpty(params.coords)) {
                params.timezone = yield maps.getTimeZoneId(params.coords.lat, params.coords.lng);
            }
            const response = yield placesService.create(params);
            if (response.prepared) {
                yield this.get();
            }
        });
    }
    update(id, params) {
        return __awaiter(this, void 0, void 0, function* () {
            const ALLOWED_KEYS = ['apartment', 'floor', 'directions', 'isHidden'];
            const response = yield placesService.update(Object.assign({ id }, _.pick(params, ALLOWED_KEYS)));
            if (response.prepared) {
                yield this.get();
            }
        });
    }
    remove(id) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield placesService.deleteAnyway({ id });
            if (response && response.prepared) {
                yield this.get();
            }
        });
    }
    getById(id) {
        return this.items.find((x) => String(x.id) === String(id)); // super-hack
    }
}
const store = new PlacesStore();
export default store;
