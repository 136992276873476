var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { makeAutoObservable, runInAction } from 'mobx';
import { settingsService } from 'ziphy-web-shared/basic/api';
class SettingsStore {
    constructor() {
        this.tosVersions = {};
        this.symptomCategories = {};
        makeAutoObservable(this);
    }
    read(params, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield settingsService.read(params, Object.assign(Object.assign({}, options), { cacheTime: 900 }));
            if (!response.error) {
                runInAction(() => {
                    var _a, _b, _c, _d;
                    if ((_a = response.prepared) === null || _a === void 0 ? void 0 : _a.tosVersions) {
                        this.tosVersions = (_b = response.prepared) === null || _b === void 0 ? void 0 : _b.tosVersions;
                    }
                    if ((_c = response.prepared) === null || _c === void 0 ? void 0 : _c.symptomCategories) {
                        this.symptomCategories = (_d = response.prepared) === null || _d === void 0 ? void 0 : _d.symptomCategories;
                    }
                });
            }
            return response;
        });
    }
    init() {
        return __awaiter(this, void 0, void 0, function* () {
            yield Promise.all([
                this.read(),
                //
            ]);
        });
    }
}
export const $settings = new SettingsStore();
