import _ from 'lodash'
import { apiService } from 'ziphy-web-shared/basic/api'
import { isAbsoluteEmpty } from 'ziphy-web-shared/basic/helpers'

import { mediaAuthService } from '@ps/services/mediaAuthService'
import { getPsPatientId, getPsPracticeId } from '@ps/services/psService.helpers'

class psService {
  #clearPayload(data, excludedKeys = []) {
    return _.pickBy(
      data,
      (x, key) => _.includes(excludedKeys, key) || !isAbsoluteEmpty(x) || x === false,
    )
  }

  async searchEvents(
    {
      resourceId,
      resources,
      appointmentId,
      diagnosticReportId, // only for observations
      subtype,
      code,
      identifier,
      text,
      startDate,
      stopDate,
      resourceProjection,
      status,
      statusNot,
    } = {},
    options,
  ) {
    let params = {
      patientId: getPsPatientId(),
      practiceId: getPsPracticeId(),
      resourceId,
      resources,
      appointmentId,
      diagnosticreportId: diagnosticReportId,
      subtype,
      code,
      identifier,
      text,
      startDate,
      stopDate,
      resourceProjection,
      status,
      statusNot,
    }

    return await apiService.fetchLoggedV3(
      'patient_stories.search_events',
      this.#clearPayload(params),
      {
        skipAlert: true,
        cacheTime: 5,
        ...options,
      },
    )
  }

  async getResourcesByReferences({ references, identifier, effectiveDateTime, extraParams }) {
    let params = {
      patientId: getPsPatientId(),
      practiceId: getPsPracticeId(),
      references,
      identifier,
      'effectiveDateTime|strict': effectiveDateTime,
      extraParams,
    }

    await mediaAuthService(params).fetch(references)

    return await apiService.fetchLoggedV3(
      'patient_stories.search_by_references',
      this.#clearPayload(params, ['references']),
      {
        skipAlert: true,
      },
    )
  }

  async getCompleteResourcesByReferences({ references, deepinclude }) {
    let params = {
      patientId: getPsPatientId(),
      practiceId: getPsPracticeId(),
      references,
      _deepinclude: deepinclude,
    }

    await mediaAuthService(params).fetch(references)

    return await apiService.fetchLoggedV3(
      'patient_stories.complete_resources',
      this.#clearPayload(params),
      {
        skipAlert: true,
      },
    )
  }

  async getMeasurements({ codes = [] } = {}) {
    let params = {
      patientId: getPsPatientId(),
      practiceId: getPsPracticeId(),
      code: [codes],
    }

    return await apiService.fetchLoggedV3(
      'patient_stories.get_measurements',
      this.#clearPayload(params),
      {
        skipAlert: true,
        cacheTime: 20,
      },
    )
  }
}

export default new psService()
