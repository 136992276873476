import { useEffect, useState } from 'react';
export const useScrollDirection = ({ threshold = 0, distanceUp = 0, distanceDown = 0, } = {}) => {
    const [direction, setDirection] = useState('up');
    const [position, setPosition] = useState(window.scrollY);
    useEffect(() => {
        let lastKnownScrollPosition = window.scrollY;
        let ticking = false;
        const handleScroll = () => {
            lastKnownScrollPosition = window.scrollY;
            if (!ticking) {
                window.requestAnimationFrame(() => {
                    const scrollDifference = lastKnownScrollPosition - position;
                    if (Math.abs(scrollDifference) < threshold) {
                        ticking = false;
                        return;
                    }
                    if (scrollDifference > 0 && scrollDifference >= distanceDown) {
                        setDirection('down');
                        setPosition(lastKnownScrollPosition);
                    }
                    else if (scrollDifference < 0 && Math.abs(scrollDifference) >= distanceUp) {
                        setDirection('up');
                        setPosition(lastKnownScrollPosition);
                    }
                    ticking = false;
                });
                ticking = true;
            }
        };
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [position, threshold, distanceUp, distanceDown]);
    return {
        scrollDirection: direction,
        scrollPosition: position,
        isScrolled: position > threshold,
    };
};
