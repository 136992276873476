var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState, } from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import { getUuid, isAbsoluteEmpty } from 'ziphy-web-shared/basic/helpers';
const initField = {
    value: '',
};
const InputSearch = forwardRef((_a, ref) => {
    var { className, innerClassNames, inputClass, clear = true, label = '', help = '', mode = '', 
    //
    field = initField, onClear, id: _id, 
    //
    children } = _a, 
    //
    props = __rest(_a, ["className", "innerClassNames", "inputClass", "clear", "label", "help", "mode", "field", "onClear", "id", "children"]);
    const inputRef = useRef(null);
    const [innerValue, setInnerValue] = useState(field.value);
    const id = useMemo(() => (_id ? _id : getUuid()), [_id]);
    const isEmpty = useMemo(() => {
        if (field && field.hasOwnProperty('value')) {
            return isAbsoluteEmpty(field.value);
        }
        return isAbsoluteEmpty(field.value);
    }, [field, field.value]); //eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => {
        if (field.value !== innerValue) {
            setInnerValue(field.value);
        }
    }, [field.value]); //eslint-disable-line react-hooks/exhaustive-deps
    function handleOnChange(event) {
        const value = _.get(event, 'target.value', event);
        setInnerValue(value);
        if (field && _.isFunction(field.onChange)) {
            field.onChange(value);
        }
    }
    function handleClear(e) {
        if (e && _.isFunction(e.stopPropagation)) {
            e.stopPropagation();
        }
        let result = '';
        if (field && _.isString(field.value)) {
            result = '';
        }
        else if (field && _.isArray(field.value)) {
            result = [];
        }
        else if (field && _.isObject(field.value)) {
            result = {};
        }
        handleOnChange(result);
        if (_.isFunction(onClear)) {
            onClear();
        }
    }
    function getMode(mode) {
        const array = _.isArray(mode) ? mode : [mode];
        return array.map((x) => 'search--' + x).join(' ');
    }
    // Search methods
    useImperativeHandle(ref, () => ({
        focus() {
            inputRef.current.focus();
        },
        clear() {
            handleClear();
        },
    }));
    return (_jsxs("div", Object.assign({ className: classNames('search', className, mode && getMode(mode), !isEmpty && !props.readOnly && 'search--active', field && field.isInvalid && 'has-error', props.readOnly && 'search--readonly') }, { children: [label && (_jsx("label", Object.assign({ className: "search-label", htmlFor: id }, { children: label }))), _jsxs("div", Object.assign({ className: classNames(innerClassNames, 'search-item') }, { children: [_jsx("input", Object.assign({ ref: inputRef, type: "text", id: id, className: classNames('search-field', inputClass), value: innerValue, onChange: handleOnChange }, props)), clear && !isEmpty && _jsx("div", { className: "search-clear", onClick: handleClear })] })), help && _jsx("div", Object.assign({ className: "search-help" }, { children: help })), !_.isEmpty(children) && children] })));
});
export default InputSearch;
