import React from 'react'

import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'

import { historyPageLimit } from '@ps/config/events'
import HistoryTable from '@ps/library/historyTable/HistoryTable'
import DocumentsFormsList from '@ps/pages/patient/tabs/documents/DocumentsFormsList'
import DocumentsList from '@ps/pages/patient/tabs/documents/DocumentsList'
import DocumentsSearch from '@ps/pages/patient/tabs/documents/DocumentsSearch'
import DocumentsTableHead from '@ps/pages/patient/tabs/documents/DocumentsTableHead'
import DocumentsTablePage from '@ps/pages/patient/tabs/documents/DocumentsTablePage'

import { $psHistory } from '@store'

const TabDocuments = () => {
  const { t } = useTranslation()

  const hasForms = $psHistory.eventsBySubcategory.documents_forms.length > 0
  const hadPersonalDocuments = $psHistory.eventsBySubcategory.documents_personal.length > 0

  return (
    <>
      {hadPersonalDocuments && (
        <DocumentsList items={$psHistory.eventsBySubcategory.documents_personal} />
      )}
      {hasForms && <DocumentsFormsList items={$psHistory.eventsBySubcategory.documents_forms} />}
      {(hadPersonalDocuments || hasForms) && (
        <div className="title-sm">{t('ps.label.medical_documents')}</div>
      )}
      <DocumentsSearch />
      <HistoryTable
        tableItems={$psHistory.table}
        isLoading={$psHistory.isLoading}
        pageLimit={historyPageLimit}
        mode={$psHistory.category}
        headComponent={DocumentsTableHead}
        pageComponent={DocumentsTablePage}
      />
    </>
  )
}

export default observer(TabDocuments)
