var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { action, makeAutoObservable, makeObservable } from 'mobx';
import _ from 'lodash';
import { apiService, paymentMethodsService } from 'ziphy-web-shared/basic/api';
import { getUuid } from 'ziphy-web-shared/basic/helpers';
import { $loader, globalLoaderHandlers } from 'ziphy-web-shared/basic/utils';
import { ContextController, GlobalStorage, IdsController } from '../storageManager';
/*
 * GlobalStorage
 */
class PaymentMethodsGlobalStorage extends GlobalStorage {
    constructor() {
        super({ idKey: 'id', updateCondition: (x) => (x === null || x === void 0 ? void 0 : x.Type) === 'payment_method' });
        makeObservable(this, {
            list: action,
        });
    }
    list(params, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield paymentMethodsService.list(params, options);
        });
    }
}
export const $paymentMethodsGlobalStorage = new PaymentMethodsGlobalStorage();
/*
 * Templates
 */
class PaymentMethodsStore {
    constructor(key = getUuid(), storage = $paymentMethodsGlobalStorage) {
        this.defaultId = null;
        this.contextKey = key;
        this.storage = storage;
        this.itemsIds = new IdsController();
        makeAutoObservable(this);
    }
    // Items
    get items() {
        return this.itemsIds.list.map((id) => this.getById(id)).filter((x) => x);
    }
    get sortedItems() {
        return _.orderBy(this.items, ['id'], ['desc']);
    }
    SET_DEFAULT_ID(id) {
        this.defaultId = id || null;
    }
    getById(id) {
        return this.storage.asObject[id];
    }
    // Actions
    create(params, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield paymentMethodsService.create(params, options);
            if (!response.error)
                this.itemsIds.add(response.preparedValue.id);
            return response;
        });
    }
    read(params, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield paymentMethodsService.read(params, options);
        });
    }
    delete(params, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield paymentMethodsService.delete(params, options);
            if (!response.error)
                this.itemsIds.delete(params.id);
            return response;
        });
    }
    list(params, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield paymentMethodsService.list(params, options);
            if (!response.error)
                this.itemsIds.set(response.preparedValue.map((x) => x.id));
            return response;
        });
    }
    getLast(type = 'lastAdded') {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function* () {
            yield this.list();
            let data = this.sortedItems[0];
            if (type === 'lastUsed') {
                // todo: move to appointmentsService.list()
                function getLastUsedPayedAppointment() {
                    return __awaiter(this, void 0, void 0, function* () {
                        return yield apiService.fetchLoggedV3('appointments.list', {
                            filter: {
                                and: [
                                    {
                                        and: [{ gt: ['payment_method_id', 0] }],
                                    },
                                    {
                                        or: [
                                            { eq: ['status', 'started'] },
                                            { eq: ['status', 'finished'] },
                                            { eq: ['status', 'complete'] },
                                            { eq: ['status', 'exception'] },
                                        ],
                                    },
                                ],
                            },
                            limit: 1,
                            order: [['id', 'desc']],
                        });
                    });
                }
                // todo: move to appointmentsService.list()
                const response = yield getLastUsedPayedAppointment();
                const id = (_b = (_a = response.prepared) === null || _a === void 0 ? void 0 : _a.appointments.items[0]) === null || _b === void 0 ? void 0 : _b.paymentMethodId;
                data = this.getById(id);
            }
            this.SET_DEFAULT_ID(data === null || data === void 0 ? void 0 : data.id);
            return data;
        });
    }
}
export const $paymentMethodsContexts = new ContextController((key) => new PaymentMethodsStore(key));
export const $paymentMethods = $paymentMethodsContexts.get('items');
/*
 * Other
 */
$loader.subscribe(globalLoaderHandlers.LOGOUT, () => {
    $paymentMethodsGlobalStorage.clear();
    $paymentMethodsContexts.clear();
});
