import { jsx as _jsx } from "react/jsx-runtime";
import React, { forwardRef, useEffect, useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import i18n from 'i18next';
import StripeInner from './StripeInner';
const Stripe = forwardRef(({ apiKey, locale }, ref) => {
    const [stripePromise, setStripePromise] = useState(null);
    if (!locale) {
        locale = i18n.language;
    }
    useEffect(() => {
        setStripePromise(loadStripe(apiKey, { locale }));
    }, [apiKey, locale]);
    return (_jsx(Elements, Object.assign({ stripe: stripePromise }, { children: _jsx(StripeInner, { ref: ref }) })));
});
export default Stripe;
