import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { Fragment, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';
import classNames from 'classnames';
import _ from 'lodash';
import moment from 'moment-timezone';
import Icon from 'ziphy-web-shared/basic/assets/images/Icon';
import { useScrollToDependency } from 'ziphy-web-shared/basic/hooks';
import { BubbleLoader } from 'ziphy-web-shared/basic/lib/loaders';
import { $windowSize } from 'ziphy-web-shared/basic/utils';
import { $time, formatDate } from 'ziphy-web-shared/basic/utils/time';
import NewDatePickerModal from '@library/newDatePicker/NewDatePicker.modal';
import { $modal } from '@store';
import { getDaysBoard } from '../TimeSlotPicker.helpers';
import { useParsedDate } from '../TimeSlotPicker.hooks';
import styles from './DaySlotPicker.module.scss';
const defaultLoaderParams = {
    hasLoadTrigger: false,
    onIntersectLoadTrigger: null,
};
const DaySlotPicker = ({ selectedDay, onChangeDay, minDate, maxDate, dates, visibleDaysCount, prevLoaderParams = defaultLoaderParams, nextLoaderParams = defaultLoaderParams, isInfinityScroll = true, }) => {
    const { t } = useTranslation();
    const parsedSelectedDate = useParsedDate(selectedDay, $time.timezone);
    const { ref: prevLoaderRef, inView: inViewPrevLoader } = useInView();
    const { ref: nextLoaderRef, inView: inViewNextLoader } = useInView();
    useLayoutEffect(() => {
        if (_.isFunction(prevLoaderParams.onIntersectLoadTrigger) && inViewPrevLoader) {
            prevLoaderParams.onIntersectLoadTrigger();
        }
    }, [inViewPrevLoader]);
    useLayoutEffect(() => {
        if (_.isFunction(nextLoaderParams.onIntersectLoadTrigger) && inViewNextLoader) {
            nextLoaderParams.onIntersectLoadTrigger();
        }
    }, [inViewNextLoader]);
    const [availableDates, disabledDates] = useMemo(() => {
        const availableDates = [];
        const disabledDates = [];
        dates.forEach((item) => {
            if (item.value) {
                availableDates.push(item.key);
            }
            else {
                disabledDates.push(item.key);
            }
        });
        return [availableDates, disabledDates];
    }, [dates]);
    const [newDatePickerModalId, setNewDatePickerModalId] = useState(null);
    const modalMaxDate = useMemo(() => moment(maxDate).tz($time.timezone).subtract(1, 'day').format('YYYY-MM-DD'), [maxDate]);
    useEffect(() => {
        $modal.updateParams(newDatePickerModalId, {
            minDate: minDate,
            maxDate: modalMaxDate,
            availableDates: availableDates,
            disabledDates: disabledDates,
            hasPrevLoadTrigger: prevLoaderParams.hasLoadTrigger,
            hasNextLoadTrigger: nextLoaderParams.hasLoadTrigger,
            ref: {
                prevLoaderRef: prevLoaderRef,
                nextLoaderRef: nextLoaderRef,
            },
        });
    }, [
        minDate,
        modalMaxDate,
        availableDates,
        disabledDates,
        prevLoaderParams.hasLoadTrigger,
        nextLoaderParams.hasLoadTrigger,
    ]);
    const daysBoard = useMemo(() => getDaysBoard({
        dates: dates,
        todayLabel: t('label.today'),
        timezone: $time.timezone,
        isMobile: $windowSize.isMobileSize,
        visibleDaysCount: visibleDaysCount,
    }), [dates, visibleDaysCount, $time.timezone, $windowSize.isMobileSize]);
    const selectedRef = useRef(null);
    const { scrollRef, contentRef } = useScrollToDependency({
        scrollDependency: minDate,
        changeContentDependency: maxDate,
        horizontalScrollToElement: selectedRef,
    });
    const isSelectedInDaysBoard = _.findIndex(daysBoard, (item) => {
        return item.value.isSame(parsedSelectedDate, 'day');
    }) > -1;
    return (_jsx("div", Object.assign({ className: styles.DaySlotPicker }, { children: _jsx("div", Object.assign({ className: styles.body }, { children: _jsxs("div", Object.assign({ className: classNames(styles.rowBoardDate, {
                    [styles.isMobile]: $windowSize.isMobileSize,
                }), ref: isInfinityScroll ? scrollRef : undefined }, { children: [((!$windowSize.isMobileSize && isSelectedInDaysBoard) || $windowSize.isMobileSize) && (_jsxs("div", Object.assign({ className: styles.rowBoardDateContent, ref: isInfinityScroll ? contentRef : undefined }, { children: [prevLoaderParams.hasLoadTrigger && $windowSize.isMobileSize && (_jsx("div", Object.assign({ ref: prevLoaderRef }, { children: _jsx("div", Object.assign({ className: classNames(styles.cell, styles.cellLoader) }, { children: _jsx(BubbleLoader, {}) })) }))), daysBoard.map(({ day, weekDay, value, valueString, notice, isLocked, isToday }, index) => {
                                const isSelected = parsedSelectedDate.isSame(value, 'day');
                                return (_jsx(Fragment, { children: _jsxs("div", Object.assign({ ref: isSelected ? selectedRef : undefined }, { children: [_jsxs("div", Object.assign({ className: classNames(styles.cell, {
                                                    [styles.cellSelected]: isSelected,
                                                    [styles.cellCurrent]: isToday,
                                                    [styles.cellLocked]: isLocked,
                                                }), onClick: () => onChangeDay(valueString) }, { children: [_jsx("div", Object.assign({ className: styles.day }, { children: day })), _jsx("div", Object.assign({ className: styles.weekDay }, { children: weekDay }))] })), notice && _jsx("div", Object.assign({ className: styles.notice }, { children: notice }))] })) }, value.toString() + index));
                            }), nextLoaderParams.hasLoadTrigger && $windowSize.isMobileSize && (_jsx("div", Object.assign({ ref: nextLoaderRef }, { children: _jsx("div", Object.assign({ className: classNames(styles.cell, styles.cellLoader) }, { children: _jsx(BubbleLoader, {}) })) })))] }))), !$windowSize.isMobileSize && !isSelectedInDaysBoard && (_jsxs("div", Object.assign({ className: classNames(styles.cell, styles.selectedDate) }, { children: [formatDate(selectedDay, { format: 'dateFull' }), _jsx("div", Object.assign({ className: styles.cellClose, onClick: () => { var _a, _b; return onChangeDay(((_a = dates.find((date) => date.value)) === null || _a === void 0 ? void 0 : _a.key) || ((_b = dates[0]) === null || _b === void 0 ? void 0 : _b.key)); } }, { children: _jsx(Icon, { type: "close" }) }))] }))), !$windowSize.isMobileSize && dates.length > visibleDaysCount && (_jsx("div", Object.assign({ onClick: () => {
                            const params = $modal.add(NewDatePickerModal, {
                                type: 'selectDateTime',
                                title: t('calendar.choose_date'),
                                selectedDate: selectedDay,
                                onChange: (value) => onChangeDay(value),
                                timezone: $time.timezone,
                                minDate: minDate,
                                maxDate: modalMaxDate,
                                confirmDate: false,
                                availableDates: availableDates,
                                disabledDates: disabledDates,
                                dayList: true,
                                hasPrevLoadTrigger: prevLoaderParams.hasLoadTrigger,
                                hasNextLoadTrigger: nextLoaderParams.hasLoadTrigger,
                                ref: {
                                    // @ts-ignore
                                    prevLoaderRef: prevLoaderRef,
                                    nextLoaderRef: nextLoaderRef,
                                },
                            });
                            setNewDatePickerModalId(params._core.id);
                        }, className: classNames(styles.cell, styles.moreDates) }, { children: t('btn.more_dates') })))] })) })) })));
};
export default observer(DaySlotPicker);
