import { action, isAction, makeAutoObservable, runInAction } from 'mobx'

import _ from 'lodash'
import moment from 'moment-timezone'
import { pushOrUpdate } from 'ziphy-web-shared/basic/helpers'
import { $loader, globalLoaderHandlers } from 'ziphy-web-shared/basic/utils'

import clientService from '@services/client'

import mainConfig from '@config/main'

let idCounter = 0

const getId = () => {
  idCounter--
  return idCounter
}

let stoppedChatIdTimeout

class NotificationsStore {
  constructor() {
    makeAutoObservable(this)
  }

  items = []
  popups = []
  lastTime = false
  stoppedChatIds = false

  clear() {
    this.items = []
    this.popups = []
    this.lastTime = false
    this.stoppedChatIds = false
  }

  // Computed
  get allItems() {
    const map = new Map()
    const prevMessages = new Set()

    this.items.forEach((item) => {
      if (item.hideIfViewed && item.viewed) return
      const appointmentId = item.event?.appointmentId

      if (item.notification === 'chat_message_received') {
        const pattern = `${appointmentId}_${item.event.actorRole}`
        if (prevMessages.has(pattern)) return
        prevMessages.add(pattern)
      }

      if (map.has(appointmentId)) {
        const mapValue = map.get(appointmentId)
        if (mapValue) {
          mapValue.items.push(item)
          mapValue.hasActual = mapValue.hasActual || item.isActual
        }
      } else {
        map.set(appointmentId, {
          appointmentId: appointmentId,
          hasActual: item.isActual,
          items: [item],
        })
      }
    })

    return map
  }

  get notViewedItems() {
    return this.items.filter((x) => !x.viewed)
  }

  get hasActualItems() {
    return Array.from(this.allItems.values()).find((x) => x.hasActual)
  }

  // Mutations
  updateActual() {
    this.items.forEach((item) => (item.isActual = item.isActualFn()))
  }

  SET_STOPPED_CHAT(ids) {
    if (ids === false) {
      stoppedChatIdTimeout = setTimeout(() => {
        runInAction(() => {
          this.stoppedChatIds = ids
        })
      }, mainConfig.notifications.updateInterval)
    } else {
      clearTimeout(stoppedChatIdTimeout)
      this.stoppedChatIds = ids
    }
  }

  // Notices Actions
  async getHistory() {
    const now = moment.utc()
    const historyTime = moment.utc().subtract(mainConfig.notifications.historyPeriod, 'days')

    // check limit on the frequency of history requests
    const lastTime = this.lastTime ? this.lastTime : historyTime
    const diff = moment.duration(now.diff(lastTime)).asMilliseconds()

    if (diff < mainConfig.notifications.updateInterval) {
      return false
    }

    const items = await clientService.getPreparedNotifications({ lastTime: historyTime })

    runInAction(() => {
      this.items = items
      this.lastTime = moment().utc()
    })
  }

  async getNew() {
    if (!this.lastTime) {
      await this.getHistory()
      return false
    }

    const list = await clientService.getPreparedNotifications({ lastTime: this.lastTime })

    runInAction(() => {
      if (list.length) {
        const canShow = true

        let hasPopup = false
        let idsNeedToView = []

        this.lastTime = moment().utc()

        list.reverse().forEach((item) => {
          if (!item.viewed) {
            let needPopup = true

            // check chat messages
            if (this.itIsOpenChat(item)) {
              needPopup = false
              item.viewed = true
              idsNeedToView.push(item.id)
            }

            if (needPopup && canShow) {
              this.addPopup(item, true)
              hasPopup = true
            }
          }

          this.addOrUpdate(item)
        })

        if (hasPopup) {
          this.showPopup()
        }
        if (idsNeedToView.length) {
          this.view({ ids: idsNeedToView })
        }
      }
    })
  }

  addOrUpdate({ head, title, body, ...other }) {
    const newItem = {
      head: isAction(head) ? head : action(() => head),
      title: isAction(title) ? title : action(() => title),
      body: isAction(body) ? body : action(() => body),
      ...other,
    }

    this.items = pushOrUpdate(this.items, newItem, { pushTo: 'start', merge: true })
  }

  async view({ ids = [], chatIds = [] }) {
    chatIds.forEach((chatId) => {
      const found = this.items.find((x) => chatId === x.event?.chatMessageId)

      if (found) {
        ids.push(found.id)
      }
    })

    ids = ids.filter((id) => {
      return this.items.find((x) => x.id === id && !x.viewed)
    })

    const res = await clientService.viewNotifications({ ids })

    if (res && res.length) {
      runInAction(() => {
        res.forEach((x) => this.addOrUpdate(x))
        this.updateActual()
      })
    }
  }

  // Popup Notices Queue
  addPopup(
    { head, title, body, actions = [], id = getId(), onLeaved, ...other } = {},
    isNotification = false,
  ) {
    const newPopup = {
      show: false,
      isNotification,
      id: id,
      head: isAction(head) ? head : action(() => head),
      title: isAction(title) ? title : action(() => title),
      body: isAction(body) ? body : action(() => body),
      actions: actions,
      onLeaved: typeof onLeaved === 'function' ? action(() => onLeaved()) : onLeaved,
      ...other,
    }

    this.popups = pushOrUpdate(this.popups, newPopup, { pushTo: 'start' })

    if (!isNotification) {
      this.showPopup()
    }
  }

  // Popup Notices Show
  updatePopup(id, payload) {
    const index = this.popups.findIndex((x) => x.id === id)

    if (index !== -1) {
      this.popups[index] = { ...this.popups[index], ...payload }
    }
  }

  showPopup() {
    if (!this.popups.length || this.popups.some((x) => x.show)) {
      return false
    }

    let item = this.popups.findLast((x) => !x.show)

    if (item.isNotification) {
      const notification = this.items.find((x) => x.id === item.id)

      if (!notification || notification.viewed || this.itIsOpenChat(notification)) {
        this.removePopup(item.id)
        return false
      }
    }

    this.updatePopup(item.id, { show: true })
  }

  hidePopup(id) {
    this.updatePopup(id, { show: false })
  }

  removePopup(id) {
    if (this.popups.length) {
      this.popups = this.popups.filter((x) => x.id !== id)
      this.showPopup()
    }
  }

  itIsOpenChat(item) {
    if (item.notification === 'chat_message_received' && this.stoppedChatIds) {
      const ids = item.event?.recipientIds || []
      const result = _.intersection(ids, this.stoppedChatIds)
      if (!_.isEmpty(result)) {
        return true
      }
    }

    return false
  }

  hideAllPopups() {
    if (this.popups.length) {
      runInAction(() => {
        this.popups = this.popups.filter((x) => x.show)
        this.popups.forEach((x) => this.hidePopup(x.id))
      })
    }
  }
}

const $notifications = new NotificationsStore()

/*
 * Other
 */
$loader.subscribe(globalLoaderHandlers.LOGOUT, () => {
  $notifications.clear()
})
$loader.subscribe(globalLoaderHandlers.NEW_MINUTE, () => {
  runInAction(() => $notifications.updateActual())
})

export default $notifications
