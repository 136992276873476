import React from 'react'

import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'

import { $auth } from 'ziphy-web-shared/basic/entities/auth'

import SidebarMenuItem from '@src/layouts/sidebar/SidebarMenuItem'

import { confirmAction } from '@library/utils/actionConfirmator/ActionConfirmator'

import Button from '@nedoShared/basic/lib/simple/button/Button'

import { $appts } from '@store'

import { routeActions } from '@config/routeActions'

import * as imgs from '@assets/images/menu'

const SidebarMenu = ({ onHideSidebar }) => {
  const { t } = useTranslation()

  const countAppts = $appts.activeList.length

  function checkAuth(e, props) {
    confirmAction('checkAuth', props, e)
  }

  return (
    <>
      {$auth.isLogged ? (
        <>
          <SidebarMenuItem
            autoTarget={routeActions.HOME()}
            onClick={onHideSidebar}
            params={{ exact: true }}
            icon={imgs.home}
          >
            {t('btn.home')}
          </SidebarMenuItem>
          <SidebarMenuItem
            autoTarget={routeActions.APPTS()}
            onClick={onHideSidebar}
            params={{ exact: true }}
            icon={imgs.appointments}
            counter={countAppts}
          >
            {t('btn.appointments')}
          </SidebarMenuItem>
          <SidebarMenuItem
            autoTarget={routeActions.PROFILE()}
            onClick={onHideSidebar}
            icon={imgs.profile}
          >
            {t('btn.profile')}
          </SidebarMenuItem>
          <SidebarMenuItem
            autoTarget={routeActions.PATIENTS()}
            onClick={onHideSidebar}
            icon={imgs.circleOfCare}
          >
            {t('menu.circle_of_care')}
          </SidebarMenuItem>
          <SidebarMenuItem
            autoTarget={routeActions.PMS()}
            onClick={onHideSidebar}
            icon={imgs.paymentMethod}
          >
            {t('menu.payment_method')}
          </SidebarMenuItem>
        </>
      ) : (
        <div className="text-center">
          <Button mode={['secondary', 'block']} action={checkAuth} label={t('btn.start')} />
        </div>
      )}
    </>
  )
}

export default observer(SidebarMenu)
