import moment from 'moment-timezone';
import { humanize } from 'ziphy-web-shared/basic/helpers';
export function paymentMethodIsExpired(item) {
    if (!item) {
        return false;
    }
    const currentMonth = moment().startOf('month');
    const cardMonth = moment(item.expYear + '-' + item.expMonth, 'YYYY-MM');
    return cardMonth.isBefore(currentMonth);
}
export function paymentMethodsLabel(item) {
    return (item === null || item === void 0 ? void 0 : item.brand) ? humanize(item.brand) : '';
}
