import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { APP_VERSION } from 'ziphy-web-shared/basic/helpers';
import { TOS_GENERAL, TOS_PROVIDER } from '@library/plugins/termsOfService/Tos.config';
import mainConfig from '@config/main';
import styles from './SidebarFooter.module.scss';
const SidebarFooter = () => {
    const { t } = useTranslation();
    return (_jsxs("div", Object.assign({ className: styles.wrapper }, { children: ["\u00A9", new Date().getFullYear(), " ", mainConfig.serviceCompanyName, ".", _jsx("div", { children: _jsx(Link, Object.assign({ to: `?${TOS_GENERAL}` }, { children: t('footer.tos') })) }), mainConfig.custom.tosProviderEnabled && (_jsx("div", { children: _jsx(Link, Object.assign({ to: `?${TOS_PROVIDER}` }, { children: t('footer.tos_provider') })) })), t('version', { number: APP_VERSION })] })));
};
export default SidebarFooter;
