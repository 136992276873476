export const ENUMS_INSURANCE_TYPES = {
    medical: {
        id: 'medical',
        tKey: 'enums.insurance.types.medical',
    },
    medicare: {
        id: 'medicare',
        tKey: 'enums.insurance.types.medicare',
        labelMemberId: 'enums.insurance.types.medicare.member_id',
    },
    medicaid: {
        id: 'medicaid',
        tKey: 'enums.insurance.types.medicaid',
        labelMemberId: 'enums.insurance.types.medicaid.member_id',
    },
    tricare: {
        id: 'tricare',
        tKey: 'enums.insurance.types.tricare',
        labelMemberId: 'enums.insurance.types.tricare.member_id',
    },
    champva: {
        id: 'champva',
        tKey: 'enums.insurance.types.champva',
    },
    group: {
        id: 'group',
        tKey: 'enums.insurance.types.group',
    },
    feca: {
        id: 'feca',
        tKey: 'enums.insurance.types.feca',
    },
    black_lung: {
        id: 'black_lung',
        tKey: 'enums.insurance.types.black_lung',
    },
};
export const ENUMS_INSURANCE_POLICY_HOLDERS = {
    self: {
        id: 'self',
        tKey: 'enums.insurance.policy_holders.self',
    },
    spouse: {
        id: 'spouse',
        tKey: 'enums.insurance.policy_holders.spouse',
    },
    guardian: {
        id: 'guardian',
        tKey: 'enums.insurance.policy_holders.guardian',
    },
    guarantor: {
        id: 'guarantor',
        tKey: 'enums.insurance.policy_holders.guarantor',
    },
    other: {
        id: 'other',
        tKey: 'enums.insurance.policy_holders.other',
    },
};
