import React from 'react'

import { observer } from 'mobx-react-lite'

import { historyPageLimit } from '@ps/config/events'
import HistoryTable from '@ps/library/historyTable/HistoryTable'
import AppointmentsSearch from '@ps/pages/patient/tabs/appointments/AppointmentsSearch'
import AppointmentsTableHead from '@ps/pages/patient/tabs/appointments/AppointmentsTableHead'
import AppointmentsTablePage from '@ps/pages/patient/tabs/appointments/AppointmentsTablePage'

import { $psHistory } from '@store'

const TabAppointments = () => {
  return (
    <>
      <AppointmentsSearch />
      <HistoryTable
        tableItems={$psHistory.table}
        isLoading={$psHistory.isLoading}
        pageLimit={historyPageLimit}
        mode={$psHistory.category}
        headComponent={AppointmentsTableHead}
        pageComponent={AppointmentsTablePage}
      />
    </>
  )
}

export default observer(TabAppointments)
