import { makeAutoObservable } from 'mobx';
import ExtractorBase from '../extractors/Base';
import { prepareMeta } from '../fhir.converters';
import { getCodingObjects, getExtensionObjects, getIdentifierObjects } from '../fhir.helpers';
import { joinArrayWithObjectToString, joinValuesToString } from '../fhir.utils';
class ExtractorCarePlan {
    constructor(resource) {
        this.base = new ExtractorBase(this);
        this.base.SET(resource);
        makeAutoObservable(this);
    }
    get resource() {
        return this.base.resource;
    }
    /*
     * RESOURCE - Minimally prepared resource data, prepareConverters, valueObjects, etc.
     */
    get extensions() {
        return getExtensionObjects(this.resource.extension);
    }
    get identifiers() {
        return getIdentifierObjects(this.resource.identifier);
    }
    get categories() {
        return getCodingObjects(this.resource.category);
    }
    // other in the FHIR - Group
    get subject() {
        return this.base.getChildExtractor(this.resource.subject);
    }
    // other in the FHIR - Device | CareTeam
    get author() {
        return this.base.getChildExtractor(this.resource.author);
    }
    get description() {
        return this.resource.description;
    }
    get meta() {
        return prepareMeta(this.resource.meta);
    }
    /*
     * READ - PREPARED Values
     */
    get zid() {
        return this.identifiers.first({ config: 'zid' });
    }
    get notes() {
        return this.resource.note;
    }
    /*
     * STRING Values
     */
    get asString() {
        const tmp = {
            description: this.description,
            notes: joinArrayWithObjectToString(this.notes, 'text', ', '),
            full: '',
        };
        tmp.full = joinValuesToString([tmp.description, tmp.notes], ' • ');
        return tmp;
    }
}
export default ExtractorCarePlan;
