import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'

import _ from 'lodash'
import { useLoader } from 'ziphy-web-shared/basic/hooks'
import { formatDate } from 'ziphy-web-shared/basic/utils/time'

import Modal from '@library/modal/Modal'
import ModalToolbarBtn from '@library/modal/toolbar/ModalToolbarBtn'
import DtlGroup from '@library/ui/dtl/DtlGroup'
import DtlItem from '@library/ui/dtl/DtlItem'

import { resourceExtractor } from '@fhir/extractor'

import { openEventModal } from '@ps/helpers/events'
import EventsViewer from '@ps/library/eventsViewer/EventsViewer'

import { $psHistory, $psResources } from '@store'

const HistoryEventModal = ({ _core, baseItem, items, showRelatedAppt = true }) => {
  const { t } = useTranslation()

  const baseEvent = baseItem.event

  const apptEvent = useMemo(() => {
    return $psHistory.getEventById(baseEvent.appointmentId, 'appointment_main')
  }, [$psHistory.events, baseEvent]) // eslint-disable-line react-hooks/exhaustive-deps

  const { isLoading } = useLoader(async () => {
    const events = [..._.map(items, (x) => x.event), apptEvent]
    return await $psResources.getCompleteResources({ items: events })
  })

  const relatedAppt = useMemo(() => {
    const resource = $psResources.getById(apptEvent.id)
    const extractor = resourceExtractor(resource)
    return { event: apptEvent, resource, extractor }
  }, [isLoading]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Modal centered scrollable size="xl">
      <div className="modal-toolbar">
        <ModalToolbarBtn type="close" action={_core.onHide} />
      </div>

      <Modal.Header>
        <div className="modal-header-title title-md">
          {t(baseEvent.label)}, {formatDate(baseEvent.effectiveDate, { format: 'date' })}
        </div>
      </Modal.Header>

      <Modal.Body isLoading={isLoading} className="styled_scroll">
        <DtlGroup className="row pb-30 row--mb-20">
          <DtlItem className="col-12 col-lg-4" label={t('ps.label.type')}>
            {t(baseEvent.label)}
          </DtlItem>
          <DtlItem className="col-12 col-lg-4" label={t('ps.label.date')}>
            {formatDate(baseEvent.effectiveDate, { format: 'date' })}
          </DtlItem>

          {showRelatedAppt && relatedAppt.extractor?.asString?.appointmentType && (
            <DtlItem className="col-12 col-lg-4" label={t('ps.label.appointment')}>
              {
                <span
                  className="a"
                  onClick={() =>
                    openEventModal([_.pick(relatedAppt, ['event', 'resource'])], false)
                  }
                >
                  {relatedAppt.extractor?.asString.appointmentType}
                </span>
              }
            </DtlItem>
          )}
        </DtlGroup>

        <div className="event_details-wrapper">
          <div className="title-sm">{t('label.details')}</div>
          {items.map(({ event, resource }) => (
            <EventsViewer type="details" event={event} resource={resource} key={event.id} />
          ))}
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default observer(HistoryEventModal)
