import { makeAutoObservable, runInAction } from 'mobx';
import i18n from 'i18next';
import _ from 'lodash';
import moment from 'moment-timezone';
import { isAbsoluteEmpty } from 'ziphy-web-shared/basic/helpers';
import { $loader, globalLoaderHandlers } from 'ziphy-web-shared/basic/utils';
import { calendarFormats, dateDiffUnits, dateFormats } from './time.constants';
class TimeStore {
    constructor() {
        makeAutoObservable(this);
        this.startUpdatingTime();
    }
    get timezone() {
        return this._timezone || Intl.DateTimeFormat().resolvedOptions().timeZone || 'UTC';
    }
    get weekType() {
        return this._weekType || 'week';
    }
    get today() {
        return this.now.clone().startOf('day');
    }
    SET_TIMEZONE(value) {
        runInAction(() => (this._timezone = value));
    }
    startUpdatingTime() {
        this.updateTime();
        const secondsUntilNextMinute = 60 - this.now.seconds();
        setTimeout(() => {
            this.updateTime();
            this.intervalId = setInterval(() => this.updateTime(), 60000);
        }, secondsUntilNextMinute * 1000);
    }
    updateTime() {
        this.now = moment.tz(this.timezone);
        $loader.simpleTrigger(globalLoaderHandlers.NEW_MINUTE);
    }
    dispose() {
        clearInterval(this.intervalId);
    }
}
export const $time = new TimeStore();
export function getFormatByLocale(key, locale) {
    const currentLocale = locale || moment.locale();
    const dates = dateFormats[currentLocale] || dateFormats['en'];
    const calendars = calendarFormats[currentLocale] || calendarFormats['en'];
    return Object.assign(Object.assign({}, dates), calendars)[key];
}
export function formatDuration(duration = 0, format = 'm:ss') {
    let negative = false;
    duration = Number(duration);
    if (duration < 0) {
        duration = Math.abs(duration);
        negative = true;
    }
    duration = Math.ceil(duration / 1000);
    let hours = Math.floor((duration / (60 * 60)) % 24);
    let minutes = Math.floor((duration / 60) % 60);
    let seconds = Math.floor(duration % 60);
    let result = [];
    function _getFormatted(type, value) {
        return _.padStart(value === null || value === void 0 ? void 0 : value.toString(), type.length, '0');
    }
    for (let v of format.split(':')) {
        let tmp;
        v = v.toLowerCase();
        if (v === 'h' || v === 'hh') {
            tmp = _getFormatted(v, hours);
        }
        else if (v === 'm' || v === 'mm') {
            tmp = _getFormatted(v, minutes);
        }
        else if (v === 's' || v === 'ss') {
            tmp = _getFormatted(v, seconds);
        }
        result.push(tmp);
    }
    result = result.join(':');
    return negative ? `-${result}` : result;
}
export function formatAsAge(date, withText = true) {
    const years = moment.utc().diff(date, 'years');
    if (withText) {
        return years + i18n.t('user.yo');
    }
    return years;
}
export function formatDate(date, formatOrOptions, timezone) {
    let format = 'auto';
    timezone = timezone || $time.timezone;
    let empty = '';
    if (typeof formatOrOptions === 'string') {
        format = formatOrOptions || format;
    }
    else {
        format = (formatOrOptions === null || formatOrOptions === void 0 ? void 0 : formatOrOptions.format) || format;
        timezone = (formatOrOptions === null || formatOrOptions === void 0 ? void 0 : formatOrOptions.timezone) || timezone;
        empty = (formatOrOptions === null || formatOrOptions === void 0 ? void 0 : formatOrOptions.empty) || empty;
    }
    if (!date) {
        return empty;
    }
    date = moment(date);
    const isDate = date.creationData().format === 'YYYY-MM-DD';
    if (format === 'auto') {
        format = isDate ? 'date' : 'datetime';
    }
    if (!isDate && timezone) {
        date = date.tz(timezone);
    }
    if (getFormatByLocale(format)) {
        format = getFormatByLocale(format);
    }
    return date.format(format);
}
export function calendar(date, { type = 'calendarDatetime', timezone = $time.timezone, relative = false, } = {}) {
    let result;
    let relativeDate = null;
    const hasDateAndTime = 
    // @ts-ignore
    typeof date === 'object' && 'date' in date && 'time' in date && !!date.date && !!date.time;
    if (hasDateAndTime) {
        // @ts-ignore
        result = moment(new Date(date.date + ' ' + date.time));
    }
    else {
        result = moment(date);
        if (relative && timezone) {
            relativeDate = moment().tz(timezone);
        }
    }
    if (timezone && relativeDate) {
        result = result.tz(timezone);
    }
    return moment(result).calendar(relativeDate, getFormatByLocale(type));
}
export const formatDateOrDash = (value, params) => {
    return formatDate(value, Object.assign(Object.assign({}, params), { empty: '—' }));
};
export const formatMinutesToHoursMinutes = (minutes) => {
    const hours = {
        value: Math.floor(minutes / 60),
        label: {
            web: dateDiffUnits[moment.locale()].h.web,
            mobile: dateDiffUnits[moment.locale()].h.mobile,
        },
    };
    const min = {
        value: Math.ceil(minutes % 60),
        label: {
            web: dateDiffUnits[moment.locale()].m.web,
            mobile: dateDiffUnits[moment.locale()].m.mobile,
        },
    };
    const h = `${hours.value > 0 ? `${hours.value}${hours.label.web}` : ''}`;
    const m = `${min.value > 0 ? `${min.value}${min.label.web}` : ''}`;
    return { hours: h, minutes: m };
};
export const getHMDiff = (from, to, _minutes) => {
    const volume = _minutes || moment(to).diff(moment(from)) / 1000 / 60;
    const { hours, minutes } = formatMinutesToHoursMinutes(volume);
    return _.trim(`${hours} ${minutes}`);
};
export const getInitFactTimeDiff = (start, finish, factStart, factFinish) => {
    let result;
    let volume = Math.ceil(moment(factFinish).diff(moment(factStart)) / 1000 / 60) -
        moment(finish).diff(moment(start)) / 1000 / 60;
    if (volume < 0) {
        const { hours, minutes } = formatMinutesToHoursMinutes(-volume);
        result = '-' + _.trim(`${hours} ${minutes}`);
    }
    else if (volume > 0) {
        const { hours, minutes } = formatMinutesToHoursMinutes(volume);
        result = '+' + _.trim(`${hours} ${minutes}`);
    }
    else {
        result = '';
    }
    return result;
};
export const composeDateComponentsToDateString = (dateComponents, asStringAlways) => {
    const { year, month, day } = dateComponents;
    let result;
    if (!isAbsoluteEmpty(day)) {
        if (asStringAlways) {
            result = `${year}-${_.padStart((month + 1).toString(), 2, '0')}-${_.padStart(day.toString(), 2, '0')}`;
        }
        else {
            result = new Date(year, month, day).toISOString();
        }
    }
    else {
        result = year.toString();
        if (!isAbsoluteEmpty(month)) {
            result += `-${_.padStart((month + 1).toString(), 2, '0')}`;
        }
    }
    return result;
};
export const decomposeDateStringToDateComponents = (dateString) => {
    let result = {};
    if (!isAbsoluteEmpty(dateString)) {
        if (dateString.length < 8) {
            // incomplete date string
            if (dateString.length === 4) {
                result.year = +dateString;
            }
            else {
                result.year = +dateString.split('-')[0];
                result.month = +dateString.split('-')[1];
            }
        }
        else {
            const tempDate = new Date(dateString);
            result.year = tempDate.getFullYear();
            result.month = tempDate.getMonth() + 1;
            result.day = tempDate.getDate();
        }
    }
    return result;
};
export const decodeAndFormatDateObject = (dateObj) => {
    const { dateString, isFull } = dateObj;
    if (isAbsoluteEmpty(dateString)) {
        return '';
    }
    let result;
    if (isFull) {
        result = formatDate(dateString, { format: 'date' });
    }
    else {
        if (dateString.length === 4) {
            // only a year, like 1994
            result = dateString;
        }
        else if (dateString.length > 7) {
            result = formatDate(dateString, { format: 'date' });
        }
        else {
            result = formatDate(dateString, { format: 'dateNoDay' });
        }
    }
    return result;
};
export function isValidDateString(str) {
    const regex = /^\d{4}-\d{2}-\d{2}(T\d{2}:\d{2}:\d{2}(?:.\d+)?(?:Z|[+-]\d{2}:\d{2})?)?$/;
    return regex.test(str);
}
export function getUtcOffset(timezone = $time.timezone, type = 'hour') {
    const timeInZone = moment.tz(timezone);
    const offsetInMinutes = timeInZone.utcOffset();
    const delimiter = type === 'hour' ? 60 : 1;
    return offsetInMinutes / delimiter;
}
export function getIsoDate(value, timezone = $time.timezone) {
    let date = moment.utc(value);
    if (timezone) {
        date = date.tz(timezone);
    }
    return date.startOf('day').format('YYYY-MM-DD');
}
