import { jsx as _jsx } from "react/jsx-runtime";
import React, { lazy } from 'react';
import DefaultLayout from '@src/layouts/DefaultLayout';
import AuthProvider from '@library/providers/AuthProvider';
import PageProvider from '@library/providers/PageProvider';
const ApptPage = lazy(() => import('@pages/ApptPage'));
const ApptsPage = lazy(() => import('@pages/ApptsPage'));
const BookingSelectPatientsPage = lazy(() => import('@booking/pages/bookingSelectPatients/BookingSelectPatientsPage'));
const BookingSymptomsPage = lazy(() => import('@booking/pages/bookingSymptoms/BookingSymptomsPage'));
const BookingTimePickerPage = lazy(() => import('@booking/pages/bookingTimePicker/BookingTimePickerPage'));
const BookingFinishPage = lazy(() => import('@booking/pages/bookingFinish/BookingFinishPage'));
const CocPatientPage = lazy(() => import('@pages/CocPatientPage'));
const CocPatientsPage = lazy(() => import('@pages/CocPatientsPage'));
const ErrorPage = lazy(() => import('@pages/errors/ErrorPage'));
const HomePage = lazy(() => import('@pages/HomePage'));
const PaymentMethodsPage = lazy(() => import('@pages/PaymentMethodsPage'));
const ClientProfilePage = lazy(() => import('@pages/ClientProfilePage'));
const PsPatientPage = lazy(() => import('@ps/pages/PsPatientPage'));
const SignoutPage = lazy(() => import('@pages/auth/SignoutPage'));
export const routes = [
    {
        id: 'home',
        path: '/',
        element: (_jsx(DefaultLayout, { children: _jsx(PageProvider, { children: _jsx(HomePage, {}) }) })),
        exact: true,
    },
    {
        id: 'bookPatients',
        tags: ['hideNearestBar', 'hideRightSidebar'],
        path: '/book/patients',
        element: (_jsx(AuthProvider, { children: _jsx(DefaultLayout, { children: _jsx(PageProvider, { children: _jsx(BookingSelectPatientsPage, {}) }) }) })),
    },
    {
        id: 'bookSymptoms',
        tags: ['hideNearestBar', 'hideRightSidebar'],
        path: '/book/symptoms',
        element: (_jsx(AuthProvider, { children: _jsx(DefaultLayout, Object.assign({ fullHeight: true }, { children: _jsx(PageProvider, { children: _jsx(BookingSymptomsPage, {}) }) })) })),
    },
    {
        id: 'bookDate',
        tags: ['hideNearestBar', 'hideRightSidebar'],
        path: '/book/date',
        element: (_jsx(AuthProvider, { children: _jsx(DefaultLayout, { children: _jsx(PageProvider, { children: _jsx(BookingTimePickerPage, {}) }) }) })),
    },
    {
        id: 'bookFinish',
        tags: ['hideNearestBar', 'hideRightSidebar'],
        path: '/book/finish',
        element: (_jsx(AuthProvider, { children: _jsx(DefaultLayout, { children: _jsx(PageProvider, { children: _jsx(BookingFinishPage, {}) }) }) })),
    },
    {
        id: 'appts',
        path: '/appointments',
        element: (_jsx(AuthProvider, { children: _jsx(DefaultLayout, { children: _jsx(PageProvider, { children: _jsx(ApptsPage, {}) }) }) })),
    },
    {
        id: 'appt',
        tags: ['hideNearestBar'],
        path: '/appointments/:appointmentId/:encounterId?',
        element: (_jsx(AuthProvider, { children: _jsx(DefaultLayout, { children: _jsx(PageProvider, Object.assign({ onEnterScrollToTop: true }, { children: _jsx(ApptPage, {}) })) }) })),
    },
    {
        id: 'profile',
        path: '/profile',
        element: (_jsx(AuthProvider, { children: _jsx(DefaultLayout, { children: _jsx(PageProvider, { children: _jsx(ClientProfilePage, {}) }) }) })),
    },
    {
        id: 'patients',
        path: '/circle',
        element: (_jsx(AuthProvider, { children: _jsx(DefaultLayout, { children: _jsx(PageProvider, { children: _jsx(CocPatientsPage, {}) }) }) })),
    },
    {
        id: 'patient',
        path: '/circle/:patientId',
        element: (_jsx(AuthProvider, { children: _jsx(DefaultLayout, { children: _jsx(PageProvider, { children: _jsx(CocPatientPage, {}) }) }) })),
    },
    {
        id: 'psPatient',
        path: '/circle/:patientId/practice/:practiceId/:categoryId?',
        element: (_jsx(AuthProvider, { children: _jsx(DefaultLayout, { children: _jsx(PageProvider, { children: _jsx(PsPatientPage, {}) }) }) })),
    },
    {
        id: 'pms',
        path: '/payment-methods',
        element: (_jsx(AuthProvider, { children: _jsx(DefaultLayout, { children: _jsx(PageProvider, { children: _jsx(PaymentMethodsPage, {}) }) }) })),
    },
    {
        id: 'signout',
        path: '/signout',
        element: (_jsx(AuthProvider, { children: _jsx(DefaultLayout, { children: _jsx(PageProvider, { children: _jsx(SignoutPage, {}) }) }) })),
    },
    {
        id: 'error',
        path: '*',
        element: (_jsx(DefaultLayout, { children: _jsx(PageProvider, { children: _jsx(ErrorPage, {}) }) })),
    },
];
