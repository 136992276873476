import React, { useMemo, useState } from 'react'

import { useTranslation } from 'react-i18next'

import classNames from 'classnames'
import _ from 'lodash'

import './RatingStars.scss'

const RatingStars = ({ field, count = 5, label = 'default', meta = false }) => {
  const { t } = useTranslation()

  const { value = 0, onChange } = field

  const [selected, setSelected] = useState(Math.max(Math.min(_.toInteger(value), count), 0))

  const preparedList = useMemo(() => {
    let result = []

    for (let i = 1; i <= count; i++) {
      result.push({ isActive: selected >= i, rate: i })
    }

    return result
  }, [selected, count])

  function handleClick(rate) {
    if (rate === selected) {
      rate = 0
    }

    setSelected(rate)

    if (_.isFunction(onChange)) {
      onChange(rate)
    }
  }

  return (
    <div className="rating-stars">
      <div className="rating-stars-group">
        {_.map(preparedList, (item, index) => (
          <div
            key={index}
            className={classNames(
              'rating-stars-item',
              item.isActive && 'rating-stars-item--active',
            )}
            onClick={() => handleClick(item.rate)}
          />
        ))}
      </div>
      {meta && (
        <div className="rating-stars-meta">
          {t(`rating.${label}_interval`, { postProcess: 'interval', count: selected })}
        </div>
      )}
    </div>
  )
}

export default RatingStars
