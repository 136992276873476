var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { BaseService } from '../baseService';
export class Places extends BaseService {
    constructor(props) {
        super(props);
    }
    create(params, options) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            let res;
            res = yield this.api.fetchLoggedV3('places.create', params, options);
            return Object.assign(Object.assign({}, res), { preparedValue: (_a = res.prepared) === null || _a === void 0 ? void 0 : _a.place });
        });
    }
    read(params, options) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            let res;
            res = yield this.api.fetchLoggedV3('places.read', params, options);
            return Object.assign(Object.assign({}, res), { preparedValue: (_a = res.prepared) === null || _a === void 0 ? void 0 : _a.place });
        });
    }
    update(params, options) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            let res;
            res = yield this.api.fetchLoggedV3('places.update', params, options);
            return Object.assign(Object.assign({}, res), { preparedValue: (_a = res.prepared) === null || _a === void 0 ? void 0 : _a.place });
        });
    }
    delete(params, options) {
        return __awaiter(this, void 0, void 0, function* () {
            let res;
            res = yield this.api.fetchLoggedV3('places.delete', params, options);
            return res;
        });
    }
    list(params, options) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            let res;
            res = yield this.api.fetchLoggedV3('places.list', params, options);
            return Object.assign(Object.assign({}, res), { preparedValue: ((_a = res.prepared) === null || _a === void 0 ? void 0 : _a.places.items) || [] });
        });
    }
    /*
     * Custom Methods
     */
    getActiveList(options) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.list({ filter: { ne: ['is_hidden', true] }, order: [['id', 'desc']] }, options);
        });
    }
    deleteAnyway(params, options) {
        return __awaiter(this, void 0, void 0, function* () {
            let res;
            res = yield this.delete(params, Object.assign({ skipAlert: true }, options));
            if (res.error) {
                res = yield this.update(Object.assign(Object.assign({}, params), { isHidden: true }), options);
            }
            return res;
        });
    }
}
