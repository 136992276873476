import _ from 'lodash';
export const getLocalAddressType = ({ use, type, }) => {
    let result = 'residential';
    if (type === 'postal') {
        if (use === 'billing') {
            result = 'billing';
        }
        else {
            result = 'mailing';
        }
    }
    return result;
};
export const orderAddressesItems = (items) => {
    return _.orderBy(items, (x) => getLocalAddressType(x), ['desc']);
};
