var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { BaseService } from '../baseService';
export class InsuranceProviders extends BaseService {
    constructor(props) {
        super(props);
    }
    update(params, options) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            let res;
            res = yield this.api.fetchLoggedV3('insurance_providers.update', params, options);
            return Object.assign(Object.assign({}, res), { preparedValue: (_a = res.prepared) === null || _a === void 0 ? void 0 : _a.insuranceProvider });
        });
    }
    list(params, options) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            let res;
            res = yield this.api.fetchV3('insurance_providers.list', params, options);
            return Object.assign(Object.assign({}, res), { preparedValue: ((_a = res.prepared) === null || _a === void 0 ? void 0 : _a.insuranceProviders.items) || [] });
        });
    }
}
