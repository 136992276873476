import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import classNames from 'classnames';
import { getStylesMode } from 'ziphy-web-shared/basic/helpers';
import { BubbleLoader, ProgressRing } from '.';
import styles from './Loader.module.scss';
export const Loader = ({ type = 'progressRing', mode = 'default', asOverlay, className, }) => {
    if (typeof type !== 'string') {
        return _jsx(_Fragment, { children: type });
    }
    return (_jsx("div", Object.assign({ className: classNames(styles.loader, asOverlay && styles.loaderOverlay, getStylesMode(mode, styles), className) }, { children: type === 'progressRing' ? (_jsx(ProgressRing, { progress: 25, spin: true })) : type === 'bubble' ? (_jsx(BubbleLoader, {})) : (_jsx(_Fragment, {})) })));
};
