import React from 'react'

import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'

import classNames from 'classnames'
import { formatDate } from 'ziphy-web-shared/basic/utils/time'

import ShowMoreText from '@library/showMoreText/ShowMoreText'

import { resourceExtractor } from '@fhir/extractor'

import { openEventModal } from '@ps/helpers/events'
import EventsViewer from '@ps/library/eventsViewer/EventsViewer'

import { $psHistory } from '@store'

const LabsTablePage = ({ pageItems, showRelatedAppt }) => {
  const { t } = useTranslation()

  return (
    <>
      {pageItems.map(({ event, resource }) => {
        const extractor = resourceExtractor(resource)
        const { asString } = extractor
        let label = asString.type || t(event.label)

        function handleClick() {
          openEventModal([{ event, resource }], showRelatedAppt)
        }

        return (
          <div
            key={event.id}
            className={classNames('h_table-tr', 'h_table-tr--' + event.rowType)}
            onClick={handleClick}
          >
            <div className="h_table-td h_table-td--delimiter" />
            <div className="h_table-td h_table-td--label">{label}</div>
            <div className="h_table-td h_table-td--details">
              <div className="font-weight-600">
                <ShowMoreText
                  expanded={$psHistory.showMore.includes('more')}
                  showLessButton={$psHistory.showMore.includes('less')}
                  buttonClassName="mt-5"
                >
                  <EventsViewer
                    type="tableDetails"
                    event={event}
                    resource={resource}
                    extractor={extractor}
                  />
                </ShowMoreText>
              </div>
            </div>
            <div className="h_table-td h_table-td--status">
              <EventsViewer event={event} resource={resource} extractor={extractor} />
            </div>
            <div className="h_table-td h_table-td--date">
              <span className="event_date">
                {formatDate(event.effectiveDate, { format: 'datetime' })}
              </span>
            </div>
          </div>
        )
      })}
    </>
  )
}

export default observer(LabsTablePage)
