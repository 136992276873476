import { useMemo } from 'react'

import _ from 'lodash'

import { resourceExtractor } from '@fhir/extractor'

import { $psResources } from '@store'

export const useGetMediaItems = ({ isLoading, items }) => {
  return useMemo(() => {
    let result = []

    if (isLoading) {
      return result
    }

    _.forEach(items, ({ event, resource }) => {
      if (_.isEmpty(resource)) {
        resource = $psResources.getById(event.id)
      }

      const extractor = resourceExtractor(resource)
      const { content, asString } = extractor

      result.push({
        ...content?.attachment,
        id: event.id,
        effectiveDate: event.effectiveDate,
        title: asString.typeDescription || asString.full,
        source: { event, resource, extractor },
      })
    })

    return result
  }, [isLoading, items]) // eslint-disable-line react-hooks/exhaustive-deps
}
