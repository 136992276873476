var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import _ from 'lodash';
import { BaseService } from '../baseService';
export class Offices extends BaseService {
    constructor(props) {
        super(props);
    }
    create(params, options) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            let res;
            res = yield this.api.fetchLoggedV3('offices.create', params, options);
            return Object.assign(Object.assign({}, res), { preparedValue: (_a = res.prepared) === null || _a === void 0 ? void 0 : _a.office });
        });
    }
    read(params, options) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            let res;
            res = yield this.api.fetchV3('offices.read', params, options);
            return Object.assign(Object.assign({}, res), { preparedValue: (_a = res.prepared) === null || _a === void 0 ? void 0 : _a.office });
        });
    }
    update(params, options) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            let res;
            res = yield this.api.fetchLoggedV3('offices.update', params, options);
            return Object.assign(Object.assign({}, res), { preparedValue: (_a = res.prepared) === null || _a === void 0 ? void 0 : _a.office });
        });
    }
    delete(params, options) {
        return __awaiter(this, void 0, void 0, function* () {
            let res;
            res = yield this.api.fetchLoggedV3('offices.delete', params, options);
            return res;
        });
    }
    list(params, options) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            let res;
            res = yield this.api.fetchV3('offices.list', params, options);
            return Object.assign(Object.assign({}, res), { preparedValue: ((_a = res.prepared) === null || _a === void 0 ? void 0 : _a.offices.items) || [] });
        });
    }
    serveAddress(params, options) {
        return __awaiter(this, void 0, void 0, function* () {
            let res;
            res = yield this.api.fetchLoggedV3('offices.serve_address', params, options);
            return Object.assign(Object.assign({}, res), { preparedValue: res.prepared });
        });
    }
    listServeAddress(params, options) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            let res;
            res = yield this.api.fetchLoggedV3('offices.list_serve_address', params, options);
            return Object.assign(Object.assign({}, res), { preparedValue: ((_a = res.prepared) === null || _a === void 0 ? void 0 : _a.offices.items) || [] });
        });
    }
    /*
     * Custom Methods
     */
    search(params, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const { id, code, practiceIds, zip } = params;
            const filters = [{ eq: ['is_active', true] }];
            if (id) {
                filters.push({ eq: ['id', id] });
            }
            else if (code) {
                filters.push({ imatch: ['code', '^' + _.escapeRegExp(code) + '$'] });
            }
            if (zip) {
                filters.push({ contains: ['serviced_zips', [zip]] });
            }
            if (_.isArray(practiceIds) && practiceIds.length) {
                filters.push({ in: ['practice_id', practiceIds] });
            }
            return yield this.list({
                expand: Object.assign({ office: ['practiceId'] }, params.expand),
                filter: { and: filters },
            }, options);
        });
    }
}
