var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import _ from 'lodash';
import { Api, } from 'ziphy-web-shared/basic/api';
import { isAbsoluteEmpty } from 'ziphy-web-shared/basic/helpers';
export class BaseService {
    constructor(props) {
        this.api = new Api(props);
    }
    clearPayload(data, excludedKeys = []) {
        return _.pickBy(data, (x, key) => _.includes(excludedKeys, key) || !isAbsoluteEmpty(x) || x === false);
    }
    batch(requests, options) {
        return __awaiter(this, void 0, void 0, function* () {
            // todo: fix any (typeof this.read | typeof this.list | ....)
            return this.api.fetchLoggedBatchV3(requests, options);
        });
    }
}
