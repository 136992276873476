var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { action, makeAutoObservable, makeObservable } from 'mobx';
import { contactsService } from 'ziphy-web-shared/basic/api';
import { getUuid } from 'ziphy-web-shared/basic/helpers';
import { $loader, globalLoaderHandlers } from 'ziphy-web-shared/basic/utils';
import { ContextController, GlobalStorage, IdsController } from '../storageManager';
import { getAvailableContactsLimit, orderContactsItems } from './';
/*
 * GlobalStorage
 */
class ContactsGlobalStorage extends GlobalStorage {
    constructor() {
        super({ idKey: 'id', updateCondition: (x) => (x === null || x === void 0 ? void 0 : x.Type) === 'contact' });
        makeObservable(this, {
            list: action,
        });
    }
    list(params, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield contactsService.list(params, options);
        });
    }
}
export const $contactsGlobalStorage = new ContactsGlobalStorage();
/*
 * Templates
 */
class ContactsStore {
    constructor(key = getUuid(), storage = $contactsGlobalStorage) {
        this.contextKey = key;
        this.storage = storage;
        this.itemsIds = new IdsController();
        makeAutoObservable(this);
    }
    // Items
    get items() {
        return this.itemsIds.list.map((id) => this.getById(id)).filter((x) => x);
    }
    get sortedItems() {
        return orderContactsItems(this.items);
    }
    get contactsLimit() {
        return getAvailableContactsLimit(this.items);
    }
    getById(id) {
        return this.storage.asObject[id];
    }
    // Actions
    create(params, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield contactsService.create(this.withContext(params), options);
            if (!response.error)
                this.itemsIds.add(response.preparedValue.id);
            return response;
        });
    }
    update(params, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield contactsService.update(this.withContext(params), options);
        });
    }
    delete(params, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield contactsService.delete(this.withContext(params), options);
            if (!response.error)
                this.itemsIds.delete(params.id);
            return response;
        });
    }
    list(params, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield contactsService.list(this.withContext(params), options);
            if (!response.error)
                this.itemsIds.set(response.preparedValue.map((x) => x.id));
            return response;
        });
    }
    //////
    withContext(params) {
        return Object.assign(Object.assign({}, params), { patientId: this.contextKey });
    }
}
export const $contactsContexts = new ContextController((key) => new ContactsStore(key));
/*
 * Other
 */
$loader.subscribe(globalLoaderHandlers.LOGOUT, () => {
    $contactsGlobalStorage.clear();
    $contactsContexts.clear();
});
