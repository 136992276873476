import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import { getStylesMode } from 'ziphy-web-shared/basic/helpers';
import styles from './Switch.module.scss';
const Switch = ({ label, field = { value: false }, className, isDisabled = false, asBox = false, isGreen = false, mode, labelPosition = 'before', }) => {
    const delta = 10;
    const [selected, setSelected] = useState(field.value);
    const [preSelected, setPreSelected] = useState(null);
    useEffect(() => setSelected(field.value), [field.value]);
    const handler = useRef(null);
    const touch = useRef({
        lastPos: null,
        startSelected: null,
        selected: null,
    });
    function change(value) {
        setSelected(value);
        if (_.isFunction(field.onChange)) {
            field.onChange(value);
        }
    }
    function handleClick() {
        if (isDisabled)
            return false;
        change(!selected);
    }
    function handleTouchStart(e) {
        if (isDisabled)
            return false;
        touch.current = {
            lastPos: e.touches[0].clientX,
            startSelected: selected,
            selected: null,
        };
    }
    function handleTouchMove(e) {
        if (isDisabled)
            return false;
        touch.current.lastPos = e.touches[0].clientX;
        const lastPos = touch.current.lastPos;
        const bodyRect = document.body.getBoundingClientRect();
        const elemRect = handler.current.getBoundingClientRect();
        const offsetLeft = elemRect.left - bodyRect.left;
        const offsetRight = elemRect.right - bodyRect.left;
        if (lastPos > offsetRight + delta) {
            if (touch.current.selected === null && touch.current.startSelected === true) {
                return;
            }
            touch.current.selected = true;
            setPreSelected(true);
        }
        else if (lastPos < offsetLeft - delta) {
            if (touch.current.selected === null && touch.current.startSelected === false) {
                return;
            }
            touch.current.selected = false;
            setPreSelected(false);
        }
    }
    function handleTouchEnd(e) {
        e.preventDefault();
        if (isDisabled)
            return false;
        const touch_state = touch.current;
        if (touch_state.selected !== null) {
            if (touch_state.selected !== touch_state.startSelected) {
                change(touch_state.selected);
            }
        }
        else {
            change(!touch_state.startSelected);
        }
        setPreSelected(null);
    }
    return (_jsxs("div", Object.assign({ className: classNames(styles.switcher, asBox && styles.switcherBox, isGreen && styles.switcherGreen, isDisabled && styles.switcherDisabled, mode && getStylesMode(mode, styles), className) }, { children: [labelPosition === 'before' && label && _jsx("div", Object.assign({ className: styles.switcherLabel }, { children: label })), _jsx("div", Object.assign({ className: classNames(styles.switcherBtn, selected && styles.switcherBtnOn, preSelected && styles.switcherBtnPreOn, preSelected === false && styles.switcherBtnPreOff), onClick: handleClick, onTouchStart: handleTouchStart, onTouchMove: handleTouchMove, onTouchEnd: handleTouchEnd }, { children: _jsx("div", Object.assign({ className: classNames(styles.switcherBtnInner) }, { children: _jsx("div", { ref: handler, className: classNames(styles.switcherBtnHandle) }) })) })), labelPosition === 'after' && label && (_jsx("div", Object.assign({ className: classNames(styles.switcherLabel, styles.switcherLabelAfter) }, { children: label })))] })));
};
export default Switch;
