export const ENUMS_ENCOUNTER_STATUSES = {
    planned: {
        id: 'planned',
        tKey: 'enums.encounter.status.booked',
    },
    arrived: {
        id: 'arrived',
        tKey: 'enums.encounter.status.arrived',
    },
    triaged: {
        id: 'triaged',
        tKey: 'enums.encounter.status.triaged',
    },
    'in-progress': {
        id: 'in-progress',
        tKey: 'enums.encounter.status.in-progress',
    },
    onleave: {
        id: 'onleave',
        tKey: 'enums.encounter.status.onleave',
    },
    finished: {
        id: 'finished',
        tKey: 'enums.encounter.status.finished',
    },
    cancelled: {
        id: 'cancelled',
        tKey: 'enums.encounter.status.cancelled',
    },
    'entered-in-error': {
        id: 'entered-in-error',
        tKey: 'enums.encounter.status.entered-in-error',
    },
    unknown: {
        id: 'unknown',
        tKey: 'enums.encounter.status.unknown',
    },
};
