// | 'Device'
// | 'HealthcareService'
// | 'Group'
// | 'CareTeam'
export var IExtractorServiceRequestSubType;
(function (IExtractorServiceRequestSubType) {
    IExtractorServiceRequestSubType["LAB"] = "lab";
    IExtractorServiceRequestSubType["ORDER"] = "order";
    IExtractorServiceRequestSubType["REFERRAL"] = "referral";
})(IExtractorServiceRequestSubType || (IExtractorServiceRequestSubType = {}));
export var IExtractorObservationSubType;
(function (IExtractorObservationSubType) {
    IExtractorObservationSubType["OBSERVATION"] = "observation";
    IExtractorObservationSubType["HPI"] = "hpi";
    IExtractorObservationSubType["EXAMINATION"] = "examination";
})(IExtractorObservationSubType || (IExtractorObservationSubType = {}));
