var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { forwardRef, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { IMaskInput } from 'react-imask';
import classNames from 'classnames';
import _ from 'lodash';
import Icon from 'ziphy-web-shared/basic/assets/images/Icon';
import { getStylesMode, getUuid, isAbsoluteEmpty } from 'ziphy-web-shared/basic/helpers';
import { useInnerValue } from 'ziphy-web-shared/basic/hooks';
import styles from './Input.module.scss';
const initField = {
    value: '',
};
const Input = forwardRef((_a, ref) => {
    var { field = initField, 
    //
    onClick, clear, onClear, type = 'text', className, label, headerActions = [], fieldActions = [], placeholder, id: _id, readonly, mode, children, mask, icon = {}, onRemove, unFocusComponent, isActive, cursorPointer } = _a, fieldAttributes = __rest(_a, ["field", "onClick", "clear", "onClear", "type", "className", "label", "headerActions", "fieldActions", "placeholder", "id", "readonly", "mode", "children", "mask", "icon", "onRemove", "unFocusComponent", "isActive", "cursorPointer"]);
    const [isFocused, setIsFocused] = useState(false);
    const isUnfocused = unFocusComponent && !isFocused;
    const { value, onChange, isInvalid } = field;
    const [innerValue, setInnerValue] = useInnerValue(value);
    const id = useMemo(() => (_id ? _id : getUuid()), [_id]);
    const hasClear = onClear || clear;
    function handleOnChange(value) {
        setInnerValue(value);
        if (_.isFunction(onChange)) {
            onChange(value);
        }
    }
    function handleClear(e) {
        if (e && _.isFunction(e.stopPropagation)) {
            e.preventDefault();
            e.stopPropagation();
        }
        handleOnChange('');
        if (typeof onClear === 'function') {
            onClear();
        }
    }
    function handleFocus(e) {
        if (readonly) {
            e.preventDefault();
            return;
        }
        setIsFocused(true);
        if (_.isFunction(fieldAttributes.onFocus)) {
            fieldAttributes.onFocus(e);
        }
    }
    function handleBlur(e) {
        if (isFocused) {
            setIsFocused(false);
        }
        if (_.isFunction(fieldAttributes.onBlur)) {
            fieldAttributes.onBlur(e);
        }
    }
    const fieldRef = useRef(null);
    useImperativeHandle(ref, () => ({
        focus() {
            fieldRef.current.focus();
        },
        clear() {
            handleClear();
        },
    }));
    const headerIsBig = mode === 'lg' && !children && !isFocused && isAbsoluteEmpty(value) && !placeholder;
    const withIcon = Boolean(icon.img);
    return (_jsxs("div", Object.assign({ className: classNames(className, styles.wrapper, isActive && styles.active, mode && getStylesMode(mode, styles), cursorPointer && styles.cursorPointer, isInvalid && styles.invalid, readonly && styles.readonly, isUnfocused && styles.unfocused, onRemove && styles.withRemove) }, { children: [_jsxs("div", Object.assign({ className: styles.inner }, { children: [(label || headerActions.length > 0) && (_jsxs("div", Object.assign({ className: classNames(styles.header, headerIsBig && styles.headerBig) }, { children: [label && (_jsx("label", Object.assign({ htmlFor: id, className: styles.label }, { children: label }))), headerActions.length > 0 && (_jsx("div", Object.assign({ className: styles.actionsContainer }, { children: headerActions.map((action) => (_jsx("div", Object.assign({ className: styles.action, onClick: () => action.onClick() }, { children: action.text }), action.text))) })))] }))), _jsxs("div", Object.assign({ className: classNames(styles.body, mode === 'lg' &&
                            headerActions.length === 0 &&
                            (withIcon || (hasClear && !isAbsoluteEmpty(innerValue))) &&
                            styles.bodyPR) }, { children: [_jsxs("div", Object.assign({ className: styles.fieldContainer }, { children: [isUnfocused && unFocusComponent, mask ? (_jsx(_Fragment, { children: _jsx(IMaskInput, Object.assign({ inputRef: (el) => (fieldRef.current = el), type: type, value: String(innerValue), onAccept: (value, masked) => {
                                                if (mask.onOutput) {
                                                    value = mask.onOutput(value, masked);
                                                }
                                                handleOnChange(value);
                                            }, id: id, placeholder: placeholder, onClick: onClick, readOnly: readonly, onFocus: handleFocus, onBlur: handleBlur }, _.omit(fieldAttributes, ['onFocus', 'onBlur']), { 
                                            //
                                            overwrite: "shift", radix: "." }, _.omit(mask, 'onOutput'))) })) : _.isEmpty(children) ? (_jsx("input", Object.assign({ ref: fieldRef, type: type, value: innerValue, onChange: (e) => handleOnChange(e.target.value), id: id, placeholder: placeholder, onClick: onClick, readOnly: readonly, onFocus: handleFocus, onBlur: handleBlur }, _.omit(fieldAttributes, ['onFocus', 'onBlur'])))) : (children), fieldActions.length > 0 && isAbsoluteEmpty(innerValue) && (_jsx("div", Object.assign({ className: styles.fieldActionsContainer }, { children: fieldActions.map((action) => (_jsx("div", Object.assign({ className: styles.action, onClick: () => action.onClick() }, { children: action.text }), action.text))) })))] })), hasClear && !readonly && !isAbsoluteEmpty(innerValue) && (_jsx("div", Object.assign({ className: classNames(styles.icon, styles.iconClickable, styles.iconClear, mode === 'lg' && headerActions.length === 0 && styles.iconAbsolute), onClick: handleClear }, { children: _jsx(Icon, { type: "close", size: 20 }) }))), !hasClear && withIcon && (headerActions.length === 0 || !headerIsBig) && (_jsx("div", Object.assign({ className: classNames(styles.icon, icon.onClick && styles.iconClickable, mode === 'lg' && headerActions.length === 0 && styles.iconAbsolute), onClick: icon.onClick }, { children: icon.img })))] }))] })), onRemove && (_jsx("div", Object.assign({ className: styles.rmBtn, onClick: (e) => {
                    e.preventDefault();
                    onRemove();
                } }, { children: _jsx(Icon, { type: "trash" }) })))] })));
});
export default observer(Input);
