var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import _ from 'lodash';
export function delay(ms) {
    return new Promise((resolve) => {
        setTimeout(() => resolve(), ms);
    });
}
export const delayedRequest = (callback, delayLimit = 1000) => __awaiter(void 0, void 0, void 0, function* () {
    const start = new Date().getTime();
    let result = yield callback();
    const end = new Date().getTime();
    const delta = end - start;
    if (delta < delayLimit) {
        yield delay(delayLimit - delta);
    }
    return result;
});
export function waitForVariable(callback, timeout = 250, maxTimeout = 3000) {
    return __awaiter(this, void 0, void 0, function* () {
        let waiting = true;
        let counter = 0;
        let condition = typeof callback === 'string' ? () => _.get(window, callback) : callback;
        while (waiting && typeof condition === 'function' && !condition()) {
            if (counter <= maxTimeout) {
                yield new Promise((resolve) => setTimeout(resolve, timeout));
                counter += timeout;
            }
            else {
                waiting = false;
            }
        }
    });
}
export function wrapPromise(promise) {
    let status = 'pending';
    let result;
    const suspender = promise.then((r) => {
        result = r;
        status = 'success';
    }, (e) => {
        result = e;
        status = 'error';
    });
    return {
        read() {
            if (status === 'pending') {
                throw suspender;
            }
            else if (status === 'error') {
                throw result;
            }
            else if (status === 'success') {
                return result;
            }
        },
    };
}
