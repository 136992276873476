import { getObservationSubType, getServiceRequestSubType } from './extractor.helpers';
import ExtractorAllergyIntolerance from './extractors/AllergyIntolerance';
import ExtractorAppointment from './extractors/Appointment';
import ExtractorCarePlan from './extractors/CarePlan';
import ExtractorCondition from './extractors/Condition';
import ExtractorDocumentReference from './extractors/DocumentReference';
import ExtractorEncounter from './extractors/Encounter';
import ExtractorImmunization from './extractors/Immunization';
import ExtractorLocation from './extractors/Location';
import ExtractorMedia from './extractors/Media';
import ExtractorMedication from './extractors/Medication';
import ExtractorMedicationStatement from './extractors/MedicationStatement';
import ExtractorObservation from './extractors/Observation';
import ExtractorObservationExamination from './extractors/ObservationExamination';
import ExtractorOrganization from './extractors/Organization';
import ExtractorPatient from './extractors/Patient';
import ExtractorPractitioner from './extractors/Practitioner';
import ExtractorPractitionerRole from './extractors/PractitionerRole';
import ExtractorProcedure from './extractors/Procedure';
import ExtractorRelatedPerson from './extractors/RelatedPerson';
import ExtractorServiceRequest from './extractors/ServiceRequest';
export function getExtractor(r, variant) {
    if (r === undefined) {
        return;
    }
    switch (r.resourceType) {
        case 'AllergyIntolerance':
            return ExtractorAllergyIntolerance;
        case 'Appointment':
            return ExtractorAppointment;
        case 'CarePlan':
            return ExtractorCarePlan;
        case 'Condition':
            return ExtractorCondition;
        case 'DocumentReference':
            return ExtractorDocumentReference;
        case 'Encounter':
            return ExtractorEncounter;
        case 'Immunization':
            return ExtractorImmunization;
        case 'Location':
            return ExtractorLocation;
        case 'Media':
            return ExtractorMedia;
        case 'Medication':
            return ExtractorMedication;
        case 'MedicationStatement':
            return ExtractorMedicationStatement;
        case 'Observation':
            const observationSubType = getObservationSubType(r);
            if (observationSubType === 'examination') {
                return ExtractorObservationExamination;
            }
            return ExtractorObservation;
        case 'Organization':
            return ExtractorOrganization;
        case 'Patient':
            return ExtractorPatient;
        case 'Procedure':
            return ExtractorProcedure;
        case 'Practitioner':
            return ExtractorPractitioner;
        case 'PractitionerRole':
            return ExtractorPractitionerRole;
        case 'RelatedPerson':
            return ExtractorRelatedPerson;
        case 'ServiceRequest':
            let serviceRequestSubType = getServiceRequestSubType(r);
            if (serviceRequestSubType === 'lab') {
                return ExtractorServiceRequest;
            }
            return ExtractorServiceRequest;
        default:
            if (r.resourceType) {
                console.warn('UNKNOWN RESOURCE EXTRACTOR: ', r.resourceType);
            }
            return;
    }
}
