import { useLayoutEffect, useRef, useState, } from 'react';
export const useGetDropdownPosition = ({ isOpen, itemsFullWidth = false, itemsLength = 0, alwaysBottom = false, }) => {
    const [dropdownStyles, setDropdownStyles] = useState(null);
    const [hasParent, setHasParent] = useState();
    const initHeightRef = useRef(null);
    const fieldRef = useRef(null);
    const dropdownRef = useRef(null);
    function handleSize() {
        const styles = setSize({
            isOpen,
            fieldRef,
            dropdownRef,
            initHeightRef,
            setHasParent,
            itemsFullWidth,
            alwaysBottom,
        });
        setDropdownStyles(styles);
    }
    useLayoutEffect(() => {
        if (hasParent && fieldRef.current) {
            hasParent.addEventListener('scroll', handleSize);
            window.addEventListener('scroll', handleSize);
        }
        return () => {
            if (hasParent && fieldRef.current) {
                hasParent.removeEventListener('scroll', handleSize);
                window.removeEventListener('scroll', handleSize);
            }
        };
    }, [hasParent]);
    useLayoutEffect(() => {
        setDropdownStyles((x) => {
            let width;
            if (fieldRef.current) {
                width = fieldRef.current.offsetWidth;
            }
            return Object.assign(Object.assign({}, x), { width });
        });
    }, []);
    useLayoutEffect(() => {
        window.addEventListener('resize', handleSize);
        handleSize();
        return () => window.removeEventListener('resize', handleSize);
    }, [isOpen, itemsLength]); // eslint-disable-line react-hooks/exhaustive-deps
    return {
        fieldRef,
        dropdownRef,
        dropdownStyles,
    };
};
function setSize({ isOpen, fieldRef, dropdownRef, initHeightRef, setHasParent, itemsFullWidth, alwaysBottom, }) {
    function getScrollParent(node) {
        if (node == null) {
            return null;
        }
        if (node.scrollHeight > node.clientHeight) {
            return node;
        }
        else {
            return getScrollParent(node.parentNode);
        }
    }
    function getSpaceTop() {
        return fieldRef.current.getBoundingClientRect().top - parentModalSpace() - 10;
    }
    function parentModalSpace() {
        const parent = fieldRef.current.closest('.modal-dialog');
        let modalParentSpace;
        if (parent) {
            modalParentSpace = window.getComputedStyle(parent, null).getPropertyValue('margin-bottom');
        }
        return parseFloat(modalParentSpace) ? parseFloat(modalParentSpace) - 5 : 20;
    }
    function getSpaceBottom() {
        return (window.innerHeight - fieldRef.current.getBoundingClientRect().bottom - parentModalSpace() - 10);
    }
    function getSpaceRight() {
        return (window.innerWidth - fieldRef.current.getBoundingClientRect().right - parentModalSpace() - 10);
    }
    function getTop(dropdownHeight, onTop) {
        const spaceTop = fieldRef.current.getBoundingClientRect().top;
        const spaceBottom = fieldRef.current.getBoundingClientRect().bottom;
        if (onTop) {
            return spaceTop - dropdownHeight - 10;
        }
        else {
            return spaceBottom + 10;
        }
    }
    function getRight(dropdownWidth, toLeft) {
        const spaceRight = window.innerWidth - fieldRef.current.getBoundingClientRect().right;
        if (toLeft) {
            return spaceRight;
        }
        else {
            return spaceRight - (dropdownWidth - fieldRef.current.getBoundingClientRect().width);
        }
    }
    if (fieldRef.current && dropdownRef.current && isOpen) {
        const spaceTop = getSpaceTop();
        const spaceBottom = getSpaceBottom();
        const spaceRight = getSpaceRight();
        let dropdownHeight = dropdownRef.current.clientHeight;
        let dropDownWidth = dropdownRef.current.clientWidth;
        if (initHeightRef.current === null) {
            initHeightRef.current = dropdownHeight;
        }
        const minHeight = 89;
        const parent = getScrollParent(fieldRef.current);
        if (parent) {
            setHasParent(parent);
        }
        else {
            setHasParent(null);
        }
        let height = dropdownHeight;
        let width = dropDownWidth;
        let onTop = false;
        let toLeft = false;
        if (height > spaceBottom) {
            height = spaceBottom;
        }
        if (width > spaceRight) {
            toLeft = true;
        }
        if (height < minHeight) {
            onTop = true;
            height = Math.min(initHeightRef.current, spaceTop);
        }
        if (spaceBottom > height && !onTop) {
            if (initHeightRef.current !== null && spaceBottom >= initHeightRef.current) {
                height = null;
            }
            else {
                height = spaceBottom;
            }
        }
        return {
            width: itemsFullWidth ? 'initial' : fieldRef.current.clientWidth + 'px',
            height: height ? height + 'px' : 'initial',
            top: getTop(height, alwaysBottom ? false : onTop) + 'px',
            right: itemsFullWidth ? getRight(dropDownWidth, toLeft) : 'auto',
        };
    }
}
