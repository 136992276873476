var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { action, computed, makeAutoObservable, makeObservable, runInAction } from 'mobx';
import { patientsService } from 'ziphy-web-shared/basic/api';
import { getUuid } from 'ziphy-web-shared/basic/helpers';
import { $loader, globalLoaderHandlers } from 'ziphy-web-shared/basic/utils';
import { ContextController, GlobalStorage, IdsController } from '../storageManager';
/*
 * GlobalStorage
 */
class PatientsGlobalStorage extends GlobalStorage {
    constructor() {
        super({ idKey: 'id', updateCondition: (x) => (x === null || x === void 0 ? void 0 : x.Type) === 'patient' });
        makeObservable(this, {
            list: action,
        });
    }
    list(params, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield patientsService.list(params, options);
        });
    }
}
export const $patientsGlobalStorage = new PatientsGlobalStorage();
/*
 * Templates
 */
class PatientsStore {
    constructor(key = getUuid(), storage = $patientsGlobalStorage) {
        this.defaultPatientId = null;
        this.defaultPatientLoaded = false;
        this.contextKey = key;
        this.storage = storage;
        this.itemsIds = new IdsController();
        makeAutoObservable(this, { items: computed.struct });
    }
    // Items
    get items() {
        return this.itemsIds.list.map((id) => this.getById(id)).filter((x) => x);
    }
    get defaultPatient() {
        return (this.getById(this.defaultPatientId) || (this.defaultPatientLoaded ? {} : null));
    }
    getById(id) {
        return this.storage.asObject[id];
    }
    clear() {
        this.defaultPatientId = null;
        this.defaultPatientLoaded = false;
    }
    // Actions
    create(params, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield patientsService.create(params, options);
            if (!response.error)
                this.itemsIds.add(response.preparedValue.id);
            return response;
        });
    }
    read(params, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield patientsService.read(params, options);
        });
    }
    update(params, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield patientsService.update(params, options);
        });
    }
    delete(params, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield patientsService.deleteAnyway(params, options);
            if (!response.error)
                this.itemsIds.delete(params.id);
            return response;
        });
    }
    list(params, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield patientsService.list(params, options);
            if (!response.error)
                this.itemsIds.set(response.preparedValue.map((x) => x.id));
            return response;
        });
    }
    activeList(params, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield patientsService.getActiveList(params, options);
            if (!response.error)
                this.itemsIds.set(response.preparedValue.map((x) => x.id));
            return response;
        });
    }
    loadDefault(options) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield patientsService.getDefault(options);
            if (!response.error) {
                runInAction(() => {
                    var _a, _b;
                    this.defaultPatientId = ((_b = (_a = response.preparedValue) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.id) || null;
                    this.defaultPatientLoaded = true;
                });
            }
            return response;
        });
    }
}
export const $patientsContexts = new ContextController((key) => new PatientsStore(key));
export const $patients = $patientsContexts.get('items');
/*
 * Other
 */
$loader.subscribe(globalLoaderHandlers.LOGOUT, () => {
    $patientsGlobalStorage.clear();
    $patientsContexts.clear();
    $patients.clear();
});
