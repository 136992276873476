import React, { useCallback, useEffect, useRef, useState } from 'react'

import { useTranslation } from 'react-i18next'

import _ from 'lodash'
import { getInitConfig } from 'ziphy-web-shared'
import { Input } from 'ziphy-web-shared/basic/lib/forms/fields'

import CodeResenderButton from '@library/codes/CodeResenderButton'
import { useInterval } from '@library/CustomHooks'

const CodeResender = (props) => {
  const { t } = useTranslation()

  const { verifyCode } = getInitConfig().phone

  const {
    onSetData,
    initialPrefetch,
    prefetch,
    onChange,
    code = {},
    label = t('code_resender.label.default'),
    placeholder = '',
    maxLength = verifyCode.maxLength,
    textButton = true,
    autoFocus = true,
    mode = 'lg',
    className,
  } = props

  let startedTime = useRef(Date.now())
  const [time, setTime] = useState(0)

  const timer = useInterval({
    callback: () => {
      let time = startedTime.current + verifyCode.resendInterval - Date.now()

      if (time > 0) {
        setTime(time)
      } else {
        resetTimer()
      }
    },
    onStart: async (sentTime) => {
      let delta = Date.parse(sentTime) - Date.now()
      delta = delta > 0 ? delta : 0
      startedTime.current = Date.parse(sentTime) - delta

      let time = startedTime.current + verifyCode.resendInterval - Date.now()
      setTime(time)
    },
    autoStart: false,
  })

  const [innerValue, setInnerValue] = useState(code.value)
  const [loading, setLoading] = useState(prefetch && 'text')

  useEffect(() => {
    if (code.value !== innerValue) {
      setInnerValue(code.value)
    }
  }, [code.value]) //eslint-disable-line react-hooks/exhaustive-deps

  const func = useCallback(
    async ({ initialPrefetch, using = 'text' } = {}) => {
      setLoading(using)
      const codeSent = initialPrefetch ? initialPrefetch : await prefetch(using)

      if (using === 'text') {
        timer.start(codeSent)
      }

      setLoading(false)
    },
    [timer, prefetch],
  )

  const resetTimer = useCallback(() => {
    timer.stop()
    setTime(0)
  }, [timer])

  useEffect(() => {
    func({ initialPrefetch })
    return () => resetTimer()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (_.isFunction(onSetData)) {
      onSetData({
        time,
        timer,
        loading,
        onSubmit: handleSubmit,
      })
    }
  }, [time, loading]) // eslint-disable-line react-hooks/exhaustive-deps

  async function handleChange(e) {
    setInnerValue(e)

    if (_.isFunction(onChange)) {
      onChange(e)
    }
  }

  async function handleSubmit(using) {
    if (using !== 'text' || !timer.isRunning) {
      await func({ using })
    }
  }

  return (
    <>
      <Input
        field={{
          value: innerValue,
          onChange: handleChange,
          isInvalid: code.isInvalid,
        }}
        className={className}
        label={label}
        maxLength={maxLength}
        inputMode="numeric"
        autoComplete="one-time-code"
        autoFocus={autoFocus}
        placeholder={placeholder}
        mode={mode}
      />
      {textButton && (
        <CodeResenderButton time={time} timer={timer} loading={loading} onSubmit={handleSubmit} />
      )}
    </>
  )
}

export default CodeResender
