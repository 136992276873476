import { makeAutoObservable } from 'mobx';
import _ from 'lodash';
import { humanize } from 'ziphy-web-shared/basic/helpers';
import { getObservationSubType } from '../extractor.helpers';
import ExtractorBase from '../extractors/Base';
import { prepareMeta, prepareValueDateTime } from '../fhir.converters';
import { getCodingObjects, getExtensionObjects, getIdentifierObjects, getObservationValues, } from '../fhir.helpers';
import { joinArrayWithObjectToString } from '../fhir.utils';
class ExtractorObservationExamination {
    constructor(resource) {
        this.base = new ExtractorBase(this);
        this.base.SET(resource);
        makeAutoObservable(this);
    }
    get resource() {
        return this.base.resource;
    }
    get subType() {
        return getObservationSubType(this);
    }
    /*
     * RESOURCE - Minimally prepared resource data, prepareConverters, valueObjects, etc.
     */
    get extensions() {
        return getExtensionObjects(this.resource.extension);
    }
    get identifiers() {
        return getIdentifierObjects(this.resource.identifier);
    }
    get categories() {
        return getCodingObjects(this.resource.category);
    }
    get codes() {
        return getCodingObjects(this.resource.code);
    }
    // other in the FHIR - Group | Device
    get subject() {
        return this.base.getChildExtractor(this.resource.subject);
    }
    // other in the FHIR - CareTeam
    get performers() {
        return _.map(this.resource.performer, (x) => this.base.getChildExtractor(x));
    }
    get date() {
        return prepareValueDateTime(this.resource.effectiveDateTime);
    }
    get notes() {
        return this.resource.note;
    }
    get meta() {
        return prepareMeta(this.resource.meta);
    }
    /*
     * READ - PREPARED Values
     */
    get zid() {
        return this.identifiers.first({ config: 'zid' });
    }
    get codeObj() {
        return this.codes.first();
    }
    get performer() {
        return _.last(this.performers);
    }
    get isLegacy() {
        var _a;
        return ((_a = this.codeObj) === null || _a === void 0 ? void 0 : _a.text) === 'examination';
    }
    get template() {
        const result = this.extensions.first('observationExaminationTemplate');
        // temporarily fix
        if ((result === null || result === void 0 ? void 0 : result.value) && !result.asString)
            result.asString = humanize(result.value, true);
        // temporarily fix
        return result;
    }
    get values() {
        let values = [];
        _.forEach(this.resource.component, (item) => {
            const value = _.head(getObservationValues(item));
            const codeObj = getCodingObjects(item.code).first();
            values.push({
                label: codeObj === null || codeObj === void 0 ? void 0 : codeObj.display,
                value: value === null || value === void 0 ? void 0 : value.asString,
            });
        });
        return values;
    }
    /*
     * STRING Values
     */
    get asString() {
        var _a, _b;
        const tmp = {
            codeDisplay: (_a = this.codeObj) === null || _a === void 0 ? void 0 : _a.display,
            template: (_b = this.template) === null || _b === void 0 ? void 0 : _b.asString,
            values: joinArrayWithObjectToString(this.values, 'asString', ', '),
            notes: joinArrayWithObjectToString(this.notes, 'text', '; '),
            full: '',
        };
        tmp.full = this.isLegacy
            ? joinArrayWithObjectToString(getObservationValues(this.resource), 'asString', '; ')
            : tmp.template;
        return tmp;
    }
}
export default ExtractorObservationExamination;
