var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { forwardRef, useImperativeHandle, useMemo, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';
import _ from 'lodash';
import Icon from 'ziphy-web-shared/basic/assets/images/Icon';
import { getStylesMode, getUuid, isAbsoluteEmpty } from 'ziphy-web-shared/basic/helpers';
import { useInnerValue } from 'ziphy-web-shared/basic/hooks';
import { AutoTextarea } from '../';
import styles from './Textarea.module.scss';
const initField = {
    value: '',
};
const Textarea = forwardRef((_a, forwardedRef) => {
    var { field = initField, onClick, onClear, className, fieldClassName, label, headerActions = [], placeholder, id: _id, readonly = false, mode, autoGrow = false, rows = 3, maxRows = rows, onRemove } = _a, fieldAttributes = __rest(_a, ["field", "onClick", "onClear", "className", "fieldClassName", "label", "headerActions", "placeholder", "id", "readonly", "mode", "autoGrow", "rows", "maxRows", "onRemove"]);
    const { value, onChange, isInvalid } = field;
    const [innerValue, setInnerValue] = useInnerValue(value);
    const id = useMemo(() => (_id ? _id : getUuid()), [_id]);
    function handleOnChange(value) {
        setInnerValue(value);
        if (_.isFunction(onChange)) {
            onChange(value);
        }
    }
    function handleClear(e) {
        if (e && _.isFunction(e.stopPropagation)) {
            e.stopPropagation();
        }
        handleOnChange('');
        if (_.isFunction(onClear)) {
            onClear();
        }
    }
    const fieldRef = useRef(null);
    // @ts-ignore
    useImperativeHandle(forwardedRef, () => ({
        focus() {
            fieldRef.current.focus();
        },
        clear() {
            handleClear();
        },
    }));
    return (_jsxs("div", Object.assign({ className: classNames(className, styles.wrapper, mode && getStylesMode(mode, styles), isInvalid && styles.invalid, readonly && styles.readonly, onRemove && styles.withRemove) }, { children: [_jsxs("div", Object.assign({ className: styles.inner }, { children: [(label || headerActions.length > 0) && (_jsxs("div", Object.assign({ className: styles.header }, { children: [label && (_jsx("label", Object.assign({ htmlFor: id, className: styles.label }, { children: label }))), !readonly && headerActions.length > 0 && (_jsx("div", Object.assign({ className: styles.actionsContainer }, { children: headerActions.map((action) => (_jsx("div", Object.assign({ className: styles.action, onClick: () => action.onClick() }, { children: action.text }), action.text))) })))] }))), _jsx("div", Object.assign({ className: styles.body }, { children: _jsxs("div", Object.assign({ className: styles.fieldContainer }, { children: [autoGrow ? (_jsx(AutoTextarea, { ref: fieldRef, minRows: rows, maxRows: maxRows, value: innerValue, onChange: handleOnChange, placeholder: placeholder, disabled: readonly })) : (_jsx("textarea", Object.assign({ ref: fieldRef, rows: maxRows, value: innerValue, onChange: (e) => handleOnChange(e.target.value), id: id, placeholder: placeholder, onClick: onClick, readOnly: readonly }, fieldAttributes))), !readonly && onClear && !isAbsoluteEmpty(value) && (_jsx("div", { className: styles.iconClear, onClick: handleClear }))] })) }))] })), !readonly && _.isFunction(onRemove) && (_jsx("div", Object.assign({ className: styles.rmBtn, onClick: onRemove }, { children: _jsx(Icon, { type: 'trash' }) })))] })));
});
export default observer(Textarea);
