var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { action, makeAutoObservable, makeObservable } from 'mobx';
import { accountsService } from 'ziphy-web-shared/basic/api';
import { getUuid } from 'ziphy-web-shared/basic/helpers';
import { $loader, globalLoaderHandlers } from 'ziphy-web-shared/basic/utils';
import { ContextController, GlobalStorage, IdsController } from '../storageManager';
/*
 * GlobalStorage
 */
class AccountsGlobalStorage extends GlobalStorage {
    constructor() {
        super({ idKey: 'id', updateCondition: (x) => (x === null || x === void 0 ? void 0 : x.Type) === 'account' });
        makeObservable(this, {
            list: action,
        });
    }
    list(params, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield accountsService.list(params, options);
        });
    }
}
export const $accountsGlobalStorage = new AccountsGlobalStorage();
/*
 * Templates
 */
class AccountsStore {
    constructor(key = getUuid(), storage = $accountsGlobalStorage) {
        this.contextKey = key;
        this.storage = storage;
        this.itemsIds = new IdsController();
        makeAutoObservable(this);
    }
    // Items
    get items() {
        return this.itemsIds.list.map((id) => this.getById(id)).filter((x) => x);
    }
    getById(id) {
        return this.storage.asObject[id];
    }
    // Actions
    create(params, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield accountsService.create(params, options);
            if (!response.error)
                this.itemsIds.add(response.preparedValue.id);
            return response;
        });
    }
    read(params, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield accountsService.read(params, options);
        });
    }
    delete(params, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const item = this.getById(params.id);
            if (item === null || item === void 0 ? void 0 : item.primary) {
                const found = this.items.find((x) => x.type === item.type && x.id !== item.id);
                if (found)
                    yield this.setPrimary({ id: found.id });
            }
            const response = yield accountsService.delete(params, options);
            if (!response.error)
                this.itemsIds.delete(params.id);
            return response;
        });
    }
    list(params, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield accountsService.list(params, options);
            if (!response.error)
                this.itemsIds.set(response.preparedValue.map((x) => x.id));
            return response;
        });
    }
    activeList(params, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.list(Object.assign({ filter: { ne: ['status', 'removed'] }, order: [['id', 'asc']] }, params), options);
        });
    }
    setPrimary(params, options = {}) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield accountsService.setPrimary(params, options);
            if (!response.error)
                yield this.activeList();
            return response;
        });
    }
}
export const $accountsContexts = new ContextController((key) => new AccountsStore(key));
export const $accounts = $accountsContexts.get('items');
/*
 * Other
 */
$loader.subscribe(globalLoaderHandlers.LOGOUT, () => {
    $accountsGlobalStorage.clear();
    $accountsContexts.clear();
});
