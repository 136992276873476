import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import classNames from 'classnames';
import Icon from 'ziphy-web-shared/basic/assets/images/Icon';
import styles from './Checkbox.module.scss';
const Checkbox = ({ isChecked, isMulti, isDark, isSmall, className }) => {
    return (_jsx("div", Object.assign({ className: classNames(styles.checkbox, className, {
            [styles.checkboxChecked]: isChecked,
            [styles.checkboxSmall]: isSmall,
            [styles.checkboxDark]: isDark,
            [styles.checkboxMulti]: isMulti,
        }) }, { children: _jsx(Icon, { className: styles.icon, type: isMulti && !isChecked ? 'plus' : 'check', width: isSmall && 20, height: isSmall && 20, color: isChecked ? '#fff' : '#000' }) })));
};
export default Checkbox;
