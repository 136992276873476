import { makeAutoObservable } from 'mobx';
import { getSubjectRole } from '../extractor.helpers';
import ExtractorBase from '../extractors/Base';
import { prepareMeta, prepareValueDate } from '../fhir.converters';
import { getAddressObjects, getExtensionObjects, getIdentifierObjects, getSubjectGender, getTelecomObjects, withFullName, } from '../fhir.helpers';
class ExtractorPatient {
    constructor(resource) {
        this.base = new ExtractorBase(this);
        this.base.SET(resource);
        makeAutoObservable(this);
    }
    get resource() {
        return this.base.resource;
    }
    /*
     * RESOURCE - Minimally prepared resource data, prepareConverters, valueObjects, etc.
     */
    get extensions() {
        return getExtensionObjects(this.resource.extension);
    }
    get identifiers() {
        return getIdentifierObjects(this.resource.identifier);
    }
    get addresses() {
        return getAddressObjects(this.resource.address);
    }
    get telecoms() {
        return getTelecomObjects(this.resource.telecom);
    }
    get meta() {
        return prepareMeta(this.resource.meta);
    }
    /*
     * READ - PREPARED Values
     */
    get zid() {
        return this.identifiers.first({ config: 'zid' });
    }
    get role() {
        return getSubjectRole(this);
    }
    get name() {
        return withFullName({ name: this.resource.name });
    }
    get birthDate() {
        return prepareValueDate(this.resource.birthDate);
    }
    get gender() {
        return getSubjectGender(this.resource.gender);
    }
    get address() {
        return this.addresses.last();
    }
    get phone() {
        return this.telecoms.last({ system: 'phone' });
    }
    get email() {
        return this.telecoms.last({ system: 'email' });
    }
    get passport() {
        return this.identifiers.first('identifierPatientPassport');
    }
    /*
     * STRING Values
     */
    get asString() {
        var _a, _b, _c, _d;
        return {
            zid: (_a = this.zid) === null || _a === void 0 ? void 0 : _a.value,
            address: (_b = this.address) === null || _b === void 0 ? void 0 : _b.asString,
            phone: (_c = this.phone) === null || _c === void 0 ? void 0 : _c.asString,
            email: (_d = this.email) === null || _d === void 0 ? void 0 : _d.asString,
            full: '',
        };
    }
}
export default ExtractorPatient;
