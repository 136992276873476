var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { forwardRef, useContext, useEffect, useLayoutEffect, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { useResizeDetector } from 'react-resize-detector';
import classNames from 'classnames';
import _ from 'lodash';
import { ProgressRing } from 'ziphy-web-shared/basic/lib/loaders';
import { analytics } from 'ziphy-web-shared/basic/utils/analytics';
import { TransitionWrapper } from '@library/animations/Animations';
import { ModalViewerContext } from '@library/modal/ModalViewer.context';
import { $modal } from '@store';
import styles from '@assets/styles/_modal.module.scss';
const Modal = ({ className, innerClassNames, dialogClassName, 
//
duration = 300, animation = 'modal', overlay = true, animationOnShow = true, delayBeforeLeave, overlayDuration, closeOnOverlay = true, 
//
size, centered, scrollable, headerBordered, footerBordered, heightAuto, fullHeight, 
//
children, 
//
onHide, }) => {
    const _core = useContext(ModalViewerContext);
    const modalContentRef = useRef();
    useEffect(() => _core.onShow(), []); // eslint-disable-line react-hooks/exhaustive-deps
    function onHideModal() {
        return __awaiter(this, void 0, void 0, function* () {
            if (closeOnOverlay) {
                if (_.isFunction(onHide)) {
                    yield onHide();
                }
                _core.onHide(false);
            }
        });
    }
    function handleOnEnter() {
        document.documentElement.classList.add('modal-open');
        analytics.breadcrumb('modal-open', { name: className === null || className === void 0 ? void 0 : className.toString() });
        if (_.isFunction(_core.onEnter)) {
            _core.onEnter();
        }
    }
    function handleOnEntered() {
        if (modalContentRef.current) {
            modalContentRef.current.style.removeProperty('transform');
        }
        if (_.isFunction(_core.onEntered)) {
            _core.onEntered();
        }
    }
    function handleOnLeave() {
        analytics.breadcrumb('modal-close', { name: className === null || className === void 0 ? void 0 : className.toString() });
        if (_.isFunction(_core.onLeave)) {
            _core.onLeave();
        }
    }
    function handleOnLeaved() {
        $modal.remove(_core.id);
        if (!$modal.current.length) {
            document.documentElement.classList.remove('modal-open');
        }
        if (_.isFunction(_core.onLeaved)) {
            _core.onLeaved();
        }
    }
    useEffect(() => {
        const handleOnEsc = (e) => __awaiter(void 0, void 0, void 0, function* () {
            var _a;
            let lastModal = $modal.current.slice(-1)[0];
            const isCurrentModal = (_core === null || _core === void 0 ? void 0 : _core.id) === ((_a = lastModal === null || lastModal === void 0 ? void 0 : lastModal._core) === null || _a === void 0 ? void 0 : _a.id);
            if (e.key === 'Escape' && isCurrentModal) {
                yield onHideModal();
            }
        });
        window.addEventListener('keydown', handleOnEsc);
        return () => window.removeEventListener('keydown', handleOnEsc);
    }, []); //eslint-disable-line react-hooks/exhaustive-deps
    if (!_core) {
        console.error('You should pass "_core" from the parent component.');
        return _jsx(_Fragment, {});
    }
    return (_jsx("div", Object.assign({ className: classNames(['modal', className]) }, { children: _jsxs("div", Object.assign({ className: classNames('modal-dialog', size && 'modal-dialog--' + size, centered && 'modal-dialog--centered', scrollable && 'modal-dialog--scrollable', headerBordered && 'modal-dialog--header-bordered', _core.footerShadow && 'modal-dialog--footer-shadow', footerBordered && 'modal-dialog--footer-bordered', heightAuto && 'modal-dialog--height-auto', fullHeight && 'modal-dialog--full-height', dialogClassName) }, { children: [_jsx(TransitionWrapper
                // @ts-ignore
                , Object.assign({ 
                    // @ts-ignore
                    items: _core.show, from: animationOnShow && _core.animationOnShow ? undefined : {}, 
                    // trail={delayBeforeLeave || _core.delayBeforeLeave}
                    config: { duration: overlayDuration || duration } }, { children: (item) => item &&
                        ((style) => (_jsx("div", { style: style, className: classNames('modal-overlay', !overlay && 'modal-overlay--hidden'), onClick: onHideModal }))) })), _jsx(TransitionWrapper
                // @ts-ignore
                , Object.assign({ 
                    // @ts-ignore
                    items: _core.show, preset: animation, from: animationOnShow && _core.animationOnShow ? undefined : {}, trail: delayBeforeLeave, config: { duration: duration }, onEnter: handleOnEnter, onEntered: handleOnEntered, onLeave: handleOnLeave, onLeaved: handleOnLeaved }, { children: (item) => item &&
                        ((style) => (_jsx("div", Object.assign({ className: classNames(innerClassNames, 'modal-content'), ref: modalContentRef, style: style }, { children: children })))) })), _core.isBlocked && _jsx("div", { className: "modal-blocked-overlay" })] })) })));
};
const ModalHeader = ({ children, className, titleClassName = 'title-md' }) => {
    if (_.isString(children)) {
        children = _jsx("div", Object.assign({ className: classNames(['modal-header-title', titleClassName]) }, { children: children }));
    }
    return _jsx("div", Object.assign({ className: classNames(['modal-header', className]) }, { children: children }));
};
const ModalBody = forwardRef((_a, forwardedRef) => {
    var _b, _c;
    var { children, className, isLoading = false } = _a, passedProps = __rest(_a, ["children", "className", "isLoading"]);
    const _core = useContext(ModalViewerContext);
    const defaultRef = useRef(null);
    const ref = (forwardedRef ? forwardedRef : defaultRef);
    const { height } = useResizeDetector({
        targetRef: ref,
        handleWidth: false,
        refreshMode: 'debounce',
        refreshRate: 50,
    });
    useLayoutEffect(() => {
        function updateSize() {
            if (ref.current && ref.current.clientHeight && ref.current.scrollHeight) {
                const modalBodyCH = ref.current.clientHeight;
                const modalBodySH = ref.current.scrollHeight;
                const result = modalBodyCH < modalBodySH;
                $modal.setFooterShadow(_core.id, result);
            }
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, [_core.id, isLoading, height, (_b = ref.current) === null || _b === void 0 ? void 0 : _b.clientHeight, (_c = ref.current) === null || _c === void 0 ? void 0 : _c.scrollHeight]); // eslint-disable-line react-hooks/exhaustive-deps
    return (_jsx("div", Object.assign({ ref: ref, className: classNames(['modal-body', className, isLoading && 'modal-body--loading']) }, passedProps, { children: isLoading ? _jsx(ProgressRing, { progress: 25, spin: true }) : children })));
});
const ModalFooter = ({ children, className, style }) => {
    return (_jsx("div", Object.assign({ style: style, className: classNames(['modal-footer', className]) }, { children: children })));
};
const ModalControls = ({ position, items, onPrev, onNext }) => {
    const _core = useContext(ModalViewerContext);
    useEffect(() => {
        function onKeyDownHandle(e) {
            if (e.code === 'ArrowLeft') {
                if (_.isFunction(onPrev)) {
                    onPrev();
                }
            }
            else if (e.code === 'ArrowRight') {
                if (_.isFunction(onNext)) {
                    onNext();
                }
            }
            else if (e.code === 'Escape') {
                _core.onHide();
            }
            else if (e.code === 'Space') {
                // todo: add play if impossible
            }
        }
        document.addEventListener('keydown', onKeyDownHandle);
        return () => document.removeEventListener('keydown', onKeyDownHandle);
    }, [items]); // eslint-disable-line react-hooks/exhaustive-deps
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: classNames(styles.controlButtonPrev, position <= 0 && styles.controlButtonDisabled), onClick: onPrev }), _jsx("div", { className: classNames(styles.controlButtonNext, position >= items.length - 1 && styles.controlButtonDisabled), onClick: onNext })] }));
};
Modal.Header = observer(ModalHeader);
Modal.Body = observer(ModalBody);
Modal.Footer = observer(ModalFooter);
Modal.Controls = observer(ModalControls);
export default observer(Modal);
