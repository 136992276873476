import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { ProgressRing } from 'ziphy-web-shared/basic/lib/loaders';
import AttentionSvg from '@assets/svg/Attention';
import styles from './Document.module.scss';
const Document = ({ className, title, status, type = 'default', loading, icon, to, onClick, clickable = !!(to || onClick), }) => {
    const Card = useCallback(() => {
        return (_jsxs("div", Object.assign({ className: classNames(styles.card, className, clickable && styles.cardClickable), onClick: clickable && !to && onClick ? onClick : null }, { children: [title && (_jsx("div", Object.assign({ className: classNames(styles.cardTitle, type === 'error' && styles.cardTitleGray) }, { children: title }))), loading && (_jsx("div", Object.assign({ className: styles.loader }, { children: _jsx("div", { children: _jsx(ProgressRing, { spin: true, centered: true, radius: 16, progress: 25 }) }) }))), icon && _jsx("div", Object.assign({ className: styles.icon }, { children: icon })), status && (_jsx("div", Object.assign({ className: styles.cardMeta }, { children: (() => {
                        switch (type) {
                            case 'warning':
                                return (_jsxs("div", Object.assign({ className: classNames(styles.status, styles.statusDefault) }, { children: [_jsx("div", { className: classNames(styles.statusPoint) }), status] })));
                            case 'error':
                                return (_jsxs("div", Object.assign({ className: classNames(styles.status, styles.statusError) }, { children: [_jsx(AttentionSvg, {}), status] })));
                            default:
                                return status;
                        }
                    })() }))), _jsx("div", { className: styles.cardInner })] })));
    }, [title, status, type, loading, onClick, clickable]); // eslint-disable-line react-hooks/exhaustive-deps
    return (_jsx(_Fragment, { children: to ? (_jsx(Link, Object.assign({ to: to, onClick: (e) => {
                if (!clickable)
                    e.preventDefault();
            } }, { children: _jsx(Card, {}) }))) : (_jsx(Card, {})) }));
};
export default observer(Document);
