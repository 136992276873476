export const ENUMS_PERSON_GENDER = {
    female: {
        id: 'female',
        tKey: 'enums.person.gender.female',
    },
    male: {
        id: 'male',
        tKey: 'enums.person.gender.male',
    },
    na: {
        id: 'na',
        tKey: 'enums.person.prefer_not_to_say',
    },
};
export const ENUMS_PERSON_PS_GENDER = {
    female: {
        id: 'female',
        tKey: 'enums.person.gender.female',
    },
    male: {
        id: 'male',
        tKey: 'enums.person.gender.male',
    },
    unknown: {
        id: 'unknown',
        tKey: 'enums.person.prefer_not_to_say',
    },
};
export const ENUMS_PERSON_PRONOUN = {
    she_her: {
        id: 'she_her',
        tKey: 'enums.person.pronoun.she_her',
    },
    he_him: {
        id: 'he_him',
        tKey: 'enums.person.pronoun.he_him',
    },
    they_them: {
        id: 'they_them',
        tKey: 'enums.person.pronoun.they_them',
    },
    // other: {
    //   id: 'other',
    //   tKey: 'enums.person.other',
    // },
};
export const ENUMS_PERSON_GENDER_IDENTITY = {
    male: {
        id: 'male',
        tKey: 'enums.person.gender_identity.male',
        tKeyDesc: 'enums.person.gender_identity.cisgender_desc',
    },
    female: {
        id: 'female',
        tKey: 'enums.person.gender_identity.female',
        tKeyDesc: 'enums.person.gender_identity.cisgender_desc',
    },
    transgender_female: {
        id: 'transgender_female',
        tKey: 'enums.person.gender_identity.transgender_female',
        tKeyDesc: 'enums.person.gender_identity.transgender_desc',
    },
    transgender_male: {
        id: 'transgender_male',
        tKey: 'enums.person.gender_identity.transgender_male',
        tKeyDesc: 'enums.person.gender_identity.transgender_desc',
    },
    non_binary: {
        id: 'non_binary',
        tKey: 'enums.person.gender_identity.non_binary',
        tKeyDesc: 'enums.person.gender_identity.non_binary_desc',
    },
    other: {
        id: 'other',
        tKey: 'enums.person.gender_identity.not_listed',
    },
    non_disclose: {
        id: 'non_disclose',
        tKey: 'enums.person.prefer_not_to_say',
    },
};
export const ENUMS_PERSON_RACE = {
    indian_alaskan: {
        id: 'indian_alaskan',
        tKey: 'enums.person.race.indian_alaskan',
    },
    asian: {
        id: 'asian',
        tKey: 'enums.person.race.asian',
    },
    black: {
        id: 'black',
        tKey: 'enums.person.race.black',
    },
    hispanic: {
        id: 'hispanic',
        tKey: 'enums.person.race.hispanic',
    },
    hawaiian_pacific: {
        id: 'hawaiian_pacific',
        tKey: 'enums.person.race.hawaiian_pacific',
    },
    white: {
        id: 'white',
        tKey: 'enums.person.race.white',
    },
    multiracial: {
        id: 'multiracial',
        tKey: 'enums.person.race.multiracial',
    },
    asked_but_unknown: {
        id: 'asked_but_unknown',
        tKey: 'enums.person.prefer_not_to_say',
    },
    // other: {
    //   id: 'other',
    //   tKey: 'enums.person.other',
    // },
};
export const ENUMS_PERSON_ETHNICITY = {
    african: {
        id: 'african',
        tKey: 'enums.person.ethnicity.african',
    },
    african_american: {
        id: 'african_american',
        tKey: 'enums.person.ethnicity.african_american',
    },
    asian: {
        id: 'asian',
        tKey: 'enums.person.ethnicity.asian',
    },
    black: {
        id: 'black',
        tKey: 'enums.person.ethnicity.black',
    },
    chinese: {
        id: 'chinese',
        tKey: 'enums.person.ethnicity.chinese',
    },
    east_african: {
        id: 'east_african',
        tKey: 'enums.person.ethnicity.east_african',
    },
    east_asian: {
        id: 'east_asian',
        tKey: 'enums.person.ethnicity.east_asian',
    },
    hispanic: {
        id: 'hispanic',
        tKey: 'enums.person.ethnicity.hispanic_latin_american',
    },
    indian: {
        id: 'indian',
        tKey: 'enums.person.ethnicity.indian',
    },
    middle_eastern: {
        id: 'middle_eastern',
        tKey: 'enums.person.ethnicity.middle_eastern',
    },
    native_american_or_inuit: {
        id: 'native_american_or_inuit',
        tKey: 'enums.person.ethnicity.native_american_or_inuit',
    },
    pacific_islander: {
        id: 'pacific_islander',
        tKey: 'enums.person.ethnicity.pacific_islander',
    },
    south_asian: {
        id: 'south_asian',
        tKey: 'enums.person.ethnicity.south_asian',
    },
    southeast_asian: {
        id: 'southeast_asian',
        tKey: 'enums.person.ethnicity.southeast_asian',
    },
    white_caucasian: {
        id: 'white_caucasian',
        tKey: 'enums.person.ethnicity.white_caucasian',
    },
    mixed_ethnicity: {
        id: 'mixed_ethnicity',
        tKey: 'enums.person.ethnicity.mixed_ethnicity',
    },
    // asked_but_unknown: {
    //   id: 'asked_but_unknown',
    //   tKey: 'enums.person.prefer_not_to_say',
    // },
    // other: {
    //   id: 'other',
    //   tKey: 'enums.person.other',
    // },
};
export const ENUMS_PERSON_MARITAL_STATUS = {
    single: {
        id: 'single',
        tKey: 'enums.person.marital_status.single',
    },
    married: {
        id: 'married',
        tKey: 'enums.person.marital_status.married',
    },
    divorced: {
        id: 'divorced',
        tKey: 'enums.person.marital_status.divorced',
    },
    legally_separated: {
        id: 'legally_separated',
        tKey: 'enums.person.marital_status.legally_separated',
    },
    domestic_partner: {
        id: 'domestic_partner',
        tKey: 'enums.person.marital_status.domestic_partner',
    },
    separated: {
        id: 'separated',
        tKey: 'enums.person.marital_status.separated',
    },
    unmarried: {
        id: 'unmarried',
        tKey: 'enums.person.marital_status.unmarried',
    },
    widowed: {
        id: 'widowed',
        tKey: 'enums.person.marital_status.widowed',
    },
    decline: {
        id: 'decline',
        tKey: 'enums.person.marital_status.decline',
    },
};
