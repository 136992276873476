var _a, _b;
import { prepareRoutesList } from 'ziphy-web-shared';
import mainConfig from '@config/main';
import { routes } from '@config/routes';
import { routesExtraList } from '@config/routesExtra';
export const InitializerConfig = {
    /// appConfig
    appEnv: mainConfig.appEnv,
    hashRouter: mainConfig.hashRouter,
    savedSearchParamsList: mainConfig.savedSearchParamsList,
    i18n: {
        languages: mainConfig.localization.availableLanguages,
        default: mainConfig.localization.defaultLanguage,
    },
    apiService: {
        urls: {
            baseUrl: mainConfig.api.baseUrl,
        },
    },
    webView: {
        isEnabled: mainConfig.custom.webViewEnabled,
        prefix: mainConfig.custom.webViewPostMessagePrefix,
    },
    analytics: {
        raygun: { apiKey: (_a = mainConfig.analytic) === null || _a === void 0 ? void 0 : _a.raygunApiKey },
        mixpanel: { apiKey: (_b = mainConfig.analytic) === null || _b === void 0 ? void 0 : _b.mixpanelApiKey },
    },
    stripe: {
        payMode: mainConfig.stripe.payMode,
        publicKey: mainConfig.stripe.publicKey,
    },
    phone: mainConfig.phone,
    ///
    routes: prepareRoutesList(routes, routesExtraList),
};
