import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { getUuid } from 'ziphy-web-shared/basic/helpers';
export function getStylesMode(mode, styles) {
    const array = Array.isArray(mode) ? mode : [mode];
    return array.map((x) => styles[x]);
}
export function simpleTagConverter(str = '', Tag = 'em') {
    const regex = new RegExp('<' + Tag + '>[\\s\\S]*?<\\/' + Tag + '>', 'ig');
    const matches = str.match(regex) || [];
    if (matches.length) {
        let lastPosition = 0;
        let result = [];
        matches.forEach((m) => {
            const start = str.indexOf(m);
            result.push(str.substring(0, start));
            result.push(_jsx(Tag, { children: m.replace(/<\/?[^>]+(>|$)/g, '') }, getUuid()));
            lastPosition = start + m.length;
            str = str.substring(lastPosition);
        });
        result.push(str);
        return result;
    }
    else {
        return str;
    }
}
export const T = ({ children, tag, className }) => {
    const Tag = tag || 'div';
    const regex = new RegExp(/(\bhttps?:\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi);
    const regexAnchor = new RegExp(/\bhttps?:\/\//gi);
    return (children && (_jsx(Tag, Object.assign({ className: className }, { children: children.split(regex).map((item, index) => {
            if (regex.test(item)) {
                const anchor = item.replace(regexAnchor, '');
                return (_jsx("a", Object.assign({ target: "_blank", href: item }, { children: anchor }), index));
            }
            return item;
        }) }))));
};
