import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { observer } from 'mobx-react-lite';
import _ from 'lodash';
import { CustomLink } from 'ziphy-web-shared/basic/lib/buttons';
import { routeActions } from '@config/routeActions';
import logoVert from '@assets/images/logo-vert.svg';
import styles from './SidebarLogo.module.scss';
const SidebarLogo = ({ onHideSidebar }) => {
    function handleHideSidebar() {
        if (_.isFunction(onHideSidebar)) {
            onHideSidebar();
        }
    }
    return (_jsx("div", Object.assign({ className: styles.wrapper }, { children: _jsx(CustomLink, Object.assign({ autoTarget: routeActions.HOME(), onClick: handleHideSidebar }, { children: _jsx("img", { src: logoVert, alt: "" }) })) })));
};
export default observer(SidebarLogo);
