import _ from 'lodash';
export function toSnakeCase(value) {
    return value.replace(/([A-Z])/g, '_$1');
}
export function toNumberIfPossible(value) {
    if (typeof value === 'number') {
        return value;
    }
    const int = parseInt(value);
    return int.toString() === value ? int : value;
}
export function isEqualIds(id1, id2) {
    return toNumberIfPossible(id1) === toNumberIfPossible(id2);
}
export function formatByMask(value, mask, { placeholder = '.', strict = true } = {}) {
    if (!mask) {
        return value;
    }
    const tmp = mask.split('').reduce((acc, character) => {
        if (!acc.remaining.length) {
            return acc;
        }
        if (character === placeholder) {
            return {
                formatted: acc.formatted + acc.remaining[0],
                remaining: acc.remaining.slice(1),
            };
        }
        return {
            formatted: acc.formatted + character,
            remaining: acc.remaining,
        };
    }, { formatted: '', remaining: value.split('') });
    let result = tmp.formatted;
    if (!strict) {
        result = result + tmp.remaining.join('');
    }
    return result;
}
export function firstLetters(names = []) {
    names = names.map((x) => (typeof x === 'undefined' ? '' : x));
    return names
        .map((x) => x.trim())
        .filter((x) => x)
        .map((x) => x[0])
        .join('');
}
// todo: add zero as free
export function formatAmount(amount, currency, precision = 2) {
    let curr = currency.toUpperCase();
    let result;
    if (curr === 'USD') {
        result = '$' + _.floor(amount, precision);
    }
    else {
        result = _.floor(amount, precision) + ' ' + curr;
    }
    return result;
}
export function humanize(str, startCase = false) {
    const strCleaned = _.replace(str, /_/g, ' ');
    return startCase ? _.startCase(strCleaned) : _.capitalize(strCleaned);
}
export function stripHtml(str) {
    return _.replace(str, /(<([^>]+)>)/gi, '');
}
export function valueOrDash(value) {
    return (value && _.trim(value)) || '—';
}
function characterByIndex(index, charStart = 'A', charEnd = 'Z') {
    let alphabet = [];
    for (let i = charStart.charCodeAt(0); i <= charEnd.charCodeAt(0); i++) {
        alphabet.push(String.fromCharCode(i));
    }
    if (index <= alphabet.length - 1) {
        return alphabet[index];
    }
    else if (index <= Math.pow(alphabet.length, 2) + alphabet.length) {
        let firstChar = '';
        let secondChar = '';
        let ind = 0;
        for (let i = index; i >= 0; i -= alphabet.length) {
            ind++;
            firstChar = alphabet[ind - 2];
            secondChar = alphabet[i];
        }
        return [firstChar, secondChar].join('');
    }
    else {
        return index;
    }
}
export function splitAtIndex(value, index) {
    return [value.slice(0, index), value.slice(index + 1)];
}
