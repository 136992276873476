import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { forwardRef, useEffect, useMemo, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import Icon from 'ziphy-web-shared/basic/assets/images/Icon';
import { useForceUpdate, useUpdateCallback } from 'ziphy-web-shared/basic/hooks';
import { $time, formatDate } from 'ziphy-web-shared/basic/utils/time';
import DayListPicker from './dayListPicker/DayListPicker';
import DayPicker from './dayPicker/DayPicker';
import { getMinMaxDate, getParsedDate, getSwitchDates } from './NewDatePicker.helpers';
import './NewDatePicker.scss';
const NewDatePicker = forwardRef(({ type = 'selectDay', title, selectedDate, onChange, timezone = $time.timezone, minDate, maxDate, onHide, confirmDate = false, availableDates, disabledDates, dayList = false, scrollTo = 'current', hasPrevLoadTrigger, hasNextLoadTrigger, }, ref) => {
    var _a;
    const { t } = useTranslation();
    const currentDate = useMemo(() => {
        return getParsedDate(null, type, timezone);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    const dayPickerRef = useRef();
    const { forceUpdate } = useForceUpdate();
    // Start screen
    const [view, setView] = useState(() => (dayList ? 'dayList' : 'day'));
    const modelMemo = useMemo(() => {
        if (!selectedDate) {
            return;
        }
        return getParsedDate(selectedDate, type, timezone);
    }, [selectedDate]); // eslint-disable-line react-hooks/exhaustive-deps
    const [model, setModel] = useState(modelMemo);
    // Re-initialize if input selectedDate (modelMemo) is changed
    useEffect(() => {
        if (!modelMemo) {
            return;
        }
        setModel(modelMemo);
    }, [modelMemo]);
    // Get available dates
    const parsedAvailableDates = useMemo(() => {
        if (_.isEmpty(availableDates)) {
            return;
        }
        return getSwitchDates(availableDates, type, timezone);
    }, [availableDates]); // eslint-disable-line react-hooks/exhaustive-deps
    // Get disabled dates
    const parsedDisabledDates = useMemo(() => {
        if (_.isEmpty(disabledDates)) {
            return;
        }
        return getSwitchDates(disabledDates, type, timezone);
    }, [disabledDates]); // eslint-disable-line react-hooks/exhaustive-deps
    // Get min and max dates
    const [parsedMinDate, parsedMaxDate] = getMinMaxDate(minDate, maxDate, parsedAvailableDates, currentDate, type, timezone);
    // Update date or datetime into parent component as date string or datetime ISO string
    const handleApplyDate = () => {
        let newDatetime;
        if (type === 'selectDay') {
            newDatetime = model.format('YYYY-MM-DD');
        }
        else if (type === 'selectDateTime') {
            const modelMidnight = model.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
            if (model.format('YYYY-MM-DD') === parsedMinDate.format('YYYY-MM-DD') &&
                modelMidnight.isBefore(parsedMinDate) &&
                scrollTo === 'start') {
                newDatetime = parsedMinDate.toISOString();
            }
            else if (model.format('YYYY-MM-DD') === parsedMaxDate.format('YYYY-MM-DD') &&
                parsedMaxDate.isAfter(modelMidnight) &&
                scrollTo === 'end') {
                newDatetime = parsedMaxDate.toISOString();
            }
            else {
                newDatetime = modelMidnight.toISOString();
            }
        }
        onChange(newDatetime);
        if (onHide) {
            onHide();
        }
    };
    const forceCallback = useUpdateCallback(handleApplyDate);
    const handleSetDate = (value) => {
        setModel(value);
        // Run callback after model is changed
        if (!confirmDate) {
            forceCallback();
        }
    };
    return (_jsxs("div", Object.assign({ className: "new-date-picker" }, { children: [_jsxs("div", Object.assign({ className: "control" }, { children: [view === 'day' && _.includes(['month', 'year'], (_a = dayPickerRef.current) === null || _a === void 0 ? void 0 : _a.getView()) && (_jsx("div", Object.assign({ className: "action back", onClick: () => { var _a; return (_a = dayPickerRef.current) === null || _a === void 0 ? void 0 : _a.goToBack(); } }, { children: _jsx(Icon, { type: "back" }) }))), onHide && (_jsx("div", Object.assign({ className: "action close", onClick: onHide }, { children: _jsx(Icon, { type: "close" }) })))] })), _jsx("div", Object.assign({ className: "header" }, { children: _jsx("div", Object.assign({ className: "title title-md text-no-select" }, { children: title || t('label.select_date') })) })), _jsxs("div", Object.assign({ className: "body" }, { children: [view === 'day' && (_jsx(DayPicker, { currentDate: currentDate, selectedDate: model, setDate: handleSetDate, minDate: parsedMinDate, maxDate: parsedMaxDate, availableDates: parsedAvailableDates, disabledDates: parsedDisabledDates, forceUpdate: forceUpdate, ref: dayPickerRef, scrollTo: scrollTo })), view === 'dayList' && (_jsx(DayListPicker, { currentDate: currentDate, selectedDate: model, setDate: handleSetDate, minDate: parsedMinDate, maxDate: parsedMaxDate, availableDates: parsedAvailableDates, disabledDates: parsedDisabledDates, scrollTo: scrollTo, hasPrevLoadTrigger: hasPrevLoadTrigger, hasNextLoadTrigger: hasNextLoadTrigger, ref: ref }))] })), confirmDate && (_jsxs("div", Object.assign({ className: "footer" }, { children: [!model && _jsx("span", Object.assign({ className: "selected-date locked" }, { children: t('label.choose_a_date') })), model && (_jsx("span", Object.assign({ className: "selected-date", onClick: handleApplyDate }, { children: formatDate(model, { format: 'date' }) })))] })))] })));
});
export default observer(NewDatePicker);
