var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { action, makeObservable, observable, runInAction } from 'mobx';
import _ from 'lodash';
import { getUuid } from 'ziphy-web-shared/basic/helpers';
import { analytics } from 'ziphy-web-shared/basic/utils/analytics';
class ModalStore {
    constructor() {
        this.current = [];
        this.items = [];
        makeObservable(this, {
            current: observable,
            items: observable,
            add: action,
            setNextCurrent: action,
            setFooterShadow: action,
            getCurrentByTemplateName: action,
            getCurrentById: action,
            show: action,
            callCallbacks: action,
            dropAll: action,
            hide: action,
            forceHide: action,
            block: action,
            unblock: action,
            remove: action,
        });
    }
    // Computed
    // Mutations
    // Actions
    add(template, modalProps) {
        var _a, _b;
        if (!modalProps) {
            modalProps = {};
        }
        const { multi = true, uniq = true, data = {}, // only for actionConfirmator
        animationOnShow = true, isBlocked = false, canDrop = true, onEnter, onEntered, onLeave, onLeaved, onCancel, onDone } = modalProps, props = __rest(modalProps, ["multi", "uniq", "data", "animationOnShow", "isBlocked", "canDrop", "onEnter", "onEntered", "onLeave", "onLeaved", "onCancel", "onDone"]);
        let id = getUuid();
        let passedProps = {};
        // @ts-ignore
        _.forEach(_.cloneDeep(props), (value, key) => {
            passedProps[key] = _.isFunction(value) ? action(value) : value;
        });
        const templateName = 
        // @ts-ignore
        ((_a = template.type) === null || _a === void 0 ? void 0 : _a.displayName) || ((_b = template.type) === null || _b === void 0 ? void 0 : _b.name) || (template === null || template === void 0 ? void 0 : template.displayName) || template.name;
        if (uniq && this.getCurrentByTemplateName(templateName)) {
            return false;
        }
        analytics.track('modal-add', { modal: templateName });
        let modalParams = Object.assign(Object.assign({ _core: {
                id,
                templateName,
                footerShadow: false,
                template,
                show: false,
                canDrop,
                multi,
                animationOnShow,
                isBlocked,
                onShow: action(() => this.show(id)),
                onHide: action((status, data) => this.hide(id, status, data)),
                onBlock: action(() => this.block(id)),
                onUnblock: action(() => this.unblock(id)),
                onEnter: onEnter ? action(() => onEnter()) : undefined,
                onEntered: onEntered ? action(() => onEntered()) : undefined,
                onLeave: onLeave ? action(() => onLeave()) : undefined,
                onLeaved: onLeaved ? action(() => onLeaved()) : undefined,
                onDone: onDone ? action(onDone) : () => { },
                onCancel: onCancel ? action(onCancel) : () => { },
                forceHide: action(() => this.forceHide(id)),
            } }, (!_.isEmpty(data) && { data })), passedProps);
        if (multi) {
            this.items = [modalParams, ...this.items];
        }
        else {
            this.items = [...this.items, modalParams];
        }
        this.setNextCurrent();
        return modalParams;
    }
    setNextCurrent() {
        if (this.items.length && (_.isEmpty(this.current) || this.items[0]._core.multi)) {
            this.current = [...this.current, this.items.splice(0, 1)[0]];
        }
    }
    setFooterShadow(id, value) {
        let current = this.getCurrentById(id);
        current._core.footerShadow = value;
    }
    getCurrentByTemplateName(name) {
        return _.find(this.current, (x) => x._core.templateName === name);
    }
    getCurrentById(id) {
        return _.find(this.current, (x) => x._core.id === id);
    }
    updateParams(id, updatedProps) {
        const modalIndex = _.findIndex(this.current, (x) => x._core.id === id);
        if (modalIndex > -1) {
            runInAction(() => {
                this.current[modalIndex] = Object.assign(Object.assign({}, this.current[modalIndex]), updatedProps);
            });
        }
    }
    show(id) {
        let current = this.getCurrentById(id);
        if (current) {
            current._core.show = true;
            analytics.track('modal-show', { modal: current._core.templateName });
        }
    }
    callCallbacks(current, status, data) {
        analytics.track('modal-event', {
            modal: current._core.templateName,
            status: status ? 'done' : 'cancel',
        });
        if (status === true) {
            current._core.onDone(data);
        }
        else if (status === false) {
            current._core.onCancel(data);
        }
    }
    dropAll() {
        this.items = [];
        _.forEach(this.current, (x) => {
            if (x._core.canDrop) {
                x._core.show = false;
            }
        });
    }
    hide(id, status, data) {
        return __awaiter(this, void 0, void 0, function* () {
            let current = this.getCurrentById(id);
            if (current && !current._core.isBlocked) {
                current._core.show = false;
                this.callCallbacks(current, status, data);
            }
        });
    }
    forceHide(id) {
        let current = this.getCurrentById(id);
        if (current) {
            this.remove(id);
        }
    }
    block(id) {
        let current = this.getCurrentById(id);
        if (current) {
            current._core.isBlocked = true;
        }
    }
    unblock(id) {
        let current = this.getCurrentById(id);
        if (current) {
            current._core.isBlocked = false;
        }
    }
    remove(id) {
        if (this.current.length) {
            this.current = this.current.filter((x) => x._core.id !== id);
            this.setNextCurrent();
        }
    }
}
const store = new ModalStore();
export default store;
