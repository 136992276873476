var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { BaseService } from '../baseService';
export class Dev extends BaseService {
    constructor(props) {
        super(props);
    }
    getStubConfig() {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.api.fetchV3('stub_plugin.get_config');
        });
    }
    /*
    check_subscription_false_rate and booked_notify_false_rate determine after how many requests the virtual labq will respond with false to the subscription request:
      0 - subscription is always present
      1 - there is never a subscription
      2 - not on every second request, etc.
     */
    stubReconfigure() {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.api.fetchV3('stub_plugin.reconfigure', {
                config: {
                    labq_stub: {
                        check_subscription_false_rate: 0,
                        booked_notify_false_rate: 0,
                        mode: 'stub',
                    },
                    travel_stub: {
                        travel_time: 20,
                        mode: 'stub',
                    },
                },
            });
        });
    }
    availableMethodsList(list = []) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.api.fetchV3('list_methods', undefined, { prepareResult: false });
            if (response.prepared) {
                let items = response.prepared;
                if (list.length > 0)
                    items = items.filter((x) => list.some((v) => { var _a; return (_a = x.name) === null || _a === void 0 ? void 0 : _a.includes(v); }));
                console.log(items);
            }
            return response;
        });
    }
}
