import MedicationStatementDetails from '@ps/library/eventsViewer/details/MedicationStatementDetails';
import AsStringTableDetails from '@ps/library/eventsViewer/tableDetails/AsStringTableDetails';
import HistoryEventModal from '@ps/library/modals/HistoryEvent.modal';
export const medicationsConfig = {
    medication_statement: {
        eventModal: HistoryEventModal,
        condition: {
            and: [{ field: 'resource', eq: 'MedicationStatement' }],
            or: [
                { field: 'subtype', eq: ['medication'] },
                { field: 'subtype', eq: ['medication', 'intake'] },
            ],
        },
        rowType: 'medications',
        viewer: {
            tableDetails: AsStringTableDetails,
            details: MedicationStatementDetails,
        },
    },
    medication_request: {
        condition: [
            { field: 'resource', eq: 'MedicationRequest' },
            // { field: 'subtype', eq: ['medication'] },
        ],
    },
};
