import { useCallback, useEffect, useRef } from 'react';
import _ from 'lodash';
import Form from './Form';
export const useForm = (params, { isReadyForInit = true } = {}) => {
    const initialized = useRef(isReadyForInit);
    let form = useRef(Form.create());
    if (initialized.current === false && isReadyForInit) {
        form.current = Form.create();
    }
    initialized.current = isReadyForInit;
    form.current.register(params);
    // todo: add update field after change params
    const useFormOnChange = (callback, deps = [], delay = 400) => {
        const fn = useCallback(_.debounce(callback, delay), []);
        useEffect(fn, deps);
    };
    const current = form.current;
    return {
        form: current,
        values: current.values,
        fields: current.fields,
        groups: current.groups,
        useFormOnChange,
        params,
    };
};
