import { makeAutoObservable, reaction } from 'mobx';
import { $loader, globalLoaderHandlers, localStore } from 'ziphy-web-shared/basic/utils';
import { analytics } from 'ziphy-web-shared/basic/utils/analytics';
class LayoutsStore {
    constructor() {
        this.sidebar = false;
        this.initExtraData = localStore.get('userSettings.initExtraData', {});
        this.rightSidebar = localStore.get('userSettings.rightSidebar', { active: null, payload: {} });
        this.marginBottom = null;
        makeAutoObservable(this);
    }
    // Computed
    // Mutations
    SET_INIT_EXTRA_DATA(value) {
        this.initExtraData = value;
    }
    SET_MARGIN_BOTTOM(value) {
        this.marginBottom = value;
    }
    // Actions
    sidebarToggle(value) {
        if (typeof value === 'undefined') {
            value = !this.sidebar;
        }
        if (value) {
            document.documentElement.classList.add('sidebar-open');
        }
        else {
            document.documentElement.classList.remove('sidebar-open');
        }
        this.sidebar = value;
        analytics.breadcrumb('nav-toggleSidebar', { state: this.sidebar });
    }
    showRightSidebar(key, payload) {
        this.rightSidebar.active = key;
        if (payload) {
            this.rightSidebar.payload[key] = payload;
        }
        localStore.set('userSettings.rightSidebar', this.rightSidebar);
    }
    hideRightSidebar() {
        this.rightSidebar.active = null;
        localStore.set('userSettings.rightSidebar', this.rightSidebar);
    }
    removeRightSidebar(key) {
        this.rightSidebar.active = null;
        delete this.rightSidebar.payload[key];
        localStore.set('userSettings.rightSidebar', this.rightSidebar);
    }
}
export const $layouts = new LayoutsStore();
$loader.subscribe(globalLoaderHandlers.LOGIN, () => {
    $layouts.rightSidebar = localStore.get('userSettings.rightSidebar', {
        active: null,
        payload: {},
    });
});
$loader.subscribe(globalLoaderHandlers.LOGOUT, () => {
    $layouts.rightSidebar = { active: null, payload: {} };
});
reaction(() => $layouts.initExtraData, (value) => {
    // todo: decrease/increase lifeTime?
    localStore.set('userSettings.initExtraData', value, { lifeTime: 3600 });
});
