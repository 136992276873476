import moment from 'moment-timezone';
const PersonAgeLimitConfig = {
    default: { min: 0, max: 120 },
    profile: { min: 18, max: 120 },
    patient: { min: 3, max: 120 },
};
export const personAgeLimit = (type) => {
    const preset = PersonAgeLimitConfig[type] || PersonAgeLimitConfig['default'];
    const currentDate = moment().format();
    let from = moment(currentDate).subtract(preset.max, 'years').format();
    let to = moment(currentDate).subtract(preset.min, 'years').format();
    return { from, to };
};
export function splitName(name = '', type = 'default') {
    let matches = name
        .replace(new RegExp(String.fromCharCode(160), 'g'), ' ')
        .split(' ')
        .filter((x) => x);
    let firstName = '';
    let lastName = '';
    if (matches && matches.length) {
        if (type === 'consistently') {
            firstName = matches[0];
            lastName = matches[1];
        }
        else {
            if (matches.length > 1) {
                firstName = matches.slice(0, -1).join(' ');
                lastName = matches[matches.length - 1];
            }
            else {
                firstName = matches[0];
            }
        }
    }
    return [firstName, lastName].filter((x) => x);
}
export function getFullName({ firstName, lastName, } = {}) {
    return [firstName, lastName]
        .map((i) => i && i.trim())
        .filter((i) => i)
        .join(' ');
}
