var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { isLocal } from 'ziphy-web-shared/basic/helpers';
import { BaseService } from '../baseService';
export class CodingsSearch extends BaseService {
    constructor(props) {
        super(props);
    }
    list(params, options) {
        return __awaiter(this, void 0, void 0, function* () {
            if (isLocal(true)) {
                const res = yield this.api.fetchUniversal('http://localhost:8010/concepts/name/search', params);
                // @ts-ignore for local environment only
                return { prepared: { items: res.prepared } };
            }
            else {
                const res = yield this.api.fetchLoggedV3('patient_stories.search_coding', params, options);
                return Object.assign(Object.assign({}, res), { prepared: { items: res.prepared } });
            }
        });
    }
}
