import _ from 'lodash'
import { webView } from 'ziphy-web-shared/basic/utils'
import { $router } from 'ziphy-web-shared/basic/utils/router'

import mainConfig from '@config/main'

// () => ({to, href, wvAction, onClick})
export const routeActions = {
  BRAND_ROOT: () => {
    const backUrl = $router.searchParams.get('backUrl')

    if (backUrl) {
      return {
        href: backUrl,
        wvAction: webView.close,
      }
    }

    return {
      href: mainConfig.web.parentUrl,
      wvAction: webView.close,
    }
  },
  APPT: ({ appointmentId, encounterIds = [], encounterId = undefined }) => ({
    to: $router.toPath('appt', {
      params: { appointmentId: appointmentId, encounterId: encounterId || _.head(encounterIds) },
    }),
  }),
}
