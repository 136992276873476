import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import classNames from 'classnames';
import styles from './Title.module.scss';
// title-lg - h1
// title-md - h2
// title-sm - h3
// title-xs - h4
const Title = ({ size, children, ellipsis, className }) => {
    return (_jsx("div", Object.assign({ className: classNames(styles.title, styles[size], ellipsis && styles.ellipsis, className) }, { children: children })));
};
export default Title;
