import { getUuid } from 'ziphy-web-shared/basic/helpers';
class LoggableSession {
    constructor(props) {
        this.key = null;
        this.appType = null;
        this.appVersion = null;
        this.appRevision = null;
        this.renderType = null;
        this.browserId = null;
        this.tabId = null;
        this.cachedValue = null;
        this.lastAdditional = '';
        if (LoggableSession.instance) {
            return LoggableSession.instance;
        }
        if (props) {
            this.init(props);
        }
        LoggableSession.instance = this;
    }
    init(props) {
        if (!props.appType || !props.appVersion || !props.appRevision) {
            throw new Error('LoggableSession: Missing required initialization properties');
        }
        this.key = props.key || 'loggable_session_id';
        this.appType = props.appType;
        this.appVersion = props.appVersion;
        this.appRevision = props.appRevision;
        if (props.renderType) {
            this.renderType = props.renderType;
        }
        if (typeof props.getAdditional === 'function') {
            this.getAdditional = props.getAdditional;
        }
        this.browserId = this.browserId || this.getOrGenerateId();
        this.tabId = this.tabId || this.getOrGenerateId(true);
    }
    getOrGenerateId(isTab = false) {
        const storage = isTab ? sessionStorage : localStorage;
        let id = storage.getItem(this.key);
        if (!id) {
            id = getUuid(12);
            storage.setItem(this.key, id);
        }
        return id;
    }
    get() {
        const additional = this.getAdditional();
        if (this.cachedValue && additional === this.lastAdditional) {
            return this.cachedValue;
        }
        let app = [this.appType, this.appVersion, this.appRevision];
        if (this.renderType)
            app.push(this.renderType);
        let session = [this.browserId, this.tabId];
        if (additional)
            session.push(additional);
        this.cachedValue = { app: app.join('_'), session: session.join('-') };
        this.lastAdditional = additional;
        return this.cachedValue;
    }
}
export const loggableSession = new LoggableSession();
