import { makeAutoObservable } from 'mobx';
import ExtractorBase from '../extractors/Base';
import { prepareCustomDate, prepareMeta } from '../fhir.converters';
import { getCodingObjects, getExtensionObjects, getIdentifierObjects } from '../fhir.helpers';
import { joinArrayWithObjectToString, joinValuesToString } from '../fhir.utils';
class ExtractorProcedure {
    constructor(resource) {
        this.base = new ExtractorBase(this);
        this.base.SET(resource);
        makeAutoObservable(this);
    }
    get resource() {
        return this.base.resource;
    }
    /*
     * RESOURCE - Minimally prepared resource data, prepareConverters, valueObjects, etc.
     */
    get extensions() {
        return getExtensionObjects(this.resource.extension);
    }
    get identifiers() {
        return getIdentifierObjects(this.resource.identifier);
    }
    get categories() {
        return getCodingObjects(this.resource.category);
    }
    get codes() {
        return getCodingObjects(this.resource.code);
    }
    // other in the FHIR - Group
    get subject() {
        return this.base.getChildExtractor(this.resource.subject);
    }
    get asserter() {
        return this.base.getChildExtractor(this.resource.asserter);
    }
    get recorder() {
        return this.base.getChildExtractor(this.resource.recorder);
    }
    get notes() {
        return this.resource.note;
    }
    get meta() {
        return prepareMeta(this.resource.meta);
    }
    /*
     * READ - PREPARED Values
     */
    get zid() {
        return this.identifiers.first({ config: 'zid' });
    }
    get codeObj() {
        return this.codes.first();
    }
    get datePerformed() {
        return prepareCustomDate({
            dateTime: this.resource.performedDateTime,
            string: this.resource.performedString,
        });
    }
    /*
     * STRING Values
     */
    get asString() {
        var _a, _b;
        const tmp = {
            codeDisplay: (_a = this.codeObj) === null || _a === void 0 ? void 0 : _a.display,
            notes: joinArrayWithObjectToString(this.notes, 'text', '; '),
            performedDate: (_b = this.datePerformed) === null || _b === void 0 ? void 0 : _b.asString,
            full: '',
        };
        tmp.full = joinValuesToString([tmp.codeDisplay, tmp.notes], ' • ');
        return tmp;
    }
}
export default ExtractorProcedure;
