var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { getInitConfig } from 'ziphy-web-shared';
import { $paymentMethods } from 'ziphy-web-shared/basic/entities/paymentMethods';
import { delay } from 'ziphy-web-shared/basic/helpers';
import { useLoader } from 'ziphy-web-shared/basic/hooks';
import { showAlert } from 'ziphy-web-shared/basic/lib/utilities';
import { $loader } from 'ziphy-web-shared/basic/utils';
import Modal from '@library/modal/Modal';
import ModalToolbarBtn from '@library/modal/toolbar/ModalToolbarBtn';
import Stripe from '@library/plugins/stripe/Stripe';
import Button from '@nedoShared/basic/lib/simple/button/Button';
const AddPaymentMethodModal = ({ _core }) => {
    var _a;
    const { t } = useTranslation();
    const stripeRef = useRef(null);
    const { isLoading } = useLoader(() => __awaiter(void 0, void 0, void 0, function* () {
        return yield delay(300);
    }));
    const handleSubmit = $loader.registerHandler('payment-method-add', (e) => __awaiter(void 0, void 0, void 0, function* () {
        e.preventDefault();
        _core.onBlock();
        const [card, stripe] = stripeRef.current.getData();
        const stripeResponse = yield stripe.createToken(card);
        let response;
        if (stripeResponse.token) {
            response = yield $paymentMethods.create({
                paymentMethodToken: stripeResponse.token.id,
            });
        }
        else if (stripeResponse.error) {
            showAlert.error(stripeResponse.error.message);
        }
        _core.onUnblock();
        if (!(response === null || response === void 0 ? void 0 : response.error) && (response === null || response === void 0 ? void 0 : response.preparedValue)) {
            _core.onHide(true, response.preparedValue);
        }
    }));
    return (_jsxs(Modal, Object.assign({ centered: true, scrollable: true }, { children: [_jsx("div", Object.assign({ className: "modal-toolbar" }, { children: _jsx(ModalToolbarBtn, { type: "close", action: _core.onHide }) })), _jsx(Modal.Header, { children: t('pms_card.title') }), _jsx(Modal.Body, Object.assign({ isLoading: isLoading, className: "mt--20" }, { children: _jsx("form", Object.assign({ id: "payment-method-add", onSubmit: handleSubmit }, { children: _jsx(Stripe, { ref: stripeRef, apiKey: (_a = getInitConfig().stripe) === null || _a === void 0 ? void 0 : _a.publicKey }) })) })), _jsx(Modal.Footer, { children: _jsx(Button, Object.assign({ tag: "button", mode: ['primary', 'block'], form: "payment-method-add", isLoading: $loader.isRunHandler('payment-method-add') }, { children: t('btn.add') })) })] })));
};
AddPaymentMethodModal.displayName = 'AddPaymentMethodModal';
export default observer(AddPaymentMethodModal);
