var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import _ from 'lodash';
import { myackAxiosInstance } from 'ziphy-web-shared/basic/api';
export function openInNewTab(url) {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) {
        newWindow.opener = null;
    }
}
export function callToPhone(number) {
    window.location.href = 'tel:' + number;
}
export function getFileNameFromUrl(url) {
    if (url) {
        const m = url.toString().match(/.*\/(.+?)\./);
        if (m && m.length > 1) {
            return m[1];
        }
    }
    return '';
}
export function nativeScrollTo(id = '', params = {}) {
    if (id) {
        const el = document.querySelector('#' + id);
        if (el) {
            el.scrollIntoView(params);
        }
    }
}
export function fetchBlob(url) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield myackAxiosInstance.get(url, { responseType: 'blob' });
    });
}
export function downloadBy({ url = '', blob, prefetch = false, fileName = 'filename', }) {
    return __awaiter(this, void 0, void 0, function* () {
        if (url && prefetch) {
            try {
                const response = yield fetchBlob(url);
                blob = response.data;
            }
            catch (e) { }
        }
        if (blob instanceof Blob) {
            url = URL.createObjectURL(blob);
        }
        const link = document.createElement('a');
        link.href = url;
        link.download = fileName;
        document.body.appendChild(link);
        link.dispatchEvent(new MouseEvent('click', {
            bubbles: true,
            cancelable: true,
            view: window,
        }));
        document.body.removeChild(link);
        if (url)
            URL.revokeObjectURL(url);
    });
}
export function removeHttp(url, removeWWW = true) {
    const regex = removeWWW ? /^https?:\/\/(www.)?/ : /^https?:\/\//;
    return _.replace(url, regex, '');
}
export function isUrl(url) {
    return url.startsWith('http');
}
export function loadScript(src, onLoad = () => { }) {
    let script = document.createElement('script');
    script.setAttribute('src', src);
    script.onload = onLoad;
    document.head.appendChild(script);
}
