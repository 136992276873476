import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import styles from './PageNoResults.module.scss';
const PageNoResults = ({ children, className, bordered, size = 'lg' }) => {
    const { t } = useTranslation();
    return (_jsx("div", Object.assign({ className: classNames(styles.noResults, styles[size], bordered && styles.bordered, className) }, { children: children ? children : t('errors.no_results') })));
};
export default PageNoResults;
