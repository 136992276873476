import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { Suspense } from 'react';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';
import { Loader } from 'ziphy-web-shared/basic/lib/loaders';
import { $layouts } from 'ziphy-web-shared/basic/utils/layouts';
import Sidebar from '@src/layouts/sidebar/Sidebar';
import './DefaultLayout.scss';
const DefaultLayout = ({ children, hasSidebar = true, fullHeight = false, showLoader = true, className, }) => {
    const style = {
        marginBottom: $layouts.marginBottom,
    };
    return (_jsxs("div", Object.assign({ className: classNames('default_layout', className) }, { children: [hasSidebar && _jsx(Sidebar, {}), _jsx("main", Object.assign({ className: classNames('content-wrapper', !hasSidebar && 'content-wrapper--full-width', fullHeight && 'content-wrapper--full-height') }, { children: _jsx(Suspense, Object.assign({ fallback: showLoader && _jsx(Loader, { mode: 'pageWrapper' }) }, { children: _jsx("div", Object.assign({ className: "content-app-wrapper", style: style }, { children: children })) })) }))] })));
};
export default observer(DefaultLayout);
