import React, { useState } from 'react'

import { useTranslation } from 'react-i18next'

import classNames from 'classnames'
import _ from 'lodash'
import { ProgressRing } from 'ziphy-web-shared/basic/lib/loaders'

import BaseDocumentPdf from '@library/media/mediaTypes/base/BaseDocumentPdf'

import styles from './Types.module.scss'

const DocumentPdf = ({ src, onIsLoading, openInIframe }) => {
  const { t } = useTranslation()

  const [isLoading, setIsLoading] = useState(true) //
  const [isDefault, setIsDefault] = useState(false)

  function changeIsLoading(value) {
    setIsLoading(value)

    if (_.isFunction(onIsLoading)) {
      onIsLoading(value)
    }
  }

  return (
    <div
      className={classNames(
        styles.document,
        isLoading && styles.isLoading, // isDefault && styles.isDefault,
      )}
    >
      <BaseDocumentPdf
        src={src}
        onIsLoading={changeIsLoading}
        onIsDefault={setIsDefault}
        openInIframe={openInIframe}
      />
      {isLoading && (
        <ProgressRing
          radius={28}
          progress={25}
          spin={true}
          centered={true}
          className={styles.loader}
        />
      )}
    </div>
  )
}

export default DocumentPdf
