import { useEffect } from 'react';
import { isAndroid, isIOS, isMacOs, isWindows } from 'react-device-detect';
export const useClassNameByOS = () => {
    useEffect(() => {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        if (isWindows) {
            (_b = (_a = document.documentElement) === null || _a === void 0 ? void 0 : _a.classList) === null || _b === void 0 ? void 0 : _b.add('isWindows');
        }
        else if (isMacOs) {
            (_d = (_c = document.documentElement) === null || _c === void 0 ? void 0 : _c.classList) === null || _d === void 0 ? void 0 : _d.add('isMacOs');
        }
        else if (isIOS) {
            (_f = (_e = document.documentElement) === null || _e === void 0 ? void 0 : _e.classList) === null || _f === void 0 ? void 0 : _f.add('isIOS');
        }
        else if (isAndroid) {
            (_h = (_g = document.documentElement) === null || _g === void 0 ? void 0 : _g.classList) === null || _h === void 0 ? void 0 : _h.add('isAndroid');
        }
    }, []);
};
