var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { action, makeAutoObservable, makeObservable } from 'mobx';
import { practicesService } from 'ziphy-web-shared/basic/api';
import { getUuid } from 'ziphy-web-shared/basic/helpers';
import { ContextController, GlobalStorage, IdsController } from '../storageManager';
/*
 * GlobalStorage
 */
class PracticesGlobalStorage extends GlobalStorage {
    constructor() {
        super({ idKey: 'id', updateCondition: (x) => (x === null || x === void 0 ? void 0 : x.Type) === 'practice' });
        makeObservable(this, {
            list: action,
        });
    }
    list(params, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield practicesService.list(params, options);
        });
    }
}
export const $practicesGlobalStorage = new PracticesGlobalStorage();
/*
 * Templates
 */
class PracticesStorage {
    constructor(key = getUuid(), storage = $practicesGlobalStorage) {
        this.contextKey = key;
        this.storage = storage;
        this.itemsIds = new IdsController();
        makeAutoObservable(this);
    }
    // Items
    get items() {
        return this.itemsIds.list.map((id) => this.getById(id)).filter((x) => x);
    }
    getById(id) {
        return this.storage.asObject[id];
    }
    // Actions
    create(params, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield practicesService.create(params, options);
            if (!response.error)
                this.itemsIds.add(response.preparedValue.id);
            return response;
        });
    }
    read(params, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield practicesService.read(params, options);
        });
    }
    update(params, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield practicesService.update(params, options);
        });
    }
    delete(params, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield practicesService.delete(params, options);
            if (!response.error)
                this.itemsIds.delete(params.id);
            return response;
        });
    }
    list(params, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield practicesService.list(params, options);
            if (!response.error)
                this.itemsIds.set(response.preparedValue.map((x) => x.id));
            return response;
        });
    }
}
export const $practicesContexts = new ContextController((key) => new PracticesStorage(key));
export const $practices = $practicesContexts.get('fullList');
/*
 * Other
 */
// $loader.subscribe(globalLoaderHandlers.LOGOUT, () => {
//   $practicesGlobalStorage.clear()
//   $practicesContexts.clear()
// })
