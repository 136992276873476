// todo: add generic ServiceStaffLoadType
export const ENUMS_APPT_VISIT_TYPE = {
    at_home: {
        id: 'at_home',
        tKey: 'enums.appt.visit_type.at_home',
    },
    at_clinic: {
        id: 'at_clinic',
        tKey: 'enums.appt.visit_type.at_clinic',
    },
    virtual: {
        id: 'virtual',
        tKey: 'enums.appt.visit_type.virtual',
    },
};
