import React from 'react'

import { observer } from 'mobx-react-lite'

import { historyPageLimit } from '@ps/config/events'
import HistoryTable from '@ps/library/historyTable/HistoryTable'
import LabsSearch from '@ps/pages/patient/tabs/labs/LabsSearch'
import LabsTableHead from '@ps/pages/patient/tabs/labs/LabsTableHead'
import LabsTablePage from '@ps/pages/patient/tabs/labs/LabsTablePage'

import { $psHistory } from '@store'

const TabLabs = () => {
  return (
    <>
      <LabsSearch />
      <HistoryTable
        tableItems={$psHistory.table}
        isLoading={$psHistory.isLoading}
        pageLimit={historyPageLimit}
        mode={$psHistory.category}
        headComponent={LabsTableHead}
        pageComponent={LabsTablePage}
      />
    </>
  )
}

export default observer(TabLabs)
