import _ from 'lodash';
import { getUserText, isAbsoluteEmpty } from 'ziphy-web-shared/basic/helpers';
import { CODING_ORG_CONFIG_PREPARED, CODING_SYSTEMS_CONFIG_PREPARED, EXTENSION_SYSTEMS_CONFIG_PREPARED, IDENTIFIER_SYSTEMS_CONFIG_PREPARED, } from './fhir.codings';
import { prepareAddress, prepareCodeableConcept, prepareExtension, prepareFhirValue, prepareIdentifier, prepareTelecom, } from './fhir.converters';
import { findPreparedObject } from './fhir.utils';
export function getGivenFamilyName(name, use = 'official') {
    let found = name;
    if (_.isArray(name)) {
        found = name.find((x) => x.use === use);
        if (!found && name.length) {
            found = name[0];
        }
    }
    const result = {};
    if (found && _.isObject(found)) {
        if ('given' in found) {
            result.firstName = found.given.join(' ');
        }
        if ('family' in found) {
            result.lastName = found.family;
        }
    }
    return result;
}
export function withFullName(item) {
    let result = _.cloneDeep(item) || {};
    const name = getGivenFamilyName(item === null || item === void 0 ? void 0 : item.name);
    result.firstName = (name === null || name === void 0 ? void 0 : name.firstName) || result.firstName || '';
    result.lastName = (name === null || name === void 0 ? void 0 : name.lastName) || result.lastName || '';
    result.fullName = result.fullName || '';
    if (!result.fullName && (result.firstName || result.lastName)) {
        result.fullName = [result.firstName, result.lastName].filter((x) => x).join(' ');
    }
    else if (typeof (item === null || item === void 0 ? void 0 : item.name) === 'string' && !result.fullName) {
        result.fullName = item.name;
    }
    return result;
}
export function getSubjectGender(gender) {
    return {
        code: gender,
        label: getUserText({ gender }, ['gender']),
    };
}
export function getReferenceId({ resourceType, resource, id, }) {
    return [resourceType || resource, id].filter((x) => x).join('/');
}
export function parseReferenceId(reference = '') {
    if (!_.isString(reference)) {
        return { resource: '', id: '' };
    }
    const parsed = reference.split('/');
    return { resource: parsed[0], id: parsed[1] };
}
export function findCodingOrganizationConfigBySystem(system) {
    if (isAbsoluteEmpty(system)) {
        return;
    }
    const result = _.find(CODING_ORG_CONFIG_PREPARED, (x) => {
        return _.includes(system, x.clearedSystem);
    });
    return result === null || result === void 0 ? void 0 : result.config;
}
export function getCodingObjects(data, params = [], useFirst = false) {
    let result = [];
    if (data !== undefined) {
        let array = [];
        if (!Array.isArray(data)) {
            data = [data];
        }
        if (data[0] && 'organizationId' in data[0]) {
            array = data;
        }
        else {
            data.forEach((x) => {
                array = [...array, ...prepareCodeableConcept(x)];
            });
        }
        if (typeof params === 'string') {
            params = { config: params };
        }
        else if (Array.isArray(params)) {
            params = params.map((x) => (typeof x === 'string' ? { config: x } : x));
        }
        result = findPreparedObject(array, params, useFirst, {
            config: {
                data: CODING_SYSTEMS_CONFIG_PREPARED,
                additionalConditions: [
                    ['system', 'in', 'clearedSystem'],
                    ['code', 'eq', 'code'],
                ],
            },
        });
    }
    if (!('first' in result)) {
        Object.defineProperty(result, 'first', {
            value: (params) => {
                return _.head(getCodingObjects(result, params, true));
            },
        });
    }
    if (!('last' in result)) {
        Object.defineProperty(result, 'last', {
            value: (params) => {
                return _.last(getCodingObjects(result, params, true));
            },
        });
    }
    if (!('list' in result)) {
        Object.defineProperty(result, 'list', {
            value: (params, useFirst) => {
                return getCodingObjects(result, params, useFirst);
            },
        });
    }
    return result;
}
export function getIdentifierObjects(data, params = [], useFirst = false) {
    let result = [];
    if (data !== undefined) {
        let array = [];
        if (!Array.isArray(data)) {
            data = [data];
        }
        if (data[0] && 'organizationId' in data[0]) {
            array = data;
        }
        else {
            _.forEach(data, (x) => {
                array = [...array, prepareIdentifier(x)];
            });
        }
        if (typeof params === 'string') {
            params = { config: params };
        }
        else if (Array.isArray(params)) {
            params = params.map((x) => (typeof x === 'string' ? { config: x } : x));
        }
        result = findPreparedObject(array, params, useFirst, {
            config: {
                data: IDENTIFIER_SYSTEMS_CONFIG_PREPARED,
                additionalConditions: [['system', 'in', 'clearedSystem']],
            },
            typeConfig: {
                data: CODING_SYSTEMS_CONFIG_PREPARED,
                additionalConditions: [
                    ['type.system', 'in', 'clearedSystem'],
                    ['type.code', 'eq', 'code'],
                ],
            },
        });
    }
    if (!('first' in result)) {
        Object.defineProperty(result, 'first', {
            value: (params) => {
                return _.head(getIdentifierObjects(result, params, true));
            },
        });
    }
    if (!('last' in result)) {
        Object.defineProperty(result, 'last', {
            value: (params) => {
                return _.last(getIdentifierObjects(result, params, true));
            },
        });
    }
    if (!('list' in result)) {
        Object.defineProperty(result, 'list', {
            value: (params, useFirst) => {
                return getIdentifierObjects(result, params, useFirst);
            },
        });
    }
    return result;
}
export function getExtensionObjects(data, params = [], useFirst = false) {
    let result = [];
    if (data !== undefined) {
        let array = [];
        if (!Array.isArray(data)) {
            data = [data];
        }
        if (data[0] && 'organizationId' in data[0]) {
            array = data;
        }
        else {
            _.forEach(data, (x) => {
                array = [...array, prepareExtension(x)];
            });
        }
        if (typeof params === 'string') {
            params = { config: params };
        }
        else if (Array.isArray(params)) {
            params = params.map((x) => (typeof x === 'string' ? { config: x } : x));
        }
        result = findPreparedObject(array, params, useFirst, {
            config: {
                data: EXTENSION_SYSTEMS_CONFIG_PREPARED,
                additionalConditions: [['url', 'in', 'clearedUrl']],
            },
        });
    }
    if (!('first' in result)) {
        Object.defineProperty(result, 'first', {
            value: (params) => {
                return _.head(getExtensionObjects(result, params, true));
            },
        });
    }
    if (!('last' in result)) {
        Object.defineProperty(result, 'last', {
            value: (params) => {
                return _.last(getExtensionObjects(result, params, true));
            },
        });
    }
    if (!('list' in result)) {
        Object.defineProperty(result, 'list', {
            value: (params, useFirst) => {
                return getExtensionObjects(result, params, useFirst);
            },
        });
    }
    return result;
}
export function getAddressObjects(data, params = [], useFirst = false) {
    let result = [];
    if (data !== undefined) {
        let array = [];
        if (!Array.isArray(data)) {
            data = [data];
        }
        if (data[0] && 'asString' in data[0]) {
            array = data;
        }
        else {
            _.forEach(data, (x) => {
                array = [...array, prepareAddress(x)];
            });
        }
        result = findPreparedObject(array, params, useFirst);
    }
    if (!('first' in result)) {
        Object.defineProperty(result, 'first', {
            value: (params) => {
                return _.head(getAddressObjects(result, params, true));
            },
        });
    }
    if (!('last' in result)) {
        Object.defineProperty(result, 'last', {
            value: (params) => {
                return _.last(getAddressObjects(result, params, true));
            },
        });
    }
    if (!('list' in result)) {
        Object.defineProperty(result, 'list', {
            value: (params, useFirst) => {
                return getAddressObjects(result, params, useFirst);
            },
        });
    }
    return result;
}
export function getTelecomObjects(data, params = [], useFirst = false) {
    let result = [];
    if (data !== undefined) {
        let array = [];
        if (!Array.isArray(data)) {
            data = [data];
        }
        if (data[0] && 'asString' in data[0]) {
            array = data;
        }
        else {
            _.forEach(data, (x) => {
                array = [...array, prepareTelecom(x)];
            });
        }
        result = findPreparedObject(array, params, useFirst);
    }
    if (!('first' in result)) {
        Object.defineProperty(result, 'first', {
            value: (params) => {
                return _.head(getTelecomObjects(result, params, true));
            },
        });
    }
    if (!('last' in result)) {
        Object.defineProperty(result, 'last', {
            value: (params) => {
                return _.last(getTelecomObjects(result, params, true));
            },
        });
    }
    if (!('list' in result)) {
        Object.defineProperty(result, 'list', {
            value: (params, useFirst) => {
                return getTelecomObjects(result, params, useFirst);
            },
        });
    }
    return result;
}
export function isCoding(obj, strict = false) {
    if (strict) {
        return (obj &&
            Object.keys(obj).length === 3 &&
            ['code', 'display', 'system'].every((x) => _.has(obj, x)));
    }
    return _.has(obj, 'system') && ['code', 'display'].some((x) => _.has(obj, x));
}
export function isCodeableConcept(obj) {
    return (obj &&
        Object.keys(obj).length > 0 &&
        (Array.isArray(obj === null || obj === void 0 ? void 0 : obj.coding) || typeof (obj === null || obj === void 0 ? void 0 : obj.text) === 'string'));
}
export function getObservationValues(r) {
    let values = [];
    if ('component' in r && typeof r.component !== 'undefined') {
        _.forEach(r.component, (item) => {
            const obj = getObservationValues(item);
            if (obj)
                values = [...values, ...obj];
        });
    }
    else {
        const obj = prepareFhirValue(r);
        if (obj)
            values = [...values, obj];
    }
    return values.filter((x) => !isAbsoluteEmpty(x.value) || !isAbsoluteEmpty(x.asString));
}
