var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import _ from 'lodash';
import queryString from 'query-string';
import { myackAxiosInstance } from 'ziphy-web-shared/basic/api';
import { $auth } from 'ziphy-web-shared/basic/entities/auth';
import { sessionStore } from 'ziphy-web-shared/basic/utils';
import { parseReferenceId } from '@fhir/fhir.helpers';
import { getPsPatientId, getPsPracticeId } from '@ps/services/psService.helpers';
import mainConfig from '@config/main';
export function mediaAuthService({ patientId = getPsPatientId(), practiceId = getPsPracticeId(), } = {}) {
    const id = `mediaAuth.${[patientId, practiceId].filter((x) => x).join('_')}`;
    return {
        get(full = false) {
            const result = sessionStore.get(id, {});
            if (!full) {
                return result.params;
            }
            return result;
        },
        set(params) {
            sessionStore.set(id, {
                date: Date.now() / 1000,
                timeout: 60 * 3,
                params,
            });
        },
        fetch(references = []) {
            return __awaiter(this, void 0, void 0, function* () {
                if (references.length && !checkIfNeedLoad(references)) {
                    return;
                }
                if (checkIfValid(this.get(true))) {
                    return;
                }
                const res = yield getMediaAuth({ patientId, practiceId });
                if (res) {
                    this.set(res);
                }
            });
        },
        getUrlWithAuth(url) {
            if (checkIfValid(this.get(true))) {
                const queryParams = this.get();
                if (_.isEmpty(queryParams))
                    return;
                return queryString.stringifyUrl({ url, query: queryParams });
            }
            return url;
        },
    };
}
// todo: use apiService from @services
function getMediaAuth({ patientId, practiceId, }) {
    return __awaiter(this, void 0, void 0, function* () {
        const accessToken = yield $auth.getAccessToken();
        if (!accessToken)
            return false;
        let result;
        try {
            let params = {
                patient_id: patientId || getPsPatientId(),
                practice_id: practiceId || getPsPracticeId(),
            };
            const host = mainConfig.api.baseUrl.replace('/myack-rpc/', '');
            const url = queryString.stringifyUrl({
                url: `${host}/hooks/zmr/${mainConfig.appType}/media/authorize`,
                query: params,
            });
            const response = yield myackAxiosInstance.get(url, {
                headers: { 'X-Access-Token': accessToken },
            });
            result = response === null || response === void 0 ? void 0 : response.data;
        }
        catch (e) { }
        return result;
    });
}
function checkIfNeedLoad(list = []) {
    let isFound = false;
    _.forEach(list, (x) => {
        if (['Media', 'DocumentReference'].includes(parseReferenceId(x).resource)) {
            isFound = true;
            return false;
        }
    });
    return isFound;
}
function checkIfValid(data) {
    const isNotExpired = Date.now() / 1000 - data.date < data.timeout;
    const hasParams = !_.isEmpty(data.params);
    return isNotExpired && hasParams;
}
