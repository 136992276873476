import React from 'react'

import { observer } from 'mobx-react-lite'

import { historyPageLimit } from '@ps/config/events'
import HistoryTable from '@ps/library/historyTable/HistoryTable'
import MedicalHistorySearch from '@ps/pages/patient/tabs/medicalHistory/MedicalHistorySearch'
import MedicalHistoryTableHead from '@ps/pages/patient/tabs/medicalHistory/MedicalHistoryTableHead'
import MedicalHistoryTablePage from '@ps/pages/patient/tabs/medicalHistory/MedicalHistoryTablePage'

import { $psHistory } from '@store'

const TabMedicalHistory = () => {
  return (
    <>
      <MedicalHistorySearch />
      <HistoryTable
        tableItems={$psHistory.table}
        isLoading={$psHistory.isLoading}
        pageLimit={historyPageLimit}
        mode={$psHistory.category}
        headComponent={MedicalHistoryTableHead}
        pageComponent={MedicalHistoryTablePage}
      />
    </>
  )
}

export default observer(TabMedicalHistory)
