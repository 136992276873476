import { $router } from 'ziphy-web-shared/basic/utils/router'

import { routeActions as routeCoreActions } from '@core/src/config/routeActions'

// () => ({to, href, wvAction, onClick})
export const routeActions = {
  ROOT: () => ({
    to: $router.toPath('home'),
  }),
  HOME: () => ({
    to: $router.toPath('home'),
  }),
  BOOK_START: () => ({
    to: $router.toPath('home'),
  }),
  BOOK_ADDRESS: () => ({
    to: $router.toPath('bookAddress'),
  }),
  BOOK_PATIENTS: () => ({
    to: $router.toPath('bookPatients'),
  }),
  BOOK_SYMPTOMS: () => ({
    to: $router.toPath('bookSymptoms'),
  }),
  BOOK_DATE: () => ({
    to: $router.toPath('bookDate'),
  }),
  BOOK_FINISH: () => ({
    to: $router.toPath('bookFinish'),
  }),
  APPTS: () => ({
    to: $router.toPath('appts'),
  }),
  APPT: routeCoreActions.APPT,
  PROFILE: () => ({
    to: $router.toPath('profile'),
  }),
  PATIENTS: () => ({
    to: $router.toPath('patients'),
  }),
  PATIENT: ({ patientId }) => ({
    to: $router.toPath('patient', { params: { patientId } }),
  }),
  PS_PATIENT: ({ patientId, practiceId, categoryId = undefined }, searchParams = {}) => ({
    to: $router.toPath('psPatient', {
      params: { patientId, practiceId, categoryId },
      query: searchParams,
    }),
  }),
  PS_PATIENTS: () => ({
    //
  }),
  PLACES: () => ({
    to: $router.toPath('places'),
  }),
  PMS: () => ({
    to: $router.toPath('pms'),
  }),
  SIGNOUT: () => ({
    to: $router.toPath('signout'),
  }),
}
