import _ from 'lodash';
import AsStringTableDetails from '@ps/library/eventsViewer/tableDetails/AsStringTableDetails';
import BaseDocumentsModal from '@ps/library/modals/BaseDocuments.modal';
export const documentPersonalTypes = {
    document_driver_license: [
        'driver_license_back_document_type',
        'driver_license_front_document_type',
    ],
    document_military_id: ['military_id_back_document_type', 'military_id_front_document_type'],
    document_state_id: ['state_id_back_document_type', 'state_id_front_document_type'],
    document_permanent_resident_card: [
        'permanent_resident_card_back_document_type',
        'permanent_resident_card_front_document_type',
    ],
    document_passport: ['passport_back_document_type', 'passport_front_document_type'],
    document_school_id: ['school_id_back_document_type', 'school_id_front_document_type'],
    document_private_health_insurance: [
        'private_health_insurance_back_document_type',
        'private_health_insurance_front_document_type',
    ],
    document_public_health_insurance: [
        'public_health_insurance_back_document_type',
        'public_health_insurance_front_document_type',
    ],
    document_other_insurance: [
        'other_insurance_back_document_type',
        'other_insurance_front_document_type',
    ],
    document_unspecified_insurance: ['unspecified_insurance_document_type'],
    document_medicare: ['medicare_back_document_type', 'medicare_front_document_type'],
    document_medicaid: ['medicaid_back_document_type', 'medicaid_front_document_type'],
    document_advance_directive: ['advance_directive_document_type'],
    document_power_of_attorney_for_hc: ['power_of_attorney_for_hc_document_type'],
    document_living_will: ['living_will_document_type'],
};
export const documentFormTypes = {
    document_intake_form: ['intake_form_document_type'],
    document_hipaa_consent: ['hipaa_consent_document_type'],
    document_telehealth_consent: ['telehealth_consent_document_type'],
    document_personal_intake: ['personal_intake_document_type'],
};
let config = {};
_.forEach(Object.assign(Object.assign({}, documentPersonalTypes), documentFormTypes), (types, key) => {
    config[key] = {
        eventModal: BaseDocumentsModal,
        condition: [
            { field: 'resource', eq: 'DocumentReference' },
            { field: 'subtype', sin: types },
        ],
        rowType: 'documents',
        viewer: {
            tableDetails: AsStringTableDetails,
        },
    };
});
config.document_symptoms = {
    condition: [
        { field: 'resource', eq: 'DocumentReference' },
        { field: 'subtype', in: ['symptoms_form_document_type'] },
    ],
};
// Other
config.document_other = {
    eventModal: BaseDocumentsModal,
    condition: [
        { field: 'resource', eq: 'DocumentReference' },
        {
            field: 'subtype',
            nin: [
                ..._.flatten(Object.values(documentPersonalTypes)),
                ..._.flatten(Object.values(documentFormTypes)),
                'symptoms_form_document_type',
            ],
        },
    ],
    rowType: 'documents',
    viewer: {
        tableDetails: AsStringTableDetails,
    },
};
// Media
config.document_media = {
    eventModal: BaseDocumentsModal,
    condition: [
        { field: 'resource', eq: 'Media' },
        // { field: 'subtype', in: ['document', 'media'] },
    ],
    rowType: 'documents',
    viewer: {
        tableDetails: AsStringTableDetails,
    },
};
export const documentsConfig = config;
