import _ from 'lodash';
function deepToJS(value, deep = 30) {
    if (deep <= 0) {
        return value;
    }
    deep--;
    if (_.isArray(value)) {
        value = value.map((x) => deepToJS(x, deep));
    }
    else if (_.isMap(value)) {
        const tmp = [];
        value.forEach((v, k) => tmp.push({ mapId: k, value: deepToJS(v, deep) }));
        return tmp;
    }
    else if (_.isSet(value)) {
        const tmp = [];
        value.forEach((v, k) => tmp.push({ mapId: k, value: deepToJS(v, deep) }));
        return deepToJS(Array.from(value), deep);
    }
    else if (_.isObject(value)) {
        return _.mapValues(value, (x) => deepToJS(x, deep));
    }
    return value;
}
console.js = function consoleJsCustom(...args) {
    console.log(...deepToJS(args));
};
