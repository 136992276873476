import { useEffect } from 'react';
import { makeAutoObservable } from 'mobx';
import { isMobile, isMobileOnly, isTablet } from 'react-device-detect';
const defaultWidth = { xs: 0, sm: 576, md: 768, lg: 992, xl: 1200 };
const defaultHeight = { xs: 0, sm: 360, md: 700, lg: 800, xl: 1080 };
class WindowSizeStore {
    constructor(props) {
        this.breakpoints = { width: defaultWidth, height: defaultHeight };
        this.width = window.innerWidth;
        this.height = window.innerHeight;
        if (props === null || props === void 0 ? void 0 : props.width) {
            this.breakpoints.width = Object.assign(Object.assign({}, this.breakpoints.width), props.width);
        }
        if (props === null || props === void 0 ? void 0 : props.height) {
            this.breakpoints.height = Object.assign(Object.assign({}, this.breakpoints.height), props.height);
        }
        makeAutoObservable(this);
    }
    handleResize() {
        this.width = window.innerWidth;
        this.height = window.innerHeight;
    }
    get isXS() {
        return this.width >= this.breakpoints.width.xs && this.width < this.breakpoints.width.sm;
    }
    get isSM() {
        return this.width >= this.breakpoints.width.sm && this.width < this.breakpoints.width.md;
    }
    get isMD() {
        return this.width >= this.breakpoints.width.md && this.width < this.breakpoints.width.lg;
    }
    get isLG() {
        return this.width >= this.breakpoints.width.lg && this.width < this.breakpoints.width.xl;
    }
    get isXL() {
        return this.width >= this.breakpoints.width.xl;
    }
    get isMobileSize() {
        return this.maxLG;
    }
    get isMobile() {
        return isMobile || this.isMobileSize;
    }
    get isMobileOnly() {
        return isMobileOnly || this.maxMD;
    }
    get isTabletSize() {
        return this.maxXL;
    }
    get isTablet() {
        return isTablet || this.isTabletSize;
    }
    get minXS() {
        return this.width >= this.breakpoints.width.xs;
    }
    get minSM() {
        return this.width >= this.breakpoints.width.sm;
    }
    get minMD() {
        return this.width >= this.breakpoints.width.md;
    }
    get minLG() {
        return this.width >= this.breakpoints.width.lg;
    }
    get minXL() {
        return this.width >= this.breakpoints.width.xl;
    }
    get maxXS() {
        return this.width < this.breakpoints.width.xs;
    }
    get maxSM() {
        return this.width < this.breakpoints.width.sm;
    }
    get maxMD() {
        return this.width < this.breakpoints.width.md;
    }
    get maxLG() {
        return this.width < this.breakpoints.width.lg;
    }
    get maxXL() {
        return this.width < this.breakpoints.width.xl;
    }
    get hMinXS() {
        return this.height >= this.breakpoints.height.xs;
    }
    get hMinSM() {
        return this.height >= this.breakpoints.height.sm;
    }
    get hMinMD() {
        return this.height >= this.breakpoints.height.md;
    }
    get hMinLG() {
        return this.height >= this.breakpoints.height.lg;
    }
    get hMinXL() {
        return this.height >= this.breakpoints.height.xl;
    }
    get hMaxXS() {
        return this.height < this.breakpoints.height.xs;
    }
    get hMaxSM() {
        return this.height < this.breakpoints.height.sm;
    }
    get hMaxMD() {
        return this.height < this.breakpoints.height.md;
    }
    get hMaxLG() {
        return this.height < this.breakpoints.height.lg;
    }
    get hMaxXL() {
        return this.height < this.breakpoints.height.xl;
    }
}
export let $windowSize = new WindowSizeStore();
export const useInitWindowSize = (props) => {
    useEffect(() => {
        if (props) {
            $windowSize = new WindowSizeStore(props);
        }
        let animationFrameId = null;
        function handleResize() {
            if (animationFrameId)
                cancelAnimationFrame(animationFrameId);
            animationFrameId = requestAnimationFrame(() => $windowSize.handleResize());
        }
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
            if (animationFrameId)
                cancelAnimationFrame(animationFrameId);
        };
    }, []);
};
