import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { forwardRef, useEffect, useMemo } from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import { useScrollToDependency } from 'ziphy-web-shared/basic/hooks';
import { ProgressRing } from 'ziphy-web-shared/basic/lib/loaders';
import DayPickerCalendar from '../dayPicker/components/dayPickerCalendar/DayPickerCalendar';
import { changeDateMonth, getCalendarRows } from '../dayPicker/DayPicker.helpers';
import { getScrollToDate } from '../NewDatePicker.helpers';
import { useListRef } from '../NewDatePicker.hooks';
const DayListPicker = forwardRef(({ currentDate, selectedDate, setDate, minDate, maxDate, shownOtherDays = false, availableDates, disabledDates, scrollTo, hasPrevLoadTrigger, hasNextLoadTrigger, }, ref) => {
    const minDateString = minDate.format();
    const maxDateString = maxDate.format();
    const board = useMemo(() => {
        const result = [];
        let dateModel = minDate.clone();
        while (dateModel.isBefore(maxDate, 'month') || dateModel.isSame(maxDate, 'month')) {
            const month = getCalendarRows(dateModel, shownOtherDays);
            // Filter empty week in month
            const filteredMonth = month.map((week) => {
                let emptyRow = true;
                week.forEach((day) => {
                    emptyRow = emptyRow && !day.text;
                });
                return !emptyRow ? week : null;
            });
            _.remove(filteredMonth, (item) => !item);
            result.push({ model: dateModel, board: filteredMonth });
            dateModel = changeDateMonth(dateModel, true);
        }
        return result;
    }, [maxDateString, minDateString]); // eslint-disable-line react-hooks/exhaustive-deps
    const [refDay, setRefDay] = useListRef();
    // Scroll to day
    useEffect(() => {
        const scrollToDate = getScrollToDate(selectedDate, currentDate, minDate, maxDate, scrollTo);
        const key = scrollToDate.format('YYYYMMDD');
        if (key in refDay) {
            refDay[key].scrollIntoView({ block: 'center' });
        }
    }, [selectedDate, scrollTo]); // eslint-disable-line react-hooks/exhaustive-deps
    // Fix scroll after adding elements to start
    const { scrollRef, contentRef } = useScrollToDependency({
        scrollDependency: minDateString,
        changeContentDependency: maxDateString,
    });
    const getHeader = () => {
        return board && board.length ? board[0].board[0] : [];
    };
    return (_jsxs("div", Object.assign({ className: "day-list-picker" }, { children: [_jsx("div", Object.assign({ className: "header" }, { children: getHeader().map(({ value }, i) => (_jsx("div", Object.assign({ className: 'cell' }, { children: value.format('ddd') }), i))) })), _jsx("div", Object.assign({ className: classNames('body', 'styled_scroll'), ref: scrollRef }, { children: _jsxs("div", Object.assign({ ref: contentRef }, { children: [hasPrevLoadTrigger && (
                        //       @ts-ignore
                        _jsx("div", Object.assign({ ref: ref.prevLoaderRef, className: "box-body box-body--padding text-center" }, { children: _jsx(ProgressRing, { className: "", progress: 25, spin: true }) }))), board.map((item, i) => (_jsx(DayPickerCalendar, { currentDate: currentDate, selectedDate: selectedDate, model: item.model, setDate: setDate, minDate: minDate, maxDate: maxDate, availableDays: availableDates === null || availableDates === void 0 ? void 0 : availableDates.day, disabledDays: disabledDates === null || disabledDates === void 0 ? void 0 : disabledDates.day, board: item.board, dayWeek: false, nameMonth: true, setRefDay: setRefDay }, i))), hasNextLoadTrigger && (
                        //       @ts-ignore
                        _jsx("div", Object.assign({ ref: ref.nextLoaderRef, className: "box-body box-body--padding text-center" }, { children: _jsx(ProgressRing, { className: "", progress: 25, spin: true }) })))] })) }))] })));
});
export default DayListPicker;
