import { toNumberIfPossible } from 'ziphy-web-shared/basic/helpers';
import { $router } from 'ziphy-web-shared/basic/utils/router';
export function getPsPatientId() {
    let id;
    id = $router.params.patientId;
    if (!id)
        id = $router.searchParams.get('patientId');
    return toNumberIfPossible(id);
}
export function getPsPracticeId() {
    let id;
    id = $router.params.practiceId;
    if (!id)
        id = $router.searchParams.get('practiceId');
    return toNumberIfPossible(id);
}
