import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import styles from './ShowMoreText.module.scss';
const ShowMoreText = ({ children, lines = 5, // max 20, see styles
expanded: _expanded = false, toggleByAll = false, className = '', buttonMore = '', buttonLess = '', buttonClassName = '', showLessButton = true, }) => {
    const { t } = useTranslation();
    const ref = useRef(null);
    const [expanded, setExpanded] = useState(_expanded);
    const [canClamped, setCanClamped] = useState(false);
    useLayoutEffect(() => {
        if (!ref.current)
            return;
        if (ref.current.scrollHeight > ref.current.clientHeight) {
            setCanClamped(true);
        }
    }, [expanded]);
    useEffect(() => {
        setExpanded(_expanded);
    }, [_expanded]);
    const canToggleByAll = toggleByAll && (showLessButton || (!showLessButton && !expanded));
    function handleToggle() {
        setExpanded(!expanded);
    }
    function clickOnWrapper() {
        if (canToggleByAll) {
            handleToggle();
        }
    }
    function clickOnButton(e) {
        if (!toggleByAll) {
            e.stopPropagation();
            handleToggle();
        }
    }
    return (_jsxs("div", Object.assign({ className: classNames(className, canClamped && canToggleByAll && styles.cursorPointer), onClick: clickOnWrapper }, { children: [_jsx("div", Object.assign({ ref: ref, className: classNames(styles.inner, expanded && styles.expanded, !expanded && lines > 0 && styles['lines' + lines]) }, { children: children })), canClamped && !expanded ? (_jsx("span", Object.assign({ className: classNames(buttonClassName, styles.button, 'a'), onClick: clickOnButton }, { children: buttonMore || t('btn.show_more') }))) : (expanded &&
                canClamped &&
                showLessButton && (_jsx("span", Object.assign({ className: classNames(buttonClassName, styles.button, 'a'), onClick: clickOnButton }, { children: buttonLess || t('btn.show_less') }))))] })));
};
export default ShowMoreText;
