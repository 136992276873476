import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';
import { $windowSize } from 'ziphy-web-shared/basic/utils';
import styles from './dtl.module.scss';
const DtlGroup = ({ className, children, asList = $windowSize.maxLG }) => {
    return (_jsx("div", Object.assign({ className: classNames(styles.group, asList && 'dtl-group--as-list', className) }, { children: children })));
};
export default observer(DtlGroup);
