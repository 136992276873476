import moment from 'moment-timezone';
export const useListRef = () => {
    const ref = {};
    const setRef = (date, format) => {
        const key = moment(date).format(format);
        return (element) => {
            if (!(key in ref)) {
                ref[key] = element;
            }
        };
    };
    return [ref, setRef];
};
