import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatDate } from 'ziphy-web-shared/basic/utils/time';
import RoleName from '@ps/library/RoleName';
import ViewCode from '@ps/library/viewCode/ViewCode';
const ObservationExaminationDetails = ({ event, extractor }) => {
    const { t } = useTranslation();
    const { asString, values, codeObj, subject, performer } = extractor;
    return (_jsxs("div", Object.assign({ className: "event_details" }, { children: [_jsxs("div", Object.assign({ className: "event_details-header" }, { children: [_jsx("div", Object.assign({ className: "event_details-title" }, { children: asString.full })), _jsx("div", Object.assign({ className: "event_details-code" }, { children: _jsx(ViewCode, { codeObj: codeObj }) }))] })), _jsxs("div", Object.assign({ className: "event_details-body" }, { children: [values.map((item) => (_jsxs("div", Object.assign({ className: "event_details-line mb-10" }, { children: [_jsxs("span", Object.assign({ className: "font-weight-700" }, { children: [item.label, ":"] })), " ", item.value] }), item.label + item.value))), asString.notes && (_jsxs("div", Object.assign({ className: "event_details-line mb-10" }, { children: [_jsx("span", Object.assign({ className: "font-weight-700" }, { children: t('ps.event_viewer.note:') })), " ", asString.notes] }))), subject && (_jsxs("div", Object.assign({ className: "event_details-line" }, { children: [t('ps.event_viewer.subject:'), " ", subject.name.fullName] }))), performer && (_jsxs("div", Object.assign({ className: "event_details-line" }, { children: [t('ps.event_viewer.performer:'), " ", performer.name.fullName, ' ', _jsx(RoleName, { role: performer.role })] }))), _jsx("div", Object.assign({ className: "event_details-line event_details-line--date" }, { children: formatDate(event.effectiveDate) }))] }))] })));
};
export default ObservationExaminationDetails;
