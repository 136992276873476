import { useEffect } from 'react';
import _ from 'lodash';
import { isJson, isUrl } from 'ziphy-web-shared/basic/helpers';
class WebView {
    constructor(props) {
        var _a, _b, _c;
        this.isEnabled = false;
        this.isTest = false;
        this.prefix = 'DefaultPrefix';
        this.postMessage = (source, payload = {}) => {
            var _a;
            if (!this.isWebView)
                return;
            (_a = window.ReactNativeWebView) === null || _a === void 0 ? void 0 : _a.postMessage(JSON.stringify({ source, payload }));
        };
        this.goTo = (event, screen = '', params = {}) => {
            if (!this.isWebView)
                return;
            if (_.isObject(event) && _.isFunction(event.preventDefault)) {
                event.preventDefault();
            }
            this.postMessage(this.prefix + 'GoTo', { screen, params });
        };
        this.close = (event) => {
            if (!this.isWebView)
                return;
            if (_.isObject(event) && _.isFunction(event.preventDefault)) {
                event.preventDefault();
            }
            this.postMessage(this.prefix + 'Close');
        };
        this.anchorClickHandler = (event) => {
            if (!this.isWebView)
                return;
            const anchor = event.target.closest('a');
            if (anchor !== null) {
                const href = anchor.getAttribute('href');
                const target = anchor.getAttribute('target');
                if (isUrl(href) || _.includes(href, 'mailto:') || _.includes(href, 'tel:')) {
                    event.preventDefault();
                    this.postMessage(this.prefix + 'AnchorClick', { href, target });
                }
            }
        };
        this.log = (payload) => {
            if (!this.isWebView)
                return;
            this.postMessage(this.prefix + 'ConsoleLog', payload);
        };
        this.isEnabled = (_a = props === null || props === void 0 ? void 0 : props.isEnabled) !== null && _a !== void 0 ? _a : this.isEnabled;
        this.isTest = (_b = props === null || props === void 0 ? void 0 : props.isTest) !== null && _b !== void 0 ? _b : this.isTest;
        this.prefix = (_c = props === null || props === void 0 ? void 0 : props.prefix) !== null && _c !== void 0 ? _c : this.prefix;
    }
    get isWebView() {
        return (!!window.hasOwnProperty('ReactNativeWebView') && this.isEnabled) || this.isTest;
    }
}
export let webView = new WebView();
export const initWebView = (props) => {
    webView = new WebView(props);
};
export const useWebViewAnchorClickHandler = (deps = []) => {
    useEffect(() => {
        if (webView.isWebView) {
            document.addEventListener('click', webView.anchorClickHandler);
        }
        return () => {
            if (webView.isWebView) {
                document.removeEventListener('click', webView.anchorClickHandler);
            }
        };
    }, deps);
};
export const useWebViewListenPostMessage = (source, callback, deps = []) => {
    useEffect(() => {
        function handle(event) {
            if (event.origin !== window.location.origin) {
                return;
            }
            const data = isJson(event.data) ? JSON.parse(event.data) : event.data;
            if ((data === null || data === void 0 ? void 0 : data.source) !== source) {
                return;
            }
            callback({ payload: (data === null || data === void 0 ? void 0 : data.payload) || {} });
        }
        window.addEventListener('message', handle);
        return () => window.removeEventListener('message', handle);
    }, [...deps]);
};
