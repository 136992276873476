export const MEDICATIONS_ACTIVE_STATUSES = ['active', 'intended'];
export const ENUMS_MEDICATIONS_STATUSES = {
    active: {
        id: 'active',
        tKey: 'enums.medications.active',
        tKeyDesc: 'enums.medications.active.additional_text',
    },
    completed: {
        id: 'completed',
        tKey: 'enums.medications.completed',
        tKeyDesc: 'enums.medications.completed.additional_text',
    },
    'entered-in-error': {
        id: 'entered-in-error',
        tKey: 'enums.medications.entered_in_error',
        tKeyDesc: 'enums.medications.entered_in_error.additional_text',
    },
    intended: {
        id: 'intended',
        tKey: 'enums.medications.intended',
        tKeyDesc: 'enums.medications.intended.additional_text',
    },
    stopped: {
        id: 'stopped',
        tKey: 'enums.medications.stopped',
        tKeyDesc: 'enums.medications.stopped.additional_text',
    },
    'on-hold': {
        id: 'on-hold',
        tKey: 'enums.medications.on_hold',
        tKeyDesc: 'enums.medications.on_hold.additional_text',
    },
    unknown: {
        id: 'unknown',
        tKey: 'enums.medications.unknown',
        tKeyDesc: 'enums.medications.unknown.additional_text',
    },
    'not-taken': {
        id: 'not-taken',
        tKey: 'enums.medications.not_taken',
        tKeyDesc: 'enums.medications.not_taken.additional_text',
    },
};
export const ENUMS_MEDICATIONS_DERIVATION_REASONS = {
    increase: {
        id: 'increase',
        tKey: 'enums.medications.increase',
    },
    decrease: {
        id: 'decrease',
        tKey: 'enums.medications.decrease',
    },
    replace: {
        id: 'replace',
        tKey: 'enums.medications.replace',
    },
};
export const ENUMS_MEDICATIONS_PRESCTIPTION_STATUSES = {
    draft: {
        id: 'increase',
        tKey: 'label.increase',
    },
    active: {
        id: 'active',
        tKey: 'label.active',
    },
    completed: {
        id: 'completed',
        tKey: 'label.completed',
    },
    'entered-in-error': {
        id: 'entered-in-error',
        tKey: 'label.entered_in_error',
    },
    stopped: {
        id: 'stopped',
        tKey: 'label.stopped',
    },
};
export const ENUMS_MEDICATIONS_UNITS = {
    milligrams: {
        id: 'milligrams',
        tKey: 'ps.notes.medications.dosage.label.milligrams',
    },
    grams: {
        id: 'grams',
        tKey: 'ps.notes.medications.dosage.label.grams',
    },
    micrograms: {
        id: 'micrograms',
        tKey: 'ps.notes.medications.dosage.label.micrograms',
    },
    milliliters: {
        id: 'milliliters',
        tKey: 'ps.notes.medications.dosage.label.milliliters',
    },
    liters: {
        id: 'milligrams',
        tKey: 'ps.notes.medications.dosage.label.liters',
    },
    drops: {
        id: 'drops',
        tKey: 'ps.notes.medications.dosage.label.drops',
    },
    percent: {
        id: 'percent',
        tKey: 'ps.notes.medications.dosage.label.percent',
    },
    other: {
        id: 'other',
        tKey: 'ps.notes.medications.dosage.label.other',
    },
};
export const ENUMS_MEDICATIONS_ROUTE = {
    oral: {
        id: 'oral',
        tKey: 'ps.notes.medications.route.label.oral',
    },
    sublingual: {
        id: 'sublingual',
        tKey: 'ps.notes.medications.route.label.sublingual',
    },
    patch: {
        id: 'patch',
        tKey: 'ps.notes.medications.route.label.patch',
    },
    injection: {
        id: 'injection',
        tKey: 'ps.notes.medications.route.label.injection',
    },
    rectal: {
        id: 'rectal',
        tKey: 'ps.notes.medications.route.label.rectal',
    },
    topical: {
        id: 'topical',
        tKey: 'ps.notes.medications.route.label.topical',
    },
    inhalation: {
        id: 'inhalation',
        tKey: 'ps.notes.medications.route.label.inhalation',
    },
    intranasal: {
        id: 'intranasal',
        tKey: 'ps.notes.medications.route.label.intranasal',
    },
    eye_drops: {
        id: 'eye_drops',
        tKey: 'ps.notes.medications.route.label.eye_drops',
    },
};
export const ENUMS_MEDICATIONS_SITE = {
    left: {
        id: 'left',
        tKey: 'ps.notes.medications.label.side_left',
    },
    right: {
        id: 'right',
        tKey: 'ps.notes.medications.label.side_right',
    },
    both: {
        id: 'both',
        tKey: 'ps.notes.medications.label.side_both',
    },
};
export const ENUMS_MEDICATIONS_FREQUENCY = {
    once_daily: {
        id: 'once_daily',
        tKey: 'ps.notes.medications.frequency.label.once_daily',
    },
    twice_a_day: {
        id: 'twice_a_day',
        tKey: 'ps.notes.medications.frequency.label.twice_a_day',
    },
    three_times_a_day: {
        id: 'three_times_a_day',
        tKey: 'ps.notes.medications.frequency.label.three_times_a_day',
    },
    four_times_a_day: {
        id: 'four_times_a_day',
        tKey: 'ps.notes.medications.frequency.label.four_times_a_day',
    },
    every_evening: {
        id: 'every_evening',
        tKey: 'ps.notes.medications.frequency.label.every_evening',
    },
    every_other_day: {
        id: 'every_other_day',
        tKey: 'ps.notes.medications.frequency.label.every_other_day',
    },
    every_six_hours: {
        id: 'every_six_hours',
        tKey: 'ps.notes.medications.frequency.label.every_six_hours',
    },
    every_eight_hours: {
        id: 'every_eight_hours',
        tKey: 'ps.notes.medications.frequency.label.every_eight_hours',
    },
    once_a_week: {
        id: 'once_a_week',
        tKey: 'ps.notes.medications.frequency.label.once_a_week',
    },
    once_a_month: {
        id: 'once_a_month',
        tKey: 'ps.notes.medications.frequency.label.once_a_month',
    },
    every_three_days: {
        id: 'every_three_days',
        tKey: 'ps.notes.medications.frequency.label.every_three_days',
    },
    other: {
        id: 'other',
        tKey: 'ps.notes.medications.frequency.label.other',
    },
};
