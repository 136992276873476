import 'react-loading-skeleton/dist/skeleton.css';
export { initServices } from 'ziphy-web-shared/basic/api';
export { initWebView, useInitWindowSize } from 'ziphy-web-shared/basic/utils';
export { initAnalytics } from 'ziphy-web-shared/basic/utils/analytics';
export { initI18N } from 'ziphy-web-shared/basic/utils/i18n';
export { initRouter, prepareRoutesList } from 'ziphy-web-shared/basic/utils/router';
let config;
export function setInitConfig(props) {
    config = props;
}
export function getInitConfig() {
    return config;
}
