import { getInitConfig } from 'ziphy-web-shared';
export const getEnv = () => {
    return getInitConfig().appEnv;
};
export const getStagedEnv = () => {
    const env = getEnv();
    return ['dev', 'staging'].includes(getEnv()) ? 'staging' : env;
};
export const isLocal = (strict = false) => {
    return getEnv() === 'local' || (!strict && window.location.hostname === 'localhost');
};
export const isDev = () => {
    return getEnv() === 'dev';
};
export const isStage = () => {
    return getEnv() === 'staging';
};
export const isProd = () => {
    return getEnv() === 'production';
};
export const PUBLIC_URL = process.env.PUBLIC_URL;
export const APP_BRAND = process.env.REACT_APP_BRAND;
export const APP_TYPE = process.env.REACT_APP_TYPE;
export const APP_VERSION = process.env.REACT_APP_VERSION;
export const APP_REVISION = process.env.REACT_APP_COMMIT_HASH;
export const isClientApp = () => {
    return APP_TYPE === 'client';
};
export const isProviderApp = () => {
    return APP_TYPE === 'provider';
};
export const isStaffApp = () => {
    return ['provider' /*, 'agent' */].includes(APP_TYPE);
};
export const isExternalApp = () => {
    return APP_TYPE === 'external';
};
