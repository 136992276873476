var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import { schedulesService } from 'ziphy-web-shared/basic/api';
import { useLoader } from 'ziphy-web-shared/basic/hooks';
import { $time } from 'ziphy-web-shared/basic/utils/time';
import mainConfig from '@config/main';
import DaySlotPicker from './daySlotPicker/DaySlotPicker';
import styles from './TimeSlotPicker.module.scss';
import WindowSlotPicker from './windowSlotPicker/WindowSlotPicker';
const defaultBegin = moment().utc();
const defaultEnd = defaultBegin.clone().add(7, 'day');
const getDefaultItems = Object.assign((visibleDaysCount) => {
    if (getDefaultItems._cache[visibleDaysCount]) {
        return getDefaultItems._cache[visibleDaysCount];
    }
    const result = [];
    for (let i = 0; i < visibleDaysCount; i++) {
        result.push({ key: defaultBegin.clone().add(i, 'day').format('YYYY-MM-DD'), value: false });
    }
    getDefaultItems._cache[visibleDaysCount] = result;
    return getDefaultItems._cache[visibleDaysCount];
}, { _cache: {} });
const TimeSlotPicker = ({ value, onChange, requestParams, placeTimezone, visibleDaysCount = 7, cellNumberInRow = {
    desktop: 6,
    tablet: 4,
    mobile: 3,
}, }) => {
    const { t } = useTranslation();
    const [stat, setStat] = useState(null);
    const [windows, setWindows] = useState(null);
    const [selectedDay, setSelectedDay] = useState(null);
    // Infinity Loading
    const loadPages = useRef([]);
    const nextPage = useRef(0);
    const prevPage = useRef(-1);
    const pagesList = useMemo(() => {
        const result = [];
        const now = moment.utc();
        const minDate = now.startOf('day');
        const maxDate = minDate.clone().add(mainConfig.booking.timePickerDaysMaxLimit, 'day');
        const pageLimit = Math.ceil(mainConfig.booking.timePickerDaysMaxLimit / mainConfig.booking.timePickerDaysLoadNumber);
        const prevSelectedDate = !selectedDay && (value === null || value === void 0 ? void 0 : value.key) ? value === null || value === void 0 ? void 0 : value.key : null;
        for (let page = 0; page < pageLimit; page++) {
            const begin = minDate.clone().add(page * mainConfig.booking.timePickerDaysLoadNumber, 'day');
            let end = begin.clone().add(mainConfig.booking.timePickerDaysLoadNumber, 'day');
            if (end > maxDate) {
                end = maxDate;
            }
            if (prevSelectedDate && begin.isBefore(prevSelectedDate) && end.isAfter(prevSelectedDate)) {
                nextPage.current = page;
                prevPage.current = page - 1;
            }
            result.push({
                begin: `$date:${begin.format('YYYY-MM-DDTHH:mm:ss')}`,
                end: `$date:${end.format('YYYY-MM-DDTHH:mm:ss')}`,
            });
        }
        return result;
    }, []);
    const loadPrevPage = () => {
        if (prevPage.current > -1) {
            loadSchedulesStat(prevPage.current, 'prev');
        }
    };
    const loadNextPage = () => {
        if (nextPage.current < pagesList.length) {
            loadSchedulesStat(nextPage.current, 'next');
        }
    };
    const loadSchedulesStat = (page, direction) => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b;
        if (loadPages.current[page])
            return;
        loadPages.current[page] = true;
        const requestParamsPeriod = pagesList[page];
        const response = yield schedulesService.stat(Object.assign(Object.assign({}, requestParams), requestParamsPeriod));
        if (response === null || response === void 0 ? void 0 : response.preparedValue) {
            const statResponse = response.preparedValue;
            if (direction === 'next') {
                nextPage.current = page + 1;
            }
            else {
                prevPage.current = page - 1;
            }
            const items = statResponse.items || [];
            setStat((prevState) => prevState
                ? Object.assign(Object.assign({}, prevState), { begin: direction === 'prev' ? statResponse.begin : prevState.begin, end: direction === 'next' ? statResponse.end : prevState.end, items: direction === 'next'
                        ? [...prevState.items, ...items]
                        : [...items, ...prevState.items] }) : { begin: statResponse.begin, end: statResponse.end, items: items });
            if (!selectedDay) {
                const day = (value === null || value === void 0 ? void 0 : value.key) && moment.utc().isBefore(value === null || value === void 0 ? void 0 : value.key)
                    ? value === null || value === void 0 ? void 0 : value.key
                    : ((_a = items.find((item) => item.value)) === null || _a === void 0 ? void 0 : _a.key) || ((_b = items[0]) === null || _b === void 0 ? void 0 : _b.key);
                setSelectedDay(day);
            }
        }
        else {
            loadPages.current[page] = undefined;
        }
    });
    const { isLoading: isInitLoadingStat } = useLoader(() => __awaiter(void 0, void 0, void 0, function* () {
        if (pagesList.length) {
            loadNextPage();
        }
    }), [pagesList]);
    const { isLoading: isLoadingWindows } = useLoader(() => __awaiter(void 0, void 0, void 0, function* () {
        var _c;
        if (!stat || !selectedDay) {
            return;
        }
        const date = moment(selectedDay);
        const params = Object.assign(Object.assign({}, requestParams), { begin: `$date:${date.format('YYYY-MM-DDTHH:mm:ss')}` });
        delete params.end;
        const response = yield schedulesService.findWindows(params);
        if (response === null || response === void 0 ? void 0 : response.preparedValue) {
            const windowsResponse = response.preparedValue;
            if (!((_c = value === null || value === void 0 ? void 0 : value.value) === null || _c === void 0 ? void 0 : _c.length)) {
                const items = windowsResponse.items || [];
                const prevSelectedWindow = items.find((item) => item.key === value.key);
                onChange(prevSelectedWindow ? prevSelectedWindow : items[0] || {});
            }
            setWindows(windowsResponse);
        }
    }), [selectedDay]);
    const onSelectWindow = (value) => {
        const item = windows.items.find((item) => item.key === value.replace('.000Z', 'Z'));
        if (item) {
            onChange(item);
        }
    };
    return (_jsxs(_Fragment, { children: [stat ? (_jsx(DaySlotPicker, { selectedDay: selectedDay, onChangeDay: setSelectedDay, minDate: stat.begin, maxDate: stat.end, dates: stat.items, visibleDaysCount: visibleDaysCount, nextLoaderParams: {
                    hasLoadTrigger: nextPage.current < pagesList.length,
                    onIntersectLoadTrigger: loadNextPage,
                }, prevLoaderParams: {
                    hasLoadTrigger: prevPage.current > -1,
                    onIntersectLoadTrigger: loadPrevPage,
                } })) : (_jsx(DaySlotPicker, { selectedDay: getDefaultItems(visibleDaysCount)[0].key, onChangeDay: setSelectedDay, minDate: defaultBegin.format('YYYY-MM-DD'), maxDate: defaultEnd.format('YYYY-MM-DD'), dates: getDefaultItems(visibleDaysCount), visibleDaysCount: visibleDaysCount, isInfinityScroll: false })), placeTimezone !== $time.timezone && (_jsx("div", Object.assign({ className: styles.notify }, { children: t('book_select_time.different_timezone') }))), _jsx(WindowSlotPicker, { selectedWindow: value.key, onChangeWindow: onSelectWindow, isLoading: (!stat && isInitLoadingStat) || isLoadingWindows, placeTimezone: placeTimezone, windows: windows === null || windows === void 0 ? void 0 : windows.items, cellNumberInRow: cellNumberInRow })] }));
};
export default TimeSlotPicker;
