import { makeAutoObservable } from 'mobx';
import ExtractorBase from '../extractors/Base';
import { prepareAttachment, prepareMeta, prepareValueDateTime } from '../fhir.converters';
import { getCodingObjects, getExtensionObjects, getIdentifierObjects } from '../fhir.helpers';
import { joinArrayWithObjectToString, joinValuesToString } from '../fhir.utils';
class ExtractorMedia {
    constructor(resource) {
        this.base = new ExtractorBase(this);
        this.base.SET(resource);
        makeAutoObservable(this);
    }
    get resource() {
        return this.base.resource;
    }
    /*
     * RESOURCE - Minimally prepared resource data, prepareConverters, valueObjects, etc.
     */
    get extensions() {
        return getExtensionObjects(this.resource.extension);
    }
    get identifiers() {
        return getIdentifierObjects(this.resource.identifier);
    }
    get types() {
        return getCodingObjects(this.resource.type);
    }
    get modalities() {
        return getCodingObjects(this.resource.modality);
    }
    get bodySites() {
        return getCodingObjects(this.resource.bodySite);
    }
    // other in the FHIR - Group | Device | Specimen
    get subject() {
        return this.base.getChildExtractor(this.resource.subject);
    }
    // other in the FHIR - CareTeam | Device
    get operator() {
        return this.base.getChildExtractor(this.resource.operator);
    }
    get content() {
        if (this.resource.content) {
            return {
                attachment: prepareAttachment(this.resource.content),
            };
        }
    }
    get notes() {
        return this.resource.note;
    }
    get deviceName() {
        return this.resource.deviceName;
    }
    get status() {
        return this.resource.status;
    }
    get createdDateTime() {
        return prepareValueDateTime(this.resource.createdDateTime);
    }
    get meta() {
        return prepareMeta(this.resource.meta);
    }
    /*
     * READ - PREPARED Values
     */
    get zid() {
        return this.identifiers.first({ config: 'zid' });
    }
    get type() {
        return this.types.first();
    }
    get modality() {
        return this.modalities.first();
    }
    get bodySite() {
        return this.bodySites.first();
    }
    /*
     * STRING Values
     */
    get asString() {
        var _a, _b, _c;
        const tmp = {
            type: (_a = this.type) === null || _a === void 0 ? void 0 : _a.display,
            modality: (_b = this.modality) === null || _b === void 0 ? void 0 : _b.display,
            bodySite: (_c = this.bodySite) === null || _c === void 0 ? void 0 : _c.display,
            deviceName: this.deviceName,
            notes: joinArrayWithObjectToString(this.notes, 'text', '; '),
            full: '',
        };
        tmp.full = joinValuesToString([tmp.modality, tmp.type, tmp.deviceName, tmp.notes, tmp.bodySite], ' • ', true);
        return tmp;
    }
}
export default ExtractorMedia;
