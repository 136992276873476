import { makeAutoObservable } from 'mobx';
import _ from 'lodash';
import { i18textOrHumanized } from '../extractor.helpers';
import ExtractorBase from '../extractors/Base';
import { prepareMeta } from '../fhir.converters';
import { getCodingObjects, getExtensionObjects, getIdentifierObjects } from '../fhir.helpers';
import { joinArrayWithObjectToString, joinValuesToString } from '../fhir.utils';
class ExtractorAllergyIntolerance {
    constructor(resource) {
        this.base = new ExtractorBase(this);
        this.base.SET(resource);
        makeAutoObservable(this);
    }
    get resource() {
        return this.base.resource;
    }
    /*
     * RESOURCE - Minimally prepared resource data, prepareConverters, valueObjects, etc.
     */
    get extensions() {
        return getExtensionObjects(this.resource.extension);
    }
    get identifiers() {
        return getIdentifierObjects(this.resource.identifier);
    }
    get codes() {
        return getCodingObjects(this.resource.code);
    }
    get clinicalStatuses() {
        return getCodingObjects(this.resource.clinicalStatus);
    }
    get verificationStatuses() {
        return getCodingObjects(this.resource.verificationStatus);
    }
    get subject() {
        return this.base.getChildExtractor(this.resource.patient);
    }
    get asserter() {
        return this.base.getChildExtractor(this.resource.asserter);
    }
    get recorder() {
        return this.base.getChildExtractor(this.resource.recorder);
    }
    get notes() {
        return this.resource.note;
    }
    get meta() {
        return prepareMeta(this.resource.meta);
    }
    /*
     * READ - PREPARED Values
     */
    get zid() {
        return this.identifiers.first({ config: 'zid' });
    }
    get codeObj() {
        return this.codes.first();
    }
    get type() {
        return this.resource.type || 'allergy';
    }
    get clinicalStatus() {
        // allergyIntoleranceClinicalStatusValue
        return this.clinicalStatuses.first();
    }
    get verificationStatus() {
        // allergyIntoleranceVerificationStatusValue
        return this.verificationStatuses.first();
    }
    // Custom Values
    // todo: values probably legacy and unused
    get values() {
        let result = [];
        _.forEach(this.resource.reaction, (item) => {
            _.forEach(item.manifestation, (x) => {
                const value = getCodingObjects(x).first();
                if (value)
                    result.push(value);
            });
        });
        return result;
    }
    /*
     * STRING Values
     */
    get asString() {
        var _a, _b, _c;
        const tmp = {
            codeDisplay: (_a = this.codeObj) === null || _a === void 0 ? void 0 : _a.display,
            type: i18textOrHumanized('ps.event_viewer.allergy.type.' + this.type, this.type),
            values: joinArrayWithObjectToString(this.values, 'asString', ', '),
            notes: joinArrayWithObjectToString(this.notes, 'text', '; '),
            clinicalStatus: (_b = this.clinicalStatus) === null || _b === void 0 ? void 0 : _b.display,
            verificationStatus: (_c = this.verificationStatus) === null || _c === void 0 ? void 0 : _c.display,
            full: '',
        };
        tmp.full = joinValuesToString([tmp.codeDisplay, tmp.clinicalStatus, tmp.verificationStatus], ' • ');
        return tmp;
    }
}
export default ExtractorAllergyIntolerance;
