import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatDate } from 'ziphy-web-shared/basic/utils/time';
import RoleName from '@ps/library/RoleName';
const CarePlanDetails = ({ event, extractor }) => {
    const { t } = useTranslation();
    const { subject, author, asString } = extractor;
    return (_jsxs("div", Object.assign({ className: "event_details" }, { children: [_jsx("div", Object.assign({ className: "event_details-header" }, { children: _jsxs("div", Object.assign({ className: "event_details-text text-pre-line" }, { children: [_jsx("div", Object.assign({ className: "font-weight-700" }, { children: asString.description })), asString.notes && (_jsxs("div", Object.assign({ className: "mt-10" }, { children: [_jsx("span", { children: t('ps.event_viewer.note:') }), " ", asString.notes] })))] })) })), _jsxs("div", Object.assign({ className: "event_details-body" }, { children: [subject && (_jsxs("div", Object.assign({ className: "event_details-line" }, { children: [t('ps.event_viewer.subject:'), " ", subject.name.fullName] }))), author && (_jsxs("div", Object.assign({ className: "event_details-line" }, { children: [t('ps.event_viewer.author:'), " ", author.name.fullName, " ", _jsx(RoleName, { role: author.role })] }))), _jsx("div", Object.assign({ className: "event_details-line event_details-line--date" }, { children: formatDate(event.effectiveDate) }))] }))] })));
};
export default CarePlanDetails;
