import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import classNames from 'classnames';
import Icon from 'ziphy-web-shared/basic/assets/images/Icon';
import { getStylesMode } from 'ziphy-web-shared/basic/helpers';
import styles from './ModalToolbarBtn.module.scss';
const ModalToolbarBtn = ({ type, action, className }) => {
    return (_jsx("div", Object.assign({ className: classNames(styles.btn, getStylesMode(type, styles), className), onClick: action }, { children: _jsx(Icon, { type: type }) })));
};
export default ModalToolbarBtn;
