var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { waitForVariable } from 'ziphy-web-shared/basic/helpers';
import { Loader } from 'ziphy-web-shared/basic/lib/loaders';
import styles from './AwsCaptcha.module.scss';
const AwsCaptcha = ({ show, domainList, apiKey, onLoad, onPuzzleCorrect, onSuccess, onError, className, }) => {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(true);
    const [isSuccess, setIsSuccess] = useState(false);
    const container = useRef();
    useEffect(() => {
        function func() {
            var _a;
            return __awaiter(this, void 0, void 0, function* () {
                yield waitForVariable('AwsWafCaptcha');
                // @ts-ignore
                const renderCaptcha = (_a = window.AwsWafCaptcha) === null || _a === void 0 ? void 0 : _a.renderCaptcha;
                if (typeof renderCaptcha !== 'function') {
                    return false;
                }
                // @ts-ignore
                window.awsWafCookieDomainList = domainList;
                renderCaptcha(container.current, {
                    apiKey: apiKey,
                    onLoad: () => {
                        setIsLoading(false);
                        if (onLoad)
                            onLoad();
                    },
                    onPuzzleCorrect: () => {
                        setIsSuccess(true);
                        if (onPuzzleCorrect)
                            onPuzzleCorrect();
                    },
                    onSuccess: () => {
                        if (onSuccess)
                            onSuccess();
                    },
                    onError: () => {
                        setIsSuccess(false);
                        if (onError)
                            onError();
                    },
                    dynamicWidth: true,
                    skipTitle: true,
                });
            });
        }
        if (show) {
            func();
        }
    }, [show, domainList, apiKey]); // eslint-disable-line react-hooks/exhaustive-deps
    if (!show) {
        return _jsx(_Fragment, {});
    }
    return (_jsxs("div", Object.assign({ className: classNames(styles.captcha, isLoading && styles.isLoading, isSuccess && styles.captchaSuccess, className) }, { children: [isLoading && _jsx(Loader, {}), isSuccess ? (_jsx("div", { children: t('label.success') })) : (_jsx("div", { id: "aws-captcha-container", ref: container }))] })));
};
export default observer(AwsCaptcha);
