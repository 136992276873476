import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatDate } from 'ziphy-web-shared/basic/utils/time';
import RoleName from '@ps/library/RoleName';
import ViewCode from '@ps/library/viewCode/ViewCode';
const ObservationDetails = ({ event, extractor }) => {
    const { t } = useTranslation();
    const { asString, codeObj, subject, performer } = extractor;
    return (_jsxs("div", Object.assign({ className: "event_details" }, { children: [_jsxs("div", Object.assign({ className: "event_details-header" }, { children: [_jsxs("div", Object.assign({ className: "event_details-title" }, { children: [asString.codeDisplay, event.isSelfReported && (_jsx("span", Object.assign({ className: "badge-self_reported" }, { children: t('ps.label.self_reported') })))] })), _jsx("div", Object.assign({ className: "event_details-code" }, { children: _jsx(ViewCode, { codeObj: codeObj }) }))] })), _jsxs("div", Object.assign({ className: "event_details-body" }, { children: [asString.values && (_jsxs("div", Object.assign({ className: "event_details-line mb-10" }, { children: [t('ps.event_viewer.value:'), " ", asString.values] }))), subject && (_jsxs("div", Object.assign({ className: "event_details-line" }, { children: [t('ps.event_viewer.subject:'), " ", subject.name.fullName] }))), performer && (_jsxs("div", Object.assign({ className: "event_details-line" }, { children: [t('ps.event_viewer.performer:'), " ", performer.name.fullName, ' ', _jsx(RoleName, { role: performer.role })] }))), _jsx("div", Object.assign({ className: "event_details-line event_details-line--date" }, { children: formatDate(event.effectiveDate) }))] }))] })));
};
export default ObservationDetails;
