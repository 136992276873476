import i18n from 'i18next';
import { $auth } from 'ziphy-web-shared/basic/entities/auth';
import CustomAlertModal from '@library/modals/CustomAlert.modal';
import LoginPhoneModal from '@pages/auth/LoginPhone.modal';
const actionConfirmatorConfig = {
    checkAuth: {
        modalTemplate: LoginPhoneModal,
        enabled: true,
        skip: () => $auth.isLogged,
    },
    customAlert: {
        modalTemplate: CustomAlertModal,
        enabled: true,
        data: {
            done: () => i18n.t('btn.confirm'),
            cancel: () => i18n.t('btn.cancel'),
        },
    },
};
export default actionConfirmatorConfig;
