var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { toNumberIfPossible } from 'ziphy-web-shared/basic/helpers';
import { BaseService } from '../baseService';
export class PsPatients extends BaseService {
    constructor(props) {
        super(props);
    }
    getPatientDemographics(params, options) {
        return __awaiter(this, void 0, void 0, function* () {
            let res;
            res = yield this.api.fetchLoggedV3('patient_stories.get_demographics', this.clearPayload(params), options);
            return res;
        });
    }
    getPatientVitals(params, options) {
        return __awaiter(this, void 0, void 0, function* () {
            let res;
            res = yield this.api.fetchLoggedV3('patient_stories.get_vital_signs', this.clearPayload(params), options);
            return res;
        });
    }
    getPatientSocialHistory(params, options) {
        return __awaiter(this, void 0, void 0, function* () {
            let res;
            res = yield this.api.fetchLoggedV3('patient_stories.get_social_history', this.clearPayload(params), options);
            return res;
        });
    }
    searchPatients(params, options) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            let res;
            res = yield this.api.fetchLoggedV3('patient_stories.search_patients', this.clearPayload(params), options);
            return Object.assign(Object.assign({}, res), { preparedValue: ((_a = res.prepared) === null || _a === void 0 ? void 0 : _a.patients) || [] });
        });
    }
    searchPatientsTypeAhead(params, options) {
        return __awaiter(this, void 0, void 0, function* () {
            let res;
            res = yield this.api.fetchLoggedV3('patient_stories.typeahead_search', this.clearPayload(params), options);
            return res;
        });
    }
    viewPractices(params, options) {
        return __awaiter(this, void 0, void 0, function* () {
            let res;
            res = yield this.api.fetchLoggedV3('patient_stories.view_practices', this.clearPayload(params), options);
            if (res.prepared) {
                res.preparedValue = [];
                res.prepared.forEach(({ id, ids, practice, lastUpdated }) => {
                    practice.forEach((item) => {
                        res.preparedValue.push({
                            id: item.id,
                            zid: toNumberIfPossible(item.ids[1]),
                            name: item.name,
                            patientId: id,
                            patientZid: toNumberIfPossible(ids[1]),
                            lastUpdated: lastUpdated,
                        });
                    });
                });
            }
            return res;
        });
    }
}
