var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { BaseService } from '../baseService';
export class DocumentTemplates extends BaseService {
    constructor(props) {
        super(props);
    }
    create(params, options) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            let res;
            res = yield this.api.fetchLoggedV3('document_templates.create', params, options);
            return Object.assign(Object.assign({}, res), { preparedValue: (_a = res.prepared) === null || _a === void 0 ? void 0 : _a.documentTemplate });
        });
    }
    read(params, options) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            let res;
            res = yield this.api.fetchV3('document_templates.read', params, options);
            return Object.assign(Object.assign({}, res), { preparedValue: (_a = res.prepared) === null || _a === void 0 ? void 0 : _a.documentTemplate });
        });
    }
    update(params, options) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            let res;
            res = yield this.api.fetchLoggedV3('document_templates.update', params, options);
            return Object.assign(Object.assign({}, res), { preparedValue: (_a = res.prepared) === null || _a === void 0 ? void 0 : _a.documentTemplate });
        });
    }
    delete(params, options) {
        return __awaiter(this, void 0, void 0, function* () {
            let res;
            res = yield this.api.fetchLoggedV3('document_templates.delete', params, options);
            return res;
        });
    }
    list(params, options) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            let res;
            res = yield this.api.fetchV3('document_templates.list', params, options);
            return Object.assign(Object.assign({}, res), { preparedValue: ((_a = res.prepared) === null || _a === void 0 ? void 0 : _a.documentTemplates.items) || [] });
        });
    }
    patientForms(params, options) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            let res;
            res = yield this.api.fetchLoggedV3('document_templates.patient_forms', params, options);
            // @ts-ignore ??
            return Object.assign(Object.assign({}, res), { preparedValue: ((_a = res.prepared) === null || _a === void 0 ? void 0 : _a.documentTemplates.items) || [] });
        });
    }
    batchPatientForms(params, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const requests = params.encounterIds.map((x) => ({
                method: 'document_templates.patient_forms',
                params: { encounterId: x },
            }));
            let res;
            res = yield this.batch(requests, options);
            // @ts-ignore ??
            return res.map((x) => { var _a; return (Object.assign(Object.assign({}, x), { preparedValue: ((_a = x.prepared) === null || _a === void 0 ? void 0 : _a.documentTemplates.items) || [] })); });
        });
    }
}
