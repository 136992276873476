export function isBase64(str = '') {
    return typeof str === 'string' && str.startsWith('data:') && str.includes('base64');
}
export function saveImageToBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        const mime = file.type;
        reader.onload = function ({ target: { result } }) {
            if (typeof result === 'string') {
                const str = btoa(result);
                resolve(`data:${mime};base64,${str}`);
            }
            else {
                reject(new Error('File reading failed'));
            }
        };
        reader.onerror = reject;
        reader.readAsBinaryString(file);
    });
}
export function getImageDimensions(file) {
    return new Promise((resolve, reject) => {
        const img = new Image();
        const objectUrl = URL.createObjectURL(file);
        img.onload = function () {
            const { width, height } = this;
            URL.revokeObjectURL(objectUrl);
            resolve({ width, height });
        };
        img.onerror = reject;
        img.src = objectUrl;
    });
}
