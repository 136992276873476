import * as EmailValidator from 'email-validator';
import _ from 'lodash';
import moment from 'moment-timezone';
import { getInitConfig } from 'ziphy-web-shared';
import { isoToObject } from 'ziphy-web-shared/basic/api/myack/api.helpers';
import { isAbsoluteEmpty, isJson, personAgeLimit } from 'ziphy-web-shared/basic/helpers';
import { isBase64, saveImageToBase64, splitBloodPressure } from 'ziphy-web-shared/basic/helpers';
/*
  rule(fieldValue, rule, rules)
 */
export const validationRules = {
    // basic
    func(value, rule, ...props) {
        if (_.isFunction(rule.value)) {
            return rule.value(value, rule, ...props);
        }
    },
    pattern(value, rule) {
        if (_.isRegExp(rule.value)) {
            return value.search(rule.value) !== -1;
        }
    },
    required(value) {
        return !isAbsoluteEmpty(value);
    },
    forceNotRequired() {
        return true;
    },
    minLen1OrEmpty(value) {
        return _.trim(value).length >= 1 || value === '';
    },
    // custom
    name(value) {
        return _.trim(value).length >= 1;
    },
    dob(value, rule) {
        if (_.isEmpty(value)) {
            return false;
        }
        const { from, to } = personAgeLimit(rule.dobType);
        return moment(value).isAfter(from) && moment(value).isBefore(to);
    },
    gender(value) {
        return ['male', 'female', 'na'].includes(value);
    },
    phone(value, rule) {
        return (!rule.required && _.isEmpty(value.number)) || value.isValid;
    },
    email(value) {
        return EmailValidator.validate(value);
    },
    code(value) {
        return value.search(new RegExp(getInitConfig().phone.verifyCode.pattern)) !== -1;
    },
    bloodPressure(value) {
        value = splitBloodPressure(value);
        return value && value.systolic && value.diastolic;
    },
    min(value, rule) {
        if (rule.strict) {
            return Number(value) > rule.compareTo;
        }
        else {
            return Number(value) >= rule.compareTo;
        }
    },
    max(value, rule) {
        if (rule.strict) {
            return Number(value) < rule.compareTo;
        }
        else {
            return Number(value) <= rule.compareTo;
        }
    },
};
export const prepValuePresets = {
    value(e) {
        return _.get(e, 'target.value', e);
    },
    valueOrEmptyString(value) {
        return !isAbsoluteEmpty(value) ? value : '';
    },
    singleSelect(value) {
        return value[0];
    },
    avatar(e) {
        let file = _.get(e, 'target.files[0]');
        if (file) {
            return saveImageToBase64(file);
        }
    },
};
export const outputPresets = {
    avatar(value) {
        if (isAbsoluteEmpty(value) || value.includes('http://') || value.includes('https://')) {
            return undefined;
        }
        return isBase64(value) ? value.split(';')[1].substring(7) : value;
        // return value
    },
    emptyUndefined(value) {
        return _.isEmpty(value) ? undefined : value;
    },
    emptyNull(value) {
        return _.isEmpty(value) ? null : value;
    },
    phone(value) {
        if (!(value === null || value === void 0 ? void 0 : value.number))
            return null;
        return '+' + value.dialCode + value.number;
    },
    integer(value) {
        return parseInt(value);
    },
    string(value) {
        return _.toString(value);
    },
    date(value) {
        return isoToObject('date', value);
    },
    datetime(value) {
        return isoToObject('datetime', value);
    },
    strict(value) {
        return isAbsoluteEmpty(value) ? value : `${value}|strict`;
    },
    sort(value) {
        return isJson(value) ? JSON.parse(value) : value;
    },
    dashedEmpty(value) {
        return isAbsoluteEmpty(value) ? '---' : value;
    },
    bloodPressure(value) {
        return splitBloodPressure(value);
    },
};
