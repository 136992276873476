import { makeAutoObservable } from 'mobx'

import _ from 'lodash'
import moment from 'moment-timezone'

import { initResourceExtractor } from '@fhir/extractor'
import { getReferenceId, parseReferenceId } from '@fhir/fhir.helpers'

import psService from '@ps/services/psService'

import { $psHistory } from '@store'

const RESOURCE_CACHE = 300 // seconds
const COMPLETE_RESOURCE_CACHE = 60 // seconds

class psResourcesStore {
  constructor() {
    makeAutoObservable(this)
  }

  items = {}
  cacheMap = {}

  // clear
  clear() {
    this.items = {}
    this.cacheMap = {}
  }

  // Computed

  // Mutations
  ADD_ITEMS(values, isComplete = false) {
    let result = this.items
    let cache = this.cacheMap

    _.forEach(values, (value) => {
      result[value.id] = value
      cache[value.id] = { date: new Date().toISOString(), isComplete }
    })

    this.items = result
    this.cacheMap = cache
  }

  // Actions
  checkInCache(id) {
    let result = false
    const cacheItem = this.cacheMap[id]

    if (cacheItem && !_.isEmpty(this.getById(id))) {
      const targetDiff = cacheItem.isComplete ? COMPLETE_RESOURCE_CACHE : RESOURCE_CACHE
      result = moment().diff(cacheItem.date, 'seconds') < targetDiff
    }

    return result
  }

  async getResources({ items = [] }, useCache = true) {
    let exists = []
    let references = []
    let forCompleteRequest = []

    _.forEach(items, (item) => {
      // forCompleteRequest.push(item)
      // return
      const found = useCache ? this.getById(item.id) : undefined

      if (!_.isEmpty(found) && this.checkInCache(item.id)) {
        exists.push(found)
      } else if (item.needDeepInclude) {
        forCompleteRequest.push(item)
      } else {
        references.push(getReferenceId(item))
      }
    })

    let result

    if (references.length) {
      const response = await psService.getResourcesByReferences({ references })

      const res = _.get(response, 'prepared.resources', [])
      this.ADD_ITEMS(res)

      result = [...exists, ...res]
    } else {
      result = exists
    }

    if (forCompleteRequest.length) {
      const res = await this.getCompleteResources({ items: forCompleteRequest })
      const filteredByItems = _.intersectionBy(forCompleteRequest, res, 'id')
      result = [...result, ...filteredByItems]
    }

    return result
  }

  async getCompleteResources({ items = [], deepinclude = [] }) {
    deepinclude = ['* > PractitionerRole:practitioner', ...deepinclude]

    let references = []

    items = items.filter((x) => !_.isEmpty(x))

    // forming ids for resources from events
    _.forEach(items, (item) => {
      references.push(getReferenceId(item))

      if (item.needDeepInclude) {
        deepinclude = [...deepinclude, ...item.needDeepInclude]

        if (item.resource === 'Appointment') {
          const encounterEvent = $psHistory.getEncounterByAppointmentId(item.id)
          if (!_.isEmpty(encounterEvent)) {
            references.push(getReferenceId(encounterEvent))
          }
        }
      }
    })

    const response = await psService.getCompleteResourcesByReferences({
      references,
      deepinclude: _.uniq(deepinclude),
    })

    const result = _.get(response, 'prepared.resources', [])
    // _.forEach(result, (x) => {
    // _.forEach(x.extension, (a) => console.log(a))
    // if (x.resourceType === 'DiagnosticReport') {
    // console.log(x.resourceType, x.id)
    // }
    // })
    this.ADD_ITEMS(result, true)
    // console.js(_.filter(this.items, (x) => x.resourceType === 'Location'))

    return result
  }

  getById(id) {
    const parsed = parseReferenceId(id)
    return this.items[parsed.id || id]
  }

  getByCondition(condition) {
    return _.filter(this.items, condition)
  }
}

initResourceExtractor({
  findResourceById: (...p) => store.getById(...p),
  findResourcesByCondition: (...p) => store.getByCondition(...p),
})

const store = new psResourcesStore()
export default store
