import _ from 'lodash';
import moment from 'moment-timezone';
export const getParsedDate = (value, type, timezone) => {
    const date = moment(value ? value : new Date());
    if (type === 'selectDateTime') {
        date.tz(timezone);
    }
    return date;
};
export const getSwitchDates = (switchDates, type, timezone) => {
    const result = {
        day: {},
        month: {},
        year: {},
    };
    switchDates.forEach((value) => {
        const parsedDate = getParsedDate(value, type, timezone);
        result.day[parsedDate.format('YYYY-MM-DD')] = parsedDate;
        result.month[parsedDate.format('YYYY-MM')] = parsedDate;
        result.year[parsedDate.format('YYYY')] = parsedDate;
    });
    return result;
};
export const getMinMaxDate = (minDate, maxDate, availableDates, currentDate, type, timezone) => {
    const currentYear = currentDate.get('year');
    const range = 100;
    let parsedMinDate;
    let parsedMaxDate;
    if (!_.isEmpty(availableDates)) {
        const keys = Object.keys(availableDates.day).sort();
        parsedMinDate = availableDates.day[keys[0]];
    }
    else if (minDate) {
        parsedMinDate = getParsedDate(minDate, type, timezone);
    }
    else {
        parsedMinDate = currentDate.clone().set('year', currentYear - range);
    }
    if (!_.isEmpty(availableDates)) {
        const keys = Object.keys(availableDates.day).sort();
        const lastIndex = keys.length - 1;
        parsedMaxDate = availableDates.day[keys[lastIndex]];
    }
    else if (maxDate) {
        parsedMaxDate = getParsedDate(maxDate, type, timezone);
    }
    else {
        parsedMaxDate = currentDate.clone().set('year', currentYear + range);
    }
    return [parsedMinDate, parsedMaxDate];
};
export const getScrollToDate = (selectedDate, currentDate, minDate, maxDate, scrollTo) => {
    let scrollToDate;
    if (!selectedDate && scrollTo === 'start') {
        scrollToDate = minDate.clone();
    }
    else if (!selectedDate && scrollTo === 'end') {
        scrollToDate = maxDate.clone();
    }
    else {
        scrollToDate = selectedDate ? selectedDate.clone() : currentDate.clone();
    }
    return scrollToDate;
};
