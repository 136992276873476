var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { $paymentMethods, paymentMethodIsExpired, paymentMethodsLabel, } from 'ziphy-web-shared/basic/entities/paymentMethods';
import { Checkbox, PatientInfoBox } from 'ziphy-web-shared/basic/lib/simple';
import { confirmAction } from '@library/utils/actionConfirmator/ActionConfirmator';
import Button from '@nedoShared/basic/lib/simple/button/Button';
import PaymentMethodsIcon from '@nedoShared/features/paymentMethods/paymentMethodsController/paymentMethodsIcon/PaymentMethodsIcon';
import { $modal } from '@store';
import AddPaymentMethodModal from '../addPaymentMethodModal/AddPaymentMethod.modal';
import styles from './PaymentMethodsList.module.scss';
const PaymentMethodsList = ({ selectedId, onSelectId, isClickable = false, showCheckbox = false, className, }) => {
    const { t } = useTranslation();
    const [methodId, setMethodId] = useState(selectedId || $paymentMethods.defaultId);
    function onSelectHandle(item) {
        if (isClickable && paymentMethodIsExpired(item)) {
            return false;
        }
        setMethodId(item.id);
        if (onSelectId) {
            onSelectId(item.id);
        }
    }
    function removeHandle(id, e) {
        e.stopPropagation();
        confirmAction('customAlert', {
            data: {
                title: t('pms.remove_alert.title'),
                message: t('pms.remove_alert.message'),
            },
            callback: () => __awaiter(this, void 0, void 0, function* () {
                yield $paymentMethods.delete({ id });
            }),
        }, e);
    }
    function openAddModal() {
        $modal.add(AddPaymentMethodModal, {
            onDone: (e) => onSelectHandle(e),
        });
    }
    return (_jsxs("div", Object.assign({ className: classNames(styles.wrapper, className) }, { children: [$paymentMethods.sortedItems.map((item) => (_jsx(PatientInfoBox, { onClick: () => onSelectHandle(item), value: [paymentMethodsLabel(item), item.last4].join(' • '), label: _jsxs("span", Object.assign({ className: classNames(paymentMethodIsExpired(item) && 'text-danger') }, { children: [item.expMonth, "/", item.expYear, ' ', paymentMethodIsExpired(item) && `(${t('pms.label.expired')})`] })), className: styles.item, leftElement: [
                    showCheckbox && (_jsx(Checkbox, { isChecked: item.id === methodId, isDark: true, className: styles.checkbox })),
                    _jsx(PaymentMethodsIcon, { brand: item.brand }),
                ], rightElement: {
                    onDelete: (e) => removeHandle(item.id, e),
                }, clickable: isClickable && !paymentMethodIsExpired(item) }, item.id))), _jsx("div", Object.assign({ className: "mt-10" }, { children: _jsx(Button, { mode: ['create', 'block'], action: openAddModal, label: t('btn.add_payment_method') }) }))] })));
};
export default observer(PaymentMethodsList);
