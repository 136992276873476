var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { makeAutoObservable, runInAction } from 'mobx';
import { insurancesService, psPatientsService } from 'ziphy-web-shared/basic/api';
import { withFullName } from '@fhir/fhir.helpers';
import { getPsPatientId, getPsPracticeId } from '@ps/services/psService.helpers';
import { $psHistory } from '@store';
class psPatientStore {
    constructor() {
        this.demographics = null;
        this.vitals = null;
        this.insurances = [];
        this.socialHistory = null;
        this._practices = [];
        makeAutoObservable(this);
    }
    // clear
    clear(isChangePatient = false) {
        this.demographics = null;
        this.vitals = null;
        this.insurances = [];
        this.socialHistory = null;
        if (isChangePatient) {
            this._practices = [];
        }
    }
    // Computed
    get patient() {
        return Object.assign(Object.assign({}, withFullName(this.demographics)), { vitals: this.vitals, insurances: this.insurances, socialHistory: this.socialHistory });
    }
    get practices() {
        return this._practices.map((x) => (Object.assign(Object.assign({}, x), { isCurrent: x.zid === this.patient.practice.zid })));
    }
    // Mutations
    // Actions
    loadData() {
        return __awaiter(this, void 0, void 0, function* () {
            const params = {
                patientId: getPsPatientId(),
                practiceId: getPsPracticeId(),
            };
            const response = yield Promise.all([
                psPatientsService.getPatientDemographics(params),
                psPatientsService.getPatientVitals(params),
                insurancesService.list({ patientId: getPsPatientId() }),
                psPatientsService.getPatientSocialHistory(params),
                $psHistory.loadEvents(),
            ]);
            runInAction(() => {
                this.demographics = response[0].prepared || null;
                this.vitals = response[1].prepared || null;
                this.insurances = response[2].preparedValue;
                this.socialHistory = response[3].prepared || null;
            });
        });
    }
    loadPractices() {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield psPatientsService.viewPractices({ patientId: getPsPatientId() }, { cacheTime: 60 });
            if (!response.error)
                runInAction(() => (this._practices = response.preparedValue));
            return response;
        });
    }
}
export default new psPatientStore();
