import i18n from 'i18next';
import _ from 'lodash';
import moment from 'moment-timezone';
import { isAbsoluteEmpty, toNumberIfPossible, toSnakeCase } from 'ziphy-web-shared/basic/helpers';
import { showAlert } from 'ziphy-web-shared/basic/lib/utilities';
import { loggableSession } from 'ziphy-web-shared/basic/utils';
export const skipAlertErrorCodes = [
    'error.jwt.invalid',
    'error.jwt.expired',
    'error.auth.expired_session',
    'error.auth.high_rate_captcha',
    'error.resource.unreachable',
    'ERR_NETWORK',
    'ECONNABORTED',
];
export const skipRGErrorCodes = [
    'error.catch.internal',
    'error.auth.high_rate_captcha',
    //
];
let requestId = 0;
export const customMessages = _.orderBy([
    {
        code: 'error.resource.precondition_failed',
        data: { method: 'appointments.rebook' },
        msg: 'api_errors.appointments.rebook',
    },
    {
        code: 'error.resource.precondition_failed',
        data: { method: 'appointments.cancel' },
        msg: 'api_errors.appointments.cancel',
    },
    {
        code: 'error.rpc.internal',
        data: { method: 'appointments.create' },
        msg: 'api_errors.appointments.create',
        msgPrefix: 'RPC Internal: ',
    },
    {
        code: 'error.rpc.internal',
        msg: 'api_errors.default',
        msgPrefix: 'RPC Internal: ',
    },
    {
        code: 'error.catch.internal',
        msg: 'api_errors.default',
        msgPrefix: 'API CATCH: ',
    },
], (x) => Object.keys(x.data || {}).length, 'desc');
export function convertKeysToCamel(data) {
    // @ts-ignore
    const processVal = (val) => typeof val !== 'object' || val === null
        ? val
        : Array.isArray(val)
            ? val.map(processVal)
            : renameKeys(val);
    // @ts-ignore
    const renameKeys = (obj) => _.fromPairs(_.entries(obj).map(([key, val]) => [
        key.replace(/_(.)/g, (g) => g[1].toUpperCase()),
        processVal(val),
    ]));
    return _.isArray(data) ? processVal(data) : renameKeys(data);
}
export function convertKeysToSnake(data) {
    // @ts-ignore
    function processVal(val) {
        if (typeof val !== 'object' || val === null) {
            return val;
        }
        else {
            if (Array.isArray(val)) {
                return val.map(processVal);
            }
            else {
                return renameKeys(val);
            }
        }
    }
    // @ts-ignore
    function renameKeys(obj) {
        return _.fromPairs(_.entries(obj).map(([key, val]) => {
            let tmp = key.split('|');
            let resultKey = tmp[0];
            if (tmp[1] === 'strict') {
            }
            else {
                resultKey = toSnakeCase(key).toLowerCase();
            }
            return [resultKey, processVal(val)];
        }));
    }
    return _.isArray(data) ? processVal(data) : renameKeys(data);
}
export function convertArrayValuesToSnake(value) {
    if (_.isArray(value)) {
        value = _.map(value, (x) => convertArrayValuesToSnake(x));
    }
    else if (_.isObjectLike(value)) {
        value = _.mapValues(value, (x) => convertArrayValuesToSnake(x));
    }
    else {
        value = toSnakeCase(value).toLowerCase();
    }
    return value;
}
export function objectToIso(type, obj = {}) {
    let { year, month, day, hour, minute, second, microsecond, utcoffset } = obj;
    let result = '';
    if (type === 'datetime') {
        result = moment.utc([year, month - 1, day, hour, minute, second]).utcOffset(-utcoffset);
        result = result.format('YYYY-MM-DDTHH:mm:ss') + 'Z';
        // if (utcoffset !== 0) {
        //   console.log(`${year}-${month}-${day}T${hour}:${minute}:${second} ${utcoffset}`, result)
        // }
    }
    else if (type === 'date') {
        result = moment.utc([year, month - 1, day]);
        result = result.format('YYYY-MM-DD');
    }
    return result;
}
export function isoToObject(type, value) {
    const date = moment.utc(value).toObject();
    if (type === 'datetime') {
        return {
            _type: 'datetime',
            year: date.years,
            month: date.months + 1,
            day: date.date,
            //
            hour: date.hours,
            minute: date.minutes,
            second: date.seconds,
            microsecond: date.milliseconds,
            utcoffset: 0,
        };
    }
    else if (type === 'date') {
        return {
            _type: 'date',
            year: date.years,
            month: date.months + 1,
            day: date.date,
        };
    }
}
export function prepareRequestData(method, data) {
    return _.cloneDeepWith(data, (item, key) => {
        const isString = typeof item === 'string';
        // todo: update to $strict:
        if (isString && item.split('|')[1] === 'strict') {
            return item.split('|')[0];
        }
        // todo: update to $toNumber:
        if (isString && item.split('|')[1] === 'toNumber') {
            return parseFloat(item.split('|')[0]);
        }
        if (isString && item.indexOf('$datetime:') !== -1) {
            const value = item.slice('$datetime:'.length);
            return isoToObject('datetime', value);
        }
        else if (isString && item.indexOf('$date:') !== -1) {
            const value = item.slice('$date:'.length);
            return isoToObject('date', value);
            // todo: update to $toNumber:
        }
        else if (key === 'coords' && !_.isEmpty(item)) {
            return [item.lat, item.lng];
        }
        else if (typeof key === 'string' &&
            isString &&
            (key === 'id' || key === 'zid' || key.slice(-2) === 'Id' || key.slice(-3) === '_id')) {
            return toNumberIfPossible(item);
        }
    });
}
export function prepareResponseData(data, { mapping = false } = {}) {
    let expanded = data.expanded;
    if (expanded) {
        delete data.expanded;
        expanded = _.mapValues(expanded, (item) => {
            if (item._type === 'mapping') {
                item = item.items.map((x) => x.value);
            }
            return item;
        });
        data = Object.assign(Object.assign({}, data), expanded);
    }
    function preparer(data) {
        return _.cloneDeepWith(data, (item, key) => {
            // @ts-ignore
            const type = _.isObject(item) && item._type;
            const isString = typeof item === 'string';
            if (isString && item.indexOf('$datetime:') === 0) {
                return item.slice('$datetime:'.length) + 'Z';
            }
            else if (type === 'datetime') {
                return objectToIso(type, item);
            }
            else if (isString && item.indexOf('$date:') === 0) {
                return item.slice('$date:'.length);
            }
            else if (type === 'date') {
                return objectToIso(type, item);
            }
            else if (type === 'bitstring') {
                return item.value;
            }
            else if (isString && item.indexOf('$bitstring:') === 0) {
                return item.slice('$bitstring:'.length);
            }
            else if (key === 'coords') {
                if (_.isEmpty(item)) {
                    return {};
                }
                return { lat: item[0], lng: item[1] };
            }
            else if (type === 'decimal') {
                return +item.value;
            }
            else if (typeof key === 'string' &&
                isString &&
                // key === 'id' ||
                key === 'zid'
            // || key.slice(-2) === 'Id' || key.slice(-3) === '_id'
            ) {
                return toNumberIfPossible(item);
            }
            else if (type === 'mapping' && mapping) {
                return preparer(item.items.reduce((acc, x) => (Object.assign(Object.assign({}, acc), { [x.key]: _.isPlainObject(x.value) ? Object.assign({ id: x.key }, x.value) : x.value })), {}));
            }
        });
    }
    return convertKeysToCamel(preparer(data));
}
export function withPreparedResponse(response, prepareResult = true, prepareResultParams) {
    if (response.result) {
        response.prepared = prepareResult
            ? prepareResponseData(response.result, prepareResultParams)
            : response.result;
    }
    if (!response.hasOwnProperty('prepared')) {
        response.prepared = null;
    }
    delete response.result;
    return response;
}
export function getRequestParams(method, payload, accessToken, apiVersion) {
    payload = convertKeysToSnake(payload);
    let meta = {
        version: apiVersion,
        loggable: loggableSession.get(),
    };
    if (accessToken) {
        meta.access_token = accessToken;
    }
    if (payload.expand) {
        meta.expand = convertArrayValuesToSnake(payload.expand);
        delete payload.expand;
    }
    return {
        id: ++requestId,
        method: method,
        meta: meta,
        params: prepareRequestData(method, payload),
    };
}
export function getUnAuthResponse(method, apiVersion) {
    return {
        id: ++requestId,
        meta: { version: apiVersion },
        method: method,
        prepared: null,
        error: {
            code: 'error.catch.access_token_not_found',
            message: 'Access token',
        },
    };
}
export function getCustomMessages(code, data = {}) {
    let found = [];
    _.forEach(customMessages, (item) => {
        if (code !== item.code) {
            return;
        }
        let all = true;
        _.forEach(item.data, (x, k) => {
            if (data[k] !== x) {
                all = false;
                return false;
            }
        });
        if (all) {
            found.push(item);
        }
    });
    return found;
}
export function getCustomMessage(code, data = {}) {
    let item = _.first(getCustomMessages(code, data));
    if (item === null || item === void 0 ? void 0 : item.msg) {
        item.msg = i18n.exists(item.msg) ? i18n.t(item.msg) : item.msg;
    }
    return item;
}
export function checkResponseErrors(requestParams, response, skipAlert) {
    var _a, _b, _c;
    const code = (_a = response.error) === null || _a === void 0 ? void 0 : _a.code;
    const message = (_b = response.error) === null || _b === void 0 ? void 0 : _b.message;
    let result = {};
    if (message) {
        const data = (_c = response.error) === null || _c === void 0 ? void 0 : _c.data;
        const customMessage = getCustomMessage(code, data);
        result.msg = customMessage === null || customMessage === void 0 ? void 0 : customMessage.msg;
        result.code = code;
        if (!result.msg) {
            let reason = data === null || data === void 0 ? void 0 : data.reason;
            if (typeof reason === 'object') {
                reason = Object.entries(reason).map((x) => x.join(': '));
            }
            const condition = data === null || data === void 0 ? void 0 : data.condition;
            if (reason) {
                result.msg =
                    typeof reason === 'string'
                        ? reason
                        : reason.filter((m) => !isAbsoluteEmpty(m)).join(', ');
            }
            else {
                result.msg = [message, condition].filter((m) => !isAbsoluteEmpty(m)).join(', ');
            }
            result.code = code;
            result.message = message;
            result.reason = reason;
            result.condition = condition;
        }
        if (result.msg && !skipRGErrorCodes.includes(code)) {
            result.analyticsMessage = [customMessage === null || customMessage === void 0 ? void 0 : customMessage.msgPrefix, requestParams.method + ' - ', result.msg]
                .filter((x) => x)
                .join('');
        }
        if (result.msg && !skipAlert && !skipAlertErrorCodes.includes(code)) {
            showAlert.error(result.msg, { isApiMessage: true });
        }
    }
    return result;
}
