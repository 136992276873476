import _ from 'lodash';
import { ENUMS_CONTACTS_EMAIL_USE, ENUMS_CONTACTS_PHONE_USE, } from './';
const contactLimitsConfig = {
    phone: { mobile: 1, home: 1, work: 1 },
    email: { home: 1, work: 1 },
};
export const getAvailableContactsLimit = (items) => {
    let all = 0;
    const limits = _.mapValues(contactLimitsConfig, (group, system) => {
        return _.mapValues(group, (limit, use) => {
            const filtered = _.filter(items, { system, use });
            const count = Math.max(limit - filtered.length, 0);
            all = all + count;
            return Math.max(limit - filtered.length, 0);
        });
    });
    return Object.assign(Object.assign({}, limits), { all });
};
export const orderContactsItems = (items) => {
    return _.orderBy(items, [
        (x) => {
            var _a, _b;
            return (x.system === 'phone' && ((_a = ENUMS_CONTACTS_PHONE_USE[x.use]) === null || _a === void 0 ? void 0 : _a.isPrimary)) ||
                (x.system === 'email' && ((_b = ENUMS_CONTACTS_EMAIL_USE[x.use]) === null || _b === void 0 ? void 0 : _b.isPrimary));
        },
    ], ['desc']);
};
