var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import _ from 'lodash';
import ActionConfirmatorConfig from '@library/utils/actionConfirmator/ActionConfirmator.config';
import { $modal } from '@store';
const ActionConfirmator = () => {
    const [actions, setActions] = useState(ActionConfirmatorConfig);
    useEffect(() => {
        const confirmHandle = ({ detail }) => {
            const { id, skip, once = true, data = {}, callback = null, event } = detail, passedProps = __rest(detail, ["id", "skip", "once", "data", "callback", "event"]);
            if (actions[id]) {
                const actionOptions = _.cloneDeep(actions[id]);
                let skipped = false;
                if (typeof skip !== 'undefined') {
                    if (skip === true || (_.isFunction(skip) && skip())) {
                        skipped = true;
                    }
                }
                else {
                    let defaultSkip;
                    if (actionOptions.hasOwnProperty('skip')) {
                        defaultSkip = actionOptions.skip;
                    }
                    if (defaultSkip === true || (_.isFunction(defaultSkip) && defaultSkip())) {
                        skipped = true;
                    }
                }
                let resultCallback;
                if (!callback && actionOptions.hasOwnProperty('defaultCallback')) {
                    resultCallback = () => {
                        actionOptions.defaultCallback();
                    };
                }
                else if (callback) {
                    resultCallback = callback;
                }
                if (actionOptions.enabled && !skipped) {
                    if (_.isObject(event) && _.isFunction(event.preventDefault)) {
                        event.preventDefault();
                    }
                    if (once && isModalOpened(actionOptions.modalTemplate)) {
                        return false;
                    }
                    $modal.add(actionOptions.modalTemplate, Object.assign({ multi: true, callback: resultCallback, data: _.merge(actionOptions.data, data) }, passedProps));
                }
                else if (resultCallback) {
                    if (_.isObject(event) && _.isFunction(event.preventDefault)) {
                        event.preventDefault();
                    }
                    resultCallback();
                }
            }
        };
        const isModalOpened = (template) => {
            return _.find($modal.current, { template }) || _.find($modal.items, { template });
        };
        const changeHandle = ({ detail }) => {
            const { id, payload } = detail;
            if (actions[id]) {
                setActions((prev) => {
                    const tmp = _.cloneDeep(prev);
                    tmp[id] = _.merge(tmp[id], payload);
                    return tmp;
                });
            }
        };
        window.addEventListener('ac-confirm', confirmHandle);
        window.addEventListener('ac-change', changeHandle);
        return () => {
            window.removeEventListener('ac-confirm', confirmHandle);
            window.removeEventListener('ac-change', changeHandle);
        };
    }, [actions, $modal]);
    return _jsx(_Fragment, {});
};
export const confirmAction = (id, props, event = null) => {
    if (id) {
        window.dispatchEvent(new CustomEvent('ac-confirm', {
            detail: Object.assign(Object.assign({ id }, props), { event }),
        }));
    }
};
export const changeAction = (id, payload) => {
    if (id) {
        window.dispatchEvent(new CustomEvent('ac-change', {
            detail: { id, payload },
        }));
    }
};
export const enableAction = (id) => {
    changeAction(id, { enabled: true });
};
export const disableAction = (id) => {
    changeAction(id, { enabled: false });
};
export default observer(ActionConfirmator);
