import React from 'react'

import classNames from 'classnames'
import Icon from 'ziphy-web-shared/basic/assets/images/Icon'

import styles from './Types.module.scss'

const Audio = ({ src }) => {
  return (
    <div className={classNames(styles.audio, styles.isDefault)}>
      <Icon className={styles.icon} type="audio" />
      <audio controls src={src} />
    </div>
  )
}

export default Audio
