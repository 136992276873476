import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { getScrollToDate } from '../NewDatePicker.helpers';
import { useListRef } from '../NewDatePicker.hooks';
import DayPickerCalendar from './components/dayPickerCalendar/DayPickerCalendar';
import DayPickerMonth from './components/dayPickerMonth/DayPickerMonth';
import DayPickerSelector from './components/dayPickerSelector/DayPickerSelector';
import DayPickerYear from './components/dayPickerYear/DayPickerYear';
import { getCalendarRows, getMonthRows, getYearRows } from './DayPicker.helpers';
const DayPicker = forwardRef(({ currentDate, selectedDate, setDate, minDate, maxDate, shownOtherDays = false, availableDates, disabledDates, forceUpdate, scrollTo, }, ref) => {
    // Internal of date model for switching date
    const [model, setModel] = useState(currentDate.clone());
    useEffect(() => {
        if (!selectedDate) {
            return;
        }
        setModel(selectedDate.clone());
    }, [selectedDate]);
    const [view, setView] = useState('calendar');
    const keyBoard = model.format('YYYYMM');
    const getBoard = (type) => {
        return (keyBoard in board && board[keyBoard][type]) || [];
    };
    const getYearBoard = () => {
        return (keyBoard in board && board[keyBoard]['year']) || [];
    };
    const boardMemo = useMemo(() => {
        return {
            [keyBoard]: {
                day: getCalendarRows(model, shownOtherDays),
                month: getMonthRows(model),
                year: getYearRows(model, minDate, maxDate),
            },
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    const [board, setBoard] = useState(boardMemo);
    useEffect(() => {
        if (!(keyBoard in board)) {
            setBoard((original) => {
                return Object.assign(Object.assign({}, original), { [keyBoard]: {
                        day: getCalendarRows(model, shownOtherDays),
                        month: getMonthRows(model),
                        year: getYearRows(model, minDate, maxDate),
                    } });
            });
        }
    }, [model]); // eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => forceUpdate(), [view]); // eslint-disable-line react-hooks/exhaustive-deps
    useImperativeHandle(ref, () => {
        return {
            getView: () => view,
            goToBack: () => setView('calendar'),
        };
    }, [view]);
    const [refDay, setRefDay] = useListRef();
    // Scroll to day
    useEffect(() => {
        if (view === 'calendar') {
            const scrollToDate = getScrollToDate(selectedDate, currentDate, minDate, maxDate, scrollTo);
            if (!selectedDate) {
                setModel(scrollToDate);
            }
        }
    }, [selectedDate, scrollTo]); // eslint-disable-line react-hooks/exhaustive-deps
    const [refYear, setRefYear] = useListRef();
    // Scroll to selected or current year
    useEffect(() => {
        if (view === 'year') {
            const key = selectedDate ? selectedDate.format('YYYY') : currentDate.format('YYYY');
            if (key in refYear) {
                refYear[key].scrollIntoView({ block: 'center' });
            }
        }
    }, [view]); // eslint-disable-line react-hooks/exhaustive-deps
    return (_jsx("div", Object.assign({ className: "day-picker" }, { children: _jsxs("div", Object.assign({ className: "body" }, { children: [view === 'calendar' && (_jsxs(_Fragment, { children: [_jsx(DayPickerSelector, { model: model, setModel: setModel, setView: setView, minDate: minDate, maxDate: maxDate }), _jsx(DayPickerCalendar, { currentDate: currentDate, selectedDate: selectedDate, model: model, setDate: setDate, minDate: minDate, maxDate: maxDate, availableDays: availableDates === null || availableDates === void 0 ? void 0 : availableDates.day, disabledDays: disabledDates === null || disabledDates === void 0 ? void 0 : disabledDates.day, board: getBoard('day'), setRefDay: setRefDay })] })), view === 'year' && (_jsx(DayPickerYear, { currentDate: currentDate, selectedDate: selectedDate, setModel: setModel, setView: setView, minDate: minDate, maxDate: maxDate, availableYears: availableDates === null || availableDates === void 0 ? void 0 : availableDates.year, board: getYearBoard(), setRefYear: setRefYear })), view === 'month' && (_jsx(DayPickerMonth, { currentDate: currentDate, selectedDate: selectedDate, setModel: setModel, setView: setView, minDate: minDate, maxDate: maxDate, availableMonths: availableDates === null || availableDates === void 0 ? void 0 : availableDates.month, board: getBoard('month') }))] })) })));
});
export default DayPicker;
