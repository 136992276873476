export class ZipAnalytics {
    constructor() {
        this.NOT_SET_ID = 'na';
        this.navTime = new Date().getTime();
        this.navPage = '';
        this.unique_id = this.NOT_SET_ID;
        this.modules = {};
    }
    registerModule(key, module) {
        if (!module) {
            return;
        }
        if (!this.modules[key]) {
            module.register();
            this.modules[key] = module;
        }
    }
    getModule(key) {
        return this.modules[key];
    }
    startSession(id, profile) {
        var _a;
        if (this.unique_id !== id && id !== this.NOT_SET_ID) {
            this.unique_id = id;
            for (const key in this.modules) {
                (_a = this.modules[key]) === null || _a === void 0 ? void 0 : _a.startSession(id, profile);
            }
        }
    }
    endSession() {
        var _a;
        if (this.unique_id !== this.NOT_SET_ID) {
            for (const key in this.modules) {
                (_a = this.modules[key]) === null || _a === void 0 ? void 0 : _a.endSession();
            }
            this.unique_id = this.NOT_SET_ID;
            this.flush();
        }
    }
    flush() {
        var _a;
        for (const key in this.modules) {
            (_a = this.modules[key]) === null || _a === void 0 ? void 0 : _a.flush();
        }
    }
    breadcrumb(event, data) {
        var _a;
        if (event) {
            for (const key in this.modules) {
                (_a = this.modules[key]) === null || _a === void 0 ? void 0 : _a.breadcrumb(event, data);
            }
        }
    }
    error(event, data = {}, priority = false) {
        var _a;
        if (event) {
            for (const key in this.modules) {
                (_a = this.modules[key]) === null || _a === void 0 ? void 0 : _a.error(event, data, priority);
            }
        }
    }
    nav(page) {
        var _a;
        const tm = new Date().getTime();
        const dt = tm - this.navTime;
        if (page) {
            const subIdx = page.lastIndexOf('/');
            if (subIdx > 0) {
                page = page.substring(subIdx);
            }
        }
        for (const key in this.modules) {
            (_a = this.modules[key]) === null || _a === void 0 ? void 0 : _a.nav(this.navPage, page, dt);
        }
        this.navTime = tm;
        this.navPage = page;
    }
    track(event, data, priority = false) {
        var _a;
        if (event) {
            for (const key in this.modules) {
                (_a = this.modules[key]) === null || _a === void 0 ? void 0 : _a.track(event, data, priority);
            }
        }
    }
}
ZipAnalytics.MIXPANEL_KEY = 'mix';
ZipAnalytics.RAYGUN_KEY = 'ray';
ZipAnalytics.CRASHLYTICS_KEY = 'crash';
ZipAnalytics.PORTAL_KEY = 'portal';
export const analytics = new ZipAnalytics();
