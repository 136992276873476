import { useState } from 'react';
import moment from 'moment-timezone';
import { $time } from 'ziphy-web-shared/basic/utils/time';
import { useInterval } from './';
export const useDateNow = (timezone = $time.timezone, updateFrequency = 60) => {
    const [currentTime, setCurrentTime] = useState(moment().tz(timezone));
    useInterval(() => {
        setCurrentTime(moment().tz(timezone));
    }, updateFrequency * 1000);
    return currentTime;
};
