import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import _ from 'lodash';
import { isAbsoluteEmpty } from 'ziphy-web-shared/basic/helpers';
import { CodingOrganizations } from '@fhir/fhir.codings';
const DISABLED_ORGANIZATIONS = [CodingOrganizations.FHIR_ZIPHY];
const ViewCode = ({ codeObj, emptyValue = '—', checkEnabled = true }) => {
    let value = codeObj === null || codeObj === void 0 ? void 0 : codeObj.asString;
    if (checkEnabled) {
        if (_.isEmpty(codeObj === null || codeObj === void 0 ? void 0 : codeObj.system) ||
            _.some(DISABLED_ORGANIZATIONS, (x) => x === codeObj.organizationId)) {
            value = '';
        }
    }
    if (isAbsoluteEmpty(value)) {
        return _jsx(_Fragment, { children: emptyValue });
    }
    return _jsx(_Fragment, { children: value });
};
export default ViewCode;
