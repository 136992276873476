import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import classNames from 'classnames';
import Icon from 'ziphy-web-shared/basic/assets/images/Icon';
import { changeDateMonth } from '../../DayPicker.helpers';
const DayPickerSelector = ({ model, setModel, setView, minDate, maxDate, }) => {
    const handleChangeView = (value) => {
        return () => setView(value);
    };
    const handleSelectMonth = (isNextMonth) => {
        return () => {
            setModel(changeDateMonth(model, isNextMonth));
        };
    };
    const isCanChange = (isNextMonth) => {
        return !isNextMonth ? model.isAfter(minDate, 'month') : model.isBefore(maxDate, 'month');
    };
    return (_jsx("div", Object.assign({ className: "day-picker-selector" }, { children: _jsxs("div", Object.assign({ className: "body" }, { children: [_jsxs(_Fragment, { children: [isCanChange(false) && (_jsx("div", Object.assign({ className: classNames('button', 'prev'), onClick: handleSelectMonth(false) }, { children: _jsx(Icon, { type: "back" }) }))), !isCanChange(false) && _jsx("div", { className: classNames('button', 'prev', 'disabled') })] }), _jsxs("div", Object.assign({ className: "current-date" }, { children: [_jsx("span", Object.assign({ className: "month", onClick: handleChangeView('month') }, { children: model.format('MMMM') })), _jsx("span", Object.assign({ className: "year", onClick: handleChangeView('year') }, { children: model.format('YYYY') }))] })), _jsxs(_Fragment, { children: [isCanChange(true) && (_jsx("div", { className: classNames('button', 'next'), onClick: handleSelectMonth(true) })), !isCanChange(true) && _jsx("div", { className: classNames('button', 'next', 'disabled') })] })] })) })));
};
export default DayPickerSelector;
