import _ from 'lodash'
import { isAbsoluteEmpty } from 'ziphy-web-shared/basic/helpers'

const APPT_MAPPING = {
  service: { source: 'services', map: { serviceId: 'id' }, list: false },
  practiceService: { source: 'practiceServices', map: { practiceServiceId: 'id' }, list: false },
  place: { source: 'places', map: { placeId: 'id' }, list: false },
  office: { source: 'offices', map: { officeId: 'id' }, list: false },
  practice: { source: 'practices', map: { practiceId: 'id' }, list: false },
  encounters: {
    source: 'encounters',
    map: { encounterIds: 'id' },
    sortBy: 'created',
    idsAsKeys: true,
    list: true,
  },
  //
  voucher: { source: 'vouchers', map: { voucherId: 'id' }, list: false },
  payment: { source: 'payments', map: { paymentId: 'id' }, list: false },
  paymentMethod: { source: 'paymentMethods', map: { paymentMethodId: 'id' }, list: false },
  //
  patientSymptoms: { source: 'documents', map: { patientSymptomsId: 'id' }, list: false },
  //
  agent: { source: 'agents', map: { agentRoleId: 'id' }, list: false },
  //
  provider: { source: 'providers', map: { providerRoleId: 'id' }, list: false },
  //
  events: { source: 'events', map: { id: 'appointmentId' }, list: true },
}

export function prepareApptListByMap(sources = {}, appointments = []) {
  let result = []
  appointments = appointments.length ? appointments : _.get(sources, 'appointments.items', [])

  _.forEach(appointments, (appt) => {
    let tmp = { appointment: appt }

    _.forEach(APPT_MAPPING, (cond, resultKey) => {
      const sourceData = sources[cond.source]

      const getByMap = (resource, condition) => {
        let result = _.filter(resource, (item) => {
          if (condition.list) {
            return _.every(condition.map, (sKey, aKey) =>
              _.includes(_.isArray(appt[aKey]) ? appt[aKey] : [appt[aKey]], item[sKey]),
            )
          } else {
            return _.every(condition.map, (sKey, aKey) => _.isEqual(item[sKey], appt[aKey]))
          }
        })

        if (condition.sortBy) {
          result = _.orderBy(result, condition.sortBy, 'asc')
        }

        return result
      }

      if (sourceData) {
        let found = getByMap(sourceData, cond)

        if (found.length) {
          if (cond.idsAsKeys) {
            let newResult = {}
            _.forEach(found, (x) => (newResult[x.id] = x))
            found = newResult
            tmp[resultKey] = found
            return
          }

          tmp[resultKey] = cond.list ? found : found[0]
        }
      }
    })

    // add patients to appointment root. TODO try to move it to MAPPING
    if (!_.isEmpty(tmp.encounters) && !_.isEmpty(sources.patients)) {
      let result = {}
      _.forEach(tmp.encounters, (x) => {
        const { patientId } = x
        const sourcePatient = _.find(sources.patients, (p) => p.id === patientId)
        const notCancelled = isAbsoluteEmpty(x.cancelTime)

        if (sourcePatient) {
          if (result[patientId]) {
            result[patientId] = {
              ...result[patientId],
              encounterIds: [...result[patientId].encounterIds, x.id],
            }
          } else {
            result[patientId] = { ...sourcePatient, encounterIds: [x.id] }
          }
          if (notCancelled) {
            result[patientId].activeEncounter = x.id
          }
        }
      })

      _.forEach(result, (patient, patientId) => {
        // if all encounters are cancelled take the newest encounter as active for navigation purpose
        if (!patient.activeEncounter) {
          let tempEncounters = []
          _.forEach(patient.encounterIds, (x) => {
            tempEncounters.push(tmp.encounters[x])
          })
          tempEncounters = _.sortBy(tempEncounters, ['updated'], 'asc')
          result[patientId].activeEncounter = _.last(tempEncounters).id
        }
      })

      tmp.patients = result
    }

    if (tmp.place && _.toLower(tmp.place.timezone) === 'asia/calcutta') {
      tmp.place.timezone = 'Asia/Kolkata'
    }

    result.push(tmp)
  })

  return result
}
