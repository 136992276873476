var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { $paymentMethods, paymentMethodIsExpired, } from 'ziphy-web-shared/basic/entities/paymentMethods';
import { useLoader } from 'ziphy-web-shared/basic/hooks';
import Modal from '@library/modal/Modal';
import ModalToolbarBtn from '@library/modal/toolbar/ModalToolbarBtn';
import PaymentMethodsList from '../paymentMethodsList/PaymentMethodsList';
const SelectPaymentMethodModal = ({ _core, selectedId, onSelectId }) => {
    const { t } = useTranslation();
    const { isLoading } = useLoader(() => __awaiter(void 0, void 0, void 0, function* () {
        yield $paymentMethods.list();
    }));
    const method = $paymentMethods.getById(selectedId);
    function handleOnSelect(id) {
        onSelectId(id);
        _core.onHide();
    }
    return (_jsxs(Modal, Object.assign({ centered: true, scrollable: true }, { children: [_jsx("div", Object.assign({ className: "modal-toolbar" }, { children: _jsx(ModalToolbarBtn, { type: "close", action: _core.onHide }) })), _jsx(Modal.Header, { children: t('label.payment_methods') }), _jsxs(Modal.Body, Object.assign({ isLoading: isLoading }, { children: [paymentMethodIsExpired(method) && (_jsx("div", Object.assign({ className: "alert alert--danger mb-20" }, { children: t('pms.error.expired') }))), _jsx(PaymentMethodsList, { selectedId: selectedId, onSelectId: handleOnSelect, isClickable: true })] }))] })));
};
SelectPaymentMethodModal.displayName = 'SelectPaymentMethodModal';
export default observer(SelectPaymentMethodModal);
