var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { forwardRef, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { IMaskInput } from 'react-imask';
import classNames from 'classnames';
import _ from 'lodash';
import { getUuid, isAbsoluteEmpty } from 'ziphy-web-shared/basic/helpers';
import { useInnerValue } from 'ziphy-web-shared/basic/hooks';
const initField = {
    value: '',
};
const InputBase = forwardRef((_a, forwardedRef) => {
    var { field = initField, 
    //
    onClick, onClear, type = 'text', className, fieldClassName, label, labelClamp = 1, headerActions = [], placeholder, id: _id, clear = false, readonly = false, mode, children, mask, icon = {}, onRemove, canRemove = false, unFocusComponent } = _a, fieldAttributes = __rest(_a, ["field", "onClick", "onClear", "type", "className", "fieldClassName", "label", "labelClamp", "headerActions", "placeholder", "id", "clear", "readonly", "mode", "children", "mask", "icon", "onRemove", "canRemove", "unFocusComponent"]);
    const [isFocused, setIsFocused] = useState(false);
    const isUnfocused = unFocusComponent && !isFocused;
    const { value, onChange, isInvalid } = field;
    const [innerValue, setInnerValue] = useInnerValue(value);
    const id = useMemo(() => (_id ? _id : getUuid()), [_id]);
    function handleOnChange(value) {
        setInnerValue(value);
        if (_.isFunction(onChange)) {
            onChange(value);
        }
    }
    function handleClear(e) {
        if (e && _.isFunction(e.stopPropagation)) {
            e.preventDefault();
            e.stopPropagation();
        }
        handleOnChange('');
        if (_.isFunction(onClear)) {
            onClear();
        }
    }
    function getMode(mode) {
        const array = _.isArray(mode) ? mode : [mode];
        return array.map((x) => 'tsform-item--' + x).join(' ');
    }
    function handleFocus(e) {
        if (readonly) {
            e.preventDefault();
            return;
        }
        if (unFocusComponent) {
            setIsFocused(true);
        }
        if (_.isFunction(fieldAttributes.onFocus)) {
            fieldAttributes.onFocus(e);
        }
    }
    function handleBlur(e) {
        if (isFocused) {
            setIsFocused(false);
        }
        if (_.isFunction(fieldAttributes.onBlur)) {
            fieldAttributes.onBlur(e);
        }
    }
    const fieldRef = useRef(null);
    // @ts-ignore
    useImperativeHandle(forwardedRef, () => ({
        focus() {
            fieldRef.current.focus();
        },
        clear() {
            handleClear();
        },
    }));
    return (_jsxs("div", Object.assign({ className: classNames(className, 'tsform-item', 'tsform-item--input', mode && getMode(mode), isInvalid && 'tsform-item--error', readonly && 'tsform-item--readonly', isUnfocused && 'tsform-item--unfocused', canRemove && _.isFunction(onRemove) && 'tsform-item--with_remove') }, { children: [_jsxs("label", Object.assign({ className: classNames('tsform-item-inner', icon && icon.class && 'tsform-item-inner--' + icon.class) }, { children: [(label || headerActions.length > 0) && (_jsxs("div", Object.assign({ className: "tsform-header" }, { children: [label && (_jsx("div", Object.assign({ className: classNames('tsform-label', labelClamp && 'tsform-label-clamp-' + labelClamp) }, { children: label }))), headerActions.length > 0 &&
                                headerActions.map((action) => (_jsx("div", Object.assign({ className: "tsform-header-action", onClick: () => action.onClick() }, { children: action.text }), action.text)))] }))), _jsxs("div", Object.assign({ className: "tsform-body" }, { children: [_jsxs("div", Object.assign({ className: classNames('tsform-field', fieldClassName) }, { children: [isUnfocused && unFocusComponent, mask ? (_jsx(_Fragment, { children: _jsx(IMaskInput, Object.assign({ inputRef: (el) => (fieldRef.current = el), type: type, value: String(innerValue), onAccept: (value, masked) => {
                                                if (mask.onOutput) {
                                                    value = mask.onOutput(value, masked);
                                                }
                                                handleOnChange(value);
                                            }, id: id, placeholder: placeholder, onClick: onClick, readOnly: readonly, onFocus: handleFocus, onBlur: handleBlur }, _.omit(fieldAttributes, ['onFocus', 'onBlur']), { 
                                            //
                                            overwrite: "shift", radix: "." }, _.omit(mask, 'onOutput'))) })) : _.isEmpty(children) ? (_jsx("input", Object.assign({ ref: fieldRef, type: type, value: innerValue, onChange: (e) => handleOnChange(e.target.value), id: id, placeholder: placeholder, onClick: onClick, readOnly: readonly, onFocus: handleFocus, onBlur: handleBlur }, _.omit(fieldAttributes, ['onFocus', 'onBlur'])))) : (children), clear && !readonly && !isAbsoluteEmpty(innerValue) && (_jsx("div", { className: "tsform-field-clear", onClick: handleClear }))] })), icon && (_jsx("div", Object.assign({ className: classNames('tsform-icon', icon.class && 'tsform-icon--' + icon.class), onClick: icon.action }, { children: typeof icon.img === 'string' ? _jsx("img", { src: icon.img, alt: "" }) : icon.img })))] }))] })), canRemove && _.isFunction(onRemove) && (_jsx("div", { className: "tsform-item-remove_button", onClick: onRemove }))] })));
});
export default observer(InputBase);
