var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { forwardRef } from 'react';
import Modal from '@library/modal/Modal';
import NewDatePicker from './NewDatePicker';
const NewDatePickerModal = forwardRef((_a, ref) => {
    var { _core } = _a, props = __rest(_a, ["_core"]);
    return (_jsx(Modal, Object.assign({ centered: true, scrollable: true }, { children: _jsx(Modal.Body, { children: _jsx(NewDatePicker, Object.assign({ onHide: _core.onHide, ref: ref }, props)) }) })));
});
NewDatePickerModal.displayName = 'NewDatePickerModal';
export default NewDatePickerModal;
