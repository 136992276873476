var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import _ from 'lodash';
// condition: {
//   and: [{ field: 'resource', eq: 'Observation' }],
//   or: [
//     { field: 'subtype', eq: ['medical_history'] },
//     { field: 'subtype', eq: ['medical_history', 'lab'] },
//   ],
// },
// condition: [
//   { field: 'resource', eq: 'Observation' },
//   { field: 'subtype', eq: ['medical_history', 'lab'] },
// ],
// type Condition = { field: 'resource' | 'subtype'; eq: string | string[] }
export const match = (data, conditions = [], { byKey = 'condition', findFirst = false } = {}) => {
    function _iterateAnd(items, obj) {
        let isDone = true;
        if (!items) {
            return false;
        }
        _.forEach(items, (item) => {
            isDone = _matchConditions(item, obj);
            if (!isDone)
                return false;
        });
        return isDone;
    }
    function _iterateOr(items, obj) {
        let isDone = false;
        if (_.isArray(items) && _.isArray(items[0])) {
            _.forEach(items, (item) => {
                isDone = _iterateAnd(item, obj);
                if (isDone)
                    return false;
            });
        }
        else {
            _.forEach(items, (item) => {
                isDone = _matchConditions(item, obj);
                if (isDone)
                    return false;
            });
        }
        return isDone;
    }
    function _matchConditions(item, obj) {
        let isDone = true;
        const { field } = item, otherFields = __rest(item, ["field"]);
        const value = _.get(obj, field);
        _.forEach(otherFields, (needle, type) => {
            isDone = _matchValue(type, needle, value);
            if (!isDone) {
                return false;
            }
        });
        return isDone;
    }
    function _matchValue(type, needle, value) {
        let tmp;
        if (_.isArray(needle)) {
            needle = _.sortBy(needle);
        }
        if (_.isArray(value)) {
            value = _.sortBy(value);
        }
        switch (_.toLower(type)) {
            case 'eq':
                return _.isEqual(value, needle);
            case 'ne':
                return !_.isEqual(value, needle);
            case 'lt':
                return value < needle;
            case 'lte':
                return value <= needle;
            case 'gt':
                return value > needle;
            case 'gte':
                return value >= needle;
            case 'in':
                tmp = _.isArray(needle) ? needle : [needle];
                return tmp.every((x) => _.includes(value, x));
            case 'nin':
                tmp = _.isArray(needle) ? needle : [needle];
                return tmp.every((x) => !_.includes(value, x));
            case 'sin': // some in
                tmp = _.isArray(needle) ? needle : [needle];
                return tmp.some((x) => _.includes(value, x));
            case 'snin': // some nin
                tmp = _.isArray(needle) ? needle : [needle];
                return tmp.some((x) => !_.includes(value, x));
            case 'exists':
                tmp = typeof value === 'undefined' || value === '';
                return tmp !== needle;
        }
        return false;
    }
    let result = [];
    _.forEach(conditions, (obj) => {
        const condition = obj[byKey] || obj;
        let andFound;
        let orFound;
        if ((condition === null || condition === void 0 ? void 0 : condition.and) || _.isArray(condition)) {
            andFound = _iterateAnd(condition.and || condition, data);
        }
        else {
            andFound = true;
        }
        if (condition === null || condition === void 0 ? void 0 : condition.or) {
            orFound = _iterateOr(condition.or, data);
        }
        else {
            orFound = true;
        }
        if (andFound && orFound) {
            result.push(obj);
            if (findFirst) {
                return false;
            }
        }
    });
    return findFirst ? _.first(result) : result;
};
// pathWithConditions = [
//   'modifierExtension',
//   [{
//     field: 'url',
//     eq: 'https://fhir.ziphy.com/1.0/StructureDefinition/observation-travelLocation',
//   }],
//   'valueString',
// ]
// condition: {
//   and: [{ field: 'resource', eq: 'Observation' }],
//   or: [
//     { field: 'subtype', eq: ['medical_history'] },
//     { field: 'subtype', eq: ['medical_history', 'lab'] },
//   ],
// },
// condition: [
//   { field: 'resource', eq: 'Observation' },
//   { field: 'subtype', eq: ['medical_history', 'lab'] },
// ],
export const getByPath = (data, pathWithConditions, multi = false) => {
    let target = data;
    let path = [...pathWithConditions];
    while (path.length) {
        if (_.isEmpty(target)) {
            break;
        }
        const currentPathEl = path[0];
        path.shift();
        if (typeof currentPathEl === 'string') {
            // if path = 'string' just get data by path
            if (_.isArray(target)) {
                let newTarget = [];
                _.forEach(target, (item) => {
                    const el = _.get(item, currentPathEl);
                    if (_.isArray(el)) {
                        newTarget = _.concat(newTarget, el);
                    }
                    else {
                        newTarget.push(el);
                    }
                });
                target = newTarget;
            }
            else {
                target = _.get(target, currentPathEl);
            }
        }
        else {
            // if path = condition object extract data by condition
            let newTarget = [];
            _.every(target, (item) => {
                if (!_.isEmpty(match(item, [currentPathEl]))) {
                    if (_.isArray(item)) {
                        _.concat(newTarget, item);
                    }
                    else {
                        newTarget.push(item);
                    }
                    if (!multi) {
                        return false; // stop iterating on first match
                    }
                }
                return true;
            });
            target = newTarget;
        }
    }
    return _.isArray(target) ? target : [target];
};
