import { makeAutoObservable } from 'mobx';
import ExtractorBase from '../extractors/Base';
import { prepareMeta, prepareValueDateTime } from '../fhir.converters';
import { getCodingObjects, getExtensionObjects, getIdentifierObjects } from '../fhir.helpers';
import { joinArrayWithObjectToString, joinValuesToString } from '../fhir.utils';
class ExtractorCondition {
    constructor(resource) {
        this.base = new ExtractorBase(this);
        this.base.SET(resource);
        makeAutoObservable(this);
    }
    get resource() {
        return this.base.resource;
    }
    /*
     * RESOURCE - Minimally prepared resource data, prepareConverters, valueObjects, etc.
     */
    get extensions() {
        return getExtensionObjects(this.resource.extension);
    }
    get identifiers() {
        return getIdentifierObjects(this.resource.identifier);
    }
    get categories() {
        return getCodingObjects(this.resource.category);
    }
    get codes() {
        return getCodingObjects(this.resource.code);
    }
    get clinicalStatuses() {
        return getCodingObjects(this.resource.clinicalStatus);
    }
    get verificationStatuses() {
        return getCodingObjects(this.resource.verificationStatus);
    }
    // other in the FHIR - Group
    get subject() {
        return this.base.getChildExtractor(this.resource.subject);
    }
    get asserter() {
        return this.base.getChildExtractor(this.resource.asserter);
    }
    get recorder() {
        return this.base.getChildExtractor(this.resource.recorder);
    }
    get recordedDate() {
        return prepareValueDateTime(this.resource.recordedDate);
    }
    get meta() {
        return prepareMeta(this.resource.meta);
    }
    /*
     * READ - PREPARED Values
     */
    get zid() {
        return this.identifiers.first({ config: 'zid' });
    }
    get codeObj() {
        return this.codes.first();
    }
    get clinicalStatus() {
        return this.clinicalStatuses.first();
    }
    get verificationStatus() {
        return this.verificationStatuses.first();
    }
    get notes() {
        return this.resource.note;
    }
    /*
     * STRING Values
     */
    get asString() {
        var _a, _b, _c, _d;
        const tmp = {
            codeDisplay: (_a = this.codeObj) === null || _a === void 0 ? void 0 : _a.display,
            notes: joinArrayWithObjectToString(this.notes, 'text', '; '),
            clinicalStatus: (_b = this.clinicalStatus) === null || _b === void 0 ? void 0 : _b.display,
            verificationStatus: (_c = this.verificationStatus) === null || _c === void 0 ? void 0 : _c.display,
            recordedDate: (_d = this.recordedDate) === null || _d === void 0 ? void 0 : _d.asString,
            full: '',
        };
        tmp.full = joinValuesToString([tmp.codeDisplay, tmp.notes, tmp.clinicalStatus, tmp.verificationStatus], ' • ');
        return tmp;
    }
}
export default ExtractorCondition;
