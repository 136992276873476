var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { MockMedicationResources } from 'ziphy-web-shared/basic/entities/medical/medications/mock';
import { BaseService } from '../../baseService';
const REVIEWED_CODE = {
    coding: [
        {
            system: 'https://fhir.ziphy.com',
            code: 'medication_review_with_patient_procedure',
            display: 'Medication reviewed and confirmed with the patient',
        },
    ],
    text: 'Medication reviewed and confirmed with the patient',
};
export class Medications extends BaseService {
    constructor(props) {
        super(props);
    }
    save(params, options) {
        return __awaiter(this, void 0, void 0, function* () {
            let res;
            let resultParams = { encounterId: params.encounterId, medications: {} };
            if ('resources' in params) {
                resultParams.medications.resources = params.resources;
            }
            if ('reviewedSection' in params) {
                const { resourceId, value } = params.reviewedSection;
                resultParams.medications.additionalPerEncounter = [
                    Object.assign(Object.assign({}, (resourceId && { resourceId })), { data: { code: REVIEWED_CODE, value } }),
                ];
            }
            res = yield this.api.fetchLoggedV3('patient_stories.save_notes', resultParams, options);
            return Object.assign(Object.assign({}, res), { preparedValue: res.prepared.result });
        });
    }
    read(params, options) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            let res;
            res = yield this.api.fetchLoggedV3('patient_stories.get_resource', Object.assign(Object.assign({}, params), { section: 'medications' }), options);
            // mock
            if (!res.error)
                res.prepared.resource = MockMedicationResources[0];
            // mock
            return Object.assign(Object.assign({}, res), { preparedValue: (_a = res.prepared) === null || _a === void 0 ? void 0 : _a.resource });
        });
    }
    list(params, options) {
        var _a, _b, _c, _d, _e;
        return __awaiter(this, void 0, void 0, function* () {
            let res;
            res = yield this.api.fetchLoggedV3('patient_stories.read_notes', Object.assign(Object.assign({}, params), { sections: ['medications'] }), options);
            // mock
            if (!res.error)
                res.prepared.medications.resources = MockMedicationResources;
            // mock
            const preparedValue = {};
            preparedValue.resources = ((_b = (_a = res.prepared) === null || _a === void 0 ? void 0 : _a.medications) === null || _b === void 0 ? void 0 : _b.resources) || [];
            preparedValue.reviewedSection = (_e = (_d = (_c = res.prepared) === null || _c === void 0 ? void 0 : _c.medications) === null || _d === void 0 ? void 0 : _d.additionalPerEncounter) === null || _e === void 0 ? void 0 : _e.find((x) => {
                return 'code' in x.data ? x.data.code.text === REVIEWED_CODE.text : false;
            });
            return Object.assign(Object.assign({}, res), { preparedValue });
        });
    }
}
