import { useLayoutEffect, useRef } from 'react';
export const useScrollToDependency = (options) => {
    const prevDependency = useRef(null);
    const prevContentRect = useRef(null);
    const scrollRef = useRef(null);
    const contentRef = useRef(null);
    const { scrollDependency, changeContentDependency, horizontalScrollToElement } = options;
    useLayoutEffect(() => {
        if (!scrollRef.current || !contentRef.current) {
            return;
        }
        const resizeObserver = new ResizeObserver((entries) => {
            for (const entry of entries) {
                if (scrollDependency !== prevDependency.current) {
                    prevDependency.current = scrollDependency;
                    if (prevContentRect.current === null) {
                        prevContentRect.current = entry.contentRect;
                        if (horizontalScrollToElement === null || horizontalScrollToElement === void 0 ? void 0 : horizontalScrollToElement.current) {
                            scrollRef.current.scrollLeft =
                                horizontalScrollToElement.current.offsetLeft -
                                    scrollRef.current.offsetWidth / 2 +
                                    horizontalScrollToElement.current.offsetWidth / 2;
                            resizeObserver.disconnect();
                            return;
                        }
                    }
                    if (prevContentRect.current.height !== entry.contentRect.height) {
                        scrollRef.current.scrollTop = entry.contentRect.height - prevContentRect.current.height;
                    }
                    if (prevContentRect.current.width !== entry.contentRect.width) {
                        scrollRef.current.scrollLeft =
                            entry.contentRect.width - prevContentRect.current.width - 200;
                    }
                    prevContentRect.current = entry.contentRect;
                    resizeObserver.disconnect();
                }
            }
        });
        resizeObserver.observe(contentRef.current);
        return () => resizeObserver.disconnect();
    }, [scrollDependency]);
    useLayoutEffect(() => {
        if (!scrollRef.current || !contentRef.current) {
            return;
        }
        const resizeObserver = new ResizeObserver((entries) => {
            for (const entry of entries) {
                prevContentRect.current = entry.contentRect;
                resizeObserver.disconnect();
            }
        });
        resizeObserver.observe(contentRef.current);
        return () => resizeObserver.disconnect();
    }, [changeContentDependency]);
    return { scrollRef, contentRef };
};
