import React from 'react';
import { toJS } from 'mobx';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
export function getUuid(length) {
    let str = uuidv4();
    if (length) {
        str = str.replace(/-/g, '').substring(0, length);
    }
    return str;
}
export function getRandomNumber(min, max) {
    return Math.ceil(Math.random() * (max - min) + min);
}
export function getRandomString(length = 30) {
    let result = '';
    let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}
export function isAbsoluteEmpty(value) {
    if (_.isArray(value) || _.isObject(value)) {
        return _.isEmpty(value);
    }
    return (value !== 0 && !value) || String(value).trim() === '';
}
export function isJson(str) {
    try {
        JSON.parse(str);
        return true;
    }
    catch (e) {
        return false;
    }
}
export function deepCleanObject(obj) {
    return _.cloneDeepWith(toJS(obj), (value) => {
        if (React.isValidElement(value))
            return null;
        if (typeof value === 'function')
            return null;
    });
}
export function pushOrUpdate(arr = [], value, { byKey = 'id', pushTo = 'end', merge = false } = {}) {
    const index = _.findIndex(arr, (x) => x[byKey] === value[byKey]);
    if (index === -1) {
        if (pushTo === 'start') {
            arr = [value, ...arr];
        }
        else {
            arr = [...arr, value];
        }
    }
    else {
        let tmp = _.cloneDeep(arr);
        if (merge) {
            tmp[index] = _.merge(tmp[index], value);
        }
        else {
            tmp[index] = value;
        }
        arr = tmp;
    }
    return arr;
}
export function parseJwt(token) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(window
        .atob(base64)
        .split('')
        .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    })
        .join(''));
    return JSON.parse(jsonPayload);
}
