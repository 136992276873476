import { makeAutoObservable } from 'mobx';
import ExtractorBase from '../extractors/Base';
import { prepareMeta } from '../fhir.converters';
import { getCodingObjects, getExtensionObjects, getIdentifierObjects } from '../fhir.helpers';
import { joinValuesToString } from '../fhir.utils';
class ExtractorMedication {
    constructor(resource) {
        this.base = new ExtractorBase(this);
        this.base.SET(resource);
        makeAutoObservable(this);
    }
    get resource() {
        return this.base.resource;
    }
    /*
     * RESOURCE - Minimally prepared resource data, prepareConverters, valueObjects, etc.
     */
    get extensions() {
        return getExtensionObjects(this.resource.extension);
    }
    get identifiers() {
        return getIdentifierObjects(this.resource.identifier);
    }
    get codes() {
        return getCodingObjects(this.resource.code);
    }
    get forms() {
        return getCodingObjects(this.resource.form);
    }
    get manufacturer() {
        return this.base.getChildExtractor(this.resource.manufacturer);
    }
    get status() {
        return this.resource.status;
    }
    get meta() {
        return prepareMeta(this.resource.meta);
    }
    /*
     * READ - PREPARED Values
     */
    get zid() {
        return this.identifiers.first({ config: 'zid' });
    }
    get codeObj() {
        return this.codes.first();
    }
    /*
     * STRING Values
     */
    get asString() {
        var _a;
        const tmp = {
            codeDisplay: (_a = this.codeObj) === null || _a === void 0 ? void 0 : _a.display,
            full: '',
        };
        tmp.full = joinValuesToString([tmp.codeDisplay], ' • ');
        return tmp;
    }
}
export default ExtractorMedication;
