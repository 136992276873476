var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { BaseService } from '../baseService';
export class Schedules extends BaseService {
    constructor(props) {
        super(props);
    }
    stat(params, options) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function* () {
            let res;
            res = yield this.api.fetchLoggedV3('schedules.stat', params, options);
            return Object.assign(Object.assign({}, res), { preparedValue: res.prepared.stat
                    ? Object.assign(Object.assign({}, res.prepared.stat), { items: ((_b = (_a = res.prepared.stat) === null || _a === void 0 ? void 0 : _a.items) === null || _b === void 0 ? void 0 : _b.items) || [] }) : undefined });
        });
    }
    findWindows(params, options) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function* () {
            let res;
            res = yield this.api.fetchLoggedV3('schedules.find_windows', params, options);
            return Object.assign(Object.assign({}, res), { preparedValue: res.prepared.windows
                    ? Object.assign(Object.assign({}, res.prepared.windows), { items: ((_b = (_a = res.prepared.windows) === null || _a === void 0 ? void 0 : _a.items) === null || _b === void 0 ? void 0 : _b.items) || [] }) : undefined });
        });
    }
    report(params, options) {
        return __awaiter(this, void 0, void 0, function* () {
            let res;
            res = yield this.api.fetchLoggedV3('schedules.report', params, Object.assign({ prepareResultParams: { mapping: true } }, options));
            // if (res.prepared.travels.length === 0) {
            //   res.prepared.travels = [
            //     {
            //       id: 10001,
            //       appointedStart: '2024-11-04T22:15:00Z',
            //       appointedFinish: '2024-11-04T23:00:00Z',
            //       roleId: 10,
            //     },
            //   ]
            // }
            return Object.assign(Object.assign({}, res), { preparedValue: res.prepared });
        });
    }
}
