import i18n from 'i18next';
import _ from 'lodash';
import { ENUMS_PERSON_ETHNICITY, ENUMS_PERSON_GENDER, ENUMS_PERSON_GENDER_IDENTITY, ENUMS_PERSON_MARITAL_STATUS, ENUMS_PERSON_PRONOUN, ENUMS_PERSON_PS_GENDER, ENUMS_PERSON_RACE, } from 'ziphy-web-shared/basic/entities/persons';
import { getEnumObjectValue, getFullName } from 'ziphy-web-shared/basic/helpers';
import { formatAsAge, formatDate } from 'ziphy-web-shared/basic/utils/time';
function getUserFullName({ firstName, lastName, name, }) {
    if (firstName || lastName) {
        return getFullName({ firstName, lastName });
    }
    return name;
}
function getUserGender(value, isShort) {
    var _a;
    let tKey = (_a = (ENUMS_PERSON_GENDER[value] || ENUMS_PERSON_PS_GENDER[value])) === null || _a === void 0 ? void 0 : _a.tKey;
    let result = i18n.exists(tKey) ? i18n.t(tKey) : value;
    return isShort ? result.substring(0, 1) : result;
}
function getUserAge(date, withText) {
    return formatAsAge(date, withText);
}
var Format;
(function (Format) {
    Format["FULL_NAME"] = "fullName";
    Format["GENDER"] = "gender";
    Format["GENDER_SHORT"] = "genderShort";
    Format["AGE"] = "age";
    Format["YO"] = "yo";
    Format["AGE_TEXT"] = "ageText";
    Format["DOB"] = "dob";
    Format["PRONOUN"] = "pronoun";
    Format["GENDER_IDENTITY"] = "genderIdentity";
    Format["RACE"] = "race";
    Format["ETHNICITY"] = "ethnicity";
    Format["MARTIAL_STATUS"] = "maritalStatus";
})(Format || (Format = {}));
export function getUserText(data = {}, format = [], delimiter = ', ') {
    let gender;
    if ('gender' in data) {
        gender = data.gender;
    }
    let dob;
    if ('dob' in data) {
        dob = data.dob;
    }
    else if ('birthdate' in data) {
        dob = data.birthdate;
    }
    else if ('birthDate' in data) {
        dob = data.birthDate;
    }
    let result = [];
    _.forEach(format, (item) => {
        if (item === 'fullName') {
            let fullName;
            if ('fullName' in data) {
                fullName = data.fullName;
            }
            else if ('firstName' in data || 'lastName' in data) {
                fullName = getUserFullName({ firstName: data.firstName, lastName: data.lastName });
            }
            else if (typeof data.name === 'string') {
                fullName = data.name;
            }
            result.push(fullName);
        }
        else if (item === 'gender') {
            result.push(getUserGender(gender, false));
        }
        else if (item === 'genderShort') {
            result.push(getUserGender(gender, true));
        }
        else if (item === 'age') {
            result.push(getUserAge(dob, false));
        }
        else if (item === 'yo') {
            result.push(getUserAge(dob, true));
        }
        else if (item === 'ageText') {
            result.push(i18n.t('user.age') + ` ${getUserAge(dob, false)}`);
        }
        else if (item === 'dob') {
            result.push(formatDate(dob));
        }
        else if (item === 'pronoun' && 'pronoun' in data) {
            const value = data.pronoun;
            result.push(getEnumObjectValue(value, ENUMS_PERSON_PRONOUN).value);
        }
        else if (item === 'genderIdentity' && 'genderIdentity' in data) {
            const value = data.genderIdentity;
            result.push(getEnumObjectValue(value, ENUMS_PERSON_GENDER_IDENTITY).value);
        }
        else if (item === 'race' && 'race' in data) {
            const value = data.race;
            result.push(getEnumObjectValue(value, ENUMS_PERSON_RACE).value);
        }
        else if (item === 'ethnicity' && 'ethnicity' in data) {
            const value = data.ethnicity;
            result.push(getEnumObjectValue(value, ENUMS_PERSON_ETHNICITY).value);
        }
        else if (item === 'maritalStatus' && 'maritalStatus' in data) {
            const value = data.maritalStatus;
            result.push(getEnumObjectValue(value, ENUMS_PERSON_MARITAL_STATUS).value);
        }
    });
    return result.filter((x) => x).join(delimiter);
}
