var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEffect, useState } from 'react';
export const useLoader = (callback, deps = []) => {
    const [isLoading, setIsLoading] = useState(true);
    const [updater, setUpdater] = useState(0);
    useEffect(() => {
        let isActive = true;
        const func = () => __awaiter(void 0, void 0, void 0, function* () {
            setIsLoading(true);
            yield callback();
            if (isActive) {
                setIsLoading(false);
            }
        });
        func();
        return () => {
            isActive = false;
        };
    }, [updater, ...deps]);
    return { isLoading, reload: () => setUpdater((x) => x + 1) };
};
