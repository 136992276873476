import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { Fragment } from 'react';
import classNames from 'classnames';
import _ from 'lodash';
const DayPickerMonth = ({ currentDate, selectedDate, setModel, setView, minDate, maxDate, availableMonths, board, }) => {
    const handleSelectMonth = (value) => {
        return () => {
            setModel(value);
            setView('calendar');
        };
    };
    const isSelected = (value) => {
        if (!selectedDate) {
            return false;
        }
        return value.isSame(selectedDate, 'month');
    };
    const isCurrent = (value) => {
        return value.isSame(currentDate, 'month');
    };
    const isLocked = (value) => {
        if (!_.isEmpty(availableMonths)) {
            return !(value.format('YYYY-MM') in availableMonths);
        }
        else {
            return value.isBefore(minDate, 'month') || value.isAfter(maxDate, 'month');
        }
    };
    return (_jsx("div", Object.assign({ className: "day-picker-month" }, { children: _jsx("div", Object.assign({ className: "body" }, { children: board.map((cells, rowIndex) => (_jsx("div", Object.assign({ className: "row-month" }, { children: cells.map(({ text, value }, i) => (_jsxs(Fragment, { children: [!isLocked(value) && (_jsx("div", Object.assign({ className: classNames('cell', {
                                'cell-selected': isSelected(value),
                                'cell-current': isCurrent(value),
                            }), onClick: handleSelectMonth(value) }, { children: text }))), isLocked(value) && (_jsx("div", Object.assign({ className: classNames('cell', {
                                'cell-selected': isSelected(value),
                                'cell-current': isCurrent(value),
                                'cell-locked': true,
                            }) }, { children: text })))] }, value.toString() + i))) }), rowIndex))) })) })));
};
export default DayPickerMonth;
