export const ENUMS_ACTOR_ROLE = {
    patient: {
        id: 'patient',
        tKey: 'enums.subject_role.patient',
    },
    related_person: {
        id: 'related_person',
        tKey: 'enums.subject_role.related_person',
    },
    practitioner: {
        id: 'practitioner',
        tKey: 'enums.subject_role.practitioner',
    },
    agent: {
        id: 'agent',
        tKey: 'enums.subject_role.agent',
    },
    provider: {
        id: 'provider',
        tKey: 'enums.subject_role.provider',
    },
    organization: {
        id: 'organization',
        tKey: 'enums.subject_role.organization',
    },
};
