import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import _ from 'lodash';
import Icon from 'ziphy-web-shared/basic/assets/images/Icon';
import { useInnerValue } from 'ziphy-web-shared/basic/hooks';
import { analytics } from 'ziphy-web-shared/basic/utils/analytics';
import { getFilteredItems } from '../MultiSelect.helpers';
import styles from './MultiSelectDropdown.module.scss';
const MultiSelectDropdown = ({ onSelected, onOpen, selection: _selection = [], items = [], multiSelect, allowUnselect, allOption, search, selectBtnWidth, }) => {
    const { t } = useTranslation();
    const [selection, setSelection] = useInnerValue(_selection);
    const [searchString, setSearchString] = useState('');
    const filteredItems = useMemo(() => {
        return getFilteredItems(searchString, items);
    }, [items, searchString]);
    function isSelected(element) {
        return selection.indexOf(element.id) !== -1;
    }
    function handleCheck(id) {
        let result = _.cloneDeep(selection);
        if (!allowUnselect && result.length === 1 && result.includes(id)) {
            if (!multiSelect) {
                onOpen(false);
            }
            return;
        }
        analytics.track('dropdown-select', {
            multi: multiSelect,
            id: id,
        });
        if (multiSelect) {
            const index = result.indexOf(id);
            if (index !== -1) {
                result.splice(index, 1);
            }
            else {
                result.push(id);
            }
            setSelection(result);
            onSelected(result);
        }
        else {
            if (id === selection[0]) {
                result = [];
            }
            else {
                result = [id];
            }
            onOpen(false);
            setSelection(result);
            onSelected(result);
        }
    }
    const handleSelectAll = () => {
        setSelection([]);
        onSelected([]);
        onOpen(false);
    };
    function handleSearch(event) {
        var _a;
        setSearchString((_a = event === null || event === void 0 ? void 0 : event.target) === null || _a === void 0 ? void 0 : _a.value);
    }
    function handleClearSearch(e) {
        e.preventDefault();
        setSearchString('');
    }
    return (_jsxs("div", Object.assign({ className: styles.dropdown }, { children: [search && (_jsx("div", Object.assign({ className: styles.search }, { children: _jsxs("div", Object.assign({ className: styles.searchItem }, { children: [_jsx("input", { type: "text", value: searchString, onChange: (e) => handleSearch(e), className: styles.searchField }), searchString && (_jsx("div", { className: styles.searchClear, onClick: (e) => handleClearSearch(e) }))] })) }))), _jsx("div", Object.assign({ className: classNames(styles.inner, 'styled_scroll'), style: { maxHeight: search ? 300 + 'px' : 360 + 'px' } }, { children: filteredItems.length > 0 && filteredItems[0].items.length > 0
                    ? filteredItems.map((item) => (_jsxs("div", { children: [item.title && _jsx("div", Object.assign({ className: styles.itemTitle }, { children: item.title && item.title })), _jsxs("ul", { children: [allOption && (_jsxs("li", Object.assign({ className: styles.itemSub, onClick: () => handleSelectAll() }, { children: [_jsx("p", Object.assign({ className: styles.text }, { children: allOption })), selection.length === 0 && (_jsx("div", Object.assign({ className: styles.isCheck }, { children: _jsx(Icon, { type: 'check' }) })))] }))), item.items.map((el) => (_jsxs("li", Object.assign({ style: {
                                            maxWidth: el.additionalText
                                                ? selectBtnWidth > 375
                                                    ? `${selectBtnWidth}px`
                                                    : '375px'
                                                : 'auto',
                                        }, className: classNames(styles.itemSub, isSelected(el) && styles.itemSub_isChecked, el.isDisabled && styles.itemSubDisabled), onClick: () => handleCheck(el.id), title: el.text }, { children: [_jsx("p", Object.assign({ className: styles.text }, { children: el.text })), el.additionalText && _jsx("span", { children: el.additionalText }), isSelected(el) && (_jsx("div", Object.assign({ className: classNames(styles.isCheck, el.additionalText && styles.isCheckPositionTop) }, { children: _jsx(Icon, { type: 'check' }) })))] }), el.id)))] })] }, item.items.map((x) => x.id).join())))
                    : search &&
                        filteredItems && (_jsx("div", Object.assign({ className: styles.searchNoResults }, { children: t('label.search.no_results', { searchString }) }))) }))] })));
};
export default observer(MultiSelectDropdown);
