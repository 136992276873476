import React from 'react'

import { useTranslation } from 'react-i18next'

const LabsTableHead = () => {
  const { t } = useTranslation()

  return (
    <div className="h_table-thead">
      <div className="h_table-tr">
        <div className="h_table-th h_table-th--label">{t('ps.label.event_type')}</div>
        <div className="h_table-th h_table-th--details">{t('ps.label.details')}</div>
        <div className="h_table-th h_table-th--code">{t('ps.label.status')}</div>
        <div className="h_table-th h_table-th--date">{t('ps.label.date')}</div>
      </div>
    </div>
  )
}

export default LabsTableHead
