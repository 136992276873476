import i18n from 'i18next';
import _ from 'lodash';
import { defaultLang } from 'ziphy-web-shared/basic/utils/i18n';
export const PLACE_TEMPLATE = {
    zip: '',
    state: '',
    country: '',
    street: '',
    city: '',
    building: '',
    apartment: '',
    floor: '',
    directions: '',
    geocoderId: '',
};
const placeFormatList = [
    // US format
    // 500 7th Avenue, apartment: 23, floor: 55, directions: opposite to drug store, New York, NY 10018, United States
    {
        lang: 'en-US',
        format: [
            '{{building}}',
            '{{street}}{{separator}}',
            '{{apartment_text}}: {{apartment}}{{separator}}',
            '{{floor_text}}: {{floor}}{{separator}}',
            '{{directions_text}}: {{directions}}{{separator}}',
            '{{city}}{{separator}}',
            '{{state}}',
            '{{zip}}{{separator}}',
            '{{country}}',
        ],
        formatCleaned: [
            '{{building}}',
            '{{street}}{{separator}}',
            '{{apartment}}{{separator}}',
            '{{floor}}{{separator}}',
            '{{directions}}{{separator}}',
            '{{city}}{{separator}}',
            '{{state}}',
            '{{zip}}{{separator}}',
            '{{country}}',
        ],
    },
];
const getAddressByParams = (params, { isFhir = false, clean = false } = {}) => {
    const separator = i18n.t('address_data.separator');
    let formatsList = placeFormatList;
    let formatData = _.find(formatsList, (x) => x.lang === i18n.language);
    if (!formatData) {
        formatData = _.find(formatsList, (x) => x.lang === defaultLang);
    }
    let formatMask;
    if (!isFhir && clean && formatData.format) {
        formatMask = formatData.formatCleaned;
    }
    else {
        formatMask = formatData.format;
    }
    let resultArr = [];
    _.forEach(formatMask, (x) => {
        _.forEach(params, (paramValue, paramKey) => {
            // push string to result if it has passed param
            if (_.includes(x, `{{${paramKey}}}`)) {
                if (!paramValue) {
                    return;
                }
                if (paramKey === 'line') {
                    paramValue = paramValue;
                    paramValue = paramValue.join(separator);
                }
                paramValue = paramValue;
                x = x.replace(`{{${paramKey}}}`, paramValue);
                x = x.replace(`{{separator}}`, separator);
                // check if we need to translate strings with i18n
                const stringToTranslateRE = /{{\w*}}/gi;
                if (stringToTranslateRE.test(x)) {
                    const paramForTr = 'address_data.' + x.match(stringToTranslateRE)[0].slice(2, -2);
                    x = x.replace(stringToTranslateRE, i18n.t(paramForTr));
                }
                resultArr.push(x);
            }
        });
    });
    let result = resultArr.join(' ');
    // trim trailing separator
    result = _.trim(result, separator).trim();
    return result;
};
export const extendPlace = (place) => {
    let tmp = _.cloneDeep(place);
    tmp.buildingStreetCity = (params) => getAddressByParams({
        building: tmp.building,
        street: tmp.street,
        city: tmp.city,
    }, params);
    tmp.line1 = (params) => getAddressByParams({
        building: tmp.building,
        street: tmp.street,
        apartment: tmp.apartment,
        floor: tmp.floor,
        directions: tmp.directions,
    }, params);
    tmp.shortLine1 = (params) => getAddressByParams({
        building: tmp.building,
        street: tmp.street,
    }, params);
    tmp.line2 = (params) => getAddressByParams({
        city: tmp.city,
        state: tmp.state,
        zip: tmp.zip,
    }, params);
    tmp.address = (params) => getAddressByParams({
        building: tmp.building,
        street: tmp.street,
        apartment: tmp.apartment,
        floor: tmp.floor,
        directions: tmp.directions,
        city: tmp.city,
        state: tmp.state,
        zip: tmp.zip,
        country: tmp.country,
    }, params);
    tmp.shortAddress = (params) => getAddressByParams({
        building: tmp.building,
        street: tmp.street,
        city: tmp.city,
        state: tmp.state,
        zip: tmp.zip,
        country: tmp.country,
    }, params);
    return tmp;
};
