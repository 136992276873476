import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import ReactDom from 'react-dom';
import classNames from 'classnames';
import _ from 'lodash';
import { $windowSize } from 'ziphy-web-shared/basic/utils';
import styles from './Tooltip.module.scss';
const Tooltip = ({ content, children, direction = 'top', delay = 200, className, }) => {
    const [show, setShow] = useState(false);
    const timeout = useRef(null);
    const wrapperRef = useRef(null);
    const [tooltipStyle, setTooltipStyle] = useState({});
    const showTip = () => {
        if ($windowSize.isMobileSize) {
            return;
        }
        if (wrapperRef.current) {
            const { x, y, width, height } = wrapperRef.current.getBoundingClientRect();
            switch (direction) {
                case 'right':
                    setTooltipStyle({ top: y + height / 2, left: x + width });
                    break;
                case 'left':
                    setTooltipStyle({ top: y + height / 2, left: x });
                    break;
                case 'bottom':
                    setTooltipStyle({ top: y + height, left: x + width / 2 });
                    break;
                default:
                    setTooltipStyle({ top: y, left: x + width / 2 });
            }
            timeout.current = setTimeout(() => setShow(true), delay);
        }
    };
    const hideTip = () => {
        if ($windowSize.isMobileSize) {
            return;
        }
        if (timeout.current) {
            clearTimeout(timeout.current);
        }
        setShow(false);
    };
    const isRenderChildren = _.isFunction(children);
    const Tip = () => show &&
        ReactDom.createPortal(_jsx("div", Object.assign({ className: classNames(styles.tip, styles[direction]), style: tooltipStyle }, { children: _jsx("div", Object.assign({ className: styles.textWrapper }, { children: typeof content === 'string' ? _jsx("p", { children: content }) : content })) })), document.querySelector('.popover-viewer'));
    return (_jsx("div", Object.assign({ className: classNames(styles.wrapper, !isRenderChildren && styles.wrapper_relative, className), onMouseEnter: showTip, onMouseLeave: hideTip, ref: wrapperRef }, { children: isRenderChildren ? (children(Tip)) : (_jsxs(_Fragment, { children: [children, _jsx(Tip, {})] })) })));
};
export default observer(Tooltip);
