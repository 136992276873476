var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { makeAutoObservable } from 'mobx';
import _ from 'lodash';
import { $patients } from 'ziphy-web-shared/basic/entities/patients';
class BookingPatientsStore {
    constructor(store) {
        this.selectedIds = [];
        this.updatedValues = {};
        this._rootStore = store;
        makeAutoObservable(this);
    }
    // Clear
    clear() {
        this.selectedIds = [];
    }
    // Computed
    get originalItems() {
        return $patients.items;
    }
    get items() {
        return $patients.items
            .filter((x) => this.selectedIds.includes(x.id))
            .map((x) => (Object.assign(Object.assign({}, x), this.updatedValues[x.id])));
    }
    get invalidList() {
        return this.items.filter((item) => false); // temporarily disabled?
    }
    get isAllUseInsurance() {
        return this.items.every((item) => item.useInsurance);
    }
    get hasUpdatedValues() {
        return _.some(this.updatedValues, (item) => _.has(item, 'phone') || _.has(item, 'email'));
    }
    get assignedVoucher() {
        var _a;
        const voucher = (_a = this.items.find((item) => item.voucher)) === null || _a === void 0 ? void 0 : _a.voucher;
        if (!voucher) {
            return null;
        }
        const hasAllPatientSameVoucher = this.items.every((item) => { var _a; return ((_a = item.voucher) === null || _a === void 0 ? void 0 : _a.code) === voucher.code; });
        if (!hasAllPatientSameVoucher) {
            this.updateAllSelected(() => ({ voucher: null }));
            return null;
        }
        return voucher;
    }
    // Mutations
    // Actions
    getOriginalById(id) {
        return _.find(this.originalItems, (x) => String(x.id) === String(id));
    }
    get() {
        return __awaiter(this, void 0, void 0, function* () {
            return yield $patients.activeList();
        });
    }
    addSelected(id) {
        var _a;
        if (!this.selectedIds.includes(id)) {
            if (((_a = this._rootStore.service) === null || _a === void 0 ? void 0 : _a.patientGroupType) === 'family') {
                this.selectedIds.push(id);
            }
            else {
                this.selectedIds = [id];
            }
        }
    }
    removeSelected(id) {
        this.selectedIds = this.selectedIds.filter((selectedId) => selectedId !== id);
    }
    updateSelected(id, values, updateOriginal = false) {
        return __awaiter(this, void 0, void 0, function* () {
            this.updatedValues[id] = Object.assign(Object.assign({}, this.updatedValues[id]), values);
            if (updateOriginal) {
                const { voucher, useInsurance } = values, updatedValues = __rest(values, ["voucher", "useInsurance"]);
                const originalValues = _.pick($patients.getById(id), Object.keys(updatedValues));
                if (!_.isEqual(updatedValues, originalValues)) {
                    const response = yield $patients.update(Object.assign({ id }, updatedValues));
                    return !response.error;
                }
            }
            return true;
        });
    }
    updateAllSelected(getUpdatedValues) {
        const newUpdatedValues = Object.assign({}, this.updatedValues);
        this.items.forEach((item) => {
            newUpdatedValues[item.id] = Object.assign(Object.assign({}, newUpdatedValues[item.id]), getUpdatedValues(item));
        });
        this.updatedValues = newUpdatedValues;
    }
}
export default BookingPatientsStore;
