import { makeAutoObservable } from 'mobx';
import { getSubjectRole } from '../extractor.helpers';
import ExtractorBase from '../extractors/Base';
import { prepareMeta, prepareValueDate } from '../fhir.converters';
import { getAddressObjects, getCodingObjects, getExtensionObjects, getIdentifierObjects, getSubjectGender, getTelecomObjects, withFullName, } from '../fhir.helpers';
class ExtractorRelatedPerson {
    constructor(resource) {
        this.base = new ExtractorBase(this);
        this.base.SET(resource);
        makeAutoObservable(this);
    }
    get resource() {
        return this.base.resource;
    }
    /*
     * RESOURCE - Minimally prepared resource data, prepareConverters, valueObjects, etc.
     */
    get extensions() {
        return getExtensionObjects(this.resource.extension);
    }
    get identifiers() {
        return getIdentifierObjects(this.resource.identifier);
    }
    get relationships() {
        return getCodingObjects(this.resource.relationship);
    }
    get address() {
        return getAddressObjects(this.resource.address);
    }
    get telecom() {
        return getTelecomObjects(this.resource.telecom);
    }
    get birthDate() {
        return prepareValueDate(this.resource.birthDate);
    }
    get meta() {
        return prepareMeta(this.resource.meta);
    }
    /*
     * READ - PREPARED Values
     */
    get zid() {
        return this.identifiers.first({ config: 'zid' });
    }
    get role() {
        return getSubjectRole(this);
    }
    get name() {
        return withFullName({ name: this.resource.name });
    }
    get gender() {
        return getSubjectGender(this.resource.gender);
    }
    get relationship() {
        return this.relationships.first();
    }
    /*
     * STRING Values
     */
    get asString() {
        var _a;
        return {
            zid: (_a = this.zid) === null || _a === void 0 ? void 0 : _a.value,
            full: '',
        };
    }
}
export default ExtractorRelatedPerson;
