import React, { useMemo, useState } from 'react'

import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'

import classNames from 'classnames'
import _ from 'lodash'
import { $loader } from 'ziphy-web-shared/basic/utils'
import { formatDate } from 'ziphy-web-shared/basic/utils/time'

import clientService from '@services/client'

import Modal from '@library/modal/Modal'
import ModalToolbarBtn from '@library/modal/toolbar/ModalToolbarBtn'

import Button from '@nedoShared/basic/lib/simple/button/Button'
import TimeSlotPicker from '@nedoShared/features/schedules/timeSlotPicker/TimeSlotPicker'

import { $appts } from '@store'

import styles from './BookingRebook.modal.module.scss'

const BookingRebookModal = ({ _core, fullAppt, isSelfRebook }) => {
  const { t } = useTranslation()

  const { appointment, patientSymptoms, place } = fullAppt

  const [selectedWindow, setSelectedWindow] = useState({
    key: appointment.appointedStart,
    value: [],
  })

  const requestParams = useMemo(() => {
    return {
      practiceId: appointment.practiceId,
      officeId: appointment.officeId,
      serviceId: appointment.serviceId,
      placeId: appointment.placeId,
      patientNumber: appointment.patientIds.length,
      symptomIds: patientSymptoms?.payload?.symptoms.map((item) => item.id),
    }
  }, [fullAppt])

  const handleClickRebook = $loader.registerHandler('rebook-submit', async () => {
    const payload = {
      id: appointment.id,
      start: `$datetime:${selectedWindow.key}`,
    }
    const response = isSelfRebook
      ? await clientService.selfRebookAppointment(payload)
      : await clientService.rebookAppointment(payload)
    const prepared = _.get(response, 'prepared', {})
    const isSuccess = !_.isEmpty(prepared)
    if (isSuccess) {
      await $appts.getOne({ id: payload.id })
    }

    _core.onHide()
  })

  return (
    <Modal centered scrollable _core={_core}>
      <div className="modal-toolbar">
        <ModalToolbarBtn type="close" action={_core.onHide} />
      </div>
      <Modal.Header>{t('appt_confirmator.rebook.title')}</Modal.Header>
      <Modal.Body className={classNames('styled_scroll', styles.body)}>
        <TimeSlotPicker
          value={selectedWindow}
          onChange={setSelectedWindow}
          requestParams={requestParams}
          placeTimezone={place.timezone}
          visibleDaysCount={3}
          cellNumberInRow={3}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button
          mode={['primary', 'block']}
          tag="button"
          buttonType={'button'}
          label={
            _.isEmpty(selectedWindow)
              ? t('btn.select_time_slot')
              : formatDate(selectedWindow.key, {
                  format: 'datetimeFullNoYear',
                  timezone: place.timezone,
                })
          }
          action={handleClickRebook}
          isLoading={$loader.isRunHandler('rebook-submit')}
        />
      </Modal.Footer>
    </Modal>
  )
}

export default observer(BookingRebookModal)
