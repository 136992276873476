import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useRef, useState } from 'react';
import { useResizeDetector } from 'react-resize-detector';
import classNames from 'classnames';
import styles from './ContainerScrollable.module.scss';
const ContainerScrollable = ({ children, wrapperClassName, className, top = true, bottom = true, }) => {
    const scrollContentRef = useRef(null);
    const [showShadowTop, setShowShadowTop] = useState(false);
    const [showShadowBottom, setShowShadowBottom] = useState(false);
    const updateShadows = () => {
        const scrollContent = scrollContentRef.current;
        if (scrollContent) {
            const { scrollTop, scrollHeight, clientHeight } = scrollContent;
            if (top)
                setShowShadowTop(scrollTop > 0);
            if (bottom)
                setShowShadowBottom(scrollTop + clientHeight < scrollHeight);
        }
    };
    const { ref: resizeRef } = useResizeDetector({
        onResize: updateShadows,
    });
    useEffect(() => {
        const scrollContent = scrollContentRef.current;
        if (scrollContent) {
            scrollContent.addEventListener('scroll', updateShadows);
            updateShadows();
            return () => {
                scrollContent.removeEventListener('scroll', updateShadows);
            };
        }
    }, []);
    return (_jsxs("div", Object.assign({ ref: resizeRef, className: classNames(styles.wrapper, wrapperClassName) }, { children: [_jsx("div", { className: classNames(styles.shadow, styles.top, showShadowTop && styles.visible) }), _jsx("div", Object.assign({ ref: scrollContentRef, className: classNames(styles.scrollContent, className), "data-scrollable": true }, { children: children })), _jsx("div", { className: classNames(styles.shadow, styles.bottom, showShadowBottom && styles.visible) })] })));
};
export default ContainerScrollable;
