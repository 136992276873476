import React, { useMemo, useState } from 'react'

import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'

import classNames from 'classnames'
import _ from 'lodash'
import { ssQuestionsService } from 'ziphy-web-shared/basic/api'
import { useLoader } from 'ziphy-web-shared/basic/hooks'
import Checkbox from 'ziphy-web-shared/basic/lib/forms/fields/checkbox/Checkbox'
import { $loader } from 'ziphy-web-shared/basic/utils'

import TextareaBase from '@library/form/fields/new/TextareaBase'
import Modal from '@library/modal/Modal'
import ModalToolbarBtn from '@library/modal/toolbar/ModalToolbarBtn'
import RatingStars from '@library/ratingStars/RatingStars'

import Button from '@nedoShared/basic/lib/simple/button/Button'

import { $appts } from '@store'

import './ApptSurvey.modal.scss'

const ApptSurveyModal = ({ fullAppt, _core }) => {
  const { t } = useTranslation()
  const [fields, setFields] = useState([])

  const formIsValid = useMemo(() => {
    return true
    // return _.every(fields, (field) => {
    //   if (field.type === 'stars') {
    //     return field.value > 0
    //   } else if (field.type === 'binary') {
    //     return _.isBoolean(field.value)
    //   } else if (field.type === 'single_choice') {
    //     return field.value.length > 0
    //   } else {
    //     return true
    //   }
    // })
  }, [fields]) //eslint-disable-line react-hooks/exhaustive-deps

  const { isLoading } = useLoader(async () => {
    const response = await ssQuestionsService.list({
      filter: {
        contains: ['practice_ids', [fullAppt.appointment.practiceId]],
      },
      order: [['rank', 'asc']],
    })
    let items = response.prepared?.ssQuestions.items || []

    let result = []
    _.forEach(items, (item) => {
      const { topic } = item

      if ((topic === 'agent' && !fullAppt[topic]) || (topic === 'provider' && !fullAppt[topic])) {
        return
      }

      if (item.type === 'stars') {
        item.value = 0
      } else if (item.type === 'multiple_choice') {
        item.value = []
      } else {
        item.value = ''
      }

      result.push(item)
    })
    setFields(result)
  })

  function setValue(index, value) {
    setFields((prev) => {
      let tmp = [...prev]

      if (tmp[index]) {
        tmp[index] = { ...tmp[index], value }
      }

      return tmp
    })
  }

  function handleChange(index, value, name) {
    let field = fields[index]

    let lastValue = field.value
    let newValue = name

    if (_.isUndefined(name)) {
      newValue = value
    }

    if (field.type === 'multiple_choice') {
      if (lastValue.indexOf(newValue) !== -1) {
        newValue = lastValue.filter((x) => x !== newValue)
      } else {
        newValue = [...lastValue, newValue]
      }
    } else {
      if (newValue === lastValue) {
        newValue = ''
      }
    }

    setValue(index, newValue)
  }

  const handleSubmit = $loader.registerHandler('appt-survey-submit', async (e) => {
    e.preventDefault()

    if (formIsValid) {
      let survey = {}

      _.forEach(fields, (item) => {
        if (
          (item.type === 'stars' && item.value > 0) ||
          (item.type === 'binary' && _.isBoolean(item.value)) ||
          (item.type === 'single_choice' && item.value.length > 0) ||
          (item.type === 'multiple_choice' && item.value.length > 0) ||
          (item.type === 'free_text' && _.trim(item.value))
        ) {
          survey[item.id] = item.value
        }
      })

      let res = await $appts.sendSurvey(fullAppt.appointment.id, { survey })

      if (res.isSuccess) {
        _core.onHide()
      }
    }
  })

  return (
    <Modal className="appt_survey" centered scrollable>
      <div className="modal-toolbar">
        <ModalToolbarBtn type="close" action={_core.onHide} />
      </div>
      <Modal.Header>{t('appt_survey.title')}</Modal.Header>
      <Modal.Body isLoading={isLoading} className="styled_scroll">
        <form id="appt_survey_modal" onSubmit={handleSubmit}>
          {fields.map((field, index) =>
            (field.type === 'single_choice' || field.type === 'multiple_choice') &&
            !field.choices ? (
              <></>
            ) : (
              <div className="appt_survey-item" key={field.id}>
                <div
                  className={classNames(
                    'appt_survey-label', // ['stars', 'binary', 'single_choice'].includes(field.type) &&
                    //   'appt_survey-label--required',
                  )}
                >
                  <span>{_.trim(field.text)}</span>
                </div>
                {field.type === 'stars' ? (
                  <RatingStars
                    field={{
                      value: field.value,
                      onChange: (value) => handleChange(index, value),
                    }}
                  />
                ) : field.type === 'binary' ? (
                  <div className="survey-btns-group">
                    <Checkbox
                      binaryValue={true}
                      field={{
                        value: field.value,
                        onChange: (value) => handleChange(index, value),
                      }}
                      isButton
                    />
                    <Checkbox
                      binaryValue={false}
                      field={{
                        value: field.value,
                        onChange: (value) => handleChange(index, value),
                      }}
                      isButton
                    />
                  </div>
                ) : field.type === 'single_choice' || field.type === 'multiple_choice' ? (
                  <div className="survey-btns-group">
                    {field.choices.map((choice) => (
                      <Checkbox
                        key={choice}
                        name={choice}
                        field={{
                          value: field.value,
                          onChange: () => handleChange(index, choice),
                        }}
                        label={choice}
                        isButton
                      />
                    ))}
                  </div>
                ) : field.type === 'free_text' ? (
                  <TextareaBase
                    rows={5}
                    field={{
                      value: field.value,
                      onChange: (value) => handleChange(index, value),
                    }}
                  />
                ) : (
                  <></>
                )}
              </div>
            ),
          )}
        </form>
      </Modal.Body>
      <Modal.Footer>
        <div className="text-center">
          <Button
            tag="button"
            mode={['primary', 'block']}
            form="appt_survey_modal"
            disabled={!formIsValid}
            isLoading={$loader.isRunHandler('appt-survey-submit')}
          >
            {t('appt_survey.btn.submit')}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}

export default observer(ApptSurveyModal)
