import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import _ from 'lodash';
import { useGetDropdownPosition, useInnerValue } from 'ziphy-web-shared/basic/hooks';
import { ClickOutside } from 'ziphy-web-shared/basic/lib/utilities';
import { getPreparedItems, getSelectedItems } from './MultiSelect.helpers';
import styles from './MultiSelect.module.scss';
import MultiSelectButton from './multiSelectButton/MultiSelectButton';
import MultiSelectDropdown from './multiSelectDropdown/MultiSelectDropdown';
const MultiSelect = ({ field, label, mode = 'sm', placeholder, wrapperClassName = '', className = '', innerClassNames, items = [], multiSelect = false, clear = false, allowUnselect = false, visibleCount = 1, separator = ', ', allOption, search = false, itemsFullWidth = false, alwaysBottom = false, readonly = false, isActive, }) => {
    const { t } = useTranslation();
    const { value, onChange, isInvalid } = field;
    const [isOpen, setIsOpen] = useState(false);
    const [selection, setSelection] = useInnerValue((_.isArray(value) ? value : [value]).filter((x) => x || x === 0));
    const hasSelection = selection.length > 0;
    const preparedItems = getPreparedItems(items, selection, setSelection, t);
    const selectedItems = getSelectedItems(preparedItems, selection, allOption, hasSelection, placeholder, visibleCount, multiSelect, separator);
    const { fieldRef, dropdownRef, dropdownStyles } = useGetDropdownPosition({
        isOpen,
        itemsFullWidth,
        alwaysBottom,
    });
    const handleOpen = (open) => {
        setIsOpen(open);
    };
    const handlerClear = (val) => {
        setSelection(val);
        onChange(val);
    };
    const handleSelected = (val) => {
        setSelection(val);
        onChange(val);
    };
    useEffect(() => {
        if (readonly) {
            handleOpen(false);
        }
    }, [readonly]);
    return (_jsx("div", Object.assign({ ref: fieldRef, className: classNames(wrapperClassName, isActive && styles.active) }, { children: _jsxs(ClickOutside, Object.assign({ className: classNames(styles.wrapper, className, readonly && styles.wrapperReadonly), onClick: () => setIsOpen(false) }, { children: [_jsx(MultiSelectButton, { onOpen: handleOpen, onClear: handlerClear, selection: selection, visibleCount: visibleCount, multiSelect: multiSelect, allOption: allOption, mode: mode, label: label, getSelected: selectedItems, clear: clear, hasSelection: hasSelection, isOpen: isOpen, placeholder: placeholder, isInvalid: isInvalid, readonly: readonly, innerClassNames: innerClassNames }), _jsx("div", Object.assign({ className: classNames(styles.dropdownWrapper, isOpen && styles.dropdownWrapperOpen), style: dropdownStyles, ref: dropdownRef }, { children: _jsx(MultiSelectDropdown, { onSelected: handleSelected, onOpen: handleOpen, selection: selection, items: preparedItems, multiSelect: multiSelect, allowUnselect: allowUnselect, allOption: allOption, search: search, selectBtnWidth: (fieldRef === null || fieldRef === void 0 ? void 0 : fieldRef.current) && fieldRef.current.innerWidth }) }))] })) })));
};
export default observer(MultiSelect);
