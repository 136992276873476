import _ from 'lodash';
import { APP_TYPE, isJson } from 'ziphy-web-shared/basic/helpers';
const TMP_KEY = 'tmp';
function getStorageInstance(storage) {
    let clearTimestamp = 0;
    return {
        _parsePath(pp, options = {}) {
            const { withAppType = true } = options;
            if (_.isObject(pp) && pp.hasOwnProperty('name')) {
                return pp;
            }
            let [name, ...path] = _.toPath(pp) || [];
            if (name === 'userSettings') {
                const userId = this.get('session.userId');
                if (userId) {
                    path = [userId, ...path];
                }
                else {
                    return { name: -1, path };
                }
            }
            if (withAppType && !name.startsWith(APP_TYPE + '_')) {
                name = [APP_TYPE, name].join('_');
            }
            return { name, path };
        },
        get(pp, defaultValue, options = {}) {
            if (pp !== TMP_KEY) {
                this.clearTemp();
            }
            const { name, path } = this._parsePath(pp, options);
            let result = storage.getItem(name);
            if (typeof result === 'undefined' || result === 'undefined') {
                return defaultValue;
            }
            result = isJson(result) ? JSON.parse(result) : result;
            if (path.length > 0) {
                return _.get(result, path, defaultValue);
            }
            return result || defaultValue;
        },
        set(pp, payload, options = { lifeTime: null, lifeTimePath: null }) {
            const { name, path } = this._parsePath(pp, options);
            if (name === -1)
                return false;
            let result;
            if (path.length > 0) {
                result = this.get(name, {}, options);
                _.set(result, path, payload);
            }
            else {
                result = payload;
            }
            if (options.lifeTime) {
                let expName = name;
                let expPath = path;
                if (options.lifeTimePath) {
                    const expParsed = this._parsePath(options.lifeTimePath, options);
                    expName = expParsed.name;
                    expPath = expParsed.path;
                }
                const expData = {
                    path: [expName, ...expPath].filter((x) => x).join('.'),
                    exp: Date.now() + options.lifeTime * 1000,
                };
                const existingTempData = this.get(TMP_KEY, {});
                this.set(TMP_KEY, Object.assign(Object.assign({}, existingTempData), { [expData.path]: expData.exp }));
            }
            storage.setItem(name, JSON.stringify(result));
        },
        remove(pp, options = {}) {
            const { name, path } = this._parsePath(pp, options);
            let result;
            if (name === -1) {
                return false;
            }
            if (path.length > 0) {
                result = this.get(name, {}, options);
                _.unset(result, path);
                storage.setItem(name, JSON.stringify(result));
            }
            else {
                storage.removeItem(name);
            }
        },
        clearTemp() {
            const now = Date.now();
            if (now < clearTimestamp)
                return;
            clearTimestamp = now + 300 * 1000;
            let result = {};
            _.forEach(this.get(TMP_KEY, {}), (exp, path) => {
                if (now < exp) {
                    result[path] = exp;
                }
                else {
                    this.remove(path);
                }
            });
            if (Object.keys(result).length) {
                this.set(TMP_KEY, result);
            }
            else {
                this.remove(TMP_KEY);
            }
        },
    };
}
export const localStore = getStorageInstance(localStorage);
export const sessionStore = getStorageInstance(sessionStorage);
