var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import intervalPlural from 'i18next-intervalplural-postprocessor';
import _ from 'lodash';
import moment from 'moment-timezone';
import { APP_REVISION, isLocal, PUBLIC_URL } from 'ziphy-web-shared/basic/helpers';
import { setRG } from 'ziphy-web-shared/basic/utils/analytics';
import { DEFAULT_LANG_LIST } from './i18n.constants';
//need to import these to be able to switch between moment locales
// import localeEs from 'moment/locale/es'
// import localePl from 'moment/locale/pl'
// import localeRu from 'moment/locale/ru'
// import localeUk from 'moment/locale/uk'
export let defaultLang;
let availableLangs = [];
const languageDetector = new LanguageDetector();
export function initI18N(props) {
    defaultLang = props.default;
    availableLangs = props.languages
        .map((locale) => {
        const item = DEFAULT_LANG_LIST[locale] || DEFAULT_LANG_LIST[locale.split('-')[0]];
        if (item) {
            return { id: locale, text: item.nativeName };
        }
        return null;
    })
        .filter((x) => x);
    i18n
        .use(intervalPlural)
        .use(initReactI18next)
        .use(Backend)
        .use(languageDetector)
        .init({
        detection: {
            order: ['localStorage', 'navigator'],
        },
        supportedLngs: props.languages,
        nsSeparator: '::',
        fallbackLng: defaultLang,
        // debug: true,
        saveMissing: true,
        returnNull: false,
        missingKeyHandler(lngs, ns, key, fallbackValue, updateMissing, options) {
            if (_.trim(key) === '') {
                return false;
            }
            const customData = { lngs, ns, key, fallbackValue, updateMissing, options };
            setRG('send', {
                error: new Error('i18next::translator: missingKey'),
                tags: ['i18n'],
                customData,
            });
            if (isLocal()) {
                console.warn('i18next::translator: missingKey', customData);
            }
        },
        interpolation: {
            escapeValue: false,
        },
        backend: {
            queryStringParams: { v: APP_REVISION },
            loadPath: `${PUBLIC_URL}/translations/{{lng}}.json`,
        },
        load: 'currentOnly',
    })
        .then(() => {
        moment.locale(i18n.language);
        props.onIsLoading(false);
    });
}
export function changeCurrentLanguage(locale) {
    return __awaiter(this, void 0, void 0, function* () {
        yield i18n.changeLanguage(locale);
        moment.locale(i18n.language);
    });
}
export function useChangeLanguage() {
    const onChangeLang = (locale) => __awaiter(this, void 0, void 0, function* () {
        if (i18n.language === locale)
            return;
        yield changeCurrentLanguage(locale);
    });
    return { availableLangs, onChangeLang };
}
