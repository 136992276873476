import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { Fragment, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';
import _ from 'lodash';
import Icon from 'ziphy-web-shared/basic/assets/images/Icon';
import { ProgressRing } from 'ziphy-web-shared/basic/lib/loaders';
import { $windowSize } from 'ziphy-web-shared/basic/utils';
import { getParsedDate } from '../TimeSlotPicker.helpers';
import { useParsedDate } from '../TimeSlotPicker.hooks';
import styles from './WindowSlotPicker.module.scss';
const WindowSlotPicker = ({ selectedWindow, onChangeWindow, placeTimezone, isLoading, windows = [], cellNumberInRow, }) => {
    const parsedSelectedWindow = useParsedDate(selectedWindow, placeTimezone);
    const parsedWindows = useMemo(() => {
        const result = {};
        _.forEach(windows, (item) => {
            const date = getParsedDate(item.key, placeTimezone);
            const key = date.tz(placeTimezone).format('A');
            if (!(key in result)) {
                result[key] = [];
            }
            result[key].push(date);
        });
        return result;
    }, [windows]);
    const isSelected = (value) => {
        return parsedSelectedWindow.isSame(value);
    };
    const cellWidth = useMemo(() => {
        const cellNumber = _.isNumber(cellNumberInRow)
            ? cellNumberInRow
            : $windowSize.isSM
                ? cellNumberInRow.tablet
                : $windowSize.isXS
                    ? cellNumberInRow.mobile
                    : cellNumberInRow.desktop;
        return `calc((100% - ${cellNumber * 10 - 10}px) / ${cellNumber})`;
    }, [cellNumberInRow, $windowSize.isSM, $windowSize.isXS]);
    return (_jsxs("div", Object.assign({ className: styles.WindowSlotPicker }, { children: [isLoading && (_jsx("div", Object.assign({ className: styles.loading }, { children: _jsx(ProgressRing, { progress: 25, spin: true, className: '' }) }))), _jsx("div", Object.assign({ className: styles.body }, { children: Object.keys(parsedWindows).map((key) => (_jsx(Fragment, { children: _jsxs("div", Object.assign({ className: styles.rowWrapper }, { children: [_jsx("div", Object.assign({ className: styles.chunkDay }, { children: key })), _jsx("div", Object.assign({ className: styles.rowSlot }, { children: parsedWindows[key].map((value, i) => (_jsx(Fragment, { children: _jsxs("div", Object.assign({ className: classNames(styles.cell, {
                                            [styles.cellSelected]: isSelected(value),
                                        }), style: { width: cellWidth }, onClick: () => onChangeWindow(value.toISOString()) }, { children: [value.format('LT'), _jsx(Icon, { type: "check", className: styles.icon })] })) }, value.toString() + i))) }))] })) }, key))) }))] })));
};
export default observer(WindowSlotPicker);
