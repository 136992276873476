import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';
import _ from 'lodash';
import Checkbox from 'ziphy-web-shared/basic/lib/forms/fields/checkbox/Checkbox';
import styles from './CheckboxSelect.module.scss';
const CheckboxSelect = ({ className, field, label, items, isMulti, additionalField }) => {
    const handleSelectItem = (name) => {
        let newValue = name;
        if (isMulti) {
            let oldValues = [...field.value];
            if (oldValues.includes(newValue)) {
                newValue = oldValues.filter((x) => x !== newValue);
            }
            else {
                newValue = [...oldValues, newValue];
            }
        }
        field.onChange(newValue);
    };
    return (_jsxs("div", Object.assign({ className: classNames(styles.wrapper, className && className, field.isInvalid && styles.invalid) }, { children: [label && _jsx("label", Object.assign({ className: styles.label }, { children: label })), items.map((x) => {
                return (_jsx(Checkbox, { label: x.text, field: {
                        value: isMulti ? field.value.includes(x.id) : field.value === x.id,
                        onChange: () => {
                            handleSelectItem(x.id);
                        },
                        isInvalid: field.isInvalid,
                    }, mode: 'gray', additionalField: (additionalField === null || additionalField === void 0 ? void 0 : additionalField.targetItem) === x.id && _.omit(additionalField, ['targetItem']) }, `checkbox-select-item-${x.id}`));
            })] })));
};
export default observer(CheckboxSelect);
