var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { t } from 'i18next';
import _ from 'lodash';
import { myackAxiosInstance } from 'ziphy-web-shared/basic/api';
import { APP_REVISION, PUBLIC_URL } from 'ziphy-web-shared/basic/helpers';
import { showAlert } from 'ziphy-web-shared/basic/lib/utilities';
import { BaseService } from '../baseService';
import { NotesEncounterStatuses } from './';
const isDuplicateResourceError = (res) => {
    var _a, _b;
    const reason = (_b = (_a = res.error) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.reason;
    return /The code of .* already exists .*/.test(reason);
};
export class Notes extends BaseService {
    constructor(props) {
        super(props);
    }
    readSignature(params, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.api.fetchLoggedV3('patient_stories.get_notes_signature', params, options);
        });
    }
    listSignatures(params, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.api.fetchLoggedV3('patient_stories.get_notes_signatures', params, options);
        });
    }
    listStatuses(params, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.api.fetchLoggedV3(`patient_stories.get_notes_status`, params, options);
        });
    }
    signNotes(params, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.api.fetchLoggedV3('patient_stories.sign_notes', params, options);
        });
    }
    reviewSection(params, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.api.fetchLoggedV3('patient_stories.review_notes', params, options);
        });
    }
    readNotesPdf(params, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.api.fetchLoggedV3('patient_stories.get_notes_pdf', params, options);
        });
    }
    /*
       Saving
       */
    saveNotes(params, options) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            let res;
            if (params.encounterStatus === NotesEncounterStatuses.CANCELED) {
                // temp hack until backend not fix locking for canceled encounters
                res = {
                    meta: {},
                    result: null,
                    error: {
                        code: 'error.resource.precondition_failed',
                        message: 'Precondition failed',
                        data: {
                            reason: 'Notes are locked for this encounter',
                            method: 'patient_stories.save_notes',
                            version: [3, 0],
                        },
                    },
                    warnings: [],
                };
                // temp hack until backend not fix locking for canceled encounters
            }
            else {
                res = yield this.api.fetchLoggedV3('patient_stories.save_notes', _.omit(params, ['encounterStatus']), Object.assign({ skipAlert: true }, options));
            }
            if (res && ((_a = res.error) === null || _a === void 0 ? void 0 : _a.code) === 'error.resource.precondition_failed') {
                if (isDuplicateResourceError(res)) {
                    showAlert.error(t('ps.notes.alert.exiting_resource'));
                }
                else if (params.encounterStatus === NotesEncounterStatuses.CANCELED) {
                    showAlert.error(t('ps.error.precondition_failed.save_canceled_encounter'));
                }
                else if (params.encounterStatus === NotesEncounterStatuses.NOT_STARTED) {
                    showAlert.error(t('ps.error.precondition_failed.save_before_encounter_start'));
                }
                else {
                    showAlert.error(t('ps.error.precondition_failed.save_after_encounter_start'));
                }
            }
            return res;
        });
    }
    saveAddendum(params, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.api.fetchLoggedV3('patient_stories.save_addendum', params, options);
        });
    }
    // /*
    //    Reading
    //    */
    readResource(params, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.api.fetchLoggedV3('patient_stories.get_resource', params, options);
        });
    }
    readResourceFullHistory(params, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.api.fetchLoggedV3('patient_stories.get_derivation_history', params, options);
        });
    }
    readNotes(params, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.api.fetchLoggedV3('patient_stories.read_notes', params, options);
        });
    }
    readSignedNotes(params, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.api.fetchLoggedV3('patient_stories.read_signed_notes', params, options);
        });
    }
    readNotesHistory(params, options) {
        return __awaiter(this, void 0, void 0, function* () {
            params = Object.assign({ includeCurrent: false }, params);
            return this.api.fetchLoggedV3('patient_stories.read_history_notes', params, options);
        });
    }
    loadConfig(params, options) {
        var _a, _b, _c;
        return __awaiter(this, void 0, void 0, function* () {
            const urls = [
                `${PUBLIC_URL}/dist/notes/${params.practiceId}-${params.serviceId}.json?v=${APP_REVISION}`,
                `${PUBLIC_URL}/dist/notes/${params.practiceId}.json?v=${APP_REVISION}`,
            ];
            let url = `${PUBLIC_URL}/dist/notes/any.json?v=${APP_REVISION}`;
            const responses = yield Promise.allSettled(urls.map((url) => myackAxiosInstance.head(url)));
            for (let index in responses) {
                const response = responses[index];
                if (response.status === 'fulfilled' &&
                    ((_a = response === null || response === void 0 ? void 0 : response.value) === null || _a === void 0 ? void 0 : _a.status) === 200 &&
                    ((_c = (_b = response === null || response === void 0 ? void 0 : response.value) === null || _b === void 0 ? void 0 : _b.headers['content-type']) === null || _c === void 0 ? void 0 : _c.includes('application/json')) &&
                    urls[index]) {
                    url = urls[index];
                    break;
                }
            }
            const response = yield myackAxiosInstance.get(url);
            return response.data;
        });
    }
}
