var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEffect, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { useFirstRender } from 'ziphy-web-shared/basic/hooks/useFirstRender';
import { useForceUpdate } from 'ziphy-web-shared/basic/hooks/useForceUpdate';
let counter = 0;
export const usePaginationInfinite = ({ fetchData, limit = 10, offset: initialOffset = 0, externalData, setExternalData }, deps = []) => {
    const { ref, inView } = useInView({ threshold: 1 });
    const { updater, forceUpdate } = useForceUpdate();
    const isFirstRender = useFirstRender();
    const offset = useRef(initialOffset);
    const hasMore = useRef(true);
    const [isLoading, setIsLoading] = useState(false);
    const [internalData, setInternalData] = useState([]);
    const data = externalData !== null && externalData !== void 0 ? externalData : internalData;
    const setData = setExternalData !== null && setExternalData !== void 0 ? setExternalData : setInternalData;
    useEffect(() => {
        setData([]);
        offset.current = initialOffset;
        hasMore.current = true;
        if (!isFirstRender) {
            forceUpdate();
            counter++;
        }
    }, [limit, initialOffset, JSON.stringify(deps)]);
    const loadMore = () => __awaiter(void 0, void 0, void 0, function* () {
        if (isLoading || !hasMore.current)
            return;
        const tmpCounter = counter;
        setIsLoading(true);
        const newData = yield fetchData({ limit, offset: offset.current });
        if (tmpCounter < counter) {
            setIsLoading(false);
            return;
        }
        setData((x) => [...x, ...newData]);
        if (newData.length < limit) {
            hasMore.current = false;
        }
        offset.current = offset.current + newData.length;
        setIsLoading(false);
    });
    useEffect(() => {
        if (inView) {
            loadMore();
        }
    }, [inView, isLoading, updater]);
    return {
        ref,
        isLoading,
        offset: offset.current,
        currentPage: Math.ceil(offset.current / limit) + 1,
        data,
    };
};
