import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe, } from '@stripe/react-stripe-js';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { Input } from 'ziphy-web-shared/basic/lib/forms/fields';
import styles from './StripeInner.module.scss';
const ELEMENT_OPTIONS = {
    style: {
        base: {
            fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', 'sans-serif', 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",
            fontSize: '16px',
            fontWeight: 600,
            lineHeight: '1.25',
            color: '#000000',
            '::placeholder': {
                color: '#d1d1d6',
            },
        },
        invalid: {
            color: '#000000',
        },
    },
};
const StripeInner = forwardRef(({}, ref) => {
    const { t } = useTranslation();
    const stripe = useStripe();
    const elements = useElements();
    useImperativeHandle(ref, () => ({
        getData: () => {
            const card = elements.getElement(CardNumberElement);
            return [card, stripe];
        },
    }));
    const [cardNumber, setCardNumber] = useState({});
    const [cardExpiry, setCardExpiry] = useState({});
    const [cardCvc, setCardCvc] = useState({});
    return (_jsxs(_Fragment, { children: [_jsx(Input, Object.assign({ field: { value: '', isInvalid: !_.isEmpty(cardNumber) }, mode: "lg", className: "mb-10", label: t('pms_card.label.card') }, { children: _jsx(CardNumberElement, { onChange: (e) => setCardNumber(e.error), options: Object.assign(Object.assign({}, ELEMENT_OPTIONS), { placeholder: t('pms_card.ph.card') }), className: styles.field }) })), _jsxs("div", Object.assign({ className: "row row--gutters-10" }, { children: [_jsx("div", Object.assign({ className: "col-6" }, { children: _jsx(Input, Object.assign({ field: { value: '', isInvalid: !_.isEmpty(cardExpiry) }, mode: "lg", label: t('pms_card.label.exp_date') }, { children: _jsx(CardExpiryElement, { onChange: (e) => setCardExpiry(e.error), options: Object.assign(Object.assign({}, ELEMENT_OPTIONS), { placeholder: t('pms_card.ph.exp_date') }), className: styles.field }) })) })), _jsx("div", Object.assign({ className: "col-6" }, { children: _jsx(Input, Object.assign({ field: { value: '', isInvalid: !_.isEmpty(cardCvc) }, mode: "lg", label: t('pms_card.label.sec_code') }, { children: _jsx(CardCvcElement, { onChange: (e) => setCardCvc(e.error), options: Object.assign({}, ELEMENT_OPTIONS), className: styles.field }) })) }))] }))] }));
});
export default StripeInner;
