import _ from 'lodash';
export function splitBloodPressure(value = '') {
    const result = value.split('/');
    return {
        systolic: result[0],
        diastolic: result[1],
    };
}
export function joinHeightToInches(foot, inch) {
    return foot * 12 + inch * 1;
}
export function splitHeightFromInches(height, asString = false) {
    const foot = Math.floor(height / 12);
    const inch = _.round(height - foot * 12, 2);
    if (asString) {
        if (!foot && !inch) {
            return '';
        }
        if (inch) {
            return `${foot}'${inch}"`;
        }
        else {
            return `${foot}'`;
        }
    }
    return { foot, inch };
}
export function getBodyMassIndex(weight, height) {
    return ((weight / Math.pow(height, 2)) * 703).toFixed(1);
}
