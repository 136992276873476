import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useLayoutEffect } from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import { useChangeScrollDirection, useFirstRender, useInnerValue, } from 'ziphy-web-shared/basic/hooks';
import { $windowSize } from 'ziphy-web-shared/basic/utils';
import styles from './RadioTabs.module.scss';
var Mode;
(function (Mode) {
    Mode["DEFAULT"] = "default";
    Mode["DARK"] = "dark";
    Mode["LIGHT"] = "light";
    Mode["MODERN"] = "modern";
})(Mode || (Mode = {}));
var Size;
(function (Size) {
    Size["SM"] = "sm";
    Size["LG"] = "lg";
})(Size || (Size = {}));
const modeStylesMap = {
    [Mode.DEFAULT]: styles.radioTabsDefault,
    [Mode.DARK]: styles.radioTabsDark,
    [Mode.LIGHT]: styles.radioTabsLight,
    [Mode.MODERN]: styles.radioTabsModern,
};
const sizeStylesMap = {
    [Size.SM]: styles.itemSm,
    [Size.LG]: styles.itemLg,
};
const RadioTabs = ({ value, setValue, items = [], mode = Mode.DEFAULT, className, size, }) => {
    const ref = useChangeScrollDirection();
    const isFirstRender = useFirstRender();
    const [innerValue, setInnerValue] = useInnerValue(value);
    function handleOnClick(id) {
        setInnerValue(value);
        if (_.isFunction(setValue)) {
            setValue(id);
        }
    }
    useLayoutEffect(() => {
        if ($windowSize.isMobileSize && ref.current && !isFirstRender) {
            // if mobile - scroll selected btn into view
            const scrollContainer = ref.current;
            const activeBtn = scrollContainer.querySelector(`.${styles.itemActive}`);
            if (activeBtn) {
                const isLastBtn = scrollContainer.childNodes.length ===
                    Array.from(scrollContainer.childNodes).indexOf(activeBtn) + 1;
                activeBtn.scrollIntoView({
                    behavior: 'smooth',
                    block: 'nearest',
                    inline: isLastBtn ? 'nearest' : 'center',
                });
            }
        }
    }, [ref, innerValue]); // eslint-disable-line react-hooks/exhaustive-deps
    return (_jsx("div", Object.assign({ className: classNames(styles.radioTabs, modeStylesMap[mode], className, 
        // TODO Maybe should be removed, used under global patient_page_print_wrapper style
        mode === Mode.DARK && 'radio_tabs--dark') }, { children: _jsx("div", Object.assign({ className: styles.scroll, ref: ref }, { children: items.map((tab, index) => (_jsxs("div", Object.assign({ className: classNames(styles.item, (tab.id || null) === (innerValue || null) && styles.itemActive, tab.isDisabled && styles.itemDisabled, size && sizeStylesMap[size], tab.className && tab.className), onClick: () => handleOnClick(tab.id) }, { children: [tab.text, typeof tab.count === 'number' && _jsx("span", Object.assign({ className: styles.count }, { children: tab.count }))] }), tab.id))) })) })));
};
export default RadioTabs;
