import AsStringTableDetails from '@ps/library/eventsViewer/tableDetails/AsStringTableDetails';
import BaseDocumentsModal from '@ps/library/modals/BaseDocuments.modal';
export const labsConfig = {
    lab_document: {
        eventModal: BaseDocumentsModal,
        condition: [
            { field: 'resource', eq: 'DocumentReference' },
            {
                field: 'subtype',
                sin: [
                    'test_result_document_type',
                    'diagnostic_report_document_type',
                    'test_result_reported_to_doh_document_type',
                ],
            },
        ],
        rowType: 'documents',
        viewer: {
            tableDetails: AsStringTableDetails,
        },
    },
};
