import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CustomLink } from 'ziphy-web-shared/basic/lib/buttons';
import Toolbar from '@nedoShared/features/layouts/toolbar/Toolbar';
import { routeActions } from '@config/routeActions';
const ErrorPage = ({ title, message, button }) => {
    const { t } = useTranslation();
    return (_jsxs("div", Object.assign({ className: "content" }, { children: [_jsx(Toolbar, {}), _jsxs("div", Object.assign({ className: "container" }, { children: [_jsx("h1", { children: title || t('errors.404') }), _jsx("p", { children: message || t('errors.page_not_found') }), button ||
                        (button !== false && (_jsx(CustomLink
                        // @ts-ignore
                        , Object.assign({ 
                            // @ts-ignore
                            autoTarget: routeActions.ROOT() }, { children: t('btn.back_to_home') }))))] }))] })));
};
export default ErrorPage;
