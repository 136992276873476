import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatDate } from 'ziphy-web-shared/basic/utils/time';
import RoleName from '@ps/library/RoleName';
import ViewCode from '@ps/library/viewCode/ViewCode';
const MedicationStatementDetails = ({ event, extractor }) => {
    const { t } = useTranslation();
    const { asString, codeObj, subject, informationSource, dateAsserted } = extractor;
    return (_jsxs("div", Object.assign({ className: "event_details" }, { children: [_jsxs("div", Object.assign({ className: "event_details-header" }, { children: [_jsxs("div", Object.assign({ className: "event_details-title" }, { children: [asString.codeDisplay, event.isSelfReported && (_jsx("span", Object.assign({ className: "badge-self_reported" }, { children: t('ps.label.self_reported') })))] })), _jsx("div", Object.assign({ className: "event_details-code" }, { children: _jsx(ViewCode, { codeObj: codeObj }) }))] })), _jsxs("div", Object.assign({ className: "event_details-body" }, { children: [asString.status && (_jsxs("div", Object.assign({ className: "event_details-line mb-10" }, { children: [t('ps.event_viewer.status:'), " ", asString.status] }))), asString.dose && (_jsxs("div", Object.assign({ className: "event_details-line" }, { children: [t('ps.event_viewer.dosage:'), " ", asString.dose] }))), asString.routeSite && (_jsxs("div", Object.assign({ className: "event_details-line" }, { children: [t('ps.event_viewer.route:'), " ", asString.routeSite] }))), asString.timing && (_jsxs("div", Object.assign({ className: "event_details-line" }, { children: [t('ps.event_viewer.frequency:'), " ", asString.timing] }))), subject && (_jsxs("div", Object.assign({ className: "event_details-line mt-10" }, { children: [t('ps.event_viewer.subject:'), " ", subject.name.fullName] }))), informationSource && (_jsxs("div", Object.assign({ className: "event_details-line" }, { children: [t('ps.event_viewer.recorder:'), " ", informationSource.name.fullName, ' ', _jsx(RoleName, { role: informationSource.role })] }))), dateAsserted && (_jsxs("div", Object.assign({ className: "event_details-line mt-10" }, { children: [t('ps.event_viewer.entered_date:'), " ", dateAsserted.asString] }))), _jsx("div", Object.assign({ className: "event_details-line event_details-line--date" }, { children: formatDate(event.effectiveDate) }))] }))] })));
};
export default MedicationStatementDetails;
