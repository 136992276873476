import React, { useEffect, useMemo, useState } from 'react'

import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'

import classNames from 'classnames'
import _ from 'lodash'
import { useForm } from 'ziphy-web-shared/basic/lib/forms'
import { Input } from 'ziphy-web-shared/basic/lib/forms/fields'
import MultiSelect from 'ziphy-web-shared/basic/lib/forms/fields/multiSelect/MultiSelect'
import { RadioTabs } from 'ziphy-web-shared/basic/lib/tabs'
import { formatDate } from 'ziphy-web-shared/basic/utils/time'

import InputBase from '@library/form/fields/new/InputBase'
import NewDatePickerModal from '@library/newDatePicker/NewDatePicker.modal'

import Button from '@nedoShared/basic/lib/simple/button/Button'

import { eventsSchema } from '@ps/config/events'

import { $modal, $psHistory } from '@store'

import styles from './MedicalHistorySearch.module.scss'

const MedicalHistorySearch = () => {
  const { t } = useTranslation()

  const [showFilters, setShowFilters] = useState(false)

  const { minDate, maxDate } = $psHistory.eventsMinMaxDate

  const { form, useFormOnChange } = useForm({
    searchString: { store: $psHistory }, // SET_SEARCH_STRING
    dateFrom: { store: $psHistory }, // SET_DATE_FROM
    dateTo: { store: $psHistory }, // SET_DATE_TO
    sortBy: { store: $psHistory, prepValue: 'singleSelect' }, // SET_SORT_BY
    reported: { store: $psHistory }, // SET_REPORTED
    eventType: { store: $psHistory }, // SET_EVENT_TYPE
    showMore: { store: $psHistory, prepValue: 'singleSelect' }, // SET_SHOW_MORE
  })
  const { searchString, dateFrom, dateTo, sortBy, reported, eventType, showMore } = form.fields

  useFormOnChange(async () => await $psHistory.searchEvents(), [searchString.value])

  useEffect(() => {
    eventType.onChange([])
  }, [$psHistory.subcategory])

  const subcategoryTabItems = useMemo(() => {
    let result = []
    const list = eventsSchema[$psHistory.category]?.subcategories

    _.forEach(_.orderBy(list, 'order', 'asc'), (x) => {
      result.push({
        id: x.id,
        text: t(x.label),
        count: _.get($psHistory.filteredEventsBySubcategory, [x.id], []).length,
      })
    })

    return result
  }, [$psHistory.filteredEventsBySubcategory, $psHistory.category])

  const eventTypeItems = useMemo(() => {
    const events = $psHistory.filteredEventsBySubcategory[$psHistory.subcategory]
    const schemaItems =
      eventsSchema[$psHistory.category]?.subcategories[$psHistory.subcategory]?.items

    const itemsCount = _.countBy(events, 'configId')
    const result = _.map(schemaItems, (x) => ({
      id: x,
      text: `${t('ps.event_label.' + x)} ${itemsCount[x] ? '(' + itemsCount[x] + ')' : ''}`,
    }))

    return _.sortBy(result, 'text')
  }, [$psHistory.subcategory, $psHistory.filteredEventsBySubcategory])

  return (
    <div className={classNames('pd-search-params mb-20', styles.searchParams)}>
      <div className={classNames('row', styles.row)}>
        <div className={classNames('col-12', 'col-lg-auto', styles.tabs)}>
          <RadioTabs
            value={$psHistory.subcategory}
            setValue={(x) => $psHistory.SET_SUBCATEGORY(x)}
            items={subcategoryTabItems}
            mode="light"
          />
        </div>
      </div>
      <div className={classNames('row', styles.row)}>
        <div className={classNames('col-12', 'col-lg-3', styles.search)}>
          <Input
            type="search"
            label={t('ps.label.search')}
            field={searchString}
            placeholder={t('ps.ph.search_details')}
            clear
          />
        </div>
        <div className="col-12 col-lg-3">
          <MultiSelect
            label={t('ps.label.reported')}
            field={reported}
            items={[
              { id: 'self', text: t('ps.label.reported.self') },
              {
                id: 'staff',
                text: t('ps.label.reported.staff'),
              },
            ]}
            mode={['sm', 'fw-400']}
            multiSelect={true}
            allowUnselect
            allOption={t('ps.label.reported.all')}
          />
        </div>
        <div className="col-12 col-lg-3">
          <MultiSelect
            label={t('ps.label.event_type')}
            field={eventType}
            items={eventTypeItems}
            mode={['sm', 'fw-400']}
            multiSelect={true}
            allowUnselect
            allOption={t('ps.label.event_type.all')}
          />
        </div>
        <div className={classNames('col-12', 'col-lg-3', styles.buttons)}>
          <Button
            mode="filter-outline"
            className={classNames('', showFilters && 'btn--active')}
            action={() => setShowFilters((x) => !x)}
          >
            <span className="btn-text">{t('btn.filters')}</span>
          </Button>
        </div>
      </div>
      {showFilters && (
        <div className="row mt-20">
          <div className="col-12 col-lg-4">
            <div className="tsform-join-group">
              <InputBase
                field={{
                  ...dateFrom,
                  value: formatDate(dateFrom.value, { format: 'dateNum' }),
                  onChange: dateFrom.onChange,
                }}
                onClick={() => {
                  $modal.add(NewDatePickerModal, {
                    type: 'selectDateTime',
                    selectedDate: dateFrom.value,
                    onChange: dateFrom.onChange,
                    minDate: minDate,
                    maxDate: maxDate,
                    scrollTo: 'start',
                  })
                }}
                label={t('label.date_from')}
                placeholder={t('ps.ph.date_from')}
                mode={['sm', 'fw-400', 'cursor-pointer']}
                readOnly={true}
                clear
              />
              <InputBase
                field={{
                  ...dateTo,
                  value: formatDate(dateTo.value, { format: 'dateNum' }),
                  onChange: dateTo.onChange,
                }}
                onClick={() => {
                  $modal.add(NewDatePickerModal, {
                    type: 'selectDateTime',
                    selectedDate: dateTo.value,
                    onChange: dateTo.onChange,
                    minDate: minDate,
                    maxDate: maxDate,
                    scrollTo: 'end',
                  })
                }}
                label={t('label.date_to')}
                placeholder={t('ps.ph.date_to')}
                mode={['sm', 'fw-400', 'cursor-pointer']}
                readOnly={true}
                clear
              />
            </div>
          </div>
          <div className="col-12 col-lg-4">
            <MultiSelect
              label={t('ps.label.sortBy')}
              items={[
                { id: 'date_desc', text: t('ps.label.sortBy.date_desc') },
                {
                  id: 'date_asc',
                  text: t('ps.label.sortBy.date_asc'),
                },
              ]}
              field={sortBy}
              mode={['sm', 'fw-400']}
            />
          </div>
          <div className="col-12 col-lg-4">
            <MultiSelect
              label={t('ps.label.view_details')}
              field={showMore}
              items={[
                { id: 'less', text: t('ps.label.collapse_all') },
                { id: 'more', text: t('ps.label.show_all') },
              ]}
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default observer(MedicalHistorySearch)
