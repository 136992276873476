var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { makeAutoObservable, runInAction } from 'mobx';
import { generatePath, matchPath } from 'react-router-dom';
import { createBrowserHistory, createHashHistory } from 'history';
import _ from 'lodash';
import queryString from 'query-string';
import { getInitConfig } from 'ziphy-web-shared';
import { PUBLIC_URL, routerSearchParams } from 'ziphy-web-shared/basic/helpers';
class RouterStore {
    constructor(router) {
        this.matched = [];
        this.instance = router;
        this.location = router.state.location;
        makeAutoObservable(this);
    }
    get routesById() {
        return _.keyBy(this.instance.routes, 'id');
    }
    get matchedRoutes() {
        return this.matched.map((x) => (Object.assign(Object.assign({}, x), { routeConfig: this.routesById[x.routeConfigId] })));
    }
    get tags() {
        return this.matchedRoutes.flatMap((x) => x.routeConfig.tags);
    }
    get params() {
        return this.matchedRoutes.reduce((acc, item) => {
            acc = Object.assign(Object.assign({}, acc), item.match.params);
            return acc;
        }, {});
    }
    get history() {
        return this.instance.state.history;
    }
    get search() {
        return this.location.search;
    }
    get searchParams() {
        return routerSearchParams({ history: this.history, search: this.search });
    }
    toPath(path, { params = {}, query: searchParams = {}, savedSearchParams = getInitConfig().savedSearchParamsList, hash = '', state = {}, } = {}) {
        if (!path)
            return; // TODO: change with undefined and check it
        const route = this.routesById[path];
        if (route) {
            path = route.path;
            savedSearchParams = [...(savedSearchParams || []), ...(route.savedSearchParams || [])];
        }
        const pathname = generatePath(path, params);
        if (savedSearchParams.length) {
            searchParams = Object.assign(Object.assign({}, _.pick(this.searchParams.getAll(), savedSearchParams)), searchParams);
        }
        return {
            pathname,
            search: queryString.stringify(searchParams),
            hash,
            state,
            url: queryString.stringifyUrl({
                url: pathname,
                query: searchParams,
                fragmentIdentifier: hash,
            }),
        };
    }
    executeAutoTarget(autoTarget, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const type = (options === null || options === void 0 ? void 0 : options.type) || 'push';
            const wvAction = autoTarget === null || autoTarget === void 0 ? void 0 : autoTarget.wvAction;
            if (typeof wvAction === 'function')
                return yield wvAction();
            const onClick = autoTarget === null || autoTarget === void 0 ? void 0 : autoTarget.onClick;
            if (typeof onClick === 'function')
                return yield onClick();
            const href = autoTarget === null || autoTarget === void 0 ? void 0 : autoTarget.href;
            if (href) {
                if (type === 'replace')
                    return window.location.replace(href);
                const target = (autoTarget === null || autoTarget === void 0 ? void 0 : autoTarget.hrefTarget) || '_self';
                const newWindow = window.open(href, target, 'noopener,noreferrer');
                if (newWindow)
                    newWindow.opener = null;
                return;
            }
            const to = autoTarget === null || autoTarget === void 0 ? void 0 : autoTarget.to;
            if (to) {
                if (type === 'replace')
                    return $router.history.replace(to);
                return $router.history.push(to);
            }
        });
    }
    hasTag(value) {
        return this.tags.includes(value);
    }
    listenHistory() {
        this.history.listen((location) => {
            runInAction(() => {
                this.findMatched(location);
                this.location = location;
            });
        });
        this.findMatched(this.location);
    }
    findMatched(location) {
        let result = [];
        this.instance.routes.forEach((item) => {
            if (item.path === '*' && result.length) {
                return;
            }
            const matched = matchPath(location.pathname, {
                path: item.path,
                exact: item.exact,
                strict: false,
            });
            if (matched) {
                matched.params = parseRouteParams(matched.params);
                result.push({ routeConfigId: item.id, match: matched });
            }
        });
        this.matched = result;
    }
}
export let $router;
export function initRouter(hashRouter, routes, listener = true) {
    if (!$router) {
        const history = hashRouter
            ? createHashHistory()
            : createBrowserHistory({ basename: PUBLIC_URL });
        $router = new RouterStore({ routes, state: { history, location: history.location } });
        if (listener) {
            $router.listenHistory();
        }
    }
    return $router;
}
export function prepareRoutesList(baseList = [], extraList = []) {
    let result = baseList;
    extraList.forEach((extraRoute) => {
        const index = result.findIndex((x) => x.id === extraRoute.id);
        if (index !== -1) {
            if (extraRoute.isRemoved) {
                result.splice(index, 1);
            }
            else {
                result[index] = Object.assign(Object.assign({}, result[index]), extraRoute);
            }
        }
        else {
            result.push(extraRoute);
        }
    });
    return _.orderBy(result, (x) => x.path.length, 'desc');
}
export function parseRouteParams(params = {}) {
    const result = params;
    Object.keys(params).forEach((key) => {
        const numericValue = parseFloat(params[key]);
        result[key] = isNaN(numericValue) ? params[key] : numericValue;
    });
    return result;
}
