var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { BaseService } from '../baseService';
export class ChatMessages extends BaseService {
    constructor(props) {
        super(props);
    }
    create(params, options) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            let res;
            res = yield this.api.fetchLoggedV3('chat_messages.create', params, options);
            return Object.assign(Object.assign({}, res), { preparedValue: (_a = res.prepared) === null || _a === void 0 ? void 0 : _a.chatMessage });
        });
    }
    read(params, options) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            let res;
            res = yield this.api.fetchLoggedV3('chat_messages.read', params, options);
            return Object.assign(Object.assign({}, res), { preparedValue: (_a = res.prepared) === null || _a === void 0 ? void 0 : _a.chatMessage });
        });
    }
    delete(params, options) {
        return __awaiter(this, void 0, void 0, function* () {
            let res;
            res = yield this.api.fetchLoggedV3('chat_messages.delete', params, options);
            return res;
        });
    }
    list(params, options) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            let res;
            res = yield this.api.fetchLoggedV3('chat_messages.list', params, options);
            return Object.assign(Object.assign({}, res), { preparedValue: ((_a = res.prepared) === null || _a === void 0 ? void 0 : _a.chatMessages.items) || [] });
        });
    }
    /*
     * Custom Methods
     */
    getForAppointment({ appointmentId, staffOnly, }, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.list({
                filter: {
                    and: [{ eq: ['appointment_id', appointmentId] }, { eq: ['staff_only', staffOnly] }],
                },
                order: [['id', 'asc']],
                limit: 1000,
            }, options);
        });
    }
    getNewForAppointment({ appointmentId, lastId, staffOnly, }, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.list({
                filter: {
                    and: [
                        { eq: ['appointment_id', appointmentId] },
                        { eq: ['staff_only', staffOnly] },
                        { gt: ['id', lastId] },
                    ],
                },
                order: [['id', 'asc']],
                limit: 1000,
            }, options);
        });
    }
}
