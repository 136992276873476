import React from 'react'

import { resourceExtractor } from '@fhir/extractor'
import { getReferenceId } from '@fhir/fhir.helpers'

import { eventsConfig } from '@ps/config/events'

const EventsViewer = ({ type, event, resource, extractor }) => {
  if (!event || !resource) {
    return <span style={{ color: 'red' }}>{getReferenceId(event)}</span>
  }

  const viewerConfig = eventsConfig[event.configId]?.viewer || {}
  const View = viewerConfig?.[type]

  if (View) {
    if (!extractor) {
      extractor = resourceExtractor(resource)
    }

    return <View event={event} resource={resource} extractor={extractor} />
  }

  return <></>
}

export default EventsViewer
