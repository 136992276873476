var CodingOrganizations;
(function (CodingOrganizations) {
    CodingOrganizations["SNOMED"] = "snomed";
    CodingOrganizations["LOINC"] = "loinc";
    CodingOrganizations["CPT"] = "cpt";
    CodingOrganizations["CVX"] = "cvx";
    CodingOrganizations["RxNorm"] = "rxnorm";
    CodingOrganizations["ICD10"] = "icd10";
    CodingOrganizations["ICD10CM"] = "icd10cm";
    CodingOrganizations["ICD10PCS"] = "icd10pcs";
    CodingOrganizations["HCPCS"] = "hcpcs";
    CodingOrganizations["NDC"] = "ndc";
    CodingOrganizations["HL7"] = "HL7";
    CodingOrganizations["THL7"] = "terminologyHL7";
    CodingOrganizations["NPI"] = "npi";
    CodingOrganizations["FHIR_ZIPHY"] = "fhirZiphy";
    CodingOrganizations["FHIR_LABQ"] = "fhirLabq";
})(CodingOrganizations || (CodingOrganizations = {}));
export const VOCAB_TO_CODING_ORGANIZATIONS = {
    SNOMED: CodingOrganizations.SNOMED,
    LOINC: CodingOrganizations.LOINC,
    CPT4: CodingOrganizations.CPT,
    CVX: CodingOrganizations.CVX,
    RxNorm: CodingOrganizations.RxNorm,
    ICD10: CodingOrganizations.ICD10,
    ICD10CM: CodingOrganizations.ICD10CM,
    ICD10PCS: CodingOrganizations.ICD10PCS,
    HCPCS: CodingOrganizations.HCPCS,
    NDC: CodingOrganizations.NDC,
};
