import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import i18n from 'i18next';
import { isAbsoluteEmpty } from 'ziphy-web-shared/basic/helpers';
import styles from './dtl.module.scss';
const DtlItem = ({ className, label, children, valueFallBack = 'label.none', hideIfEmpty = false, valueClassName, }) => {
    const { t } = useTranslation();
    if (hideIfEmpty && isAbsoluteEmpty(children)) {
        return _jsx(_Fragment, {});
    }
    return (_jsxs("div", Object.assign({ className: classNames(styles.item, className) }, { children: [_jsx("div", Object.assign({ className: styles.label }, { children: label })), !isAbsoluteEmpty(children) ? (_jsx("div", Object.assign({ className: classNames(styles.value, valueClassName) }, { children: children }))) : (_jsx("div", Object.assign({ className: classNames(styles.value, styles.muted) }, { children: i18n.exists(valueFallBack) ? t(valueFallBack) : valueFallBack })))] })));
};
export default DtlItem;
