import i18n from 'i18next';
import _ from 'lodash';
import { getLocalAddressType } from 'ziphy-web-shared/basic/entities/addresses';
import { isAbsoluteEmpty, splitAtIndex } from 'ziphy-web-shared/basic/helpers';
import { defaultLang } from 'ziphy-web-shared/basic/utils/i18n';
import { decodeAndFormatDateObject, formatDate, } from 'ziphy-web-shared/basic/utils/time';
import { i18textOrHumanized } from './extractor.helpers';
import { findCodingOrganizationConfigBySystem, isCodeableConcept } from './fhir.helpers';
import { getPhoneNumberDetails, getUrlWithAuth, joinValuesToString } from './fhir.utils';
/*
 * FHIR Value converters
 */
// https://hl7.org/fhir/r4/extensibility.html#Extension
export function prepareFhirValue(r) {
    if (typeof r === 'undefined') {
        return;
    }
    const valueMapping = {
        valueBoolean: prepareValueBoolean,
        valueCode: prepareValueCode,
        valueDate: prepareValueDate,
        valueDateTime: prepareValueDateTime,
        valueString: prepareValueString,
        valueCodeableConcept: prepareValueCodeableConcept,
        valuePeriod: prepareValuePeriod,
        valueQuantity: prepareValueQuantity,
        valueReference: prepareValueReference,
    };
    for (const key in valueMapping) {
        if (key in r && r[key] !== undefined) {
            return valueMapping[key](r[key]);
        }
    }
}
// valueBase64Binary: base64Binary
// valueBoolean: boolean
export function prepareValueBoolean(val, trueLabel = 'label.yes', falseLabel = 'label.no') {
    if (typeof val === 'undefined') {
        return;
    }
    const value = val;
    const valueClear = val;
    return {
        type: 'valueBoolean',
        value,
        valueClear,
        asString: value ? i18textOrHumanized(trueLabel, 'yes') : i18textOrHumanized(falseLabel, 'no'),
        asStringClear: valueClear
            ? i18textOrHumanized(trueLabel, 'yes')
            : i18textOrHumanized(falseLabel, 'no'),
    };
}
// valueCanonical: canonical
// valueCode: code (only if the extension definition provides a fixed binding to a suitable set of codes)
export function prepareValueCode(val) {
    if (typeof val === 'undefined') {
        return;
    }
    const value = val;
    const valueClear = val;
    return {
        type: 'valueCode',
        value,
        valueClear,
        asString: value,
        asStringClear: valueClear,
    };
}
// valueDate: date
// valueDateTime: dateTime
export function prepareValueDate(val, format = 'date') {
    if (typeof val === 'undefined') {
        return;
    }
    const value = val;
    const valueClear = val;
    return {
        type: 'valueDate',
        value,
        valueClear,
        asString: val && formatDate(val, { format }),
        asStringClear: valueClear && formatDate(valueClear, { format }),
    };
}
export function prepareValueDateTime(val, format) {
    if (typeof val === 'undefined') {
        return;
    }
    const value = val;
    const valueClear = val;
    return {
        type: 'valueDateTime',
        value,
        valueClear,
        asString: val && formatDate(val, { format }),
        asStringClear: valueClear && formatDate(valueClear, { format }),
    };
}
// valueDecimal: decimal
// valueId: id
// valueInstant: instant
// valueInteger: integer
// valueMarkdown: markdown
// valueOid: oid
// valuePositiveInt: positiveInt
// valueString: string
export function prepareValueString(val) {
    if (typeof val === 'undefined') {
        return;
    }
    const value = val;
    const valueClear = _.replace(val, /[^+\d.]/g, '');
    return {
        type: 'valueString',
        value,
        valueClear,
        asString: value,
        asStringClear: valueClear,
    };
}
// valueTime: time
// valueUnsignedInt: unsignedInt
// valueUri: uri
// valueUrl: url
// valueUuid: uuid
// valueAddress: Address
// valueAge: Age
// valueAnnotation: Annotation
// valueAttachment: Attachment
// valueCodeableConcept: CodeableConcept
export function prepareValueCodeableConcept(val) {
    var _a;
    let codeObj = (_a = prepareCodeableConcept(val)) === null || _a === void 0 ? void 0 : _a[0];
    if (typeof codeObj === 'undefined') {
        return;
    }
    return {
        type: 'valueCodeableConcept',
        value: codeObj.code,
        valueClear: codeObj.code,
        codeObj,
        asString: codeObj.display,
        asStringClear: codeObj.asString,
    };
}
// valueCoding: Coding
// valueContactPoint: ContactPoint
// valueCount: Count
// valueDistance: Distance
// valueDuration: Duration
// valueHumanName: HumanName
// valueIdentifier: Identifier
// valueMoney: Money
// valuePeriod: Period
export function prepareValuePeriod(val, format = 'date') {
    const start = prepareValueDateTime(val === null || val === void 0 ? void 0 : val.start);
    const end = prepareValueDateTime(val === null || val === void 0 ? void 0 : val.end);
    if (isAbsoluteEmpty(start) && isAbsoluteEmpty(end)) {
        return;
    }
    return {
        type: 'valuePeriod',
        start: start,
        end: end,
        startAsString: (start === null || start === void 0 ? void 0 : start.asString) && formatDate(start === null || start === void 0 ? void 0 : start.asString, { format }),
        endAsString: (end === null || end === void 0 ? void 0 : end.asString) && formatDate(end === null || end === void 0 ? void 0 : end.asString, { format }),
    };
}
// valueQuantity: Quantity
export function prepareValueQuantity(val) {
    if (typeof val === 'undefined') {
        return;
    }
    const value = val.value;
    const valueClear = val.value;
    const unit = val.unit;
    const comparator = val.comparator || '';
    return {
        type: 'valueQuantity',
        value,
        valueClear,
        unit,
        comparator,
        asString: comparator + [value, unit].filter((x) => !isAbsoluteEmpty(x)).join(' '),
        asStringClear: [valueClear, unit].filter((x) => !isAbsoluteEmpty(x)).join(' '),
    };
}
// valueRange: Range
// valueRatio: Ratio
// valueReference: Reference - a reference to another resource
export function prepareValueReference(val) {
    if (typeof val === 'undefined') {
        return;
    }
    const value = val;
    const valueClear = val === null || val === void 0 ? void 0 : val.reference;
    return {
        type: 'valueReference',
        value,
        valueClear,
        asString: val.display,
        asStringClear: val.display,
    };
}
// valueSampledData: SampledData
// valueSignature: Signature
// valueTiming: Timing
// valueContactDetail: ContactDetail
// valueContributor: Contributor
// valueDataRequirement: DataRequirement
// valueExpression: Expression
// valueParameterDefinition: ParameterDefinition
// valueRelatedArtifact: RelatedArtifact
// valueTriggerDefinition: TriggerDefinition
// valueUsageContext: UsageContext
// valueDosage: Dosage
// valueMeta: Meta
/*
 * Other converters for part of resources
 */
export function prepareCustomDate(val) {
    if (typeof val === 'undefined') {
        return;
    }
    let result;
    if (val.dateTime) {
        result = { date: val.dateTime, isFull: true, asString: '' };
    }
    else if (val.string) {
        result = { date: val.string, isFull: false, asString: '' };
    }
    if (_.isEmpty(result)) {
        return;
    }
    result.asString = decodeAndFormatDateObject({ dateString: result.date, isFull: result.isFull });
    return result;
}
export function prepareCodeableConcept(data) {
    let result = [];
    if (isAbsoluteEmpty(data)) {
        return result;
    }
    let concept;
    if (isCodeableConcept(data)) {
        concept = data;
    }
    else {
        concept = { coding: Array.isArray(data) ? data : [data] };
    }
    const coding = concept === null || concept === void 0 ? void 0 : concept.coding;
    const text = (concept === null || concept === void 0 ? void 0 : concept.text) || '';
    if (!_.isEmpty(coding)) {
        _.forEach(coding, (codingItem) => {
            const codingOrganization = findCodingOrganizationConfigBySystem(codingItem.system);
            result.push({
                text: text,
                code: codingItem.code,
                display: codingItem.display || text,
                system: codingItem.system,
                organizationId: codingOrganization === null || codingOrganization === void 0 ? void 0 : codingOrganization.id,
                asString: [codingOrganization === null || codingOrganization === void 0 ? void 0 : codingOrganization.label, codingItem.code].filter((x) => x).join(' '),
            });
        });
    }
    else if (text) {
        result.push({
            text: text,
            code: '',
            display: text,
            system: '',
            organizationId: undefined,
            asString: text,
        });
    }
    return result;
}
export function prepareIdentifier(data) {
    var _a;
    if (isAbsoluteEmpty(data)) {
        return;
    }
    const codingOrganization = findCodingOrganizationConfigBySystem(data === null || data === void 0 ? void 0 : data.system);
    return {
        system: data === null || data === void 0 ? void 0 : data.system,
        value: data === null || data === void 0 ? void 0 : data.value,
        type: (_a = prepareCodeableConcept(data.type)) === null || _a === void 0 ? void 0 : _a[0],
        use: data === null || data === void 0 ? void 0 : data.use,
        organizationId: codingOrganization === null || codingOrganization === void 0 ? void 0 : codingOrganization.id,
        asString: data === null || data === void 0 ? void 0 : data.value,
    };
}
export function prepareExtension(data) {
    const codingOrganization = findCodingOrganizationConfigBySystem(data === null || data === void 0 ? void 0 : data.url);
    const valueObj = prepareFhirValue(data);
    if (isAbsoluteEmpty(data)) {
        return;
    }
    return {
        url: data === null || data === void 0 ? void 0 : data.url,
        type: valueObj === null || valueObj === void 0 ? void 0 : valueObj.type,
        value: valueObj === null || valueObj === void 0 ? void 0 : valueObj.value,
        valueObj: valueObj,
        organizationId: codingOrganization === null || codingOrganization === void 0 ? void 0 : codingOrganization.id,
        asString: valueObj === null || valueObj === void 0 ? void 0 : valueObj.asString,
    };
}
export const addressFormatList = [
    // US format
    // 500 7th Avenue, apartment: 23, floor: 55, directions: opposite to drug store, New York, NY 10018, United States
    {
        lang: 'en-US',
        format: [
            '{{line}}{{separator}}',
            '{{city}}{{separator}}',
            '{{state}}',
            '{{postalCode}}{{separator}}',
            '{{country}}',
        ],
        formatCleaned: [
            '{{line}}{{separator}}',
            '{{city}}{{separator}}',
            '{{state}}',
            '{{postalCode}}{{separator}}',
            '{{country}}',
        ],
    },
];
export function prepareAddress(data) {
    if (data === undefined) {
        return;
    }
    const getAsString = (address) => {
        const separator = i18n.t('address_data.separator');
        let formatData = _.find(addressFormatList, (x) => x.lang === i18n.language);
        if (!formatData) {
            formatData = _.find(addressFormatList, (x) => x.lang === defaultLang);
        }
        const formatMask = formatData.format;
        let resultArr = [];
        formatMask.forEach((formatString) => {
            _.forEach(address, (paramValue, paramKey) => {
                if (_.includes(formatString, `{{${paramKey}}}`)) {
                    if (_.isEmpty(paramValue)) {
                        return;
                    }
                    if (paramKey === 'line') {
                        paramValue = paramValue;
                        paramValue = paramValue.join(`${separator} `);
                    }
                    paramValue = paramValue;
                    formatString = formatString.replace(`{{${paramKey}}}`, paramValue);
                    formatString = formatString.replace(`{{separator}}`, separator);
                    // check if we need to translate strings with i18n
                    const stringToTranslateRE = /{{\w*}}/gi;
                    if (stringToTranslateRE.test(formatString)) {
                        const paramForTr = 'address_data.' + formatString.match(stringToTranslateRE)[0].slice(2, -2);
                        formatString = formatString.replace(stringToTranslateRE, i18n.t(paramForTr));
                    }
                    resultArr.push(formatString);
                }
            });
        });
        let result = resultArr.join(' ');
        // trim trailing separator
        result = _.trim(result, separator).trim();
        return result;
    };
    const parsedLine = {
        lineOne: '',
        lineTwo: '',
        fullLineAsString: '',
    };
    if (!_.isEmpty(data.line)) {
        parsedLine.lineOne = data.line[0];
        parsedLine.fullLineAsString = data.line[0];
        if (data.line.length > 1) {
            parsedLine.lineTwo = data.line[1];
            parsedLine.fullLineAsString += `${i18n.t('address_data.separator')} ${data.line[1]}`;
        }
    }
    return {
        id: data.id,
        city: data.city,
        country: data.country,
        district: data.district,
        line: data.line,
        postalCode: data.postalCode,
        state: data.state,
        text: data.text,
        type: data.type,
        use: data.use,
        asStringLineOne: parsedLine.lineOne,
        asStringLineTwo: parsedLine.lineTwo,
        asStringLine: parsedLine.fullLineAsString,
        asStringCityStateZip: getAsString({
            city: data.city,
            state: data.state,
            postalCode: data.postalCode,
        }),
        asString: getAsString(data),
        localType: getLocalAddressType(data),
    };
}
export function prepareTelecom(data) {
    if (data === undefined) {
        return;
    }
    let asString = data.value;
    let asStringClear = data.value;
    if (data.system === 'phone') {
        const prepared = getPhoneNumberDetails(data.value);
        asString = prepared.formatted;
        asStringClear = prepared.formattedClear;
    }
    return {
        rank: data.rank,
        system: data.system,
        use: data.use,
        value: data.value,
        asString: asString,
        asStringClear: asStringClear,
    };
}
export function prepareTiming(data) {
    var _a;
    if (data === undefined) {
        return;
    }
    const codeObj = (_a = prepareCodeableConcept(data === null || data === void 0 ? void 0 : data.code)) === null || _a === void 0 ? void 0 : _a[0];
    return {
        codeObj: codeObj,
        asString: codeObj === null || codeObj === void 0 ? void 0 : codeObj.display,
    };
}
export function prepareDosage(data) {
    var _a;
    if (data === undefined) {
        return;
    }
    const doseAndRate = _.map(data.doseAndRate, (x) => {
        return {
            dose: prepareValueQuantity(x.doseQuantity), // || x.doseRange,
        };
    })[0];
    const route = prepareCodeableConcept(data.route)[0];
    const site = prepareCodeableConcept(data.site)[0];
    const text = data.text;
    const timing = prepareTiming(data.timing);
    let asString = '';
    if (doseAndRate) {
        asString = joinValuesToString([
            (_a = doseAndRate.dose) === null || _a === void 0 ? void 0 : _a.asString,
            joinValuesToString([route === null || route === void 0 ? void 0 : route.display, ['(', site === null || site === void 0 ? void 0 : site.display, ')']], ' '),
            timing === null || timing === void 0 ? void 0 : timing.asString,
        ], ' • ');
    }
    else {
        // legacy
        asString = joinValuesToString([text, timing === null || timing === void 0 ? void 0 : timing.asString], ' ');
    }
    return {
        doseAndRate,
        route,
        site,
        text,
        timing,
        asString,
    };
}
export function prepareAttachment(data) {
    if (data.contentType === undefined) {
        return;
    }
    let [type, extension] = data.contentType.split('/');
    let result = {
        type,
        extension,
        contentType: data.contentType,
        url: getUrlWithAuth(data.url),
        thumb: '',
        thumb3x: '',
        originalExtension: '',
    };
    if (type === 'image') {
        const thumbSuffix = '/thumbnail';
        const url = new URL(result.url);
        const dotIndex = url.pathname.lastIndexOf('.');
        const [path, ext] = splitAtIndex(url.pathname, dotIndex);
        url.pathname = path + thumbSuffix + '.' + ext;
        result.thumb = url.href;
        url.pathname = path + thumbSuffix + '@3x.' + ext;
        result.thumb3x = url.href;
        result.originalExtension = ext;
    }
    return result;
}
export function prepareMeta(data) {
    if (data === undefined) {
        return;
    }
    return Object.assign(Object.assign({}, data), { lastUpdated: prepareValueDateTime(data.lastUpdated) });
}
