import React, { useState } from 'react'

import classNames from 'classnames'
import _ from 'lodash'
import { ProgressRing } from 'ziphy-web-shared/basic/lib/loaders'

import BaseVideo from '@library/media/mediaTypes/base/BaseVideo'

import styles from './Types.module.scss'

const Video = ({ src, contentType, time, onIsLoading }) => {
  const [isLoading, setIsLoading] = useState(true)
  const isDefault = !isLoading
  const [isError, setIsError] = useState(false)

  function changeIsLoading(value) {
    setIsLoading(value)

    if (_.isFunction(onIsLoading)) {
      onIsLoading(value)
    }
  }

  return (
    <div
      className={classNames(
        styles.video,
        isLoading && styles.isLoading,
        isDefault && styles.isDefault,
        isError && styles.isError,
      )}
    >
      <div className="embed-responsive embed-responsive-16by9">
        <BaseVideo
          src={src}
          contentType={contentType}
          time={time}
          controls={false}
          onIsLoading={changeIsLoading}
          onIsError={setIsError}
        />
        {isLoading && (
          <ProgressRing
            radius={21}
            progress={25}
            spin={true}
            centered={true}
            mode={'secondary'}
            className={styles.loader}
          />
        )}
      </div>
    </div>
  )
}

export default Video
