import { getSnapshot, types } from 'mobx-state-tree';
import _ from 'lodash';
import { getUuid, isAbsoluteEmpty } from 'ziphy-web-shared/basic/helpers';
import { prepValuePresets } from './Form.config';
import FormHelpers from './Form.helpers';
const { getValidResult, prepareRules, presetHandler, updateStoreValue, validByRule } = FormHelpers;
const Field = types
    .model({
    id: '',
    counter: types.number,
    name: types.identifier,
    value: types.frozen(),
    isValid: true,
    isInvalid: false,
    errors: types.array(types.string),
    isChanged: false,
    isDeleted: false,
    changeTime: '',
    customParams: types.optional(types.frozen(), {}),
    group: '',
    customParamsRules: types.frozen(),
    customParamsValidity: types.frozen(),
    prepValue: types.optional(types.enumeration('PrepValue', Object.keys(prepValuePresets)), 'value'),
    output: types.union(types.array(types.string), types.string, types.undefined),
    rules: types.frozen(),
    store: types.frozen(),
    storeUpdateMethod: types.frozen(),
    initialState: types.frozen(),
})
    .actions((self) => ({
    afterCreate() {
        const json = getSnapshot(self);
        self.initialState = _.pick(json, [
            'id',
            'group',
            'value',
            'changeTime',
            'isChanged',
            'isDeleted',
            'customParams',
        ]);
    },
    resetToInitialState(include = []) {
        const exclude = ['customParams'].filter((x) => !_.includes(include, x));
        const filteredItems = _.omit(self.initialState, exclude);
        _.forEach(filteredItems, (value, key) => {
            self[key] = value;
        });
    },
    setErrors(errors) {
        self.errors = errors;
        self.isValid = _.isEmpty(errors);
        self.isInvalid = !_.isEmpty(errors);
    },
    setCustomParamValidity(key, value) {
        self.customParamsValidity = Object.assign(Object.assign({}, self.customParamsValidity), { [key]: value });
    },
}))
    .actions((self) => ({
    setIsChanged() {
        const compareList = ['value', 'isDeleted', 'customParams'];
        if (_.isEqual(_.pick(self, compareList), _.pick(self.initialState, compareList))) {
            self.resetToInitialState();
        }
        else {
            self.isChanged = true;
            self.changeTime = new Date().toISOString();
        }
    },
}))
    .actions((self) => ({
    setValue(value) {
        self.value = value;
        self.setIsChanged();
        self.isInvalid = false;
        self.isValid = true;
        updateStoreValue({
            value: self.value,
            fieldName: self.name,
            store: self.store,
            storeUpdateMethod: self.storeUpdateMethod,
        });
    },
    setIsDeleted(value = false) {
        self.isDeleted = value;
        self.setIsChanged();
    },
    setCustomParams(customParamsObj) {
        self.customParams = customParamsObj;
        _.forEach(self.customParams, (paramValue, paramKey) => {
            self.customParamsValidity = Object.assign(Object.assign({}, self.customParamsValidity), { [paramKey]: true });
        });
        self.setIsChanged();
    },
    setSpecificCustomParam(key, value) {
        self.customParams = Object.assign(Object.assign({}, self.customParams), { [key]: value });
        self.customParamsValidity = Object.assign(Object.assign({}, self.customParamsValidity), { [key]: true });
        self.setIsChanged();
    },
}))
    .actions((self) => ({
    onChange(value) {
        const prepValue = presetHandler(value, self.prepValue, 'prepValue');
        self.setValue(prepValue);
    },
}))
    .views((self) => ({
    getOutputValue() {
        return presetHandler(self.value, self.output, 'output');
    },
}));
const Form = types
    .model({
    counter: 1,
    _fields: types.optional(types.map(Field), {}),
    isRegistered: false,
})
    .actions((self) => ({
    addField(name, data) {
        let usedValue = data.value;
        if (data.store) {
            if (!isAbsoluteEmpty(data.value))
                updateStoreValue({
                    value: usedValue,
                    fieldName: name,
                    store: data.store,
                    storeUpdateMethod: data.storeUpdateMethod,
                });
            else if (_.isUndefined(data.value)) {
                usedValue = data.store[name];
            }
        }
        let customParamsValidity = {};
        let customParamsRules = {};
        _.forEach(data.customParamsRules, (paramRules, paramKey) => {
            customParamsRules[paramKey] = prepareRules(paramRules);
        });
        _.forEach(data.customParams, (paramValue, paramKey) => {
            customParamsValidity[paramKey] = true;
        });
        self._fields.set(name, Field.create(Object.assign(Object.assign({}, data), { customParamsValidity: customParamsValidity, counter: self.counter, id: data.id || getUuid(), name, value: usedValue, rules: prepareRules(data.rules), customParamsRules })));
        self.counter = self.counter + 1;
    },
    validate(name, withCustomParams = false) {
        const errors = [];
        const field = self._fields.get(name);
        _.forEach(field.rules, (rule) => {
            const result = validByRule(field.value, rule, field.rules, self);
            if (!result.isValid) {
                errors.push(result.error);
            }
        });
        field.setErrors(errors);
        if (withCustomParams) {
            _.forEach(field.customParamsRules, (paramRules, paramKey) => {
                _.forEach(paramRules, (rule) => {
                    const result = validByRule(field.customParams[paramKey], rule, paramRules, self);
                    if (!result.isValid) {
                        field.setCustomParamValidity(paramKey, false);
                    }
                });
            });
        }
        return getValidResult(errors);
    },
    getOutputValue(name) {
        const field = self._fields.get(name);
        return field.getOutputValue();
    },
    forceError(name, value = true) {
        const field = self._fields.get(name);
        if (value) {
            if (field.errors.includes('force_error'))
                return false;
            else
                field.setErrors([...field.errors, 'force_error']);
        }
        else
            field.setErrors(field.errors.filter((el) => el !== 'force_error'));
    },
    deleteField(name, imitation = false) {
        if (imitation) {
            const field = self._fields.get(name);
            field.setIsDeleted(true);
        }
        else {
            self._fields.delete(name);
        }
    },
}))
    .actions((self) => ({
    addFieldToGroup(groupId, data) {
        self.addField(groupId + self.counter, Object.assign(Object.assign({}, data), { group: groupId }));
    },
    validateAll(withCustomParams = false) {
        const errors = [];
        self._fields.forEach((value, key) => {
            const result = self.validate(key, withCustomParams);
            if (!result.isValid)
                errors.push(Object.assign({ key: key }, result));
        });
        return getValidResult(errors);
    },
}))
    .actions((self) => ({
    register(items) {
        if (!self.isRegistered) {
            _.forEach(items, (value, key) => {
                if (_.isArray(value)) {
                    _.forEach(value, (groupValue) => self.addFieldToGroup(key, groupValue));
                }
                else {
                    self.addField(key, value);
                }
            });
            self.isRegistered = true;
        }
    },
}))
    .views((self) => ({
    getGroup(name) {
        let group = [];
        self._fields.forEach((value) => {
            if (value.group === name)
                group.push(value);
        });
        return group;
    },
    get groups() {
        let result = {};
        self._fields.forEach((value) => {
            if (value.group) {
                if (result[value.group]) {
                    result[value.group].push(value);
                }
                else {
                    result[value.group] = [value];
                }
            }
        });
        return result;
    },
    get fields() {
        let result = {};
        self._fields.forEach((field, name) => {
            result[name] = field;
        });
        return result;
    },
    get values() {
        return _.mapValues(self._fields.toJSON(), (field, name) => self.getOutputValue(name));
    },
    get isChanged() {
        return _.some(self._fields.toJSON(), (x) => x.isChanged);
    },
}));
export default Form;
