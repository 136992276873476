import { useEffect, useState } from 'react';
import { useFirstRender } from '.';
export const useUpdateCallback = (callback) => {
    const isFirstRender = useFirstRender();
    const [updater, setUpdater] = useState(0);
    useEffect(() => {
        if (!isFirstRender) {
            callback();
        }
    }, [updater]);
    return () => setUpdater((x) => x + 1);
};
