export const ENUMS_ADDRESS_LOCAL_TYPE = {
    residential: {
        id: 'residential',
        tKey: 'enums.address.local_type.residential',
    },
    billing: {
        id: 'billing',
        tKey: 'enums.address.local_type.billing',
    },
    mailing: {
        id: 'mailing',
        tKey: 'enums.address.local_type.mailing',
    },
};
