import React from 'react'

import { useTranslation } from 'react-i18next'

import classNames from 'classnames'
import { $auth } from 'ziphy-web-shared/basic/entities/auth'
import { formatDate } from 'ziphy-web-shared/basic/utils/time'

import ShowMoreText from '@library/showMoreText/ShowMoreText'

import { resourceExtractor } from '@fhir/extractor'

import { openEventModal } from '@ps/helpers/events'
import EventsViewer from '@ps/library/eventsViewer/EventsViewer'

const AppointmentsTablePage = ({ pageItems }) => {
  const { t } = useTranslation()

  return (
    <>
      {pageItems.map(({ event, resource }) => {
        const extractor = resourceExtractor(resource)
        if (!extractor) {
          return <></>
        }
        const { asString, provider } = extractor

        return (
          <div
            key={event.id}
            className={classNames('h_table-tr', 'h_table-tr--' + event.rowType)}
            onClick={() => openEventModal([{ event, resource }])}
          >
            <div className="h_table-td h_table-td--delimiter" />
            <div className="h_table-td h_table-td--label">{t(event.label)}</div>
            <div className="h_table-td h_table-td--details">
              <div className="font-weight-600">
                <ShowMoreText buttonClassName="mt-5">
                  {Number(provider?.actor?.asString?.zid) === $auth.user.id && (
                    <>
                      <span className="badge-self_reported">{t('ps.label.my_appointment')}</span>{' '}
                    </>
                  )}
                  <EventsViewer
                    type="tableDetails"
                    event={event}
                    resource={resource}
                    extractor={extractor}
                  />
                </ShowMoreText>
              </div>
            </div>
            <div className="h_table-td h_table-td--code">{asString.combinedStatus}</div>
            <div className="h_table-td h_table-td--date">
              <span className="event_date">
                {formatDate(event.effectiveDate, { format: 'date' })}
              </span>
            </div>
          </div>
        )
      })}
    </>
  )
}

export default AppointmentsTablePage
